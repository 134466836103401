import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import InfoSubmissionModal from './modal/info-submission-modal';

class AdditionalInfo extends Component {
	constructor(props) {
		super(props);

		this.setState({
			isLoading: false,
			additionalDocuments: [],
			openInfoSubmissionModal: false,
			infoList: [],
		});
	}

	componentWillMount() {
		let documentsWithoutInput = [];

		for (let i = 0; i < this.props.confirmation.additionalDocuments.length; i++) {
			const document = this.props.confirmation.additionalDocuments[i];

			if (!document.input) {
				document.index = i;
				documentsWithoutInput.push(document);
			}
		}

		let infoList = [];
		const DESCRIPTION_FOR_FILE =
			'Prašome įkelti dokumentus. Galite įkelti ne daugiau kaip penkis failus.';
		const DESCRIPTION_FOR_TEXT = 'Prašome pateikti informaciją';

		for (let document of documentsWithoutInput) {
			infoList.push({
				title: document.info,
				type: document.type,
				description: document.type === 'file' ? DESCRIPTION_FOR_FILE : DESCRIPTION_FOR_TEXT,
				location: String(documentsWithoutInput.indexOf(document)),
				required: true,
				maxFiles: document.type === 'file' ? 5 : 0,
			});
		}

		this.setState({ additionalDocuments: documentsWithoutInput, infoList });
	}

	handleDocumentUpload = (event, index) => {
		if (event.target.files[0].size > 4000000) {
			return toast.error('Failas yra per didelis');
		}

		let additionalDocuments = this.state.additionalDocuments;
		additionalDocuments[index].input = event.target.files[0];

		this.setState({
			additionalDocuments,
		});
	};

	handleChange = (event, index) => {
		let additionalDocuments = this.state.additionalDocuments;
		additionalDocuments[index][event.target.name] = event.target.value;

		this.setState({
			additionalDocuments,
		});
	};

	checkIfDisabled = () => {
		let additionalDocuments = this.state.additionalDocuments;
		let isDisabled = false;

		for (let i = 0; i < additionalDocuments.length; i++) {
			let document = additionalDocuments[i];

			if (!document.input) {
				isDisabled = true;
			}
		}

		if (additionalDocuments.length < 1) {
			isDisabled = true;
		}

		return isDisabled;
	};

	handleSubmit = async (submittedInfo) => {
		const { confirmation } = this.props;
		this.setState({ isLoading: true });

		return fetch(
			process.env.REACT_APP_API + '/api/creditConfirmations/' + confirmation._id + '/addInfo',
			{
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token'),
				},
				body: JSON.stringify(submittedInfo),
			},
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko išsiųsti papildomos informacijos');
				}

				return window.location.reload();
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		if (!this.state.additionalDocuments || this.state.additionalDocuments.length === 0) {
			return (
				<small>
					Informacija sėkmingai atnaujinta. Kredito bendrovė išnagrinės Jūsų paraišką ir pateiks
					pasiūlymą.
				</small>
			);
		}

		return (
			<>
				{this.state.openInfoSubmissionModal && (
					<InfoSubmissionModal
						containerState={this.state}
						setContainerState={(state) => this.setState(state)}
						infoList={this.state.infoList}
						handleSubmit={this.handleSubmit}
					/>
				)}

				<ul>
					{this.state.additionalDocuments.map((document, index) => {
						return (
							<li key={index}>
								<Typography>{document.info}</Typography>
							</li>
						);
					})}
				</ul>

				<Button
					variant='contained'
					color='primary'
					onClick={() =>
						this.setState({
							openInfoSubmissionModal: true,
						})
					}
					disabled={this.state.isLoading}
				>
					Įkelti papildomą informaciją
				</Button>
			</>
		);
	}
}

export default AdditionalInfo;
