import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CloudUpload } from '@material-ui/icons';

function AdditionalDocumentsModal({
	requiredDocuments,
	state,
	setState,
	additionalDocumentsModalOpen,
	handleDocumentUpload,
	removeUploadedFile,
	handleSubmit,
}) {
	const handleClose = () => {
		setState({
			additionalDocumentsModalOpen: false,
		});
	};

	const currentDocument = requiredDocuments[state.activeStep];

	return (
		<Dialog
			open={additionalDocumentsModalOpen}
			onClose={handleClose}
			aria-labelledby='form-dialog-title'
			maxWidth={'sm'}
			fullWidth={true}
		>
			<DialogTitle id='form-dialog-title'>
				<Stepper alternativeLabel activeStep={state.activeStep}>
					{requiredDocuments.map((document, index) => (
						<Step key={index}>
							<StepLabel>{document.title}</StepLabel>
						</Step>
					))}

					<Step key={requiredDocuments.length}>
						<StepLabel>Tapatybės patvirtinimas</StepLabel>
					</Step>
				</Stepper>
			</DialogTitle>
			<DialogContent className='text-center'>
				<DialogContentText className='text-left'>{currentDocument.description}</DialogContentText>

				<div
					style={{
						border: '2px solid #3f51b5',
						padding: '10px',
						borderRadius: '10px',
					}}
				>
					{/* <Typography variant='h6'>Įkelti dokumentai</Typography> */}
					{state[currentDocument.location].length > 0 && (
						<div className='row'>
							{state[currentDocument.location].map((documentFile, index) => (
								<div
									style={{
										wordWrap: 'break-word',
									}}
									className='text-left col-12'
								>
									<Typography variant='overline'>{documentFile.name}</Typography>{' '}
									<a
										onClick={() => removeUploadedFile(index, currentDocument.location)}
										style={{ color: 'red', cursor: 'pointer' }}
										href='#'
									>
										<i class='fas fa-trash-alt'></i>
									</a>
								</div>
							))}
						</div>
					)}
					<div>
						<IconButton
							variant='contained'
							component='label'
							color='primary'
							disabled={state[currentDocument.location].length >= currentDocument.maxFiles}
						>
							<CloudUpload
								style={{
									height: 128,
									width: 128,
								}}
							/>
							<input
								accept='image/*, application/pdf'
								type='file'
								style={{ display: 'none' }}
								onChange={(event) => handleDocumentUpload(event, currentDocument.location)}
								disabled={state[currentDocument.location].length >= currentDocument.maxFiles}
							/>
						</IconButton>
					</div>

					<div>
						<small>
							Maksimalus vieno failo dydis 4MB. Galimi formatai: .jpg, .jpeg, .gif, .png ir .pdf.
						</small>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={state.activeStep === 0 || state.isLoading}
					onClick={(event) => {
						return setState({
							activeStep: state.activeStep - 1,
						});
					}}
					color='primary'
				>
					Atgal
				</Button>
				<div style={{ flex: '1 0 0' }} />

				<Button
					disabled={
						(state[currentDocument.location].length < 1 && currentDocument.required) ||
						state.isLoading
					}
					onClick={(event) => {
						if (state.activeStep === requiredDocuments.length - 1) {
							return handleSubmit(event, true);
						}

						return setState({
							activeStep: state.activeStep + 1,
						});
					}}
					color='primary'
				>
					Tęsti
				</Button>
				<Button diasbled={state.isLoading} onClick={handleClose} color='primary'>
					Išeiti
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AdditionalDocumentsModal;
