import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import NativeSelect from '@material-ui/core/NativeSelect';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Clear, Add } from '@material-ui/icons';

const listOfAvailableDocuments = [
	'Banko sąskaitos išrašas',
	'Darbo sutarties kopija',
	'Sutuoktinio darbo sutarties kopija',
	'Tarnybinio pažymėjimo foto',
	'Turimų ir refinansuoti norimų paskolų sutartys',
	'Asmens dokumento foto',
	'Sutuoktinio asmens dokumento foto',
	'Pažyma iš Sodros apie priskirtą išmoką už vaiko priežiūros atostogas',
	'Individualios veiklos/ verslo liudijimo pažyma',
	'Metinė pajamų deklaracija',
	'Pajamų/išlaidų žurnalo kopija',
	'Pinigų priėmimo kvitai ',
	'Sąskaitos faktūros ',
	'Patvarkymas dėl vykdomosios bylos uždarymo',
];

class LackingInfoEditModal extends Component {
	constructor() {
		super();

		this.state = {
			isDisabled: false,
			requiredDocuments: [
				{
					selected: '',
					entered: '',
				},
			],
		};
	}

	handleChange = (event, index) => {
		event.preventDefault();

		let rows = [...this.state.rows];

		rows[index][event.target.id] = event.target.value;

		this.setState({
			rows,
		});
	};

	handleEnter = () => {
		let containerState = this.props.getContainerState();
		let { request } = containerState;

		let { requiredDocuments } = request.creditConfirmations[0] || [];

		requiredDocuments = requiredDocuments.map((document) => {
			if (listOfAvailableDocuments.includes(document)) {
				return {
					selected: document,
					entered: '',
				};
			} else {
				// custom
				return {
					selected: 'Kita',
					entered: document,
				};
			}
		});

		this.setState({
			requiredDocuments,
		});

		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	checkIfDisabled = () => {
		const { requiredDocuments } = this.state;

		if (!requiredDocuments || requiredDocuments.length < 1) {
			return true;
		}

		let anyEmpty = false;

		for (let i = 0; i < requiredDocuments.length; i++) {
			const document = requiredDocuments[i];

			if (
				!document.selected ||
				(document.selected === 'Kita' && (!document.entered || document.entered === 'Kita'))
			) {
				anyEmpty = true;
			}
		}

		return anyEmpty;
	};

	handleClose = () => {
		this.props.setContainerState({
			lackingInfoEditModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleAccept = (event) => {
		event.preventDefault();
		this.setState({ isDisabled: true });

		let containerState = this.props.getContainerState();
		let { request } = containerState;

		let creditConfirmation = request.creditConfirmations[0];

		let { requiredDocuments } = this.state;

		requiredDocuments = requiredDocuments.map((document) => {
			if (document.selected === 'Kita') {
				return document.entered;
			}

			return document.selected;
		});

		creditConfirmation.requiredDocuments = requiredDocuments;

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations/' + creditConfirmation._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify(creditConfirmation),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko atnaujinti');
				}

				this.props.getRequests('', false, true);
				toast.success('Sėkmingai atnaujinta');
				return this.handleClose();
			})
			.catch((error) => {
				return toast.error('Nepavyko atnaujinti');
			})
			.finally(() => {
				this.setState({ isDisabled: false });
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.lackingInfoEditModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				fullWidth={true}
				maxWidth={'sm'}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Trūksta informacijos</DialogTitle>

				<DialogContent>
					<DialogContentText>
						Įveskite trūkstamo dokumento pavadinimą arba įrašykite, kokią papildomą informaciją
						klientas turi pateikti. Po patvirtinimo paraiška bus perkelta į skiltį "Trūksta
						informacijos", o vartotojas bus informuotas
					</DialogContentText>
					<Table className='w-100'>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '95%' }}>Dokumento tipas</TableCell>

								<TableCell
									style={{
										width: '5%',
									}}
								>
									<IconButton
										onClick={() => {
											const { requiredDocuments } = this.state;

											requiredDocuments.push({
												selected: '',
												entered: '',
											});

											this.setState({ requiredDocuments });
										}}
										margin='dense'
									>
										<Add />
									</IconButton>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{this.state.requiredDocuments.map((document, index) => (
								<TableRow key={index}>
									<TableCell component='th' scope='row'>
										<NativeSelect
											required
											controlId='documentType'
											onChange={(event) => {
												const { value } = event.target;
												const { requiredDocuments } = this.state;

												requiredDocuments[index].selected = value;
												requiredDocuments[index].entered = '';

												this.setState({ requiredDocuments });
											}}
											value={document.selected}
											inputProps={{
												name: 'type',
												id: 'type',
											}}
											fullWidth
										>
											<option value='' selected disabled></option>

											{listOfAvailableDocuments.map((availableDocument, index) => (
												<option value={availableDocument} key={index}>
													{availableDocument}
												</option>
											))}
										</NativeSelect>

										{document.selected === 'Kita' && (
											<>
												<div className='w-100 mt-2'>
													<TextField
														required
														type='text'
														fullWidth
														value={document.entered}
														onChange={(event) => {
															const { value } = event.target;
															const { requiredDocuments } = this.state;

															requiredDocuments[index].entered = value;
															this.setState({ requiredDocuments });
														}}
														margin='dense'
														label='Dokumento pavadinimas'
													/>
												</div>
											</>
										)}
									</TableCell>

									<TableCell>
										<IconButton
											color='secondary'
											onClick={() => {
												const { requiredDocuments } = this.state;

												requiredDocuments.splice(index, 1);

												this.setState({
													requiredDocuments,
												});
											}}
											disabled={this.state.requiredDocuments.length === 1}
										>
											<Clear />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.handleAccept}
						color='primary'
						disabled={this.state.isDisabled || this.checkIfDisabled()}
					>
						Patvirtinti
					</Button>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default LackingInfoEditModal;
