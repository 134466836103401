import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Timer from 'react-compound-timer';
import { toast } from 'react-toastify';

class SmartidConfirmationModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: true,
			errored: false,
			complete: false,
		};
	}

	componentDidMount() {
		this.handleConfirmation();
	}

	handleConfirmation = async () => {
		const { requestConfirmation } = this.props;

		this.setState({
			isLoading: true,
		});

		try {
			const sessionResponse = await fetch(
				process.env.REACT_APP_API +
					'/api/requestConfirmations/' +
					requestConfirmation._id +
					'/smartid/session',
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			).then((response) => response.json());

			if (sessionResponse.error) {
				return this.setState({
					errored: sessionResponse.error,
					isLoading: false,
				});
			}

			this.setState({
				errored: false,
				code: sessionResponse.code,
			});

			const authenticationResponse = await await fetch(
				process.env.REACT_APP_API +
					'/api/requestConfirmations/' +
					requestConfirmation._id +
					'/smartid/authentication',
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
					body: JSON.stringify({
						sessionId: sessionResponse.sessionId,
					}),
				},
			).then((response) => response.json());

			if (authenticationResponse.error) {
				return this.setState({
					errored: authenticationResponse.error,
					isLoading: false,
				});
			}

			this.setState({
				errored: false,
				complete: true,
			});

			toast.success('Tapatybė sėkmingai patvirtinta');

			return setTimeout(() => window.location.reload(), 1500);
		} catch (error) {
			this.setState({
				errored: error,
			});
		} finally {
			this.setState({ isLoading: false });
		}
	};

	render() {
		const { handleClose } = this.props;

		return (
			<Dialog
				open={true}
				onClose={handleClose}
				aria-labelledby='smartid-dialog-title'
				aria-describedby='smartid-dialog-description'
			>
				<DialogTitle id='smartid-dialog-title'>Smart-ID tapatybės patvirtinimas</DialogTitle>
				<DialogContent>
					<DialogContentText id='smartid-dialog-description'>
						{this.state.isLoading && (
							<div className='text-center'>
								{this.state.code ? (
									<h1 className='text-center my-3'>{this.state.code}</h1>
								) : (
									<CircularProgress className='my-3' />
								)}

								<p className='my-3 text-justify'>
									Tapatybė tvirtinama. Suveskite savo PIN kodą Smart-ID programėlėje.
								</p>

								<Timer
									initialTime={1000 * 120}
									direction='backward'
									formatValue={(n) => {
										return Number(n) > 9 ? '' + n : '0' + n;
									}}
								>
									{() => (
										<h1 className='text-center'>
											<Timer.Minutes /> : <Timer.Seconds />
										</h1>
									)}
								</Timer>
							</div>
						)}

						{this.state.errored && (
							<>
								{this.state.errored === 'Invalid account' ? (
									<p className='mt-3 text-justify'>
										Tapatybės patvirtinti nepavyko, kadangi naudojate Smart-ID Basic paskyrą.
										Atnaujinti savo Smart-ID Basic paskyrą į visiškos prieigos Smart-ID paskyrą
										galite Smart-ID programėlėje savo telefone.
									</p>
								) : (
									<p className='mt-3 text-justify'>
										Tapatybės patvirtinti nepavyko, pabandykite dar kartą.
									</p>
								)}
							</>
						)}

						{this.state.complete && (
							<p className='mt-3 text-justify'>
								Tapatybė sekmingai patvirtinta. Neišjunkite puslapio.
							</p>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default SmartidConfirmationModal;
