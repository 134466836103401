import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Intercom from 'react-intercom';

// ASSETS
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
import 'react-toastify/dist/ReactToastify.min.css';

// ROUTES
import UserSignup from './components/authorization/signup/user/user-signup';
import UserRequestSignup from './components/authorization/signup/user/user-request-signup';
import CompanySignup from './components/authorization/signup/company/company-signup';
import Signin from './components/authorization/signin/signin';
import Forgot from './components/authorization/forgot/forgot';
import Reset from './components/authorization/reset/reset';
import Dashboard from './components/dashboard/dashboard';
import IdleLogoutAlertModal from './components/dashboard/modal/idle-logout-alert-modal';
import { Provider } from './AppContext';

class App extends Component {
	constructor() {
		super();

		this.state = {
			userDataFetched: false,
			municipalities: [],
			idleLogoutAlertModalOpen: false,
			intercom: {},
			integrations: [],
		};

		let accessToken = localStorage.getItem('token');

		if (!accessToken) {
			this.state = {
				userDataFetched: true,
				authenticated: false,
				intercom: {
					authenticated: false,
				},
			};
		} else {
			this.fetchData();
		}

		this.getMunicipalities();
	}

	startTimer = () => {
		this.timer = setTimeout(this.onIdle, 15 * 60 * 1000);
	};

	resetTimer = () => {
		clearTimeout(this.timer);
		if (this.state.idleLogoutAlertModalOpen) this.onActive();

		this.startTimer();
	};

	onIdle = () => {
		this.setState({
			idleLogoutAlertModalOpen: true,
		});
	};

	onActive = () => {
		this.setState({
			idleLogoutAlertModalOpen: false,
		});
	};

	componentDidMount() {
		// google analytics
		ReactGA.initialize('UA-151567819-1');
		ReactGA.pageview(window.location.pathname + window.location.search);

		// facebook pixel
		ReactPixel.init('722929731560508');
		ReactPixel.pageView();

		window.onload = this.startTimer();

		const catchEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

		catchEvents.forEach((name) => {
			window.addEventListener(name, this.resetTimer, true);
		});
	}

	getMunicipalities = () => {
		fetch(process.env.REACT_APP_API + '/api/static/lithuanianMunicipalities', {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((response) => {
				if (Array.isArray(response)) {
					this.setState({
						municipalities: response,
					});
				}
			});
	};

	fetchData = () => {
		let accessToken = localStorage.getItem('token');

		fetch(process.env.REACT_APP_API + '/api/accessTokens/' + accessToken, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					// if there is token in the response, authenticate user
					response.authenticated = true;
					let userId = response.data;

					fetch(process.env.REACT_APP_API + '/api/users/' + userId, {
						method: 'GET',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: accessToken,
						},
					})
						.then((response) => response.json())
						.then((response) => {
							if (!response.error) {
								response.authenticated = true;
								let mainResponse = response;
								fetch(process.env.REACT_APP_API + '/api/site', {
									method: 'GET',
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json',
										Authorization: accessToken,
									},
								})
									.then((response) => response.json())
									.then((response) => {
										let mergedObject = { ...mainResponse, ...response };
										mergedObject.intercom = {
											authenticated: true,
											id: mergedObject._id,
											email: mergedObject.email,
											phone: mergedObject.contactNumber,
											firstName: mergedObject.firstName,
											lastName: mergedObject.lastName,
											marketingAccept: mergedObject.marketingAccept,
											registered: true,
										};

										mergedObject.userDataFetched = true;
										this.setState(mergedObject);
										this.getIntegrations();
									});
							} else {
								// if an error occurs, clear the token as it might be invalid or expired
								localStorage.removeItem('token');

								this.setState({
									userDataFetched: true,
									authenticated: false,
									displayAlert: false,
									intercom: {
										authenticated: false,
									},
								});

								if (response.error === 'Token has expired, please login again') {
									window.location.reload();
								}
							}
						});
				} else {
					response.userDataFetched = true;

					// if an error occurs, clear the token as it might be invalid or expired
					localStorage.removeItem('token');
					window.location.reload();
				}
			})
			.catch((error) => {
				window.location.reload();
			});
	};

	getAppState = () => {
		return this.state;
	};

	setAppState = (newState) => {
		this.setState(newState);
	};

	getIntegrations = () => {
		if (!['admin', 'consultant'].includes(this.state.role)) {
			return;
		}

		fetch(process.env.REACT_APP_API + '/api/integrations', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					integrations: response,
				});
			});
	};

	initRedirect = (path) => {
		this.setState({
			redirect: true,
			redirectPath: path,
		});
	};

	render() {
		if (!this.state.userDataFetched) {
			return <div />;
		}

		// universal redirect handler
		if (this.state.redirect) {
			this.setState({
				redirect: false,
			});

			return <Redirect to={this.state.redirectPath} />;
		}

		// adding them to main object for easier reference
		let $helper = {
			getAppState: this.getAppState,
			setAppState: this.setAppState,
			initRedirect: this.initRedirect,
			fetchData: this.fetchData,
		};

		if (this.state.authenticated) {
			return (
				<Provider value={this.state}>
					{this.state.role === 'credit' && (
						<>
							<Intercom appID='muastr6j' {...this.state.intercom} alignment='left' />
						</>
					)}

					{this.state.idleLogoutAlertModalOpen && this.state.role === 'credit' && (
						<IdleLogoutAlertModal state={this.state} setState={(state) => this.setState(state)} />
					)}

					<ToastContainer
						position='top-right'
						autoClose={2000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>

					<Dashboard $helper={$helper} />
				</Provider>
			);
		} else {
			return (
				<Provider value={this.state}>
					<Intercom appID='muastr6j' {...this.state.intercom} alignment='left' />

					<ToastContainer
						position='top-right'
						autoClose={2000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>

					<Switch>
						<Route exact path='/' render={() => <Signin $helper={$helper} />} />
						<Route exact path='/signup' render={() => <UserSignup $helper={$helper} />} />
						<Route
							exact
							path='/requestSignup'
							render={() => <UserRequestSignup $helper={$helper} />}
						/>
						<Route exact path='/companySignup' render={() => <CompanySignup $helper={$helper} />} />
						<Route exact path='/forgot' render={() => <Forgot $helper={$helper} />} />
						<Route exact path='/reset' render={(props) => <Reset $helper={$helper} {...props} />} />
						<Route exact render={() => <Redirect to='/' />} />
					</Switch>
				</Provider>
			);
		}
	}
}

export default App;
