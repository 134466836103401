import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AcceptConfirmationModal from './modal/accept-confirmation-modal';
import Tooltip from '@material-ui/core/Tooltip';
import { Info, AccessTime, Clear } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import LogoApproved from '../../../assets/img/approved.png';
import LogoCanceled from '../../../assets/img/canceled.png';
import LogoWaiting from '../../../assets/img/waiting.png';
import AdditionalInfo from './additional-info';

const styles = {
	offerContainer: {
		borderBottom: '1px solid #e1e1e1',
	},
	sideBorder: {
		borderRight: '1px solid #e1e1e1',
	},
	button: {
		backgroundColor: '#0080FF',
		color: '#fff',
	},
	img: {
		width: '30px',
		height: '30px',
		marginBottom: '8px',
		marginRight: '10px',
	},
	img_mobile: {
		width: '30px',
		height: '30px',
		marginBottom: '3px',
		marginRight: '10px',
	},
	confirmWrap: {
		display: 'flex',
		justifyContent: 'center',
	},
	confirmBox: {
		display: 'flex',
		alignItems: 'center',
	},
	confirmHeader: {
		width: 'min-content',
	},
	midSize: {
		fontSize: '1.3rem',
	},
	smallSize: {
		fontSize: '0.9rem',
	},
};

class CompanyConfirmation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openAcceptDialog: false,
			confirmation: props.confirmation,
		};
	}

	getContainerState = () => {
		return this.state;
	};

	setContainerState = (state) => {
		this.setState(state);
	};

	getCompanyImage = (creditCompany) => {
		try {
			return require(`../../../assets/img/companies/${creditCompany.slug}.png`);
		} catch (err) {
			return 'http://placehold.jp/d4d4d4/003366/300x100.png?text=' + creditCompany.slug;
		}
	};

	render() {
		let { confirmation, creditCompany, request } = this.props;

		if (!confirmation) {
			return (
				<div className={`${this.props.classes.offerContainer} container-fluid`}>
					<div className='row mb-1 credit-requests-container-content px-4 py-3'>
						<div className='col-2'>
							<img
								src={this.getCompanyImage(creditCompany)}
								className='img-fluid'
								alt={creditCompany.name + '-img'}
							/>
						</div>
						<div className='col-10 my-auto'>
							<Typography>
								{creditCompany.visible ? (
									<>
										<AccessTime className='mr-1' />
										Jūsų paraiška apdorojama. Išanalizavusi Jūsų duomenis, kredito įstaiga pateiks
										paskolos pasiūlymą.
									</>
								) : (
									<>
										<Clear className='mr-1' style={{ color: 'red' }} />
										Apgailestaujame, ši kredito įstaiga paskolos Jums suteikti negali, kadangi Jūsų
										paraiška neatitinka įstaigos reikalavimų.
									</>
								)}
							</Typography>
						</div>
					</div>
					<div className='row credit-requests-container-content-mobile py-4 px-2'>
						<div className='col-12 text-center mb-3'>
							<img
								src={this.getCompanyImage(creditCompany)}
								className='img-fluid'
								style={{
									maxHeight: '50px',
								}}
								alt={creditCompany.name + '-img'}
							/>
						</div>
						<div className='col-12 text-justify mb-3'>
							<Typography>
								{creditCompany.visible ? (
									<>
										<AccessTime className='mr-1' />
										Jūsų paraiška apdorojama. Išanalizavusi Jūsų duomenis, kredito įstaiga pateiks
										paskolos pasiūlymą.
									</>
								) : (
									<>
										<Clear className='mr-1' style={{ color: 'red' }} />
										Apgailestaujame, ši kredito įstaiga paskolos Jums suteikti negali, kadangi Jūsų
										paraiška neatitinka įstaigos reikalavimų.
									</>
								)}
							</Typography>
						</div>
					</div>
				</div>
			);
		}

		if (confirmation.type === 'decline') {
			return (
				<div className={`${this.props.classes.offerContainer} container-fluid`}>
					<div className='row mb-1 credit-requests-container-content px-4 py-3'>
						<div className='col-2'>
							<img
								src={this.getCompanyImage(creditCompany)}
								className='img-fluid'
								alt={creditCompany.name + '-img'}
							/>
						</div>
						<div className='col-10 my-auto'>
							<Typography>
								<Clear className='mr-1' style={{ color: 'red' }} />
								Apgailestaujame, ši kredito įstaiga paskolos Jums suteikti negali, kadangi Jūsų
								paraiška neatitinka įstaigos reikalavimų.
							</Typography>

							{confirmation.reason && (
								<Typography variant='caption'>{confirmation.reason}</Typography>
							)}
						</div>
					</div>
					<div className='row credit-requests-container-content-mobile py-4 px-2'>
						<div className='col-12 text-center mb-3'>
							<img
								src={this.getCompanyImage(creditCompany)}
								className='img-fluid'
								style={{
									maxHeight: '50px',
								}}
								alt={creditCompany.name + '-img'}
							/>
						</div>
						<div className='col-12 text-justify mb-3'>
							<Typography>
								<Clear className='mr-1' style={{ color: 'red' }} />
								Apgailestaujame, ši kredito įstaiga paskolos Jums suteikti negali, kadangi Jūsų
								paraiška neatitinka įstaigos reikalavimų.
							</Typography>

							{confirmation.reason && (
								<Typography variant='caption'>{confirmation.reason}</Typography>
							)}
						</div>
					</div>
				</div>
			);
		}

		if (confirmation.type === 'lacking-info') {
			return (
				<div className={`${this.props.classes.offerContainer} container-fluid`}>
					<div className='row mb-1 credit-requests-container-content px-4 py-3'>
						<div className='col-2'>
							<img
								src={this.getCompanyImage(creditCompany)}
								className='img-fluid'
								alt={creditCompany.name + '-img'}
							/>
						</div>
						<div className='col-10 my-auto'>
							<Typography>
								<Info className='mr-1' color='primary' />
								Tam, kad kredito įstaiga galėtų pateikti Jums pasiūlymą, kredito įstaigai reikalinga
								papildoma informacija. Prašome pateikti šią informaciją:
							</Typography>

							<br />

							<AdditionalInfo confirmation={confirmation} />
						</div>
					</div>
					<div className='row credit-requests-container-content-mobile py-4 px-2'>
						<div className='col-12 text-center mb-3'>
							<img
								src={this.getCompanyImage(creditCompany)}
								className='img-fluid'
								style={{
									maxHeight: '50px',
								}}
								alt={creditCompany.name + '-img'}
							/>
						</div>
						<div className='col-12 text-justify mb-3'>
							<Typography>
								<Info className='mr-1' color='primary' />
								Tam, kad kredito įstaiga galėtų pateikti Jums pasiūlymą, kredito įstaigai reikalinga
								papildoma informacija. Prašome pateikti šią informaciją:
							</Typography>

							<AdditionalInfo confirmation={confirmation} />
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className={`${this.props.classes.offerContainer} container-fluid`}>
				<AcceptConfirmationModal
					confirmation={confirmation}
					creditCompany={creditCompany}
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
					$helper={this.props.$helper}
				/>
				<div className='row mb-1 credit-requests-container-content px-4 py-3'>
					<div className='col-2 my-auto'>
						<img
							src={this.getCompanyImage(creditCompany)}
							className='img-fluid'
							alt={creditCompany.name + '-img'}
						/>
					</div>
					<div className='col-2 my-auto'>
						<Typography variant='h5'>
							{/* {confirmation ? confirmation.creditOfferFrom : request.creditAmount}{' '} */}
							{confirmation.type === 'accept' ? confirmation.creditOfferFrom : ' - '}
							<span className={this.props.classes.midSize}> €</span>
						</Typography>
						{confirmation.creditOfferFrom !== confirmation.creditOfferTo && (
							<Typography className={this.props.classes.smallSize}>
								Iki:{' '}
								{confirmation && confirmation.type === 'accept'
									? confirmation.creditOfferTo + ' €'
									: '- €'}{' '}
								<Tooltip
									title='Maksimali paskolos suma, kurią gali pasiūlyti kredito įstaiga. Paskolos sąlygos gali keistis.'
									placement='top'
								>
									<Info
										className='user-request-info-tooltip'
										style={{
											width: 18,
											height: 18,
										}}
									/>
								</Tooltip>
							</Typography>
						)}
					</div>
					<div className='col-2 my-auto'>
						<Typography variant='h5'>
							{confirmation && confirmation.type === 'accept' ? (
								<>{confirmation.creditLength} </>
							) : (
								'-'
							)}{' '}
							<span className={this.props.classes.midSize}>mėn.</span>
						</Typography>
					</div>
					<div className='col-2 my-auto'>
						{confirmation.type === 'accept' &&
						request.creditType === 'car' &&
						confirmation.isCarLoan ? (
							<Typography variant='h5'>-</Typography>
						) : (
							<>
								<Typography variant='h5'>
									{confirmation && confirmation.type === 'accept'
										? confirmation.interestRate
										: '- '}
									<span className={this.props.classes.midSize}> %</span>
								</Typography>
								<Typography variant='caption'>
									BVKKMN: {confirmation.BVKKMN || ' 0'}%{' '}
									<Tooltip
										title='BVKKMN - bendra vartojimo kredito kainos metinė norma (metinė palūkanų norma ir administraciniai mokesčiai, išreiškiami procentais).'
										placement='top'
									>
										<Info
											className='user-request-info-tooltip'
											style={{
												marginTop: '-2px',
												width: 14,
												height: 14,
											}}
										/>
									</Tooltip>
								</Typography>
							</>
						)}
					</div>
					<div className={`${this.props.classes.sideBorder} col-2 my-auto`}>
						<Typography variant='h5'>
							{confirmation && confirmation.type === 'accept' ? confirmation.monthlyPayment : '- '}{' '}
							<span className={this.props.classes.midSize}>€ / mėn.</span>
						</Typography>

						{confirmation.type && (
							<div>
								<fieldset
									style={{
										border: '1px solid #2f3359',
										borderRadius: '3px',
										padding: '5px',
									}}
								>
									<legend
										style={{
											fontSize: '11px',
											color: '#2f3359',
											width: 'inherit',
											padding: '0 10px',
											borderBottom: 'none',
										}}
									>
										Papildomi vienkartiniai mokesčiai
									</legend>

									<div
										style={{
											marginTop: '-15px',
										}}
									>
										<Typography variant='caption'>
											Sutarties mokestis:{' '}
											<span
												style={{
													whiteSpace: 'nowrap',
												}}
											>
												{confirmation.fee || ' -'} €
											</span>
										</Typography>
										{creditCompany.isCreditUnion && (
											<>
												<div>
													<Typography variant='caption'>
														Kredito unijos stojimo mokestis:{' '}
														<span
															style={{
																whiteSpace: 'nowrap',
															}}
														>
															{confirmation.entranceFee || ' -'} €
														</span>
													</Typography>
												</div>
												<div>
													<Typography variant='caption'>
														Kredito unijos pajinis įnašas:{' '}
														<span
															style={{
																whiteSpace: 'nowrap',
															}}
														>
															{confirmation.share || ' -'} €
														</span>
													</Typography>
												</div>
											</>
										)}
									</div>
								</fieldset>
							</div>
						)}
					</div>
					<div className={`${this.props.classes.confirmWrap} col-2`}>
						{confirmation && confirmation.type === 'accept' && (
							<div>
								<div className={`${this.props.classes.confirmBox}`}>
									<img
										src={LogoApproved}
										className={`${this.props.classes.img} img-fluid`}
										alt='approved-logo'
									/>
									<h6 className={`${this.props.classes.confirmHeader}`}>
										{this.props.selected ? 'Pasirinktas' : 'Patvirtintas'}
									</h6>
								</div>
								<div>
									<Button
										fullWidth
										variant='contained'
										className={this.props.classes.button}
										onClick={() => {
											this.setState({
												openAcceptDialog: true,
											});
										}}
										disabled={this.props.selected}
									>
										PASIRINKTI
									</Button>
								</div>
							</div>
						)}

						{confirmation && confirmation.type === 'decline' && (
							<div>
								<div className={`${this.props.classes.confirmBox}`}>
									<img
										src={LogoCanceled}
										className={`${this.props.classes.img} img-fluid`}
										alt='canceled-logo'
									/>
									<h6 className={`${this.props.classes.confirmHeader}`}>Atmesta</h6>
								</div>
								<div>
									<Button
										fullWidth
										variant='contained'
										className={this.props.classes.button}
										disabled
										onClick={() => {
											this.setState({
												openAcceptDialog: true,
											});
										}}
									>
										PASIRINKTI
									</Button>
								</div>
							</div>
						)}

						{((confirmation && confirmation.type === 'lacking-info') ||
							(!confirmation.type && creditCompany.visible)) && (
							<div>
								<div className={`${this.props.classes.confirmBox}`}>
									<img
										src={LogoWaiting}
										className={`${this.props.classes.img} img-fluid`}
										alt='waiting-logo'
									/>
									<h6 className={`${this.props.classes.confirmHeader}`}>
										{confirmation && confirmation.type === 'lacking-info'
											? 'Trūksta informacijos'
											: 'Laukiamas atsakymas'}
									</h6>
								</div>
								<div>
									<Button
										fullWidth
										variant='contained'
										className={this.props.classes.button}
										disabled
									>
										PASIRINKTI
									</Button>
								</div>
							</div>
						)}
					</div>

					{confirmation && confirmation.type === 'lacking-info' && (
						<div className='col-10'>
							<AdditionalInfo confirmation={confirmation} />
						</div>
					)}

					<div className='col-10 mt-2 text-justify'>
						<Typography variant='caption'>{creditCompany.description}</Typography>
					</div>

					{confirmation.type === 'accept' && confirmation.comment && (
						<div className='col-10 mt-2'>
							<fieldset
								style={{
									border: '1px solid #2f3359',
									borderRadius: '3px',
									padding: '5px',
									height: '100%',
								}}
							>
								<legend
									style={{
										fontSize: '11px',
										color: '#2f3359',
										width: 'inherit',
										padding: '0 10px',
										borderBottom: 'none',
									}}
								>
									Kredito įstaigos komentaras
								</legend>

								<div
									style={{
										marginTop: '-15px',
									}}
								>
									<Typography
										variant='caption'
										style={{
											wordBreak: 'break-all',
										}}
									>
										{confirmation.comment || 'Komentaro nėra.'}
									</Typography>
								</div>
							</fieldset>
						</div>
					)}
				</div>

				<div className='row credit-requests-container-content-mobile py-4 px-2'>
					<div className='col-12 text-center mb-3'>
						<img
							src={this.getCompanyImage(creditCompany)}
							className='img-fluid'
							alt={creditCompany.name + '-img'}
							style={{
								maxHeight: '50px',
							}}
						/>
					</div>
					<div className='col-6 credit-request-table-heading pr-0'>
						<Typography>Kompanija</Typography>
					</div>
					<div className='col-6 credit-request-table-text pl-0 pr-0'>
						<Typography>{creditCompany.name}</Typography>
					</div>
					<div className='col-6 credit-request-table-heading mt-2 pr-0'>
						<Typography>Pasiūlymas</Typography>
					</div>
					<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
						<Typography>
							{confirmation.type === 'accept' ? confirmation.creditOfferFrom : '-'}{' '}
							<span className={this.props.classes.midSize}>€</span>
						</Typography>
					</div>

					{confirmation.creditOfferFrom !== confirmation.creditOfferTo && (
						<>
							<div className='col-6 credit-request-table-heading mt-2 pr-0'>
								<Typography>Maksimali suma</Typography>
							</div>
							<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
								<Typography className={this.props.classes.smallSize}>
									{confirmation && confirmation.type === 'accept'
										? confirmation.creditOfferTo + ' €'
										: '- €'}{' '}
									<Tooltip
										title='Maksimali paskolos suma, kurią gali pasiūlyti kredito įstaiga. Paskolos sąlygos gali keistis.'
										placement='top'
									>
										<Info
											className='user-request-info-tooltip'
											style={{
												marginTop: '-2px',
												width: 14,
												height: 14,
											}}
										/>
									</Tooltip>
								</Typography>
							</div>
						</>
					)}

					<div className='col-6 credit-request-table-heading mt-2 pr-0'>
						<Typography>Įmoka</Typography>
					</div>
					<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
						<Typography>
							{confirmation && confirmation.type === 'accept' ? confirmation.monthlyPayment : '- '}{' '}
							<span className={this.props.classes.midSize}>€ / mėn.</span>
						</Typography>
					</div>
					<div className='col-6 credit-request-table-heading mt-2 pr-0'>
						<Typography>Terminas</Typography>
					</div>
					<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
						<Typography>
							{confirmation && confirmation.type === 'accept' ? confirmation.creditLength : '-'}{' '}
							<span className={this.props.classes.midSize}>mėn.</span>
						</Typography>
					</div>
					{confirmation.type === 'accept' && confirmation.comment ? (
						<>
							<div className='col-12 credit-request-table-heading mt-2 pr-0'>
								<Typography>Kredito įstaigos komentaras</Typography>
							</div>
							<div className='col-12 credit-request-table-text mt-2'>
								<Typography>{confirmation.comment || 'Komentaro nėra.'}</Typography>
							</div>
						</>
					) : (
						<>
							<div className='col-6 credit-request-table-heading mt-2 pr-0'>
								<Typography>Palūkanos</Typography>
							</div>
							<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
								<Typography>
									{confirmation && confirmation.type === 'accept' ? confirmation.interestRate : '-'}{' '}
									<span className={this.props.classes.midSize}>%</span>
								</Typography>
							</div>
							<div className='col-6 credit-request-table-heading mt-2 pr-0'>
								<Typography>BVKKMN</Typography>
							</div>
							<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
								<Typography>
									{confirmation && confirmation.type === 'accept' ? confirmation.BVKKMN : '-'}{' '}
									<span className={this.props.classes.midSize}>%</span>{' '}
									<Tooltip
										title='BVKKMN - bendra vartojimo kredito kainos metinė norma (metinė palūkanų norma ir administraciniai mokesčiai, išreiškiami procentais).'
										placement='top'
									>
										<Info
											className='user-request-info-tooltip'
											style={{
												marginTop: '-2px',
												width: 14,
												height: 14,
											}}
										/>
									</Tooltip>
								</Typography>
							</div>
						</>
					)}

					<div className='col-6 credit-request-table-heading mt-2 pr-0'>
						<Typography>Statusas</Typography>
					</div>
					<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
						<Typography>
							{!confirmation.type && 'Laukiamas atsakymas'}
							{this.props.selected && 'Pasirinktas'}
							{confirmation &&
								confirmation.type === 'accept' &&
								!this.props.selected &&
								'Patvirtinta'}
							{confirmation && confirmation.type === 'decline' && 'Atmesta'}
							{confirmation && confirmation.type === 'lacking-info' && 'Trūksta informacijos'}
						</Typography>
					</div>
					<div className='col-12 credit-request-table-heading mt-4 pr-0'>
						<Typography>Papildomi vienkartiniai mokesčiai</Typography>
					</div>
					<div className='col-6 credit-request-table-heading mt-2 pr-0'>
						<Typography>Sutarties mokestis</Typography>
					</div>
					<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
						<Typography>{confirmation.fee || '-'} €</Typography>
					</div>

					{creditCompany.isCreditUnion && (
						<>
							<div className='col-6 credit-request-table-heading mt-2 pr-0'>
								<Typography>Stojimo mokestis</Typography>
							</div>
							<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
								<Typography>{confirmation.entranceFee || '-'} €</Typography>
							</div>
							<div className='col-6 credit-request-table-heading mt-2 pr-0'>
								<Typography>Pajinis įnašas</Typography>
							</div>
							<div className='col-6 credit-request-table-text mt-2 pl-0 pr-0'>
								<Typography>{confirmation.share || '-'} €</Typography>
							</div>
						</>
					)}

					{confirmation.type === 'accept' && creditCompany.description && (
						<div className='col-12 mt-2 text-justify'>
							<Typography variant='caption'>{creditCompany.description}</Typography>
						</div>
					)}

					<div className='col-12 text-center mt-4 mb-3'>
						<Button
							variant='contained'
							fullWidth
							disabled={!confirmation || confirmation.type !== 'accept' || this.props.selected}
							className={this.props.classes.button}
							onClick={() => {
								this.setState({
									openAcceptDialog: true,
								});
							}}
						>
							{confirmation.type ? 'PASIRINKTI' : 'LAUKIAMAS ATSAKYMAS'}
						</Button>
					</div>

					{confirmation && confirmation.type === 'lacking-info' && (
						<div className='col-12'>
							<AdditionalInfo confirmation={confirmation} />
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(CompanyConfirmation);
