import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Add, Remove } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class EditCompanyModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			_id: null,
			name: null,
			email: null,
			slug: null,
			description: null,
			type: null,
			isCreditUnion: false,
			types: [],
			legalCompanyName: '',
			registrationNumber: '',
			legalAddress: '',
			accountNumber: '',
			swiftCode: '',
			commissionFee: '',
			agreementSignDate: '',
			agreementNumber: '',
			successCallbackUrl: '',
			hideNewRequestEmailNotification: false,
			contactVisibility: false,
			commissionStepsEnabled: false,
			commissionSteps: [
				{
					from: 0,
					fee: 5,
				},
			],
		};

		this.getCreditCompanyTypes();
	}

	getCreditCompanyTypes = () => {
		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/creditCompanyTypes', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (Array.isArray(response)) {
					this.setState({
						types: response,
					});
				} else {
					toast.error('Serverio klaida');
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		fetch(process.env.REACT_APP_API + '/api/creditCompanies/' + this.state._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				name: this.state.name,
				email: this.state.email,
				slug: this.state.slug,
				type: this.state.type,
				description: this.state.description,
				isCreditUnion: this.state.isCreditUnion,
				successCallbackUrl: this.state.successCallbackUrl,
				hideNewRequestEmailNotification: this.state.hideNewRequestEmailNotification,
				contactVisibility: this.state.contactVisibility,
				details: {
					legalCompanyName: this.state.legalCompanyName,
					registrationNumber: this.state.registrationNumber,
					legalAddress: this.state.legalAddress,
					accountNumber: this.state.accountNumber,
					swiftCode: this.state.swiftCode,
					commissionFee: this.state.commissionFee,
					agreementSignDate: this.state.agreementSignDate,
					agreementNumber: this.state.agreementNumber,
					commissionStepsEnabled: this.state.commissionStepsEnabled,
					commissionSteps: this.state.commissionStepsEnabled
						? this.state.commissionSteps
						: undefined,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error('Nepavyko atnaujinti kredito įstaigos');
				} else {
					toast.success('Sėkmingai atnaujinto kredito įstaiga');
					this.props.getCreditCompanies();

					this.props.setContainerState({
						editCompanyModalOpen: false,
					});
				}
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleDropdown = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	addCommissionStep = (event) => {
		event.preventDefault();

		const { commissionSteps } = this.state;
		const prev = commissionSteps[commissionSteps.length - 1];

		commissionSteps.push({
			from: prev.from * 2,
			fee: prev.fee + 1,
		});

		this.setState({ commissionSteps });
	};

	removeCommissionStep = (event, index) => {
		event.preventDefault();

		let { commissionSteps } = this.state;
		commissionSteps = commissionSteps.filter((_step, i) => i != index);

		this.setState({ commissionSteps });
	};

	handleCommisionStepChange = (event, index) => {
		const { name, value } = event.target;
		const { commissionSteps } = this.state;

		commissionSteps[index][name] = value === '' ? value : Number(value);
		this.setState({ commissionSteps });
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.editCompanyModalOpen}
				onClose={() => {
					this.props.setContainerState({
						editCompanyModalOpen: false,
					});
				}}
				onEnter={() => {
					this.setState({
						_id: containerState.creditCompany._id,
						name: containerState.creditCompany.name,
						email: containerState.creditCompany.email,
						slug: containerState.creditCompany.slug,
						successCallbackUrl: containerState.creditCompany.successCallbackUrl,
						hideNewRequestEmailNotification:
							containerState.creditCompany.hideNewRequestEmailNotification,
						contactVisibility: containerState.creditCompany.contactVisibility,
						type: containerState.creditCompany.type
							? containerState.creditCompany.type._id
							: 'default',
						description: containerState.creditCompany.description,
						isCreditUnion: containerState.creditCompany.isCreditUnion,
						...(containerState.creditCompany.details
							? containerState.creditCompany.details
							: {
									legalCompanyName: '',
									registrationNumber: '',
									legalAddress: '',
									accountNumber: '',
									swiftCode: '',
									commissionFee: '',
									agreementSignDate: '',
									agreementNumber: '',
									hideNewRequestEmailNotification: false,
									commissionStepsEnabled: false,
									commissionSteps: [
										{
											from: 0,
											fee: 5,
										},
									],
							  }),
					});
				}}
			>
				<DialogTitle>Kredito įstaigos redagavimas</DialogTitle>
				<form onSubmit={this.handleSubmit}>
					<DialogContent>
						{/* <DialogContentText>
							Norint sukurti naują kredito įstaigą, užpildykite apačioje esančią formą
						</DialogContentText> */}

						<div className='row'>
							<div className='col-6'>
								<TextField
									autoFocus
									margin='dense'
									id='email'
									onChange={this.handleChange}
									value={this.state.email}
									label='Pagrindinis įmonės el. paštas'
									type='email'
									fullWidth
								/>
							</div>
							<div className='col-6'>
								<TextField
									margin='dense'
									id='name'
									onChange={this.handleChange}
									value={this.state.name}
									label='Pavadinimas'
									type='text'
									fullWidth
								/>
							</div>
							<div className='col-6'>
								<TextField
									margin='dense'
									id='slug'
									onChange={this.handleChange}
									value={this.state.slug}
									label='Trumpinys'
									type='text'
									fullWidth
								/>
							</div>
							<div className='col-6'>
								<FormControl fullWidth margin='dense'>
									<InputLabel htmlFor='type'>Kredito įstaigos tipas</InputLabel>
									<Select
										required
										controlId='type'
										id='type'
										onChange={this.handleDropdown}
										value={this.state.type}
										inputProps={{
											name: 'type',
											id: 'type',
										}}
									>
										<MenuItem value='default'>Numatytasis</MenuItem>
										{this.state.types.map((type) => {
											return <MenuItem value={type._id}>{type.name}</MenuItem>;
										})}
									</Select>
								</FormControl>
							</div>
							<div className='col-12'>
								<FormControlLabel
									className='mt-2'
									control={
										<Checkbox
											onChange={() =>
												this.setState({
													isCreditUnion: !this.state.isCreditUnion,
												})
											}
											checked={this.state.isCreditUnion}
											color='primary'
										/>
									}
									label='Kredito įstaiga yra kredito unija'
								/>
							</div>
							<div className='col-12'>
								<TextField
									margin='dense'
									id='description'
									onChange={this.handleChange}
									value={this.state.description}
									label='Aprašymas'
									type='text'
									multiline
									fullWidth
								/>
							</div>
							<div className='col-6'>
								<TextField
									required
									margin='dense'
									id='legalCompanyName'
									value={this.state.legalCompanyName}
									onChange={this.handleChange}
									label='Legalus įmonės pavadinimas'
									type='text'
									fullWidth
								/>
							</div>
							<div className='col-6'>
								<TextField
									required
									margin='dense'
									id='registrationNumber'
									value={this.state.registrationNumber}
									onChange={this.handleChange}
									label='Įmonės registracijos nr.'
									type='number'
									fullWidth
								/>
							</div>
							<div className='col-12'>
								<TextField
									required
									margin='dense'
									id='legalAddress'
									value={this.state.legalAddress}
									onChange={this.handleChange}
									label='Įmonės adresas'
									type='text'
									fullWidth
								/>
							</div>
							<div className='col-12'>
								<TextField
									required
									margin='dense'
									id='accountNumber'
									value={this.state.accountNumber}
									onChange={this.handleChange}
									label='Įmonės banko saskaita'
									type='text'
									fullWidth
								/>
							</div>
							<div className='col-12'>
								<TextField
									required
									margin='dense'
									id='swiftCode'
									value={this.state.swiftCode}
									onChange={this.handleChange}
									label='Įmonės banko saskaitos SWIFT kodas'
									type='text'
									fullWidth
								/>
							</div>

							<div className='col-12'>
								<FormControlLabel
									className='mt-2'
									control={
										<Checkbox
											onChange={() =>
												this.setState({
													commissionStepsEnabled: !this.state.commissionStepsEnabled,
												})
											}
											checked={this.state.commissionStepsEnabled}
											color='primary'
										/>
									}
									label='Komisiniai laiptai'
								/>
							</div>

							{this.state.commissionStepsEnabled && (
								<div className='col-12'>
									<Table
										style={{
											width: '100%',
										}}
									>
										<TableHead
											style={{
												width: '100%',
											}}
										>
											<TableRow>
												<TableCell
													style={{
														width: '45%',
													}}
													className='pl-1'
												>
													Nuo, imtinai (€)
												</TableCell>
												<TableCell
													style={{
														width: '45%',
													}}
												>
													Komisinis (%)
												</TableCell>
												<TableCell
													style={{
														width: '10%',
													}}
												>
													<IconButton
														className='p-1'
														margin='dense'
														onClick={this.addCommissionStep}
													>
														<Add />
													</IconButton>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.commissionSteps.map((step, index) => (
												<TableRow key={index}>
													<TableCell
														style={{
															width: '45%',
														}}
														className='px-1'
													>
														<TextField
															name='from'
															label='Nuo'
															value={this.state.commissionSteps[index].from}
															onChange={(event) => this.handleCommisionStepChange(event, index)}
															// variant='outlined'
															size='small'
															margin='dense'
															fullWidth
															type='number'
														/>
													</TableCell>
													<TableCell
														style={{
															width: '45%',
														}}
													>
														<TextField
															name='fee'
															label='Komisinis'
															value={this.state.commissionSteps[index].fee}
															onChange={(event) => this.handleCommisionStepChange(event, index)}
															// variant='outlined'
															size='small'
															margin='dense'
															fullWidth
															type='number'
														/>
													</TableCell>
													<TableCell
														style={{
															width: '10%',
														}}
													>
														<IconButton
															className='p-1'
															margin='dense'
															disabled={index === 0}
															onClick={(event) => this.removeCommissionStep(event, index)}
															disableRipple
														>
															<Remove />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
							)}

							{!this.state.commissionStepsEnabled && (
								<div className='col-6'>
									<TextField
										required
										margin='dense'
										id='commissionFee'
										value={this.state.commissionFee}
										onChange={this.handleChange}
										label='Komisinis mokestis'
										type='number'
										inputProps={{
											step: '0.01',
										}}
										fullWidth
									/>
								</div>
							)}

							<div className='col-6'>
								<TextField
									required
									margin='dense'
									id='agreementNumber'
									value={this.state.agreementNumber}
									onChange={this.handleChange}
									label='Sutarties numeris'
									type='text'
									fullWidth
								/>
							</div>
							<div className='col-6'>
								<TextField
									required
									margin='dense'
									id='agreementSignDate'
									value={this.state.agreementSignDate}
									onChange={this.handleChange}
									label='Sutarties sudarymo data'
									type='text'
									helperText={'Formatas: YYYY-MM-DD'}
									fullWidth
								/>
							</div>
							<div className='col-12'>
								<TextField
									margin='dense'
									id='successCallbackUrl'
									onChange={this.handleChange}
									value={this.state.successCallbackUrl}
									label='Pasiūlymo pasirinkimo callback URL'
									type='text'
									fullWidth
								/>
							</div>

							<div className='col-12'>
								<FormControlLabel
									className='mt-2'
									control={
										<Checkbox
											onChange={() =>
												this.setState({
													hideNewRequestEmailNotification:
														!this.state.hideNewRequestEmailNotification,
												})
											}
											checked={this.state.hideNewRequestEmailNotification}
											color='primary'
										/>
									}
									label='Nesiųsti naujų paraiškų e-mail pranešimų'
								/>
							</div>

							<div className='col-12'>
								<FormControlLabel
									className='mt-2'
									control={
										<Checkbox
											onChange={() =>
												this.setState({
													contactVisibility: !this.state.contactVisibility,
												})
											}
											checked={this.state.contactVisibility}
											color='primary'
										/>
									}
									label='Rodyti kliento kontaktus (kai klientas dar nepasirinko pasiūlymo)'
								/>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								this.props.setContainerState({
									editCompanyModalOpen: false,
								});
							}}
							color='primary'
						>
							Atšaukti
						</Button>
						<Button
							disabled={this.state.email === '' || this.state.name === '' || this.state.slug === ''}
							type='submit'
							color='primary'
						>
							Patvirtinti
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

export default EditCompanyModal;
