import { Button, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../../../AppContext';
import Popconfirm from '../../shared/popconfirm';

const Integrations = ({ request }) => {
	const appContext = React.useContext(AppContext);

	if (!request.visibleCompanies) {
		request.visibleCompanies = [];
	}

	if (!request.integrationDetails) {
		request.integrationDetails = {};
	}

	const visibleIntegrations = appContext.integrations.filter((integration) =>
		// appContext.integrations.includes(company._id),
		request.visibleCompanies.includes(integration.creditCompany),
	);

	return (
		<div>
			<p>Rodomos tik tos integracijos, kurių kredito įstaigos mato šią parašką.</p>

			<table className='w-100'>
				<tr>
					<th>Pavadinimas</th>
					<th>Statusas</th>
					<th>Integracijos sistemos atsakymas</th>
				</tr>

				{visibleIntegrations.length === 0 && (
					<tr>
						<td colSpan='100%'>
							<em>Integracijų šiai paraiškai nėra.</em>
						</td>
					</tr>
				)}

				{visibleIntegrations.map((integration, index) => {
					const isSentOut = Boolean(request.integrationDetails[integration.name]);

					return (
						<tr key={index}>
							<td>{integration.name} API</td>
							<td>{isSentOut ? 'Išsiųsta' : 'Neišsiųsta'}</td>
							<td>
								<Tooltip
									placement='top'
									title={
										isSentOut
											? JSON.stringify(request.integrationDetails[integration.name], null, 4)
											: '-'
									}
								>
									<Info />
								</Tooltip>
							</td>
						</tr>
					);
				})}
			</table>

			{visibleIntegrations.length > 0 && request.status === 'waiting_offer' && (
				<Popconfirm
					trigger={
						<Button color='primary' variant='contained' className='mt-3'>
							Persiųsti paraišką
						</Button>
					}
					onOkButtonClick={async () => {
						try {
							var response = await fetch(
								process.env.REACT_APP_API + '/api/requests/' + request._id + '/reschedule',
								{
									method: 'POST',
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json',
										Authorization: localStorage.getItem('token'),
									},
								},
							).then((r) => r.json());

							if (response.error) {
								return toast.error('Nepavyko atnaujinti');
							}
						} catch {
							return toast.error('Nepavyko atnaujinti');
						}

						return toast.success('Sėkmingai atnaujinta');
					}}
					text='Paraiška bus persiųsta tik įstaigų integracijoms kurios yra pažymėtos kaip "Neišsiųsta". Įstaigos persiuntimas gali užtrukti iki 5 minučių.'
				/>
			)}
		</div>
	);
};

export default Integrations;
