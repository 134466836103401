import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Add, Edit, Clear } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import NewCreditCompanyTypeModal from './modal/new-credit-company-type-modal';
import EditCreditCompanyTypeModal from './modal/edit-credit-company-type-modal';
import DeleteCreditCompanyTypeModal from './modal/delete-credit-company-type-modal';

class CreditCompanyTypes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			typesPerPage: 15,
			page: 0,
			status: props.status,
			types: [],
			type: {},
			newCreditCompanyTypeModalOpen: false,
			editCreditCompanyTypeModalOpen: false,
			deleteCreditCompanyTypeModalOpen: false,
		};
	}

	componentWillMount() {
		// get credit company types on mount
		this.getCreditCompanyTypes();
	}

	componentDidMount() {
		document.title = 'Credit King - kredito įstaigų tipai';
	}

	getContainerState = () => {
		return this.state;
	};

	setContainerState = (state) => {
		this.setState(state);
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	getCreditCompanyTypes = () => {
		fetch(process.env.REACT_APP_API + '/api/creditCompanyTypes', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (Array.isArray(response)) {
					this.setState({
						types: response,
					});
				} else {
					toast.error('Serverio klaida');
				}
			});
	};

	render() {
		let sliceFrom = this.state.page * this.state.typesPerPage;
		let sliceTo = sliceFrom + this.state.typesPerPage;

		return (
			<div className='fade-in'>
				{this.state.newCreditCompanyTypeModalOpen && (
					<NewCreditCompanyTypeModal
						getContainerState={this.getContainerState}
						setContainerState={this.setContainerState}
						getCreditCompanyTypes={this.getCreditCompanyTypes}
					/>
				)}

				{this.state.editCreditCompanyTypeModalOpen && (
					<EditCreditCompanyTypeModal
						getContainerState={this.getContainerState}
						setContainerState={this.setContainerState}
						getCreditCompanyTypes={this.getCreditCompanyTypes}
						type={this.state.type}
					/>
				)}

				{this.state.deleteCreditCompanyTypeModalOpen && (
					<DeleteCreditCompanyTypeModal
						getContainerState={this.getContainerState}
						setContainerState={this.setContainerState}
						getCreditCompanyTypes={this.getCreditCompanyTypes}
						type={this.state.type}
					/>
				)}

				<div className='row no-gutters'>
					<div className='col-12'>
						<h1 className='app-page-title'>Kredito įstaigų tipai</h1>
					</div>
				</div>
				<Paper id='company-requests-wrapper'>
					<Table
						style={{
							minWidth: 1000,
						}}
						size='small'
					>
						<TableHead>
							<TableRow className='px-3'>
								<TableCell>Pavadinimas</TableCell>
								<TableCell align='right'>Kredito suma nuo</TableCell>
								<TableCell align='right'>Kredito suma iki</TableCell>
								<TableCell align='right'>Kredito įstaigu kiekis</TableCell>
								{/* action */}
								<TableCell align='right'>
									<Tooltip title='Naujas tipas' interactive>
										<Add
											className='new-company-add'
											onClick={() => {
												this.setState({
													newCreditCompanyTypeModalOpen: true,
												});
											}}
										/>
									</Tooltip>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.types &&
								this.state.types.slice(sliceFrom, sliceTo).map((type) => {
									return (
										<>
											<TableRow key={type._id}>
												<TableCell component='th' scope='row'>
													{type.name}
												</TableCell>
												<TableCell scope='row' align='right'>
													{type.creditAmountFrom}
												</TableCell>
												<TableCell scope='row' align='right'>
													{type.creditAmountTo}
												</TableCell>
												<TableCell scope='row' align='right'>
													0
												</TableCell>
												<TableCell scope='row' align='right'>
													<IconButton
														className='request-credit-action-btn'
														color='primary'
														onClick={() => {
															this.setState({
																type: type,
																editCreditCompanyTypeModalOpen: true,
															});
														}}
													>
														<Edit />
													</IconButton>
													<IconButton
														className='request-credit-action-btn'
														color='secondary'
														onClick={() => {
															this.setState({
																type: type,
																deleteCreditCompanyTypeModalOpen: true,
															});
														}}
													>
														<Clear />
													</IconButton>
												</TableCell>
											</TableRow>
										</>
									);
								})}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={this.state.types.length}
						rowsPerPage={this.state.typesPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</Paper>
			</div>
		);
	}
}

export default CreditCompanyTypes;
