import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Info } from '@material-ui/icons';
import moment from 'moment';
import ConfirmationInfoModal from './modal/confirmation-info-modal';

class CreditConfirmation extends Component {
	constructor() {
		super();

		this.state = {
			confirmationInfoModalOpen: false,
		};
	}

	render() {
		let { confirmation, status } = this.props;

		return (
			<TableRow>
				<TableCell>{confirmation._id}</TableCell>
				<TableCell>
					{moment(confirmation.createdAt)
						.locale('lt')
						.format('LLL')}
				</TableCell>
				<TableCell>{confirmation.creditCompany.name}</TableCell>
				<TableCell>{confirmation.creditRequest.id}</TableCell>
				{status === 'accept' && <TableCell>{confirmation.offerAccepted ? 'Taip' : 'Ne'}</TableCell>}
				{status === 'lacking-info' && <TableCell>{confirmation.isInfoSubmited? 'Taip' : 'Ne'}</TableCell>}
				<TableCell align='right'>
					{this.state.confirmationInfoModalOpen && (
						<ConfirmationInfoModal
							state={this.state}
							confirmation={confirmation}
							setState={(state) => this.setState(state)}
						/>
					)}

					{['lacking-info', 'accept'].includes(status) && (
						<IconButton
							onClick={() => {
								this.setState({
									confirmationInfoModalOpen: true,
								});
							}}
							className='p-0'
						>
							<Info />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
		);
	}
}

export default CreditConfirmation;
