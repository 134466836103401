import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

class MakeInactiveModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
		};
	}

	handleSubmit = () => {
		const { request } = this.props;

		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/status', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				status: 'inactive',
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					return window.location.reload();
				}

				toast.error('Nepavyko pakeisti statuso');
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		const { makeInactiveModalOpen, handleClose } = this.props;

		return (
			<Dialog open={makeInactiveModalOpen} fullWidth={true} maxWidth={'sm'} onClose={handleClose}>
				<DialogTitle>Ar tikrai norite padaryti paraišką nematoma?</DialogTitle>
				<DialogContent>
					<Typography>
						Pakeitus paraiškos statusą į <strong>neaktyvi</strong>, ji nebebus matoma kredito
						bendrovėms bei vartotojui.
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.handleSubmit} disabled={this.state.isLoading} color='primary'>
						Patvirtinti
					</Button>
					<Button onClick={handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default MakeInactiveModal;
