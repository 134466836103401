import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class RevertCreditDeclineModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	handleClose = () => {
		this.props.setContainerState({
			revertCreditDeclineModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleDelete = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let creditConfirmation = this.props.request.creditConfirmations[0];

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations/' + creditConfirmation._id, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					this.handleClose();

					// refresh requests
					this.props.getRequests('', false, true);
					toast.success('Sėkmingai atnaujinta');
				} else {
					toast.error('Nepavyko atnaujinti');
				}
			})
			.catch((error) => {
				console.error(error);
				toast.error('Vidinė serverio klaida');
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.revertCreditDeclineModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Atmetimo atšaukimas</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Atšaukus kredito pasiūlymo atmetimą, paraiška bus pasiekiama skiltyje "Naujos
						paraiškos".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={this.state.isLoading} onClick={this.handleDelete} color='primary'>
						Patvirtinti
					</Button>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default RevertCreditDeclineModal;
