import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class ProfileForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			firstName: props.appState.firstName,
			lastName: props.appState.lastName,
			contactNumber: props.appState.contactNumber,
			marketingAccept: [true, false].includes(props.appState.marketingAccept)
				? props.appState.marketingAccept
				: false,
			currentPassword: '',
			passwordChange: false,
			password: '',
			passwordConfirmation: '',
		};
	}

	logoutHandler = async (event) => {
		event.preventDefault();

		localStorage.removeItem('token');
		window.location.reload();
	};

	handleCheckboxChange = (event) => {
		this.setState({
			[event.target.name]: !this.state[event.target.name],
		});
	};

	checkIfDisabled = () => {
		let { appState } = this.props;

		return (
			!this.state.currentPassword ||
			(this.state.firstName === appState.firstName &&
				this.state.lastName === appState.lastName &&
				this.state.contactNumber === appState.contactNumber &&
				!this.state.passwordChange &&
				this.state.marketingAccept === appState.marketingAccept) ||
			this.state.password !== this.state.passwordConfirmation ||
			(this.state.password === '' && this.state.passwordChange)
		);
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		fetch(process.env.REACT_APP_API + '/api/users/' + this.props.appState._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify(this.state),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					toast.success('Paskyra sėkmingai atnaujinta');

					this.props.$helper.fetchData();
				} else {
					toast.error('Serverio klaida');
				}
			})
			.catch((error) => {
				toast.error('Serverio klaida');
			});
	};

	render() {
		if (this.props.appState.role === 'credit') {
			var creditCompanies = this.props.appState.creditCompanies.filter((creditCompany) => {
				return creditCompany.id === this.props.appState.creditCompany._id;
			});
		}

		return (
			<>
				<Typography
					className='desktop-content'
					variant='h4'
					style={{
						fontWeight: 'lighter',
						marginTop: '20px',
						marginBottom: '10px',
					}}
				>
					Paskyros nustatymai
				</Typography>
				<form onSubmit={this.handleSubmit}>
					<div className='row mt-2'>
						<div className='col-sm-6 col-12'>
							<TextField
								required
								fullWidth
								id='firstName'
								label='Vardas'
								type='text'
								onChange={this.handleChange}
								value={this.state.firstName}
								margin='dense'
							/>
						</div>
						<div className='col-sm-6 col-12'>
							<TextField
								required
								fullWidth
								id='lastName'
								label='Pavardė'
								type='text'
								onChange={this.handleChange}
								value={this.state.lastName}
								margin='dense'
							/>
						</div>
						<div className='col-sm-6 col-12'>
							<TextField
								fullWidth
								required
								type='number'
								id='contactNumber'
								label='Telefono numeris'
								value={this.state.contactNumber}
								onChange={this.handleChange}
								margin='dense'
								InputProps={{
									startAdornment: <InputAdornment position='start'>+370</InputAdornment>,
								}}
							/>
						</div>
						{this.props.appState.role === 'credit' && (
							<div className='col-sm-6 col-12'>
								<TextField
									fullWidth
									label='Kredito įstaiga'
									disabled
									type='text'
									value={creditCompanies[0].name}
									margin='dense'
								/>
							</div>
						)}
						<div className='col-sm-6 col-12'>
							<TextField
								required
								fullWidth
								id='currentPassword'
								label='Dabartinis slaptažodis'
								type='password'
								onChange={this.handleChange}
								value={this.state.currentPassword}
								margin='dense'
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<FormControlLabel
								control={
									<Checkbox
										color='default'
										onChange={this.handleCheckboxChange}
										checked={this.state.passwordChange}
										name='passwordChange'
									/>
								}
								label='Ar norite pakeisti dabartinį savo paskyros slaptažodį?'
								style={{
									marginTop: '5px',
									marginBottom: '-5px',
								}}
							/>
						</div>

						{this.state.passwordChange && (
							<>
								<div className='col-sm-6 col-12'>
									<TextField
										required
										fullWidth
										id='password'
										label='Naujas slaptažodis'
										type='password'
										onChange={this.handleChange}
										value={this.state.password}
										margin='dense'
									/>
								</div>
								<div className='col-sm-6 col-12'>
									<TextField
										required
										fullWidth
										id='passwordConfirmation'
										label='Pakartokite slaptažodį'
										type='password'
										error={
											this.state.password !== this.state.passwordConfirmation &&
											this.state.password.length > 0 &&
											this.state.passwordConfirmation.length
										}
										onChange={this.handleChange}
										value={this.state.passwordConfirmation}
										margin='dense'
									/>
								</div>
							</>
						)}
						<div className='col-12'>
							<FormControlLabel
								className='mt-2'
								control={
									<Checkbox
										onChange={() =>
											this.setState({
												marketingAccept: !this.state.marketingAccept,
											})
										}
										checked={this.state.marketingAccept}
										color='primary'
									/>
								}
								label='Sutinku, kad mano duomenys būtų tvarkomi tiesioginės rinkodaros tikslais'
							/>
						</div>
					</div>
					<div className='row'>
						<div className='offset-md-8 col-md-4 col-12 offset-0'>
							<Button
								fullWidth
								type='submit'
								disabled={this.checkIfDisabled()}
								variant='contained'
								color='primary'
								style={{
									marginTop: '15px',
								}}
							>
								Išsaugoti
							</Button>
						</div>
						<div id='mobile-settings-logout-btn' className='col-12'>
							<Button
								fullWidth
								variant='contained'
								color='secondary'
								onClick={this.logoutHandler}
								style={{
									marginTop: '15px',
								}}
							>
								Atsijungti
							</Button>
						</div>
					</div>
				</form>
			</>
		);
	}
}

export default ProfileForm;
