import React, { Component } from 'react';
import { toast } from 'react-toastify';
import CompanyRequests from './company-requests';
import Favicon from '../../../../assets/img/favicon.png';
import LinearLoader from '../../../shared/linear-loader';
import ReactNotifications from 'react-browser-notifications';

class CompanyRequestsRouter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			requests: [],
			isLoading: true,
			status: props.status,
			page: 0,
			filterQuery: '',
		};

		this.getRequests('?status=' + props.status);
	}

	showNotifications = () => {
		if (this.n.supported()) this.n.show();
	};

	handleClick(event) {
		window.focus();
		this.n.close(event.target.tag);
	}

	getRequests = (filterQuery, updateState = true, useCurrent = false, fromInterval = false) => {
		this.setState({
			isLoading: true,
		});

		if (updateState) {
			this.setState({
				filterQuery: filterQuery,
				page: 0,
			});
		}

		if (useCurrent) {
			filterQuery = this.state.filterQuery;
		}

		fetch(process.env.REACT_APP_API + '/api/requests' + filterQuery, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(response.error);
				} else {
					this.setState({
						requests: response,
					});

					if (response.length > 0 && fromInterval) {
						this.showNotifications();
					}
				}
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				// not loading anymore
				this.setState({
					isLoading: false,
				});
			});
	};

	componentDidMount() {
		let title = {
			canceled: 'Nepavyko susisiekti',
			waiting_offer: 'Naujos paraiškos',
			waiting_call: 'Laukia skambučio',
			waiting_sign: 'Laukia pasirašymo',
			signed: 'Pasirašytos paraiškos',
			accepted: 'Pateikti pasiūlymai',
			declined: 'Atmestos paraiškos',
			lacking_info: 'Trūksta informacijos',
		};

		document.title = 'Credit King - ' + title[this.props.status].toLowerCase();

		this.interval = setInterval(() => {
			this.getRequests(this.state.filterQuery, false, false, true);
		}, 30000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		let title = {
			canceled: 'Nepavyko susisiekti',
			waiting_offer: 'Naujos paraiškos',
			waiting_call: 'Laukia skambučio',
			waiting_sign: 'Laukia pasirašymo',
			signed: 'Pasirašytos paraiškos',
			accepted: 'Pateikti pasiūlymai',
			declined: 'Atmestos paraiškos',
			lacking_info: 'Trūksta informacijos',
		};

		if (this.state.requests) {
			return (
				<>
					{this.state.isLoading && <LinearLoader />}
					<ReactNotifications
						onRef={(ref) => (this.n = ref)} // Required
						title='Credit King' // Required
						body='Gauta nauja paraiška'
						icon={Favicon}
						tag='abcdef'
						onClick={(event) => this.handleClick(event)}
					/>
					<CompanyRequests
						requests={this.state.requests}
						title={title[this.state.status]}
						getRequests={this.getRequests}
						$helper={this.props.$helper}
						status={this.props.status}
						setState={(state) => this.setState(state)}
						page={this.state.page}
					/>
				</>
			);
		}
	}
}

export default CompanyRequestsRouter;
