import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import {
	KeyboardArrowDown,
	AssignmentLate,
	Delete,
	Bookmarks,
	Spellcheck,
	PermPhoneMsg,
	Assignment,
	AssignmentTurnedIn,
	ContactSupport,
} from '@material-ui/icons';

class MobileRequestsMenu extends Component {
	constructor() {
		super();

		this.state = {
			anchorElement: null,
		};
	}

	handleMobileMenuClose = () => {
		this.setState({
			anchorElement: null,
		});
	};

	handleMobileMenuOpen = (event) => {
		this.setState({
			anchorElement: event.target,
		});
	};

	render() {
		return (
			<>
				<span id='company-mobile-requests-navigation'>
					<IconButton aria-haspopup='true' onClick={this.handleMobileMenuOpen}>
						<KeyboardArrowDown
							style={{
								fontSize: 30,
								color: 'white',
							}}
						/>
					</IconButton>
				</span>

				<Menu
					elevation={0}
					anchorEl={this.state.anchorElement}
					open={Boolean(this.state.anchorElement)}
					getContentAnchorEl={null}
					onClose={this.handleMobileMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<NavLink
						exact={true}
						to='/requests'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<Bookmarks /> NAUJOS PARAIŠKOS
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/lacking-info'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<ContactSupport /> TRŪKSTA INFORMACIJOS
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/accepted'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<Spellcheck /> PATEIKTI PASIŪLYMAI
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/waiting-call'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<PermPhoneMsg /> LAUKIA SKAMBUČIO
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/waiting-sign'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<Assignment /> LAUKIA PASIRAŠYMO
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/signed'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<AssignmentTurnedIn /> PASIRAŠYTOS PARAIŠKOS
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/archive'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<AssignmentLate /> NEPAVYKO SUSISIEKTI
						</div>
					</NavLink>
					<NavLink
						exact={true}
						to='/requests/declined'
						className='sidenav-item'
						activeClassName='sidenav-item-active'
					>
						<div className='text-left'>
							<Delete /> ATMESTOS PARAIŠKOS
						</div>
					</NavLink>
				</Menu>
			</>
		);
	}
}

export default MobileRequestsMenu;
