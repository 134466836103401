import React, { Component } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ReactNotifications from 'react-browser-notifications';
import Favicon from '../../../assets/img/favicon.png';
import io from 'socket.io-client';
import { ArrowForwardIos, ArrowBackIos, Close } from '@material-ui/icons';
import moment from 'moment';
import 'moment/locale/lt';

import { AppContext } from '../../../AppContext';
import { toast } from 'react-toastify';

class Notifications extends Component {
	constructor() {
		super();

		this.state = {
			notifications: [],
			unseenNotifications: [],
			notificationsPopperOpen: false,
			anchorElement: null,
			page: 0,
			notificationsPerPage: 10,
			notification: '', // marks current new notification
		};
	}

	componentWillMount() {
		this.getNotifications();
		this.loadSockets();
	}

	getNotifications = () => {
		fetch(process.env.REACT_APP_API + '/api/notifications', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					notifications: response,
				});

				this.getUnseenNotifications();
			});
	};

	getUnseenNotifications = async () => {
		const unseenNotifications = this.state.notifications.filter(
			(notification) => !notification.seen,
		);

		this.setState({ unseenNotifications });
	};

	markNotificationsAsSeen = async () => {
		let ids = await this.state.unseenNotifications.map((notification) => notification._id);

		fetch(process.env.REACT_APP_API + '/api/notifications', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				ids,
				seen: true,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					this.getNotifications();
				}
			});
	};

	showNotifications = () => {
		if (this.n.supported()) this.n.show();
	};

	handleNotificationClick = (event) => {
		window.focus();
		this.n.close(event.target.tag);
	};

	deleteNotification = (index, notificationId) => {
		fetch(process.env.REACT_APP_API + '/api/notifications/' + notificationId, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko ištrinti pranešimo');
				}

				const notifications = [...this.state.notifications];
				notifications.splice(index, 1);

				this.setState({
					notifications,
				});

				return toast.success('Pranešimas sėkmingai ištrintas');
			});
	};

	loadSockets = () => {
		// loading sockets to receive notifications
		const socket = io(process.env.REACT_APP_API);

		socket.on(`${this.context._id}-notification`, (notification) => {
			this.setState({
				notification: notification.text,
				notifications: [notification, ...this.state.notifications],
			});

			this.showNotifications();
			this.getUnseenNotifications();
		});
	};

	render() {
		let { notifications, unseenNotifications, page, notificationsPerPage } = this.state;
		const maxPage = Math.ceil(notifications.length / notificationsPerPage);

		const open = Boolean(this.state.anchorElement);
		const id = open ? 'notifications-popper' : undefined;

		return (
			<>
				<Badge
					color='secondary'
					badgeContent={unseenNotifications.length}
					invisible={!unseenNotifications.length}
				>
					<span>
						<ReactNotifications
							onRef={(ref) => (this.n = ref)}
							title='Credit King'
							body={this.state.notification}
							icon={Favicon}
							tag={this.state.notification}
							onClick={(event) => this.handleNotificationClick(event)}
						/>
					</span>
					<a
						aria-describedby={id}
						href='#'
						onClick={(event) => {
							if (this.state.anchorElement) this.markNotificationsAsSeen();

							this.setState({
								anchorElement: this.state.anchorElement ? null : event.currentTarget,
								page: 0,
							});
						}}
					>
						<NotificationsIcon />
					</a>
				</Badge>

				<ClickAwayListener
					onClickAway={() => {
						this.setState({ anchorElement: null, page: 0 });
						this.markNotificationsAsSeen();
					}}
				>
					<Popper
						id={id}
						open={open}
						anchorEl={this.state.anchorElement}
						placement={'left-start'}
						style={{
							zIndex: 10000000,
							minWidth: 300,
						}}
					>
						<Paper>
							<Typography variant='caption' className='p-1'>
								Sistemos pranešimai ({unseenNotifications.length})
							</Typography>

							<hr
								style={{
									marginTop: 2,
									marginBottom: 0,
								}}
							/>

							<div>
								{notifications.length === 0 && (
									<div class='pb-3'>
										<Typography variant='caption' className='px-2'>
											Šiuo metu naujų sistemos pranešimų neturite.
										</Typography>
									</div>
								)}

								{notifications
									.slice(page * notificationsPerPage, (page + 1) * notificationsPerPage)
									.map((notification, index) => (
										<div
											className='notification-item px-2'
											key={index}
											style={{
												borderBottom: '1px solid rgba(0,0,0, 0.1)',
												background: notification.seen ? 'white' : 'rgb(218, 217, 217)',
											}}
										>
											<div>
												<div
													style={{
														display: 'inline-block',
														width: '94%',
													}}
												>
													<Typography variant='caption'>{notification.text}</Typography>
												</div>
												<div
													style={{
														display: 'inline-block',
														width: '5%',
														verticalAlign: 'top',
														paddingTop: '2px',
														textAlign: 'right',
													}}
												>
													<div
														className='cursor-pointer'
														onClick={() => this.deleteNotification(index, notification._id)}
													>
														<Close
															style={{
																fontSize: '18px',
															}}
														/>
													</div>
												</div>
											</div>
											<div className='text-right'>
												<Typography variant='caption'>
													{moment(notification.createdAt).locale('lt').format('LLL')}
												</Typography>
											</div>
										</div>
									))}

								{notifications.length > 0 && (
									<div className='row'>
										<div className='col-6 my-auto'>
											<Typography variant='caption' className='px-2'>
												{page + 1} iš {maxPage}
											</Typography>
										</div>
										<div className='col-6 text-right'>
											<IconButton
												disabled={page === 0}
												onClick={() => this.setState({ page: this.state.page - 1 })}
											>
												<ArrowBackIos style={{ fontSize: 13 }} />
											</IconButton>
											<IconButton
												disabled={page === maxPage - 1}
												onClick={() => this.setState({ page: this.state.page + 1 })}
											>
												<ArrowForwardIos style={{ fontSize: 13 }} />
											</IconButton>
										</div>
									</div>
								)}
							</div>
						</Paper>
					</Popper>
				</ClickAwayListener>
			</>
		);
	}
}

Notifications.contextType = AppContext;
export default Notifications;
