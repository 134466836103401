import React, { Component } from 'react';

class Loader extends Component {
	render() {
		return (
			<div className='text-center' id='app-loader'>
				<div className='lds-dual-ring' />
			</div>
		);
	}
}

export default Loader;
