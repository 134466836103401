import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class SignConfirmModal extends Component {
	constructor(props) {
		super();

		const { acceptedOffer } = props.request;

		this.state = {
			signedAmount: acceptedOffer.creditOfferFrom ? String(acceptedOffer.creditOfferFrom) : '0',
			signedLength: acceptedOffer.creditLength ? String(acceptedOffer.creditLength) : '0',
			signedFee: acceptedOffer.fee ? String(acceptedOffer.fee) : '0',
			signedInterestRate: acceptedOffer.interestRate ? String(acceptedOffer.interestRate) : '0',
			signedMonthlyPayment: acceptedOffer.monthlyPayment
				? String(acceptedOffer.monthlyPayment)
				: '0',
			signedBVKKMN: acceptedOffer.BVKKMN ? String(acceptedOffer.BVKKMN) : '0',
			sign_confirmed: true,
			isLoading: false,
		};
	}

	handleConfirm = (event) => {
		event.preventDefault();

		if (this.isFormValid()) {
			this.setState({ isLoading: true });

			fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id + '/sign', {
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token'),
				},
				body: JSON.stringify(this.state),
			})
				.then((response) => response.json())
				.then((response) => {
					if (!response.error) {
						this.handleClose();
						toast.success('Sėkmingai atnaujinta');

						// refresh requests
						this.props.getRequests('', false, true);
					} else {
						toast.error('Nepavyko atnaujinti');
					}
				})
				.catch((error) => {
					this.handleClose();
				})
				.finally(() => {
					this.setState({ isLoading: false });
				});
		}
	};

	isFormValid = () => {
		let {
			signedAmount,
			signedLength,
			signedFee,
			signedInterestRate,
			signedMonthlyPayment,
			signedBVKKMN,
		} = this.state;

		return (
			signedAmount !== '' &&
			signedLength !== '' &&
			signedFee !== '' &&
			signedInterestRate !== '' &&
			signedMonthlyPayment !== '' &&
			signedBVKKMN !== ''
		);
	};

	checkIfDisabled = () => {
		let {
			signedAmount,
			signedLength,
			signedFee,
			signedInterestRate,
			signedMonthlyPayment,
			signedBVKKMN,
		} = this.state;

		return (
			!signedAmount ||
			!signedLength ||
			signedFee === '' ||
			!signedInterestRate ||
			!signedMonthlyPayment ||
			(!signedBVKKMN && signedBVKKMN !== 0) ||
			signedAmount <= 0 ||
			signedLength <= 0 ||
			signedFee < 0 ||
			signedInterestRate < 0 ||
			signedMonthlyPayment <= 0 ||
			signedBVKKMN < 0
		);
	};

	handleClose = () => {
		this.setState({ isLoading: false });
		this.props.setContainerState({
			signConfirmModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.signConfirmModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
			>
				<form onSubmit={this.handleConfirm}>
					<DialogTitle id='form-dialog-title'>Pasirašymo patvirtinimas</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Norint patvirtinti pasirašymą, žemiau pateikite informaciją apie pasirašytą sutartį.
						</DialogContentText>
						<div className='row'>
							<div className='col-md-4 col-sm-6 col-12'>
								<TextField
									required
									margin='dense'
									id='signedAmount'
									label='Paskolos suma'
									type='number'
									inputProps={{
										step: '0.01',
									}}
									onChange={this.handleChange}
									value={this.state.signedAmount}
									fullWidth
								/>
							</div>
							<div className='col-md-4 col-sm-6 col-12'>
								<TextField
									required
									margin='dense'
									id='signedLength'
									label='Paskolos laikotarpis'
									type='number'
									onChange={this.handleChange}
									value={this.state.signedLength}
									fullWidth
								/>
							</div>
							<div className='col-md-4 col-sm-6 col-12'>
								<TextField
									required
									margin='dense'
									id='signedFee'
									label='Sutarties mokestis'
									type='number'
									inputProps={{
										step: '0.01',
									}}
									onChange={this.handleChange}
									value={this.state.signedFee}
									fullWidth
								/>
							</div>
							<div className='col-md-4 col-sm-6 col-12'>
								<TextField
									required
									margin='dense'
									id='signedInterestRate'
									label='Palūkanos'
									type='number'
									inputProps={{
										step: '0.01',
									}}
									onChange={this.handleChange}
									value={this.state.signedInterestRate}
									fullWidth
								/>
							</div>
							<div className='col-md-4 col-sm-6 col-12'>
								<TextField
									required
									margin='dense'
									id='signedBVKKMN'
									label='BVKKMN'
									type='number'
									min='0'
									inputProps={{
										step: '0.01',
									}}
									onChange={this.handleChange}
									value={this.state.signedBVKKMN}
									fullWidth
								/>
							</div>
							<div className='col-md-4 col-sm-6 col-12'>
								<TextField
									required
									margin='dense'
									id='signedMonthlyPayment'
									label='Menėsinė įmoka'
									type='number'
									inputProps={{
										step: '0.01',
									}}
									onChange={this.handleChange}
									value={this.state.signedMonthlyPayment}
									fullWidth
								/>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							type='submit'
							color='primary'
							disabled={this.checkIfDisabled() || this.state.isLoading}
						>
							Patvirtinti
						</Button>
						<Button onClick={this.handleClose} color='primary'>
							Atšaukti
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

export default SignConfirmModal;
