import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

class LinearLoader extends Component {
	render() {
		return (
			<LinearProgress
				style={{
					position: 'fixed',
					top: '-1px',
					left: '0px',
					width: '100vw',
					zIndex: '100000000000000',
				}}
			/>
		);
	}
}

export default LinearLoader;
