import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

class CommentModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			noteEditorOpen: false,
			noteTarget: {},
			notes: [],
		};
	}

	componentDidMount() {
		this.getNotes();
	}

	handleClose = () => {
		this.props.setState({ commentModalOpen: false });
	};

	getNotes = () => {
		const { potentialRequest } = this.props;

		fetch(process.env.REACT_APP_API + '/api/potentialRequests/' + potentialRequest._id + '/notes', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					notes: response,
				});
			});
	};

	submitNote = () => {
		const { note } = this.state.noteTarget;
		const { potentialRequest } = this.props;

		fetch(process.env.REACT_APP_API + '/api/potentialRequests/' + potentialRequest._id + '/notes', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({ note }),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) return;

				this.getNotes();
				this.setState({ noteEditorOpen: false, noteTarget: {} });
			});
	};

	editNote = (note, newNote) => {
		const { potentialRequest } = this.props;

		fetch(
			process.env.REACT_APP_API +
				'/api/potentialRequests/' +
				potentialRequest._id +
				'/notes/' +
				note._id,
			{
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token'),
				},
				body: JSON.stringify({ note: newNote }),
			},
		).then((response) => {
			this.getNotes();
			this.setState({ noteEditorOpen: false, noteTarget: {} });
		});
	};

	deleteNote = (note) => {
		const { potentialRequest } = this.props;

		fetch(
			process.env.REACT_APP_API +
				'/api/potentialRequests/' +
				potentialRequest._id +
				'/notes/' +
				note._id,
			{
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token'),
				},
			},
		)
			.then((response) => response.json())
			.then((response) => {
				this.getNotes();
			});
	};

	render() {
		let { commentModalOpen, potentialRequest } = this.props;

		return (
			<Dialog
				onClose={this.handleClose}
				aria-labelledby='customized-dialog-title'
				open={commentModalOpen}
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle id='customized-dialog-title' onClose={this.handleClose}>
					Potencialaus kliento komentaras
				</DialogTitle>
				<DialogContent>
					<div>
						<Button
							onClick={() =>
								this.setState({
									noteEditorOpen: !this.state.noteEditorOpen,
									noteTarget: {},
								})
							}
						>
							Sukurti naują komentarą
						</Button>
					</div>

					{this.state.noteEditorOpen && (
						<>
							<div className='mt-3'>
								<TextField
									label='Komentaras'
									placeholder='Įrašykite savo komentarą'
									value={this.state.noteTarget.note}
									onChange={(event) => {
										let { noteTarget } = this.state.noteTarget;

										if (!noteTarget) noteTarget = {};

										noteTarget.note = event.target.value;

										this.setState({ noteTarget });
									}}
									fullWidth
									rows={4}
									multiline
								/>
							</div>

							<div className='text-right mt-3'>
								<Button
									onClick={() =>
										this.setState({
											noteEditorOpen: false,
											noteTarget: {},
										})
									}
								>
									Atšaukti
								</Button>

								<Button
									disabled={!this.state.noteTarget.note || this.state.noteTarget.length === 0}
									onClick={this.submitNote}
								>
									Patvirtinti
								</Button>
							</div>
						</>
					)}

					<div className='mt-3'>
						{this.state.notes.map((note, index) => (
							// <div key={index}>{note.note}</div>
							<Card key={index} className='mt-3'>
								<CardContent>
									<div
										className='mb-3'
										style={{
											display: 'flex',
										}}
									>
										<span>
											<Avatar>
												{note.user.firstName[0]}
												{note.user.lastName[0]}
											</Avatar>
										</span>

										<span className='my-auto pl-3'>
											<Typography>
												{note.user.firstName} {note.user.lastName} (
												{moment(note.createdAt)
													.locale('LT')
													.format('LLL')}
												)
											</Typography>
										</span>
									</div>

									{!this.state.noteTarget || this.state.noteTarget._id !== note._id ? (
										<Typography
											onClick={() =>
												this.setState({
													noteTarget: note,
												})
											}
											style={{
												pointer: 'hover',
											}}
										>
											{note.note}
										</Typography>
									) : (
										<>
											<TextField
												label='Komentaras'
												placeholder='Įrašykite savo komentarą'
												value={this.state.noteTarget.note}
												onChange={(event) => {
													let { noteTarget } = this.state;

													if (!noteTarget) noteTarget = {};

													noteTarget.note = event.target.value;

													this.setState({ noteTarget });
												}}
												fullWidth
												rows={4}
												multiline
											/>
										</>
									)}

									{this.state.noteTarget && this.state.noteTarget._id === note._id && (
										<div className='row mt-3'>
											<div className='col-6'>
												<Button color='secondary' onClick={() => this.deleteNote(note)}>
													Ištrinti
												</Button>
											</div>

											<div className='col-6 text-right'>
												<Button
													onClick={() => {
														this.setState({
															noteTarget: {},
														});

														this.getNotes();
													}}
												>
													Atšaukti
												</Button>

												<Button
													disabled={
														!this.state.noteTarget.note || this.state.noteTarget.length === 0
													}
													onClick={() => this.editNote(note, this.state.noteTarget.note)}
												>
													Patvirtinti
												</Button>
											</div>
										</div>
									)}
								</CardContent>
							</Card>
						))}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default CommentModal;
