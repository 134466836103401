import { Tooltip } from '@material-ui/core';
import { Clear, Done, Info } from '@material-ui/icons';
import React from 'react';
import { toast } from 'react-toastify';
import Popconfirm from '../../shared/popconfirm';

const IdentityConfirmations = ({ request = {}, getRequest = () => {} }) => {
	const { personalConfirmation = {}, partnerConfirmation = {} } = request;

	const cancelIdentityConfirmation = async (requestConfirmationId) => {
		try {
			var response = await fetch(
				process.env.REACT_APP_API + '/api/requestConfirmations/' + requestConfirmationId + '/error',
				{
					method: 'PATCH',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			).then((r) => r.json());

			if (response.error) {
				return toast.error('Nepavyko atnaujinti');
			}
		} catch {
			return toast.error('Nepavyko atnaujinti');
		}

		getRequest();
		return toast.success('Sėkmingai atnaujinta');
	};

	const popConfirmText =
		'Atšaukus tapatybės patvirtinimą, tapatybės patvirtinimo klaidos statusas pasikeis į "1" ir klientui bus sugeneruojamos naujos tapatybės patvirtinimo nuorodos.';

	return (
		<div className='col-md-12 col-sm-12 col-12 mt-3'>
			<div
				className='request-section-label mb-2 p-2'
				style={{
					border: '1px solid #3f51b5',
					textTransform: 'uppercase',
				}}
			>
				<i className='fas fa-file-invoice request-section-label-icon' />
				Tapatybės patvirtinimas
			</div>
			<div className='row'>
				<div className='col-12'>
					<table
						style={{
							width: '100%',
							maxWidth: '100%',
							overflow: 'scroll',
						}}
					>
						<tr>
							<th>Tipas</th>
							<th>Vardas</th>
							<th>Pavardė</th>
							<th>Asmens kodas</th>
							<th>Statusas</th>
							<th>Klaidos statusas</th>
							<th>Paysera atsakymas</th>
							<th></th>
						</tr>

						<tr>
							<td>Asmeninis patvirtinimas</td>
							<td>{request.firstName}</td>
							<td>{request.lastName}</td>
							<td>{request.personalID}</td>
							<td>
								{personalConfirmation.completed ? (
									<Done style={{ color: 'green' }} />
								) : (
									<Clear style={{ color: 'red' }} />
								)}
							</td>
							<td>{personalConfirmation.error ? '1' : '0'}</td>
							<td>
								<Tooltip
									placement='top'
									title={
										personalConfirmation.payseraResponse
											? JSON.stringify(personalConfirmation.payseraResponse, null, 4)
											: '-'
									}
								>
									<Info />
								</Tooltip>
							</td>
							<td>
								<Popconfirm
									trigger={<button disabled={personalConfirmation.completed}>Atšaukti</button>}
									text={popConfirmText}
									onOkButtonClick={() => cancelIdentityConfirmation(personalConfirmation._id)}
								/>
							</td>
						</tr>

						{request.maritalStatus === 'married' && request.withCoApplicant && (
							<tr>
								<td>Sutuoktinio patvirtinimas</td>
								<td>{request.partnerFirstName}</td>
								<td>{request.partnerLastName}</td>
								<td>{request.partnerPersonalID}</td>
								<td>
									{partnerConfirmation.completed ? (
										<Done style={{ color: 'green' }} />
									) : (
										<Clear style={{ color: 'red' }} />
									)}
								</td>
								<td>{partnerConfirmation.error ? '1' : '0'}</td>
								<td>
									<Tooltip
										placement='top'
										title={
											partnerConfirmation.payseraResponse
												? JSON.stringify(partnerConfirmation.payseraResponse, null, 4)
												: '-'
										}
									>
										<Info />
									</Tooltip>
								</td>
								<td>
									<Popconfirm
										trigger={
											<button
												disabled={partnerConfirmation.completed || !personalConfirmation.completed}
											>
												Atšaukti
											</button>
										}
										text={popConfirmText}
										onOkButtonClick={() => cancelIdentityConfirmation(partnerConfirmation._id)}
									/>
								</td>
							</tr>
						)}
					</table>
				</div>
			</div>
		</div>
	);
};

export default IdentityConfirmations;
