import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import SearchBar from '../company/shared/search-bar';
import AdminRequest from './admin-request';

class AdminRequests extends Component {
	constructor(props) {
		super(props);

		this.state = {
			requestsPerPage: 15,
			page: 0,
			status: props.status,
			requests: [],
			filterQuery: '',
			consultant: props.consultant || null,
		};
	}

	componentDidMount() {
		document.title = 'Credit King - sistemos paraiškos';

		this.getRequests('?status=' + this.state.status, false, false);
	}

	changeRequestByIndex = (index, target, value) => {
		const requests = [...this.state.requests];
		requests[index][target] = value;

		return this.setState({ requests });
	};

	getRequests = async (filterQuery, fromFilters = true, useCurrent = false) => {
		if (fromFilters) {
			await this.setState({ page: 0, requests: [] });
		}

		if (useCurrent) {
			filterQuery = this.state.filterQuery;
		} else {
			this.setState({ filterQuery: filterQuery });
		}

		filterQuery += `&page=${this.state.page}&perPage=${this.state.requestsPerPage}`;

		if (this.state.consultant) {
			filterQuery += '&consultant=' + this.state.consultant;
		}

		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/requests' + filterQuery, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(response.error);
				} else {
					let { requests } = this.state;

					this.setState({
						requests: [...requests, ...response],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				// not loading anymore
				this.setState({
					isLoading: false,
				});
			});
	};

	handleChangePage = async (event, page) => {
		// check if there's a need to paginate
		if (page > this.state.page && page * this.state.requestsPerPage >= this.state.requests.length) {
			this.setState({ page }, () => {
				this.getRequests('', false, true);
			});
		} else {
			this.setState({ page });
		}
	};

	render() {
		let { status } = this.props;
		let sliceFrom = this.state.page * this.state.requestsPerPage;
		let sliceTo = sliceFrom + this.state.requestsPerPage;

		return (
			<div className='fade-in'>
				<div className='row no-gutters'>
					<div className='col-md-5 col-12'>
						<h1 className='app-page-title'>Paraiškos</h1>
					</div>
					<div className='col-md-7 col-12 text-right pt-2 pb-2'>
						<SearchBar
							getRequests={this.getRequests}
							status={status}
							$helper={this.props.$helper}
							hideConsultantFilter={Boolean(this.state.consultant)}
						/>
					</div>
				</div>
				<Paper id='company-requests-wrapper'>
					<Table
						style={{
							minWidth: 1000,
						}}
						size='small'
					>
						<TableHead>
							<TableRow className='px-3'>
								<TableCell>Paraiškos kodas</TableCell>
								<TableCell align='right'>Paraiškos statusas</TableCell>
								<TableCell align='right'>Sukūrimo data</TableCell>
								<TableCell align='right'>Paraiškos pateikėjas</TableCell>
								<TableCell align='right'>Paskolos tipas</TableCell>
								<TableCell align='right'>Paskolos suma</TableCell>
								<TableCell align='right'>Paskolos terminas</TableCell>
								<TableCell align='right'>Išduota suma</TableCell>
								<TableCell align='right' />
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.requests &&
								this.state.requests.slice(sliceFrom, sliceTo).map((request, index) => {
									return (
										<AdminRequest
											key={request._id}
											changeRequestByIndex={(target, value) =>
												this.changeRequestByIndex(index, target, value)
											}
											request={request}
										/>
									);
								})}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={
							this.state.requests.length % this.state.requestsPerPage === 0
								? this.state.requests.length + 1
								: this.state.requests.length
						}
						rowsPerPage={this.state.requestsPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</Paper>
			</div>
		);
	}
}

export default AdminRequests;
