import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { CloudUpload, Close } from '@material-ui/icons';

class AttachFilesModal extends Component {
	constructor(props) {
		super();

		const { request } = props;

		this.state = {
			isLoading: false,
			currentAttachedFiles: request.attachedFiles || [],
		};
	}

	handleSubmit = () => {
		const { request } = this.props;
		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/attachedFiles', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				attachedFiles: this.state.currentAttachedFiles,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko išsiųsti papildomos informacijos');
				}

				return window.location.reload();
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	handleUpload = (event) => {
		const file = event.target.files[0];

		if (!file) return;

		const formData = new FormData();
		formData.append('file', file);

		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/files', {
			method: 'POST',
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			body: formData,
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.path) {
					return toast.error('Nepavyko įkelti failo');
				}

				const { currentAttachedFiles } = this.state;
				currentAttachedFiles.push({
					name: file.name,
					path: response.path,
				});

				this.setState({ currentAttachedFiles });
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		const { attachFilesModalOpen, handleClose } = this.props;

		return (
			<Dialog
				open={attachFilesModalOpen}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'
				fullWidth
				maxWidth='sm'
			>
				<DialogTitle id='form-dialog-title'>Papildomų dokumentų įkėlimas</DialogTitle>
				<DialogContent>
					<div>
						<input
							accept='image/*, application/pdf'
							onChange={this.handleUpload}
							style={{
								display: 'none',
							}}
							id='contained-button-file'
							type='file'
							disabled={this.state.isLoading}
						/>
						<label htmlFor='contained-button-file'>
							<Button
								variant='contained'
								color='primary'
								component='span'
								disabled={this.state.isLoading}
							>
								<CloudUpload className='pr-2' /> Įkelti failą
							</Button>
						</label>
					</div>

					<div>
						<Table className='w-100' aria-label='simple table'>
							<TableBody>
								{this.state.currentAttachedFiles.map((file, index) => (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>
											<img src={file.path} className='uploaded-file-img' />
										</TableCell>
										<TableCell>
											{/* {file.name} */}
											<TextField
												label='Failo pavadinimas'
												value={file.name}
												onChange={(event) => {
													const { currentAttachedFiles } = this.state;

													currentAttachedFiles[index].name = event.target.value;

													this.setState({ currentAttachedFiles });
												}}
												fullWidth
											/>
										</TableCell>
										<TableCell align='right'>
											<IconButton
												color='secondary'
												className='p-1'
												onClick={() => {
													const { currentAttachedFiles } = this.state;

													currentAttachedFiles.splice(index, 1);

													this.setState({ currentAttachedFiles });
												}}
											>
												<Close />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</DialogContent>
				<DialogActions>
					<Button disabled={this.state.isLoading} onClick={this.handleSubmit} color='primary'>
						Patvirtinti
					</Button>
					<Button onClick={handleClose} color='primary'>
						Atšaukti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default AttachFilesModal;
