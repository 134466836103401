import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Cookies from 'js-cookie';
import Logo from '../../../../assets/img/logo.png';
import authErrors from '../../../../locale/lt_LT/auth-errors';
import SubmitRequestForm from '../../../shared/request/submit-request-form';
import Header from '../../header';

const queryString = require('query-string');

class UserRequestSignup extends Component {
	constructor(props) {
		super(props);

		let queryParams = queryString.parse(window.location.search);

		if (queryParams && (queryParams.contactNumber || queryParams.email)) {
			// push data to intercom
			let appState = props.$helper.getAppState();

			appState.intercom.email = queryParams.email;
			appState.intercom.phone = queryParams.contactNumber;
			appState.intercom.marketingAccept = queryParams.agreement === 'on';

			props.$helper.setAppState(appState);

			// send to create potential request for marketing purposes
			fetch(process.env.REACT_APP_API + '/api/potentialRequests', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(queryParams),
			});
		}

		let state = {
			municipalities: [],
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			contactNumber: '',
			isSubmited: false,
			marketingAccept: queryParams.agreement === 'on',
			termsAccepted2: false,
			creditType: 'spending',
			personalID: '',
			age: null,
			termsAccepted: false,
			termsAccepted2: false,
			termsAccepted3: false,
			documentID: '',
			documentType: '',
			occupationType: '',
			workExperience: '',
			personalIncome: '',
			financialObligations: '',
			changeInIncome: false,
			ownsRealEstate: false,
			ownsCar: false,
			incomeChangeReason: '',
			housingObligations: 0,
			residenceMunicipality: '',
			residenceCity: '',
			residenceAddress: '',
			maritalStatus: '',
			partnerFirstName: '',
			partnerLastName: '',
			partnerContactNumber: '',
			partnerEmail: '',
			partnerPersonalID: '',
			partnerPersonalIncome: '',
			partnerFinancialObligations: '',
			creditAmount: 1000,
			creditLength: 1,
			carInfo: '',
			carManufacturingYear: '',
			educationLevel: '',
			childrenCount: 0,
			error: {},
			isLoading: false,
			validIncome: false,
			documentFile: null,
			documentFile1: null,
			documentFile2: null,
			documentFile3: null,
			documentFiles: [],
			refinancingFiles: [],
			refinancingFile1: null,
			refinancingFile2: null,
			refinancingFile3: null,
			driversLicense: false,
			futurePersonalIncome: '',
			futureFinancialObligations: '',
			carCreditType: null,
			additionalDocumentsModalOpen: false,
			acceptConfirmationModalOpen: false,
			requiredDocuments: [],
			activeStep: 0,
			ref: Cookies.get('creditking_ref'),
			analyticsClientId: Cookies.get('_ga'),

			// editable
			isEditing: false,
			editableBusinessCreditType: '',
			editableCreditType: '',
			editableCreditAmount: 0,
			editableCreditLength: 0,
		};

		this.state = { ...state, ...queryParams };
		this.getMunicipalities();
	}

	componentDidMount() {
		document.title = 'Credit King - registracija';
	}

	getMunicipalities = () => {
		fetch(process.env.REACT_APP_API + '/api/static/lithuanianMunicipalities', {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((response) => {
				if (Array.isArray(response)) {
					this.setState({
						municipalities: response,
					});
				}
			});
	};

	checkIncome = () => {
		if (
			this.state.personalIncome !== 0 &&
			this.state.creditAmount / this.state.creditLength < this.state.personalIncome
		) {
			this.setState({
				validIncome: true,
			});
		} else {
			this.setState({
				validIncome: false,
			});
		}
	};

	handleSubmit = (event, skipFileModal = false) => {
		event.preventDefault();

		let requiredDocuments = [];

		if (['working-abroad', 'self-employed'].includes(this.state.occupationType) && !skipFileModal) {
			requiredDocuments.push({
				title: 'Pajamas įrodantys dokumentai',
				description:
					'Prašome pateikti pajamas pagrindžiančius dokumentus už paskutinius 4 mėnesius. Galite įkelti ne daugiau kaip tris failus.',
				location: 'documentFiles',
				required: true,
				maxFiles: 3,
			});
		}

		if (this.state.creditType === 'refinancing' && !skipFileModal) {
			requiredDocuments.push({
				title: 'Refinansavimo dokumentai',
				description:
					'Prašome pateikti finansinių įsipareigojimų (paskolų, lizingo, kt.), kuriuos norite refinansuoti, sutarčių kopijas. Galite įkelti ne daugiau kaip tris failus.',
				location: 'refinancingFiles',
				required: true,
				maxFiles: 3,
			});
		}

		if (requiredDocuments.length > 0 && !skipFileModal) {
			return this.setState({
				additionalDocumentsModalOpen: true,
				requiredDocuments: requiredDocuments,
			});
		}

		this.checkIncome();
		this.setState({
			isSubmited: true,
			isLoading: true,
		});

		process.nextTick(() => {
			if (this.state.validIncome) {
				fetch(process.env.REACT_APP_API + '/api/users', {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						firstName: this.state.firstName,
						lastName: this.state.lastName,
						email: this.state.email,
						password: this.state.password,
						contactNumber: this.state.contactNumber,
						marketingAccept: this.state.marketingAccept,
					}),
				})
					.then((response) => response.json())
					.then((response) => {
						if (response.error) {
							this.setState({ isLoading: false });

							toast.error(authErrors[response.error] || response.error);
						} else {
							if (response.success) {
								// no errors occured, then login
								fetch(process.env.REACT_APP_API + '/api/users/login', {
									method: 'POST',
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({
										email: this.state.email,
										password: this.state.password,
									}),
								})
									.then((response) => response.json())
									.then((response) => {
										if (response.error) {
											toast.error(authErrors[response.error] || response.error);
										} else {
											// saving JWT token in localStorage
											localStorage.setItem('token', response.token);

											let formData = new FormData();

											for (let data in this.state) {
												formData.append(data, this.state[data]);
											}

											formData.append('documentFile1', this.state.documentFiles[0]);
											formData.append('documentFile2', this.state.documentFiles[1]);
											formData.append('documentFile3', this.state.documentFiles[2]);

											if (this.state.creditType === 'refinancing') {
												formData.append('refinancingFile1', this.state.refinancingFiles[0]);
												formData.append('refinancingFile2', this.state.refinancingFiles[1]);
												formData.append('refinancingFile3', this.state.refinancingFiles[2]);
											}

											fetch(process.env.REACT_APP_API + '/api/requests', {
												method: 'POST',
												headers: {
													Authorization: localStorage.getItem('token'),
												},
												body: formData,
											})
												.then((response) => response.json())
												.then((response) => {
													window.location.reload();
												})
												.catch((error) => {
													window.location.reload();
												});
										}
									})
									.catch((error) => {
										window.location.reload();
									});
							}
						}
					})
					.catch((error) => {
						toast.error(error);
					});
			} else {
				this.setState({
					isSubmited: false,
					isLoading: false,
				});

				toast.error('Netinkamos pajamos');
			}
		});
	};

	render() {
		return (
			<>
				<Header />

				<div className='container-fluid auth-page' id='request-signup-page'>
					<div className='row no-gutters'>
						<div className='col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-12 offset-sm-0 col-12 offset-0'>
							<Paper className='input-form-paper' id='request-signup-container'>
								<div className='desktop-content col-xl-8 offset-xl-2 col-lg-8 offset-lg-2'>
									<Stepper activeStep={0} className='pt-0'>
										<Step key={0}>
											<StepLabel>Paraiškos informacija</StepLabel>
										</Step>
										<Step key={1}>
											<StepLabel>Patvirtinimas</StepLabel>
										</Step>
										<Step key={2}>
											<StepLabel>Geriausi pasiūlymai Jums</StepLabel>
										</Step>
									</Stepper>
								</div>

								<Stepper activeStep={0} className='pl-0 pt-0 mobile-content' orientation='vertical'>
									<Step key={0}>
										<StepLabel>Paraiškos informacija</StepLabel>
									</Step>
									<Step key={1}>
										<StepLabel>Patvirtinimas</StepLabel>
									</Step>
									<Step key={2}>
										<StepLabel>Pasiūlymai</StepLabel>
									</Step>
								</Stepper>

								<form onSubmit={this.handleSubmit}>
									<SubmitRequestForm
										state={this.state}
										setState={(state) => this.setState(state)}
										isSignup={true}
										handleSubmit={this.handleSubmit}
									/>
								</form>
								<div className='text-center mt-3 mb-3'>
									Jau turite paskyrą? <Link to='/'>Prisijunkite</Link>
								</div>
							</Paper>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default UserRequestSignup;
