import React, { Component } from 'react';
import { toast } from 'react-toastify';
import UserRequest from './user-request';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearLoader from '../../../shared/linear-loader';

class UserRequestsContainer extends Component {
	_isMounted = false;

	constructor() {
		super();

		this.state = {
			requests: false,
			skip: 0,
			limit: 30,
			filter: {
				status: 'waiting',
			},
		};

		fetch(process.env.REACT_APP_API + '/api/requests/', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(response.error);
				} else if (this._isMounted) {
					this.setState({
						requests: response,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	}

	componentDidMount() {
		this._isMounted = true;
		document.title = 'Credit King - mano paraiškos';
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		if (this.state.requests.length === 0) {
			// redirect user to new request if he does not have any
			toast.warn('Dar neturite aktyvių kredito paraiškų');

			return <Redirect to='/submit' />;
		} else if (this.state.requests.length === 1) {
			// if user only has one request, redirect to single request page
			let request = this.state.requests[0];

			return <Redirect to={'/requests/' + request._id} />;
		}

		return (
			<div className='fade-in'>
				<h1 className='app-page-title'>Mano paraiškos</h1>
				<Paper id='requests-container-wrapper' className='pl-1 py-2'>
					<section id='requests-container'>
						<div className='container-fluid py-3'>
							<div className='row credit-request-table-heading credit-requests-container-content mb-1'>
								<div className='col-1'>
									<Typography>Kodas</Typography>
								</div>
								<div className='col-2'>
									<Typography>Sukūrimo data</Typography>
								</div>
								<div className='col-2'>
									<Typography>Asmens kodas</Typography>
								</div>
								<div className='col-2'>
									<Typography>Tipas</Typography>
								</div>
								<div className='col-2'>
									<Typography>Suma</Typography>
								</div>
								<div className='col-1'>
									<Typography>Terminas</Typography>
								</div>
								<div className='col-2 text-center'>
									<Typography>Statusas</Typography>
								</div>
							</div>

							<div class='row credit-request-table-heading credit-requests-container-content-mobile mb-1'>
								<div className='col-4'>
									<Typography>Kodas</Typography>
								</div>
								<div className='col-3'>
									<Typography>Suma</Typography>
								</div>
								<div className='col-5 text-center'>
									<Typography>Statusas</Typography>
								</div>
							</div>
						</div>
						{!this.state.requests && <LinearLoader />}
						{this.state.requests &&
							this.state.requests.map((request, index) => {
								return (
									<UserRequest
										key={index}
										requestObject={request}
										requestIndex={index}
										mainAppState={this.props.mainAppState}
									/>
								);
							})}
					</section>
				</Paper>
			</div>
		);
	}
}

export default UserRequestsContainer;
