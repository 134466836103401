let ErrorTranslation = {
	firstName: {
		'0x1': 'Vardas negali turėti skaičių. ',
	},
	lastName: {
		'0x1': 'Pavardė negali turėti skaičių. ',
	},
	personalID: {
		'0x1': 'Asmens kodas privalo būti 11 skaitmenų. ',
		'0x2': 'Pateikti paraišką gali asmenys nuo 18 metų. ',
	},
	contactNumber: {
		'0x1': 'Tel. numeris privalo būti 8 skaitmenų. ',
	},
	documentID: {
		'0x1': 'Paso numeris privalo būti 8 skaitmenų. ',
		'0x2': 'Tapatybės kortelės numeris privalo būti 8 skaitmenų. ',
		'0x3': 'Pasirinkite dokumento tipą. ',
	},
	age: {
		'0x1': 'Pateikti paraišką gali asmenys nuo 18 metų. ',
	},
	finances: {
		'0x1': 'Suma negali būti mažesnė už nulį. ',
	},
	partnerPersonalID: {
		'0x1': 'Sutuoktinio asmens kodas negali sutapti su Jūsų. ',
	},
};

export default ErrorTranslation;
