import React from 'react';
import Logo from '../../assets/img/logo.svg';

const Header = () => (
	<div
		style={{
			background:
				'transparent linear-gradient(64deg, #253b7a 0%, #1a2a65 68%, #0d154a 100%) 0% 0% no-repeat padding-box',
			color: 'white',
			padding: '20px 0px',
		}}
		className='text-center'
	>
		<img
			src={Logo}
			style={{
				height: '40px',
				width: 'auto',
			}}
		/>
	</div>
);

export default Header;
