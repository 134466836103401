import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorHandler from '../../../shared/error-handler';

class WaitingPartnerInfoForm extends Component {
	constructor() {
		super();

		this.state = {
			partnerFirstName: '',
			partnerLastName: '',
			partnerContactNumber: '',
			partnerEmail: '',
			partnerPersonalID: '',
			isLoading: false,
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	checkIfDisabled = () => {
		const { request } = this.props;

		return (
			!this.state.partnerFirstName ||
			!this.state.partnerLastName ||
			!this.state.partnerContactNumber ||
			!this.state.partnerEmail ||
			!this.state.partnerPersonalID ||
			this.state.isLoading ||
			ErrorHandler.contactNumber(this.state.partnerContactNumber) ||
			ErrorHandler.personalID(this.state.partnerPersonalID) ||
			ErrorHandler.partnerPersonalID(this.state.partnerPersonalID, request.personalID)
		);
	};

	handleSubmit = (event) => {
		event.preventDefault();

		const id = this.props.request._id;
		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/requests/' + id + '/partner', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				partnerFirstName: this.state.partnerFirstName,
				partnerLastName: this.state.partnerLastName,
				partnerContactNumber: this.state.partnerContactNumber,
				partnerEmail: this.state.partnerEmail,
				partnerPersonalID: this.state.partnerPersonalID,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					return window.location.reload();
				}

				toast.error('Nepavyko pridėti sutuoktinio informacijos');
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		const { request } = this.props;

		return (
			<div className='p-4'>
				<form onSubmit={this.handleSubmit}>
					<div class='row'>
						<div className='col-12'>
							<div className='request-section-label-marital'>
								<i className='fas fa-users request-section-label-icon' />
								Sutuoktinio duomenys
							</div>
						</div>

						<div className='col-lg-4 col-md-6 col-sm-6 col-6'>
							<TextField
								required
								type='text'
								fullWidth
								name='partnerFirstName'
								label='Vardas'
								onChange={this.handleChange}
								value={this.state.partnerFirstName}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-6'>
							<TextField
								required
								type='text'
								fullWidth
								name='partnerLastName'
								label='Pavardė'
								onChange={this.handleChange}
								value={this.state.partnerLastName}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='number'
								fullWidth
								name='partnerPersonalID'
								label='Asmens kodas'
								onChange={this.handleChange}
								value={this.state.partnerPersonalID}
								error={
									ErrorHandler.personalID(this.state.partnerPersonalID) ||
									ErrorHandler.partnerPersonalID(this.state.partnerPersonalID, request.personalID)
								}
								helperText={
									ErrorHandler.personalID(this.state.partnerPersonalID) ||
									ErrorHandler.partnerPersonalID(this.state.partnerPersonalID, request.personalID)
								}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								fullWidth
								required
								type='number'
								name='partnerContactNumber'
								label='Telefono numeris'
								value={this.state.partnerContactNumber}
								onChange={this.handleChange}
								error={ErrorHandler.contactNumber(this.state.partnerContactNumber)}
								helperText={ErrorHandler.contactNumber(this.state.partnerContactNumber)}
								margin='dense'
								InputProps={{
									startAdornment: <InputAdornment position='start'>+370</InputAdornment>,
								}}
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='email'
								fullWidth
								name='partnerEmail'
								label='El. paštas'
								onChange={this.handleChange}
								value={this.state.partnerEmail}
								margin='dense'
							/>
						</div>
						<div className='col-12 text-right'>
							<Button
								type='submit'
								variant='contained'
								color='primary'
								disabled={this.checkIfDisabled()}
							>
								Tęsti
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default WaitingPartnerInfoForm;
