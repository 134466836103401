import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AttachFile } from '@material-ui/icons';

function CreditCompanyHelpModal({ creditCompanyHelpModalOpen, setState }) {
	const handleClose = () => {
		setState({
			creditCompanyHelpModalOpen: false,
		});
	};

	return (
		<Dialog
			open={creditCompanyHelpModalOpen}
			onClose={handleClose}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>Pagalba</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Turite klausimų? Susisiekite su mumis:
					<ul>
						<li>
							Telefonu: <a href='tel:+37065206491'>+37065206491</a>
						</li>
						<li>
							El. paštu: <a href='mailto:raminta@creditking.lt'>raminta@creditking.lt</a>
						</li>
						<li>Live chat’u</li>
					</ul>
					<a
						href='https://creditking.lt/wp-content/uploads/2021/01/PRIVATIEMS-instrukcija-2021-01-12-.pdf'
						target='_blank'
					>
						<AttachFile /> Aplikacijos naudojimosi instrukcija
					</a>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					Išeiti
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CreditCompanyHelpModal;
