import { getActiveReminders, removeReminder } from '../services/reminders';

const reminderNotifier = () => {
  setInterval(() => {
    const reminders = getActiveReminders();
    
    for (const reminder of reminders) {
      alert(`[${reminder.requestId}] ${reminder.text}`);
      removeReminder(reminder.requestId, reminder.id);
    }
  }, 5 * 1000);
};

export default reminderNotifier;