import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';

class SignDeclineModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			dropdownValue: '',
			canceledReason: '',
		};
	}

	handleDropdownChange = (event) => {
		const { name, value } = event.target;

		this.setState({
			[name]: value,
			canceledReason: value === 'Kita' ? '' : value,
		});
	};

	handleChange = (event) => {
		const { name, value } = event.target;

		this.setState({
			[name]: value,
		});
	};

	handleDecline = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id + '/sign', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				sign_confirmed: false,
				canceledReason: this.state.canceledReason,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					this.handleClose();
					toast.success('Sėkmingai atnaujinta');

					// refresh requests
					this.props.getRequests('', false, true);
				} else {
					toast.error('Nepavyko atnaujinti');
				}
			})
			.catch((error) => {
				this.handleClose();
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleClose = () => {
		this.props.setContainerState({
			signDeclineModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		const availableDeclineOptions = [
			'Klientas neatsiliepia',
			'Klientas persigalvojo, paskolos nebereikia',
			'Klientas persigalvojo, paskolos pasiūlymas nebetinka',
			'Paaiškėjo aplinkybių, dėl kurių paskolos išduoti nebegalime',
			'Kita',
		];

		return (
			<Dialog
				open={containerState.signDeclineModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
			>
				<form onSubmit={this.handleDecline}>
					<DialogTitle id='form-dialog-title'>Pasirašymo atšaukimas</DialogTitle>
					<DialogContent>
						<DialogContentText>Ar tikrai nepavyko pasirašyti kredito sutarties?</DialogContentText>

						<div className='row'>
							<div className='col-12'>
								<NativeSelect
									value={this.state.dropdownValue}
									name='dropdownValue'
									onChange={this.handleDropdownChange}
									fullWidth
									required
								>
									<option value='' selected disabled>
										Pasirinkite priežastį
									</option>

									{availableDeclineOptions.map((option, index) => (
										<option value={option} key={index}>
											{option}
										</option>
									))}
								</NativeSelect>
							</div>

							{this.state.dropdownValue === 'Kita' && (
								<div className='col-12'>
									<TextField
										margin='dense'
										id='canceledReason'
										name='canceledReason'
										onChange={this.handleChange}
										value={this.state.canceledReason}
										label='Priežastis'
										type='text'
										multiline
										fullWidth
										required
									/>
								</div>
							)}
						</div>
					</DialogContent>
					<DialogActions>
						<Button disabled={this.state.isLoading} type='submit' color='primary'>
							Patvirtinti
						</Button>
						<Button onClick={this.handleClose} color='primary'>
							Atšaukti
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

export default SignDeclineModal;
