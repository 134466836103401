import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class RevertLackingInfoModal extends Component {
	constructor() {
		super();

		this.state = {
			isDisabled: false,
		};
	}

	handleClose = () => {
		this.props.setContainerState({
			revertLackingInfoModalOpen: false,
		});
	};

	handleAccept = (event) => {
		event.preventDefault();
		this.setState({ isDisabled: true });

		let { request } = this.props;
		let creditConfirmation = request.creditConfirmations[0];

		creditConfirmation.additionalDocuments[this.props.index].input = '';

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations/' + creditConfirmation._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify(creditConfirmation),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko atnaujinti');
				}

				if (!this.props.getRequests) {
					return window.location.reload();
				}

				this.props.getRequests('', false, true);
				toast.success('Sėkmingai atnaujinta');
				return this.handleClose();
			})
			.catch((error) => {
				return toast.error('Nepavyko atnaujinti');
			})
			.finally(() => {
				this.setState({ isDisabled: false });
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.revertLackingInfoModalOpen}
				onClose={this.handleClose}
				fullWidth={true}
				maxWidth={'sm'}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Netinkama informacija</DialogTitle>
				<DialogContent>
					Dokumentą ar atsakymą pažymėjus kaip netinkamą, vartotojas bus informuotas ir informaciją
					turės pateikti iš naujo.
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleAccept} disabled={this.state.isDisabled} color='primary'>
						Patvirtinti
					</Button>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default RevertLackingInfoModal;
