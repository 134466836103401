import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { AppContext } from '../../../../../../AppContext';

class FilterModal extends Component {
	constructor(props) {
		super();

		let mainAppState = props.$helper.getAppState();

		this.state = {
			age: '',
			creditCompanyUsers: [],
		};

		if (mainAppState.role === 'credit') {
			fetch(
				process.env.REACT_APP_API +
					'/api/creditCompanies/' +
					mainAppState.creditCompany._id +
					'/users',
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			)
				.then((response) => response.json())
				.then((response) => {
					if (response.error) {
						toast.error('Serverio klaida');
					} else {
						this.setState({
							creditCompanyUsers: response,
						});
					}
				})
				.catch((error) => {
					toast.error('Serverio klaida');
				});
		}
	}

	render() {
		let containerState = this.props.getContainerState();
		let { filterTarget } = containerState;
		let isFilterOpen = Boolean(filterTarget);
		const { context } = this;

		return (
			<Dialog
				open={isFilterOpen}
				onClose={this.props.handleFilterClose}
				minWidth={'lg'}
				fullWidth
				style={{
					overflow: 'hidden',
				}}
			>
				<DialogTitle>Rušiuoti pagal</DialogTitle>

				<DialogContent>
					{context.role === 'credit' && (
						<>
							<div className='row'>
								<div className='col-12'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterText.enabled}
													name='filterText'
												/>
											}
											label='Papildomą teksto paiešką:'
										/>
									</FormGroup>
								</div>

								<div className='col-12'>
									<FormGroup>
										<FormControl>
											<TextField
												name='data'
												label='Paieškos tekstas'
												value={containerState.filterText.data}
												disabled={!containerState.filterText.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterText')}
												margin='dense'
												fullWidth
											/>
										</FormControl>
									</FormGroup>
								</div>

								<div className='col-12'>
									<small>
										Papildoma teksto paieška atlieka paraiškų paiešką pagal įvestą tekstą ir lygina
										jį su kliento vardu, pavarde bei asmens kodu. Paieška gali užtrukti šiek tiek
										daugiau nei įprasta.
									</small>
								</div>

								<div className='col-12 mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterDate.enabled}
													name='filterDate'
												/>
											}
											label='Sukūrimo datą:'
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div justify='space-around'>
												<DatePicker
													margin='dense'
													fullWidth
													label='Nuo'
													name='from'
													value={containerState.filterDate.from}
													disabled={!containerState.filterDate.enabled}
													onChange={(date) =>
														this.props.handleFilterChange(
															'from',
															'filterDate',
															moment(date).format('YYYY-MM-DD'),
														)
													}
													format={'dd-MM-yyyy'}
												/>
											</div>
										</MuiPickersUtilsProvider>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div justify='space-around'>
												<DatePicker
													margin='dense'
													fullWidth
													label='Iki'
													name='to'
													value={containerState.filterDate.to}
													disabled={!containerState.filterDate.enabled}
													onChange={(date) =>
														this.props.handleFilterChange(
															'to',
															'filterDate',
															moment(date).format('YYYY-MM-DD'),
														)
													}
													format={'dd-MM-yyyy'}
												/>
											</div>
										</MuiPickersUtilsProvider>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-12  mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterType.enabled}
													name='filterType'
												/>
											}
											label='Paskolos tipą:'
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='age-simple'>Paskolos tipas</InputLabel>
											<Select
												fullWidth
												value={containerState.filterType.data}
												disabled={!containerState.filterType.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterType')}
												inputProps={{
													name: 'data',
													id: 'data',
												}}
											>
												<MenuItem value='all'>
													<em>Visi</em>
												</MenuItem>
												<MenuItem value='spending'>Vartojimo paskola</MenuItem>
												<MenuItem value='car'>Automobilio lizingas</MenuItem>
												<MenuItem value='refinancing'>Refinansavimas</MenuItem>
											</Select>
										</FormControl>
									</FormGroup>
								</div>
							</div>
							{this.props.status !== 'waiting_offer' && (
								<div className='row'>
									<div className='col-12 mt-3'>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														color='default'
														name='filterManager'
														onChange={this.props.handleCheckboxChange}
														checked={containerState.filterManager.enabled}
													/>
												}
												label='Vadybininką:'
											/>
										</FormGroup>
									</div>

									<div className='col-12'>
										<FormGroup>
											<FormControl>
												<InputLabel htmlFor='age-simple'>Vadybininkas</InputLabel>
												<Select
													value={containerState.filterManager.data}
													disabled={!containerState.filterManager.enabled}
													onChange={(event) =>
														this.props.handleFilterChange(event, 'filterManager')
													}
													inputProps={{
														name: 'data',
														id: 'data',
													}}
												>
													{this.state.creditCompanyUsers.map((user, index) => {
														return (
															<MenuItem value={user._id} key={index}>
																{user.firstName} {user.lastName}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</FormGroup>
									</div>
								</div>
							)}
							<div className='row'>
								<div className='col-12 mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterLength.enabled}
													name='filterLength'
												/>
											}
											label='Paskolos terminą:'
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<FormControl>
											<TextField
												name='from'
												label='Nuo'
												value={containerState.filterLength.from}
												disabled={!containerState.filterLength.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterLength')}
												margin='dense'
												fullWidth
											/>
										</FormControl>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<FormControl>
											<TextField
												name='to'
												type='number'
												label='Iki'
												value={containerState.filterLength.to}
												disabled={!containerState.filterLength.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterLength')}
												margin='dense'
												fullWidth
											/>
										</FormControl>
									</FormGroup>
								</div>
							</div>
						</>
					)}

					{['admin', 'consultant'].includes(context.role) && (
						<>
							<div className='row'>
								<div className='col-12'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterText.enabled}
													name='filterText'
												/>
											}
											label='Papildomą teksto paiešką:'
										/>
									</FormGroup>
								</div>

								<div className='col-12'>
									<FormGroup>
										<FormControl>
											<TextField
												name='data'
												label='Paieškos tekstas'
												value={containerState.filterText.data}
												disabled={!containerState.filterText.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterText')}
												margin='dense'
												fullWidth
											/>
										</FormControl>
									</FormGroup>
								</div>

								<div className='col-12'>
									<small>
										Papildoma teksto paieška atlieka paraiškų paiešką pagal įvestą tekstą ir lygina
										jį su kliento vardu, pavarde bei asmens kodu. Paieška gali užtrukti šiek tiek
										daugiau nei įprasta.
									</small>
								</div>

								{!containerState.hideConsultantFilter && (
									<>
										<div className='col-12 mt-3'>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															color='default'
															onChange={this.props.handleCheckboxChange}
															checked={containerState.filterConsultant.enabled}
															name='filterConsultant'
														/>
													}
													label='Konsultantą:'
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup>
												<FormControl>
													<InputLabel htmlFor='age-simple'>Konsultantas</InputLabel>
													<Select
														fullWidth
														value={containerState.filterConsultant.data}
														disabled={!containerState.filterConsultant.enabled}
														onChange={(event) =>
															this.props.handleFilterChange(event, 'filterConsultant')
														}
														inputProps={{
															name: 'data',
															id: 'data',
														}}
													>
														{containerState.consultants.map((consultant, index) => (
															<MenuItem key={index} value={consultant._id}>
																{consultant.firstName} {consultant.lastName}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</FormGroup>
										</div>
									</>
								)}

								<div className='col-12 mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterDate.enabled}
													name='filterDate'
												/>
											}
											label='Sukūrimo datą:'
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div justify='space-around'>
												<DatePicker
													margin='dense'
													fullWidth
													label='Nuo'
													name='from'
													value={containerState.filterDate.from}
													disabled={!containerState.filterDate.enabled}
													onChange={(date) =>
														this.props.handleFilterChange(
															'from',
															'filterDate',
															moment(date).format('YYYY-MM-DD'),
														)
													}
													format={'dd-MM-yyyy'}
												/>
											</div>
										</MuiPickersUtilsProvider>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div justify='space-around'>
												<DatePicker
													margin='dense'
													fullWidth
													label='Iki'
													name='to'
													value={containerState.filterDate.to}
													disabled={!containerState.filterDate.enabled}
													onChange={(date) =>
														this.props.handleFilterChange(
															'to',
															'filterDate',
															moment(date).format('YYYY-MM-DD'),
														)
													}
													format={'dd-MM-yyyy'}
												/>
											</div>
										</MuiPickersUtilsProvider>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-12 mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterStatus.enabled}
													name='filterStatus'
												/>
											}
											label='Statusas:'
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='age-simple'>Paraiškos statusas</InputLabel>
											<Select
												fullWidth
												value={containerState.filterStatus.status}
												disabled={!containerState.filterStatus.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterStatus')}
												inputProps={{
													name: 'status',
													id: 'status',
												}}
											>
												<MenuItem value='all' defaultValue>
													<em>Visos</em>
												</MenuItem>
												<MenuItem value='canceled'>Nepavyko susisiekti</MenuItem>
												<MenuItem value='unconfirmed'>Nepatvirtinta tapatybė</MenuItem>
												<MenuItem value='waiting_info'>Laukia informacijos</MenuItem>
												<MenuItem value='waiting_offer'>Laukia pasiūlymo</MenuItem>
												<MenuItem value='waiting_call'>Laukia skambučio</MenuItem>
												<MenuItem value='waiting_sign'>Laukia pasirašymo</MenuItem>
												<MenuItem value='signed'>Pasirašytos</MenuItem>
												<MenuItem value='inactive'>Neaktyvios</MenuItem>
												<MenuItem value='lacking_info'>
													Trūksta informacijos (pasiūlymo statusas)
												</MenuItem>
											</Select>
										</FormControl>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-12 mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterLength.enabled}
													name='filterLength'
												/>
											}
											label='Paskolos terminą:'
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<FormControl>
											<TextField
												name='from'
												label='Nuo'
												value={containerState.filterLength.from}
												disabled={!containerState.filterLength.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterLength')}
												margin='dense'
												fullWidth
											/>
										</FormControl>
									</FormGroup>
								</div>
								<div className='col-md-6 col-12'>
									<FormGroup>
										<FormControl>
											<TextField
												name='to'
												type='number'
												label='Iki'
												value={containerState.filterLength.to}
												disabled={!containerState.filterLength.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterLength')}
												margin='dense'
												fullWidth
											/>
										</FormControl>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-12 mt-3'>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													color='default'
													onChange={this.props.handleCheckboxChange}
													checked={containerState.filterType.enabled}
													name='filterType'
												/>
											}
											label='Paskolos tipą:'
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='age-simple'>Paskolos tipas</InputLabel>
											<Select
												value={containerState.filterType.data}
												disabled={!containerState.filterType.enabled}
												onChange={(event) => this.props.handleFilterChange(event, 'filterType')}
												fullWidth
												inputProps={{
													name: 'data',
													id: 'data',
												}}
											>
												<MenuItem value='all'>
													<em>Visi</em>
												</MenuItem>
												<MenuItem value='spending'>Vartojimo paskola</MenuItem>
												<MenuItem value='car'>Automobilio lizingas</MenuItem>
												<MenuItem value='refinancing'>Refinansavimas</MenuItem>
											</Select>
										</FormControl>
									</FormGroup>
								</div>
							</div>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<div className='my-2 w-100'>
						<div className='text-right'>
							<Button variant='contained' color='primary' onClick={this.props.handleFilterClose}>
								Filtruoti
							</Button>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		);
	}
}

FilterModal.contextType = AppContext;
export default FilterModal;
