import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/img/logo.png';

class Forgot extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
			isLoading: false,
			isSubmited: false,
		};
	}

	componentDidMount() {
		document.title = 'Credit King - slaptažodžio atkūrimas';
	}

	handleSubmit = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/resetTokens', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: this.state.email,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					isLoading: false,
				});

				if (!response.success) {
					let error =
						response.error === 'User does not exist'
							? 'Paskyra neegzistuoja'
							: 'Nepavyko išsiųsti užklausos';

					toast.error(error);
				} else {
					toast.success('Užklausa sėkmingai išsiųsta');

					this.setState({
						isSubmited: true,
					});
				}
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
				});

				toast.error('Nepavyko išsiųsti užklausos');
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	render() {
		return (
			<div className='container' id='forgot-page'>
				<div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 offset-0'>
					<Paper className='input-form-paper'>
						<div className='text-center'>
							<img src={Logo} className='img-fluid auth-page-logo' alt='creditking-logo' />
						</div>

						{this.state.isSubmited ? (
							<div className='text-justify'>
								<div
									className='pb-3'
									style={{
										borderBottom: '1px solid black',
									}}
								>
									Paskyros slaptažodžio atkūrimo nuoroda sėkmingai išsiųsta Jūsų nurodytu
									elektroniniu paštu. Norint atkurti slaptažodį, paspauskite ant nuorodos, kurią
									gavote elektroniniame laiške. Nuoroda galioja <strong>valandą</strong>.
								</div>
							</div>
						) : (
							<form onSubmit={this.handleSubmit}>
								<div className='row'>
									<div className='col-12'>
										<TextField
											required
											autoFocus
											type='email'
											fullWidth
											id='email'
											label='El. paštas'
											onChange={this.handleChange}
											value={this.state.email}
											margin='dense'
										/>
									</div>
									<div className='col-12 mt-3'>
										<Button
											fullWidth
											type='submit'
											variant='contained'
											color='primary'
											disabled={this.state.isLoading || this.state.email.length === 0}
										>
											Atkurti slaptažodį
										</Button>
									</div>
								</div>
							</form>
						)}

						<div className='text-center mt-3'>
							Norite prisijungti? <Link to='/'>Prisijunkite</Link>
						</div>
					</Paper>
				</div>
			</div>
		);
	}
}

export default Forgot;
