import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CallAcceptedModal extends Component {
	constructor() {
		super();

		this.state = {
			sendSignUrl: false,
			signUrl: '',
			isLoading: false,
		};
	}

	isValidURL = () => {
		let string = this.state.signUrl;

		var res = string.match(
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
		);
		return res !== null;
	};

	handleAccept = () => {
		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id + '/call', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				call_accepted: true,
				signUrl: this.state.sendSignUrl ? this.state.signUrl : undefined,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					this.handleClose();

					toast.success('Sėkmingai atnaujinta');

					// refresh requests
					this.props.getRequests('', false, true);
				} else {
					toast.error('Nepavyko atnaujinti');
				}
			})
			.catch((error) => {
				toast.error('Nepavyko atnaujinti');
				this.handleClose();
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleClose = () => {
		this.props.setContainerState({
			callAcceptedModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.callAcceptedModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
				maxWidth='xs'
			>
				<DialogTitle id='form-dialog-title'>Skambučio patvirtinimas</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Ar norite patvirtinti, jog buvo susisiekta su paraiškos teikėju?
					</DialogContentText>
					<div className='row'>
						<div className='col-12'>
							<FormControlLabel
								className='mt-2'
								control={
									<Checkbox
										onChange={() =>
											this.setState({
												sendSignUrl: !this.state.sendSignUrl,
												signUrl: '',
											})
										}
										checked={this.state.sendSignUrl}
										color='primary'
									/>
								}
								label='Kartu siųsti elektroninio pasirašymo nuorodą'
							/>
						</div>

						{this.state.sendSignUrl && (
							<div className='col-12'>
								<TextField
									required
									type='text'
									fullWidth
									name='signUrl'
									label='Elektroninio pasirašymo nuoroda'
									onChange={this.handleChange}
									value={this.state.signUrl}
									error={!this.isValidURL() && this.state.signUrl.length > 0}
									helperText={
										!this.isValidURL() && this.state.signUrl.length > 0
											? 'Nuoroda yra neteisinga'
											: ''
									}
									margin='dense'
								/>
							</div>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.handleAccept}
						disabled={
							(this.state.sendSignUrl && !this.isValidURL()) ||
							(this.state.sendSignUrl && this.state.signUrl.length < 1) ||
							this.state.isLoading
						}
						color='primary'
					>
						Patvirtinti
					</Button>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default CallAcceptedModal;
