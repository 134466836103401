import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../shared/loader';

class CreditRequest extends Component {
	constructor(props) {
		super();

		try {
			var requestID = props.match.params.id;
		} catch (error) {
			toast.error('Serverio klaida');
		}

		fetch(process.env.REACT_APP_API + '/api/requests/' + requestID, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response) {
					toast.error('Paraiška neegzistuoja');

					props.$helper.initRedirect('/');
				} else {
					if (response.error) {
						toast.error(response.error);

						props.$helper.initRedirect('/');
					} else {
						let request = response[0];

						if (request.status === 'unconfirmed') {
							this.props.$helper.initRedirect('/requests/' + request._id + '/confirmation');
						} else {
							this.props.$helper.initRedirect('/offers/' + request._id);
						}
					}
				}
			});
	}

	render() {
		return <Loader />;
	}
}

export default CreditRequest;
