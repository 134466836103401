import React, { Component } from 'react';
import { Error, Loop, CheckCircle } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/lt';

const styles = {
	button: {
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
};

class Report extends Component {
	render() {
		let { report, creditCompanyUsers, appState } = this.props;
		let user = 'A B';

		if (report.info.manager !== 'all') {
			for (let i = 0; i < creditCompanyUsers.length; i++) {
				if (creditCompanyUsers[i]._id === report.info.manager) {
					user = `${creditCompanyUsers[i].firstName} ${creditCompanyUsers[i].lastName}`;
				}
			}
		}

		let alertIcons = {
			created: <CheckCircle className='csv-created-icon mr-2' />,
			errored: <Error className='csv-errored-icon mr-2' />,
			in_progress: <Loop className='csv-in-progress-icon mr-2' />,
		};

		let alertTranslations = {
			created: 'Sėkmingai sukurta',
			errored: 'Įvyko klaida',
			in_progress: 'Ataskaita kuriama',
		};

		let creditTypeTranslations = {
			all: 'Visi',
			spending: 'Vartojimo paskola',
			car: 'Automobilio lizingas',
			refinancing: 'Refinansavimas',
		};

		let reportTypeTranslations = {
			accepted: 'Pateikti pasiūlymai',
			declined: 'Atmesti pasiūlymai',
			signed: 'Pasirašytos paraiškos',
			all: 'Visos paraiškos',
			canceled: 'Atšauktos paraiškos',
		};

		return (
			<TableRow>
				<TableCell component='th' scope='row'>
					{report._id}
				</TableCell>
				<TableCell align='center'>
					<Tooltip
						title={alertTranslations[report.status]}
						aria-label={alertTranslations[report.status]}
					>
						{alertIcons[report.status]}
					</Tooltip>
					{/* <span className='mobile-hide'>{alertTranslations[report.status]}</span> */}
				</TableCell>
				<TableCell align='left'>
					{moment(report.createdAt)
						.locale('lt')
						.format('lll')}
				</TableCell>
				<TableCell align='left'>
					{/* {report.info.dateFrom} */}
					{moment(new Date(report.info.dateFrom))
						.locale('lt')
						.format('LL')}
				</TableCell>
				<TableCell align='left'>
					{' '}
					{moment(new Date(report.info.dateTo))
						.locale('lt')
						.format('LL')}
				</TableCell>
				<TableCell align='left'>{creditTypeTranslations[report.info.creditType]}</TableCell>
				<TableCell align='left'>
					{appState.role === 'credit' ? (
						<>{report.info.manager === 'all' || !report.info.manager ? 'Visi' : user}</>
					) : (
						<>{report.info.creditCompany ? report.info.creditCompany.name : 'Visi'}</>
					)}
				</TableCell>
				<TableCell align='left'>{reportTypeTranslations[report.info.status]}</TableCell>
				<TableCell align='right'>
					<Button
						className={this.props.classes.button}
						disabled={report.status !== 'created'}
						href={report.path}
						// onClick={() => {
						// 	downloadReport(report._id);
						// }}
					>
						ATSISIŲSTI
					</Button>
				</TableCell>
			</TableRow>
		);
	}
}

export default withStyles(styles)(Report);
