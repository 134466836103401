import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RequestAcceptForm from '../shared/request-accept-form';

class EditConfirmationModal extends Component {
	constructor(props) {
		super(props);

		let creditConfirmation = props.request.creditConfirmations[0];

		this.state = {
			creditOfferFrom: creditConfirmation.creditOfferFrom,
			creditOfferTo: creditConfirmation.creditOfferTo,
			interestRate: creditConfirmation.interestRate,
			monthlyPayment: creditConfirmation.monthlyPayment,
			creditLength: creditConfirmation.creditLength,
			fee: creditConfirmation.fee,
			BVKKMN: creditConfirmation.BVKKMN,
			share: creditConfirmation.share,
			entranceFee: creditConfirmation.entranceFee,
			comment: creditConfirmation.comment,
			isCarLoan: creditConfirmation.isCarLoan,
			isLoading: false,
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleClose = () => {
		this.props.setContainerState({
			editConfirmationModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	checkIfDisabled = () => {
		function isFloat(x) {
			return !!(x % 1);
		}

		// cases where it is disabled
		return (
			// this.state.creditOfferFrom > this.state.creditOfferTo ||
			// this.state.creditOfferFrom <= 0 ||
			// this.state.creditOfferFrom <= 0 ||
			// this.state.interestRate <= 0 ||
			// this.state.monthlyPayment <= 0 ||
			// this.state.creditLength <= 0 ||
			// this.state.fee < 0 ||
			// isFloat(this.state.creditLength) ||
			// isFloat(this.state.creditOfferFrom) ||
			// isFloat(this.state.creditOfferTo) ||
			// this.state.interestRate > 100
			// (this.state.creditOfferTo && this.state.creditOfferFrom > this.state.creditOfferTo) ||
			this.state.creditOfferFrom <= 0 ||
			this.state.interestRate < 0 ||
			this.state.monthlyPayment <= 0 ||
			this.state.creditLength <= 0 ||
			this.state.fee < 0 ||
			isFloat(this.state.creditLength) ||
			isFloat(this.state.creditOfferFrom) ||
			(this.state.creditOfferTo && isFloat(this.state.creditOfferTo)) ||
			this.state.interestRate > 100
		);
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	handleAccept = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let creditConfirmation = this.props.request.creditConfirmations[0];

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations/' + creditConfirmation._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				creditOfferFrom: this.state.creditOfferFrom,
				creditOfferTo: !this.state.creditOfferTo
					? this.state.creditOfferFrom
					: this.state.creditOfferTo,
				interestRate: !this.state.isCarLoan ? this.state.interestRate : undefined,
				monthlyPayment: this.state.monthlyPayment,
				creditLength: this.state.creditLength,
				fee: this.state.fee,
				BVKKMN: !this.state.isCarLoan ? this.state.BVKKMN : undefined,
				share: this.state.share,
				entranceFee: this.state.entranceFee,
				isCarLoan: this.state.isCarLoan,
				comment: this.state.comment,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					this.handleClose();
					toast.success('Sėkmingai atnaujinta');

					// refresh requests
					this.props.getRequests('', false, true);
				} else {
					toast.error('Nepavyko atnaujinti');
				}
			})
			.catch((error) => {
				toast.error('Nepavyko atnaujinti');
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.editConfirmationModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
			>
				<form onSubmit={this.handleAccept}>
					<DialogTitle id='form-dialog-title'>Pasiūlymo pakeitimas</DialogTitle>
					<DialogContent>
						<DialogContentText>Išsiuntus pakeitimą, vartotojas bus informuotas.</DialogContentText>
						<RequestAcceptForm
							state={this.state}
							handleChange={this.handleChange}
							request={this.props.request}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							type='submit'
							disabled={this.checkIfDisabled() || this.state.isLoading}
							color='primary'
						>
							Atnaujinti
						</Button>
						<Button onClick={this.handleClose} color='primary'>
							Atšaukti
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

export default EditConfirmationModal;
