import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import NativeSelect from '@material-ui/core/NativeSelect';
import { toast } from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import { Chart } from 'react-google-charts';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';

class CreditCompaniesOverview extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: true,
			period: 7,
			dateFrom: moment().subtract(7, 'd').format('YYYY-MM-DD'),
			dateTo: moment(Date.now()).format('YYYY-MM-DD'),
			creditCompany: 0,
			creditCompanies: [],
			allConfirmations: [],
			acceptedConfirmations: [],
			declinedConfirmations: [],
			confirmedRequests: [],
			signedRequests: [],
			chartData: [],
			allChart: [],
			confirmationAmounts: {},
		};
	}

	componentWillMount() {
		this.getCreditCompanies();
	}

	componentDidMount() {
		document.title = 'Credit King - kredito įstaigų apžvalgų';
	}

	getRequests = (type, stateObject) => {
		return new Promise((resolve, reject) => {
			fetch(
				process.env.REACT_APP_API +
					'/api/requests/overview?type=' +
					type +
					// '&period=' +
					// this.state.period +
					'&dateFrom=' +
					this.state.dateFrom +
					'T00:00:00.000Z' +
					'&dateTo=' +
					this.state.dateTo +
					'T23:59:59Z',
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			)
				.then((response) => response.json())
				.then(async (response) => {
					this.setState({
						[stateObject]: response,
					});

					resolve();
				});
		});
	};

	getCreditConfirmations = (type, stateObject) => {
		return new Promise((resolve, reject) => {
			fetch(
				process.env.REACT_APP_API +
					'/api/creditCompanies/' +
					this.state.creditCompany +
					'/overview?type=' +
					type +
					'&period=' +
					this.state.period +
					'&dateFrom=' +
					this.state.dateFrom +
					'T00:00:00.000Z' +
					'&dateTo=' +
					this.state.dateTo +
					'T23:59:59Z',
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			)
				.then((response) => response.json())
				.then(async (response) => {
					this.setState({
						[stateObject]: response,
					});

					resolve();
				});
		});
	};

	getChartData = () => {
		this.setState({
			isLoading: true,
		});

		Promise.all([
			this.getRequests('confirmed', 'confirmedRequests'),
			this.getCreditConfirmations('all', 'allConfirmations'),
			this.getCreditConfirmations('accepted', 'acceptedConfirmations'),
			this.getCreditConfirmations('declined', 'declinedConfirmations'),
			this.getCreditConfirmations('signed', 'signedRequests'),
			// this.getCreditConfirmations('signed', 'signedRequests'),
			this.getCreditConfirmations('amount', 'confirmationAmounts'),
		])
			.then(() => {
				const {
					allConfirmations,
					acceptedConfirmations,
					declinedConfirmations,
					signedRequests,
				} = this.state;

				const data = allConfirmations.map((confirmation, index) => {
					return {
						name: moment(new Date(confirmation[0])).format('YYYY-MM-DD'),
						'Patvirtintos paraiškos': acceptedConfirmations[index][1],
						'Atmestos paraiškos': declinedConfirmations[index][1],
						'Pasirašytos paraiškos': signedRequests[index][1],
					};
				});

				return data;
			})
			.then((data) => {
				return this.setState({
					allChart: data,
				});
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleChange = async (event) => {
		await this.setState({
			[event.target.name]: event.target.value,
		});

		this.getChartData();
	};

	getCreditCompanies = () => {
		fetch(process.env.REACT_APP_API + '/api/creditCompanies', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then(async (response) => {
				if (response.error) {
					toast.error(response.error);
				} else {
					await this.setState({
						creditCompany: response[0]._id,
						creditCompanies: response,
					});

					this.getChartData();
				}
			});
	};

	render() {
		return (
			<>
				<h1 className='app-page-title'>Įstaigų apžvalga</h1>
				<Paper style={{ maxHeight: '80vh', width: '100%', overflowX: 'hidden' }} className='p-2'>
					<div className='row px-2'>
						<div className='col-8 pt-2'>
							<Chip
								style={{
									background: 'rgb(219, 68, 55)',
									color: 'white',
								}}
								label={
									'Patvirtintos paraiškos: ' +
									(this.state.confirmationAmounts.acceptedConfirmations !== undefined
										? this.state.confirmationAmounts.acceptedConfirmations
										: '-')
								}
								className='mx-2 my-2'
							/>
							<Chip
								style={{
									background: 'rgb(244, 180, 0)',
									color: 'white',
								}}
								label={
									'Atmestos paraiškos: ' +
									(this.state.confirmationAmounts.declinedConfirmations !== undefined
										? this.state.confirmationAmounts.declinedConfirmations
										: '-')
								}
								className='mx-2 my-2'
							/>
							<Chip
								style={{
									background: 'rgb(15, 157, 88)',
									color: 'white',
								}}
								label={
									'Pasirašytos paraiškos: ' +
									(this.state.confirmationAmounts.signedRequests !== undefined
										? this.state.confirmationAmounts.signedRequests
										: '-')
								}
								className='mx-2 my-2'
							/>
							<Chip
								color='primary'
								label={
									'Pasirašytų paskolų suma: ' +
									(this.state.confirmationAmounts.signedAmount !== undefined
										? this.state.confirmationAmounts.signedAmount
										: '- ') +
									'€'
								}
								className='mx-2 my-2'
							/>
							<Chip
								style={{
									background: 'black',
									color: 'white',
								}}
								label={
									'Vidutinė atsakymo trukmė: ' +
									(this.state.confirmationAmounts.averageReactionTime !== undefined
										? this.state.confirmationAmounts.averageReactionTime
										: '-') +
									' s.'
								}
								className='mx-2 my-2'
							/>
						</div>

						<div className='col-4 text-right'>
							<div className='row'>
								<div className='col-12'>
									<FormControl margin='normal' fullWidth>
										<InputLabel htmlFor='creditCompany'>Kredito įstaiga</InputLabel>
										<NativeSelect
											required
											controlId='creditCompany'
											onChange={this.handleChange}
											value={this.state.creditCompany}
											inputProps={{
												name: 'creditCompany',
												id: 'creditCompany',
											}}
										>
											{this.state.creditCompanies.map((company, index) => {
												return (
													<option key={index} value={company._id}>
														{company.name}
													</option>
												);
											})}
										</NativeSelect>
									</FormControl>
								</div>
								{/* <div className='col-6'>
									<FormControl margin='normal' fullWidth>
										<InputLabel htmlFor='period'>Laikotarpis</InputLabel>
										<NativeSelect
											required
											controlId='period'
											onChange={this.handleChange}
											value={this.state.period}
											inputProps={{
												name: 'period',
												id: 'period',
											}}
										>
											<option value={3}>3 dienos</option>
											<option value={7}>7 dienos</option>
											<option value={15}>15 dienų</option>
											<option value={30}>30 dienų</option>
											<option value={150}>150 dienų</option>
											<option value={365}>1 metai</option>
											<option value={730}>2 metai</option>
										</NativeSelect>
									</FormControl>
								</div> */}

								<div className='col-6'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='normal'
												label='Nuo'
												name='from'
												className='m-0'
												value={this.state.dateFrom}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'dateFrom',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</div>
								<div className='col-6'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='normal'
												label='Iki'
												name='to'
												className='m-0'
												value={this.state.dateTo}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'dateTo',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</div>
							</div>
						</div>

						{this.state.isLoading && (
							<div className='col-12 mt-3'>
								<LinearProgress />
							</div>
						)}

						{!this.state.isLoading && (
							<>
								<div className='col-12 mt-3'>
									<ResponsiveContainer width={'95%'} height={400}>
										<LineChart data={this.state.allChart}>
											<CartesianGrid strokeDasharray='3 3' />
											<XAxis dataKey='name' />
											<YAxis />
											<Tooltip />
											<Legend />
											<Line type='monotone' dataKey='Patvirtintos paraiškos' stroke='#4286f4' />
											<Line type='monotone' dataKey='Atmestos paraiškos' stroke='#db4437' />
											<Line type='monotone' dataKey='Pasirašytos paraiškos' stroke='#f4b400' />
										</LineChart>
									</ResponsiveContainer>
								</div>
							</>
						)}
					</div>
				</Paper>
			</>
		);
	}
}

export default CreditCompaniesOverview;
