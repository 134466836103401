import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {
	Info,
	Done,
	Clear,
	SettingsBackupRestore,
	Edit,
	ContactSupport,
	Note,
} from '@material-ui/icons';
import RequestInfoModal from './modal/request-info-modal';
import RequestAcceptModal from './modal/waiting-offer/request-accept-modal';
import RequestDeclineModal from './modal/waiting-offer/request-decline-modal';
import CallAcceptedModal from './modal/waiting-call/call-accepted-modal';
import CallDeclinedModal from './modal/waiting-call/call-declined-modal';
import SignConfirmModal from './modal/waiting-sign/sign-confirm-modal';
import SignDeclineModal from './modal/waiting-sign/sign-decline-modal';
import ReverseActionModal from './modal/archive/reverse-action-modal';
import EditConfirmationModal from './modal/accepted/edit-confirmation-modal';
import CancelConfirmationModal from './modal/accepted/cancel-confirmation-modal';
import RevertCreditDeclineModal from './modal/declined/revert-credit-decline-modal';
import LackingInfoModal from './modal/waiting-offer/lacking-info-modal';
import LackingInfoEditModal from './modal/lacking-info/lacking-info-edit-modal';
import AcceptLackingInfoModal from './modal/lacking-info/accept-lacking-info-modal';
import DeclineLackingInfoModal from './modal/lacking-info/decline-lacking-info-modal';
import NotesModal from './modal/waiting-sign/notes-modal';
import LinearLoader from '../../../shared/linear-loader';
import moment from 'moment';
import 'moment/locale/lt';

class CompanyRequest extends Component {
	constructor(props) {
		super(props);

		this.state = {
			request: props.request,
			requestInfoOpen: false,
			requestAcceptModalOpen: false,
			requestDeclineModalOpen: false,
			callAcceptedModalOpen: false,
			callDeclinedModalOpen: false,
			signConfirmModalOpen: false,
			signDeclineModalOpen: false,
			reverseActionModalOpen: false,
			editConfirmationModal: false,
			cancelConfirmationModalOpen: false,
			revertCreditDeclineModalOpen: false,
			lackingInfoModalOpen: false,
			lackingInfoEditModalOpen: false,
			acceptLackingInfoModalOpen: false,
			declineLackingInfoModalOpen: false,
			notesModalOpen: false,
			isLoading: false,
		};
	}

	setContainerState = (state) => {
		this.setState(state);
	};

	getContainerState = () => {
		return this.state;
	};

	deleteRequestTakeover = () => {
		let request = this.props.request._id;

		this.props.setContainerState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/requestTakeovers', {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				request,
			}),
		}).finally(() => {
			this.props.setContainerState({
				isLoading: false,
			});
		});
	};

	takeRequestOver = () => {
		return new Promise((resolve, reject) => {
			let request = this.props.request._id;

			if (this.props.isLoading === true) {
				return resolve();
			} else {
				this.props.setContainerState({
					isLoading: true,
				});
			}

			fetch(process.env.REACT_APP_API + '/api/requestTakeovers', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token'),
				},
				body: JSON.stringify({
					request,
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.error) {
						this.setState({
							requestInfoOpen: false,
							requestAcceptModalOpen: false,
							requestDeclineModalOpen: false,
							callAcceptedModalOpen: false,
							callDeclinedModalOpen: false,
							signConfirmModalOpen: false,
							signDeclineModalOpen: false,
							reverseActionModalOpen: false,
							editConfirmationModal: false,
							cancelConfirmationModalOpen: false,
							revertCreditDeclineModalOpen: false,
							lackingInfoModalOpen: false,
							lackingInfoEditModalOpen: false,
							acceptLackingInfoModalOpen: false,
							declineLackingInfoModalOpen: false,
							notesModalOpen: false,
						});

						toast.error('Kredito paraiška kontroliuojama kito vartotojo');

						reject();
					} else {
						resolve();
					}
				})
				.finally(() => {
					this.props.setContainerState({
						isLoading: false,
					});
				});
		});
	};

	render() {
		const translations = require('../../../../locale/lt_LT/requests.json');
		let { request } = this.props;
		let documentCount = 0;
		for(let doc of request.user.requiredDocuments) {
			
			documentCount = documentCount + doc.files.length;
			
		}
		

		// conditional loading of creditAmount and creditLength
		let creditAmount, creditLength;

		if (request.status === 'signed') {
			creditAmount = request.signedAmount;
			creditLength = request.signedLength;
		} else if (['waiting_call', 'waiting_sign', 'canceled'].includes(request.status)) {
			creditAmount = request.acceptedOffer.creditOfferFrom;
			creditLength = request.acceptedOffer.creditLength;
		} else if (
			this.props.status === 'accepted' &&
			request.creditConfirmations &&
			request.creditConfirmations.length > 0
		) {
			creditAmount = request.creditConfirmations[0].creditOfferFrom;
			creditLength = request.creditConfirmations[0].creditLength;
		} else {
			creditAmount = request.creditAmount;
			creditLength = request.creditLength;
		}

		if (request.status === 'waiting_offer') {
			request.acceptedOffer = request.creditConfirmations[0];
		}

		return (
			<>
				{this.props.isLoading && <LinearLoader />}

				{this.state.requestInfoOpen && (
					<RequestInfoModal
						requestInfoOpen={this.state.requestInfoOpen}
						request={request}
						getRequests={this.props.getRequests}
						status={this.props.status}
						setContainerState={this.setContainerState}
						takeRequestOver={this.takeRequestOver}
						deleteRequestTakeover={this.deleteRequestTakeover}
					/>
				)}

				<TableRow className='px-3' id={request._id}>
					<TableCell
						component='th'
						scope='row'
						style={{
							textTransform: 'uppercase',
						}}
					>
						{request.id}
					</TableCell>
					<TableCell align='right'>
						{moment(request.createdAt)
							.locale('lt')
							.format('LLL')}
					</TableCell>
					<TableCell align='right'>
						{request.firstName} {request.lastName}
					</TableCell>
					<TableCell align='right'>{translations.creditType[request.creditType]}</TableCell>
					<TableCell align='right'>{creditAmount}€</TableCell>
					<TableCell align='right'>{creditLength} mėn.</TableCell>
					<TableCell align='right'>{documentCount}</TableCell>

					{this.props.status !== 'waiting_offer' && (
						<TableCell align='right'>
							{request.status === 'waiting_offer' ? (
								<>
									{request.creditConfirmations[0] &&
									request.creditConfirmations[0].creditCompanyUser &&
									request.creditConfirmations[0].creditCompanyUser.firstName ? (
										<>
											{request.creditConfirmations[0].creditCompanyUser.firstName}{' '}
											{request.creditConfirmations[0].creditCompanyUser.lastName}
										</>
									) : (
										'-'
									)}
								</>
							) : (
								<>
									{request.acceptedOffer &&
									request.acceptedOffer.creditCompanyUser &&
									request.acceptedOffer.creditCompanyUser.firstName ? (
										<>
											{request.acceptedOffer.creditCompanyUser.firstName}{' '}
											{request.acceptedOffer.creditCompanyUser.lastName}
										</>
									) : (
										'-'
									)}
								</>
							)}
						</TableCell>
					)}
					<TableCell align='right'>
						<IconButton
							disabled={this.props.isLoading}
							className='request-credit-action-btn'
							onClick={() => {
								this.takeRequestOver().then(() => {
									let target = document.getElementsByClassName('active-company-request-item')[0];

									if (target) {
										target.classList.remove('active-company-request-item');
									}

									target = document.getElementById(request._id);
									target.classList.add('active-company-request-item');

									this.setState({
										requestInfoOpen: true,
									});
								});
							}}
						>
							<Info />
						</IconButton>

						{request.status === 'waiting_offer' &&
							!['accepted', 'declined', 'lacking_info'].includes(this.props.status) && (
								<>
									{this.state.requestAcceptModalOpen && (
										<RequestAcceptModal
											setContainerState={this.setContainerState}
											getContainerState={this.getContainerState}
											takeRequestOver={this.takeRequestOver}
											getRequests={this.props.getRequests}
											deleteRequestTakeover={this.deleteRequestTakeover}
										/>
									)}

									{this.state.requestDeclineModalOpen && (
										<RequestDeclineModal
											setContainerState={this.setContainerState}
											getContainerState={this.getContainerState}
											takeRequestOver={this.takeRequestOver}
											getRequests={this.props.getRequests}
											deleteRequestTakeover={this.deleteRequestTakeover}
										/>
									)}

									{this.state.lackingInfoModalOpen && (
										<LackingInfoModal
											setContainerState={this.setContainerState}
											getContainerState={this.getContainerState}
											takeRequestOver={this.takeRequestOver}
											getRequests={this.props.getRequests}
											deleteRequestTakeover={this.deleteRequestTakeover}
										/>
									)}

									<IconButton
										disabled={this.props.isLoading}
										className='request-credit-action-btn'
										color='primary'
										onClick={() => {
											this.takeRequestOver().then(() => {
												this.setState({
													lackingInfoModalOpen: true,
												});
											});
										}}
									>
										<ContactSupport />
									</IconButton>

									<IconButton
										disabled={this.props.isLoading}
										className='request-credit-action-btn'
										onClick={() => {
											this.takeRequestOver().then(() => {
												this.setState({
													requestAcceptModalOpen: true,
												});
											});
										}}
									>
										<Done />
									</IconButton>

									<IconButton
										disabled={this.props.isLoading}
										className='request-credit-action-btn'
										color='secondary'
										onClick={() => {
											this.takeRequestOver().then(() => {
												this.setState({
													requestDeclineModalOpen: true,
												});
											});
										}}
									>
										<Clear />
									</IconButton>
								</>
							)}

						{request.status === 'waiting_offer' && this.props.status === 'lacking_info' && (
							<>
								{this.state.lackingInfoEditModalOpen && (
									<LackingInfoEditModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										takeRequestOver={this.takeRequestOver}
										getRequests={this.props.getRequests}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.acceptLackingInfoModalOpen && (
									<AcceptLackingInfoModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										takeRequestOver={this.takeRequestOver}
										getRequests={this.props.getRequests}
										deleteRequestTakeover={this.deleteRequestTakeover}
										request={request}
									/>
								)}

								{this.state.declineLackingInfoModalOpen && (
									<DeclineLackingInfoModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										takeRequestOver={this.takeRequestOver}
										getRequests={this.props.getRequests}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.notesModalOpen && (
									<NotesModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												notesModalOpen: true,
											});
										});
									}}
								>
									<Note />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												lackingInfoEditModalOpen: true,
											});
										});
									}}
								>
									<ContactSupport />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									id='request-credit-action-accept'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												acceptLackingInfoModalOpen: true,
											});
										});
									}}
								>
									<Done />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='secondary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												declineLackingInfoModalOpen: true,
											});
										});
									}}
								>
									<Clear />
								</IconButton>
							</>
						)}

						{request.status === 'waiting_offer' && this.props.status === 'accepted' && (
							<>
								{this.state.editConfirmationModalOpen && (
									<EditConfirmationModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										takeRequestOver={this.takeRequestOver}
										request={request}
										getRequests={this.props.getRequests}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.cancelConfirmationModalOpen && (
									<CancelConfirmationModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										takeRequestOver={this.takeRequestOver}
										request={request}
										getRequests={this.props.getRequests}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.notesModalOpen && (
									<NotesModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												notesModalOpen: true,
											});
										});
									}}
								>
									<Note />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.setState({
											editConfirmationModalOpen: true,
										});
									}}
								>
									<Edit />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='secondary'
									onClick={() => {
										this.setState({
											cancelConfirmationModalOpen: true,
										});
									}}
								>
									<Clear />
								</IconButton>
							</>
						)}

						{request.status === 'waiting_offer' && this.props.status === 'declined' && (
							<>
								{this.state.revertCreditDeclineModalOpen && (
									<RevertCreditDeclineModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										takeRequestOver={this.takeRequestOver}
										request={request}
										deleteRequestTakeover={this.deleteRequestTakeover}
										getRequests={this.props.getRequests}
									/>
								)}

								{this.state.notesModalOpen && (
									<NotesModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												notesModalOpen: true,
											});
										});
									}}
								>
									<Note />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.setState({
											revertCreditDeclineModalOpen: true,
										});
									}}
								>
									<SettingsBackupRestore />
								</IconButton>
							</>
						)}

						{request.status === 'waiting_call' && (
							<>
								{this.state.callAcceptedModalOpen && (
									<CallAcceptedModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.callDeclinedModalOpen && (
									<CallDeclinedModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.notesModalOpen && (
									<NotesModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												notesModalOpen: true,
											});
										});
									}}
								>
									<Note />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									id='request-credit-action-accept'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												callAcceptedModalOpen: true,
											});
										});
									}}
								>
									<Done />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='secondary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												callDeclinedModalOpen: true,
											});
										});
									}}
								>
									<Clear />
								</IconButton>
							</>
						)}

						{request.status === 'waiting_sign' && (
							<>
								{this.state.signConfirmModalOpen && (
									<SignConfirmModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.signDeclineModalOpen && (
									<SignDeclineModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								{this.state.notesModalOpen && (
									<NotesModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												notesModalOpen: true,
											});
										});
									}}
								>
									<Note />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									id='request-credit-action-accept'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												signConfirmModalOpen: true,
											});
										});
									}}
								>
									<Done />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='secondary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												signDeclineModalOpen: true,
											});
										});
									}}
								>
									<Clear />
								</IconButton>
							</>
						)}

						{request.status === 'canceled' && (
							<>
								{this.state.reverseActionModalOpen && (
									<ReverseActionModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
										getRequests={this.props.getRequests}
									/>
								)}

								{this.state.notesModalOpen && (
									<NotesModal
										setContainerState={this.setContainerState}
										getContainerState={this.getContainerState}
										request={this.state.request}
										getRequests={this.props.getRequests}
										takeRequestOver={this.takeRequestOver}
										deleteRequestTakeover={this.deleteRequestTakeover}
									/>
								)}

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												notesModalOpen: true,
											});
										});
									}}
								>
									<Note />
								</IconButton>

								<IconButton
									disabled={this.props.isLoading}
									className='request-credit-action-btn'
									color='primary'
									onClick={() => {
										this.takeRequestOver().then(() => {
											this.setState({
												reverseActionModalOpen: true,
											});
										});
									}}
								>
									<SettingsBackupRestore />
								</IconButton>
							</>
						)}
					</TableCell>

				</TableRow>
			</>
		);
	}
}

export default CompanyRequest;
