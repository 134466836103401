import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Timer from 'react-compound-timer';

class IdleLogoutAlertModal extends Component {
	handleClose = () => {
		this.props.setState({
			idleLogoutAlertModalOpen: false,
		});
	};

	componentWillUnmount() {
		this.handleClose();
	}

	prepend(n) {
		return Number(n) > 9 ? '' + n : '0' + n;
	}

	render() {
		return (
			<Dialog
				open={this.props.state.idleLogoutAlertModalOpen}
				onClose={this.handleClose}
				aria-labelledby='form-dialog-title'
				minWidth='sm'
				fullWidth
			>
				<DialogTitle id='form-dialog-title'>Įspėjimas</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Typography>
							Dėl neaktyvumo Jūsų sesija bus nutraukta. Jeigu norite tęsti darbą, pajudinkite
							kompiuterio pelę.
						</Typography>

						<Timer
							initialTime={1000 * 90}
							direction='backward'
							formatValue={this.prepend}
							checkpoints={[
								{
									time: 0,
									callback: () => {
										localStorage.removeItem('token');
										window.location.reload();
									},
								},
							]}
						>
							{() => (
								<h1 className='text-center'>
									<Timer.Minutes /> : <Timer.Seconds />
								</h1>
							)}
						</Timer>
					</DialogContentText>
				</DialogContent>
				{/* <DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions> */}
			</Dialog>
		);
	}
}

export default IdleLogoutAlertModal;
