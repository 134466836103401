import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreditRequest from './credit-request/credit-request';
import Offers from './credit-request/offers';
import RequestConfirmation from './credit-request/request-confirmation/request-confirmation';
import SubmitRequest from './credit-request/submit-request';
import Reports from './reports/reports';
import Invoices from './invoices/invoices';
import Profile from './profile/profile';
import UserRequestsContainer from './credit-requests/user/user-requests-container';
import CompanyRequestsRouter from './credit-requests/company/company-requests-router';
import CreditCompanies from './credit-companies/credit-companies';
import CreditCompanyTypes from './credit-company-types/credit-company-types';
import AdminRequests from './credit-requests/admin/admin-requests';
import Overview from './overview/overview';
import CreditCompaniesOverview from './overview/credit-companies-overview';
import Users from './users/users';
import PotentialRequests from './potential-requests/potential-requests';
import CreditConfirmationsRouter from './credit-confirmations/admin/credit-confirmations-router';
import Sources from './sources/sources';

class Router extends Component {
	render() {
		let appState = this.props.$helper.getAppState();

		return (
			<>
				{/* user specific routes */}
				{appState.role === 'user' ? (
					<Switch>
						{/* Need route for path=ARCHIVE and path=HELP */}
						<Route
							exact
							path='/submit'
							render={() => <SubmitRequest $helper={this.props.$helper} />}
						/>
						<Route
							exact
							path='/requests/:id'
							render={(props) => <CreditRequest $helper={this.props.$helper} {...props} />}
						/>
						<Route
							exact
							path='/offers/:id'
							render={(props) => <Offers $helper={this.props.$helper} {...props} />}
						/>
						<Route
							exact
							path='/requests/:id/confirmation'
							render={(props) => <RequestConfirmation $helper={this.props.$helper} {...props} />}
						/>
						<Route
							exact
							path='/requests'
							render={() => (
								<UserRequestsContainer mainAppState={appState} $helper={this.props.$helper} />
							)}
						/>
						<Route exact path='/profile' render={() => <Profile $helper={this.props.$helper} />} />
						<Route path='*' render={() => <Redirect to='/requests' />} />
					</Switch>
				) : (
					''
				)}
				{appState.role === 'credit' ? (
					<Switch>
						{/* Need route for path=HELP */}
						<Route
							exact
							key={0}
							path='/requests'
							render={() => (
								<CompanyRequestsRouter status='waiting_offer' $helper={this.props.$helper} />
							)}
						/>
						<Route
							key={1}
							exact
							path='/reports'
							render={() => <Reports $helper={this.props.$helper} />}
						/>
						<Route
							key={2}
							exact
							path='/requests/waiting-call'
							render={() => (
								<CompanyRequestsRouter status='waiting_call' $helper={this.props.$helper} />
							)}
						/>
						<Route
							key={3}
							exact
							path='/requests/waiting-sign'
							render={() => (
								<CompanyRequestsRouter status='waiting_sign' $helper={this.props.$helper} />
							)}
						/>
						<Route
							key={4}
							exact
							path='/requests/signed'
							render={() => <CompanyRequestsRouter status='signed' $helper={this.props.$helper} />}
						/>
						<Route
							key={5}
							exact
							path='/requests/archive'
							render={() => (
								<CompanyRequestsRouter status='canceled' $helper={this.props.$helper} />
							)}
						/>
						<Route
							key={6}
							exact
							path='/requests/accepted'
							render={() => (
								<CompanyRequestsRouter status='accepted' $helper={this.props.$helper} />
							)}
						/>
						<Route
							key={7}
							exact
							path='/requests/declined'
							render={() => (
								<CompanyRequestsRouter status='declined' $helper={this.props.$helper} />
							)}
						/>
						<Route
							key={8}
							exact
							path='/requests/lacking-info'
							render={() => (
								<CompanyRequestsRouter status='lacking_info' $helper={this.props.$helper} />
							)}
						/>
						<Route exact path='/profile' render={() => <Profile $helper={this.props.$helper} />} />
						<Route path='*' render={() => <Redirect to='/requests' />} />
					</Switch>
				) : (
					''
				)}
				{appState.role === 'consultant' && (
					<Switch>
						<Route
							key='requests'
							exact
							path='/requests'
							render={() => (
								<AdminRequests parentProps={this.props} $helper={this.props.$helper} status='all' />
							)}
						/>
						<Route
							key='myRequests'
							exact
							path='/myRequests'
							render={() => (
								<AdminRequests
									parentProps={this.props}
									$helper={this.props.$helper}
									status='all'
									consultant={appState._id}
								/>
							)}
						/>

						<Route
							exact
							path='/confirmations'
							render={() => (
								<CreditConfirmationsRouter
									parentProps={this.props}
									$helper={this.props.$helper}
									status='accept'
								/>
							)}
						/>
						<Route
							exact
							path='/potentialRequests'
							render={() => <PotentialRequests $helper={this.props.$helper} />}
						/>
						<Route exact path='/profile' render={() => <Profile $helper={this.props.$helper} />} />
						<Route path='*' render={() => <Redirect to='/requests' />} />
					</Switch>
				)}

				{appState.role === 'admin' && (
					<Switch>
						<Route
							exact
							path='/overview'
							render={() => <Overview parentProps={this.props} $helper={this.props.$helper} />}
						/>
						<Route
							exact
							path='/creditCompanies/overview'
							render={() => (
								<CreditCompaniesOverview parentProps={this.props} $helper={this.props.$helper} />
							)}
						/>
						<Route
							exact
							key='requests'
							path='/requests'
							render={() => (
								<AdminRequests parentProps={this.props} $helper={this.props.$helper} status='all' />
							)}
						/>
						<Route
							key='myRequests'
							exact
							path='/myRequests'
							render={() => (
								<AdminRequests
									parentProps={this.props}
									$helper={this.props.$helper}
									status='all'
									consultant={appState._id}
								/>
							)}
						/>
						<Route
							exact
							path='/confirmations'
							render={() => (
								<CreditConfirmationsRouter
									parentProps={this.props}
									$helper={this.props.$helper}
									status='accept'
								/>
							)}
						/>
						<Route exact path='/users' render={() => <Users $helper={this.props.$helper} />} />
						<Route exact path='/reports' render={() => <Reports $helper={this.props.$helper} />} />
						<Route
							exact
							path='/invoices'
							render={() => <Invoices $helper={this.props.$helper} />}
						/>
						<Route
							exact
							path='/creditCompanies/types'
							render={() => <CreditCompanyTypes $helper={this.props.$helper} />}
						/>
						<Route
							exact
							path='/creditCompanies'
							render={() => <CreditCompanies $helper={this.props.$helper} />}
						/>
						<Route
							exact
							path='/potentialRequests'
							render={() => <PotentialRequests $helper={this.props.$helper} />}
						/>
						<Route exact path='/sources' render={() => <Sources $helper={this.props.$helper} />} />
						<Route exact path='/profile' render={() => <Profile $helper={this.props.$helper} />} />
						<Route path='*' render={() => <Redirect to='/requests' />} />
					</Switch>
				)}
			</>
		);
	}
}

export default Router;
