import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/lt';

const translations = require('../../../../locale/lt_LT/requests.json');

class CampaignTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// table pagination stuff
			rowsPerPage: 10,
			page: 0,
		};
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	render() {
		const sliceFrom = this.state.page * this.state.rowsPerPage;
		const sliceTo = sliceFrom + this.state.rowsPerPage;
		const { requests } = this.props;

		return (
			<>
				<div className='w-100'>
					<div className='row'>
						<div className='col-6'>
							<Typography variant='h5' component='div' className='pl-2 pt-2'>
								Klientų sąrašas
							</Typography>
						</div>
					</div>
				</div>

				<div
					style={{
						overflowX: 'scroll',
					}}
				>
					<Table
						style={{
							minWidth: 1000,
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>Paraiška</TableCell>
								<TableCell align='right'>Paraiškos statusas</TableCell>
								<TableCell align='right'>Paraiškos sukūrimo laikas</TableCell>
								<TableCell align='right'>Klientas</TableCell>
								<TableCell align='right'>Source</TableCell>
								<TableCell align='right'>Keyword</TableCell>
								<TableCell align='right'>Campaign</TableCell>
								<TableCell align='right'>utm_source</TableCell>
								<TableCell align='right'>utm_medium</TableCell>
								<TableCell align='right'>utm_campaign</TableCell>
								<TableCell align='right'>utm_term</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{requests.slice(sliceFrom, sliceTo).map((request, index) => (
								<TableRow key={index}>
									<TableCell component='th' scope='row'>
										{request.id}
									</TableCell>
									<TableCell align='right'>{translations.status[request.status]}</TableCell>
									<TableCell align='right'>
										{moment(request.createdAt).locale('lt').format('LLL')}
									</TableCell>
									<TableCell align='right'>
										{request.user.firstName} {request.user.lastName}
									</TableCell>

									{request.ref ? (
										<>
											<TableCell align='right'>{request.ref.source || '-'}</TableCell>
											<TableCell align='right'>{request.ref.keyword || '-'}</TableCell>
											<TableCell align='right'>{request.ref.campaign || '-'}</TableCell>
											<TableCell align='right'>{request.ref.utm_source || '-'}</TableCell>
											<TableCell align='right'>{request.ref.utm_medium || '-'}</TableCell>
											<TableCell align='right'>{request.ref.utm_campaign || '-'}</TableCell>
											<TableCell align='right'>{request.ref.utm_term || '-'}</TableCell>
										</>
									) : (
										<>
											<TableCell align='right'>-</TableCell>
											<TableCell align='right'>-</TableCell>
											<TableCell align='right'>-</TableCell>
											<TableCell align='right'>-</TableCell>
											<TableCell align='right'>-</TableCell>
											<TableCell align='right'>-</TableCell>
											<TableCell align='right'>-</TableCell>
										</>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={requests.length}
						rowsPerPage={this.state.rowsPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</div>
			</>
		);
	}
}

export default CampaignTable;
