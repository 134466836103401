import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Popconfirm = ({
	title = 'Ar tikrai norite tęsti?',
	text = '',
	okButtonText = 'Patvirtinti',
	closeButtonText = 'Atšaukti',
	onOkButtonClick = async () => alert('OK!'),
	trigger = <button>Trigger popconfirm open</button>,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	return (
		<>
			<Dialog
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby='popconfirm-dialog-title'
				aria-describedby='popconfirm-dialog-description'
				fullWidth
				maxWidth={'xs'}
				disableBackdropClick={true}
			>
				<DialogTitle id='popconfirm-dialog-title'>{title}</DialogTitle>
				{Boolean(text) && (
					<DialogContent>
						<DialogContentText id='popconfirm-dialog-description'>{text}</DialogContentText>
					</DialogContent>
				)}

				<DialogActions>
					<Button
						disabled={isLoading}
						onClick={() => {
							setIsLoading(true);

							onOkButtonClick()
								.then(() => {
									setIsOpen(false);
								})
								.finally(() => {
									setIsLoading(false);
								});
						}}
						color='primary'
					>
						{okButtonText}
					</Button>
					<Button onClick={() => setIsOpen(false)} disabled={isLoading} color='default'>
						{closeButtonText}
					</Button>
				</DialogActions>
			</Dialog>

			{React.cloneElement(trigger, { onClick: () => setIsOpen(true) })}
		</>
	);
};

export default Popconfirm;
