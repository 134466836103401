import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Redirect, Link } from 'react-router-dom';
import Logo from '../../../assets/img/logo.png';
import { withStyles } from '@material-ui/core/styles';
import authErrors from '../../../locale/lt_LT/auth-errors';

const styles = {
	img: {
		maxHeight: '40px !important',
		height: 'unset !important',
	},
};

class Signin extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
			password: '',
			redirect: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		document.title = 'Credit King';
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		if (this.validateForm()) {
			this.setState({ isLoading: true });

			fetch(process.env.REACT_APP_API + '/api/users/login', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: this.state.email,
					password: this.state.password,
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.error) {
						this.setState({ isLoading: false });

						toast.error(authErrors[response.error] || response.error);
					} else {
						// saving JWT token in localStorage
						localStorage.setItem('token', response.token);
						window.location.reload();
					}
				})
				.catch((error) => {
					toast.error('Serverio klaida');
				});
		}
	};

	render() {
		let appState = this.props.$helper.getAppState();

		// do not allow logged in users
		if (appState.authenticated) {
			return <Redirect to='/' />;
		}

		return (
			<div className='container auth-page' id='signin-page'>
				<div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 offset-0'>
					<Paper className='input-form-paper'>
						<div className='text-center'>
							<img
								src={Logo}
								className={`${this.props.classes.img} img-fluid auth-page-logo`}
								alt='creditking-logo'
							/>
						</div>
						<form onSubmit={this.handleSubmit}>
							<div className='row'>
								<div className='col-12'>
									<TextField
										required
										autoFocus
										type='email'
										fullWidth
										id='email'
										label='El. paštas'
										onChange={this.handleChange}
										value={this.state.email}
										margin='dense'
									/>
								</div>
								<div className='col-12'>
									<TextField
										required
										type='password'
										fullWidth
										id='password'
										label='Slaptažodis'
										onChange={this.handleChange}
										value={this.state.password}
										margin='dense'
									/>
								</div>
								<div className='col-12 mt-3'>
									<Button
										fullWidth
										type='submit'
										variant='contained'
										color='primary'
										disabled={this.state.isLoading}
									>
										Prisijungti
									</Button>
									<div className='text-right pt-1'>
										<small>
											<Link
												style={{
													color: 'black',
												}}
												to='/forgot'
											>
												Pamiršote slaptažodį?
											</Link>
										</small>
									</div>
								</div>
							</div>
						</form>
						<div className='text-center mt-2'>
							Vis dar neturite paskyros? <Link to='/requestSignup'>Užsiregistruokite</Link>
						</div>
					</Paper>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(Signin);
