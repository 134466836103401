import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Add, GroupAdd, Edit } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NewCompanyModal from './modal/new-company-modal';
import ConfirmationCodeModal from './modal/confirmation-code-modal';
import EditCompanyModal from './modal/edit-company-modal';
import moment from 'moment';
import 'moment/locale/lt';

class CreditCompanies extends Component {
	constructor() {
		super();

		this.state = {
			creditCompanies: [],
			newCompanyModalOpen: false,
			editCompanyModalOpen: false,
			confirmationCodeModalOpen: false,
			hash: false,
			creditCompany: false,
		};
	}

	generateHash = (id, creditCompany) => {
		fetch(process.env.REACT_APP_API + '/api/creditCompanies/' + id + '/code', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					hash: response,
					creditCompany: creditCompany,
				});
			});
	};

	getContainerState = () => {
		return this.state;
	};

	setContainerState = (state) => {
		this.setState(state);
	};

	componentDidMount = () => {
		this.getCreditCompanies();

		document.title = 'Credit King - kredito įstaigos';
	};

	getCreditCompanies = () => {
		fetch(process.env.REACT_APP_API + '/api/creditCompanies', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(response.error);
				} else {
					this.setState({
						creditCompanies: response,
					});
				}
			});
	};

	render() {
		return (
			<>
				<h1 className='app-page-title'>Kredito įstaigos</h1>
				<NewCompanyModal
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
					$helper={this.props.$helper}
					getCreditCompanies={this.getCreditCompanies}
				/>
				<EditCompanyModal
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
					$helper={this.props.$helper}
					getCreditCompanies={this.getCreditCompanies}
					company={this.state.creditCompany}
				/>
				<ConfirmationCodeModal
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
					creditCompany={this.state.creditCompany}
					$helper={this.props.$helper}
					hash={this.state.hash}
				/>
				<Paper style={{ maxHeight: '80vh', width: '100%', overflowX: 'auto' }}>
					<Table
						style={{
							minWidth: 700,
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell align='right'>Pavadinimas</TableCell>
								<TableCell align='right'>Trumpinys</TableCell>
								<TableCell align='right'>Tipas</TableCell>
								<TableCell align='right'>Vadybininkų kiekis</TableCell>
								<TableCell align='right'>Sukūrimo data</TableCell>
								<TableCell align='right'>
									<Tooltip title='Nauja įstaiga' interactive>
										<Add
											className='new-company-add'
											onClick={() => {
												this.setState({
													newCompanyModalOpen: true,
												});
											}}
										/>
									</Tooltip>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.creditCompanies.map((creditCompany) => (
								<TableRow key={creditCompany._id}>
									<TableCell align='right'>{creditCompany.name}</TableCell>
									<TableCell align='right'>{creditCompany.slug}</TableCell>
									<TableCell align='right'>
										{creditCompany.type && creditCompany.type.name
											? creditCompany.type.name
											: 'Numatytasis'}
									</TableCell>
									<TableCell align='right'>{creditCompany.users.length}</TableCell>
									<TableCell align='right'>
										{' '}
										{moment(creditCompany.createdAt).locale('lt').format('LL')}
									</TableCell>
									<TableCell align='right'>
										<IconButton
											className='request-credit-action-btn'
											variant='dense'
											color='grey'
											onClick={async () => {
												await this.generateHash(creditCompany._id, creditCompany);
												this.setState({
													confirmationCodeModalOpen: true,
												});
											}}
										>
											<GroupAdd />
										</IconButton>
										<IconButton
											className='request-credit-action-btn'
											variant='dense'
											color='primary'
											onClick={() => {
												this.setState({
													creditCompany: creditCompany,
													editCompanyModalOpen: true,
												});
											}}
										>
											<Edit />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Paper>
			</>
		);
	}
}

export default CreditCompanies;
