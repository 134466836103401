import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AppContext } from '../../../../../AppContext';
import moment from 'moment';

const requestTranslation = require('../../../../../locale/lt_LT/requests.json');

function CreditCompanyTypeForm({ state, setState, handleSubmit }) {
	const context = React.useContext(AppContext);

	const handleChange = (event) => {
		setState({
			[event.target.id]: event.target.value,
		});
	};

	const handleToggle = (value, type) => () => {
		let array = state[type];

		const currentIndex = array.indexOf(value);
		const newChecked = [...array];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setState({
			[type]: newChecked,
		});
	};

	const handleToggleMunicipalities = (value) => () => {
		let { municipalities } = state;

		const currentIndex = municipalities.indexOf(value);
		const newChecked = [...municipalities];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setState({
			municipalities: newChecked,
		});
	};

	const workExperiences = ['0-3', '4-12', '12+', '12-24', '24-36', '36-60', '60+'];

	return (
		<form onSubmit={handleSubmit}>
			<div className='row'>
				<div className='col-12'>
					<TextField
						autoFocus
						margin='dense'
						id='name'
						value={state.name}
						onChange={handleChange}
						label='Tipo pavadinimas'
						type='text'
						fullWidth
					/>
				</div>
				<div className='col-12'>
					<br />
					<Typography>Paskolos tipas (-ai)</Typography>
					<List dense component='div' role='list'>
						{state.listOfCreditTypes.map((value) => {
							const labelId = `transfer-list-all-item-${value}-label`;

							return (
								<ListItem
									key={value}
									role='listitem'
									button
									onClick={handleToggle(value, 'creditTypes')}
								>
									<ListItemIcon>
										<Checkbox
											checked={state.creditTypes.indexOf(value) !== -1}
											color='primary'
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={requestTranslation.creditType[value]} />
								</ListItem>
							);
						})}
					</List>
				</div>
				{state.creditTypes.includes('car') && (
					<div className='col-12'>
						<br />
						<Typography>Automobilio lizingo tipas (-ai)</Typography>
						<List dense component='div' role='list'>
							<ListItem
								key={'buy'}
								role='listitem'
								button
								onClick={handleToggle('buy', 'carCreditTypes')}
							>
								<ListItemIcon>
									<Checkbox
										checked={state.carCreditTypes.indexOf('buy') !== -1}
										color='primary'
										tabIndex={-1}
										disableRipple
										inputProps={{ 'aria-labelledby': 'buy' }}
									/>
								</ListItemIcon>
								<ListItemText id={'buy'} primary={requestTranslation.carCreditType.buy} />
							</ListItem>

							<ListItem
								key={'exchange'}
								role='listitem'
								button
								onClick={handleToggle('exchange', 'carCreditTypes')}
							>
								<ListItemIcon>
									<Checkbox
										checked={state.carCreditTypes.indexOf('exchange') !== -1}
										color='primary'
										tabIndex={-1}
										disableRipple
										inputProps={{ 'aria-labelledby': 'exchange' }}
									/>
								</ListItemIcon>
								<ListItemText id={'exchange'} primary={requestTranslation.carCreditType.exchange} />
							</ListItem>
						</List>
					</div>
				)}
				<div className='col-6'>
					<TextField
						margin='dense'
						id='creditAmountFrom'
						value={state.creditAmountFrom}
						onChange={handleChange}
						label='Kredito suma nuo (€)'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-6'>
					<TextField
						margin='dense'
						id='creditAmountTo'
						value={state.creditAmountTo}
						onChange={handleChange}
						label='Kredito suma iki (€)'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-6'>
					<TextField
						margin='dense'
						id='creditLengthFrom'
						value={state.creditLengthFrom}
						onChange={handleChange}
						label='Kredito laikotarpis nuo (mėn.)'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-6'>
					<TextField
						margin='dense'
						id='creditLengthTo'
						value={state.creditLengthTo}
						onChange={handleChange}
						label='Kredito laikotarpis iki (mėn.)'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-6'>
					<TextField
						margin='dense'
						id='personalIncomeFrom'
						value={state.personalIncomeFrom}
						onChange={handleChange}
						label='Pajamos nuo (€)'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-6'>
					<TextField
						margin='dense'
						id='personalIncomeTo'
						value={state.personalIncomeTo}
						onChange={handleChange}
						label='Pajamos iki (€)'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-12'>
					<FormControlLabel
						className='mt-2'
						control={
							<Checkbox
								onChange={() =>
									setState({
										additionalChildIncomeRequired: !state.additionalChildIncomeRequired,
									})
								}
								checked={state.additionalChildIncomeRequired}
								color='primary'
							/>
						}
						label='Reikalingos papildomos pajamos turintiems išlaikytinių'
					/>
				</div>
				{state.additionalChildIncomeRequired && (
					<div className='col-12'>
						<TextField
							margin='dense'
							id='additionalChildIncome'
							value={state.additionalChildIncome}
							onChange={handleChange}
							label='Reikalingos pajamos turint bent vieną išlaikytinį (€)'
							type='number'
							fullWidth
						/>
					</div>
				)}
				<div className='col-6'>
					<TextField
						margin='dense'
						id='ageFrom'
						value={state.ageFrom}
						onChange={handleChange}
						label='Amžius nuo'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-6'>
					<TextField
						margin='dense'
						id='ageTo'
						value={state.ageTo}
						onChange={handleChange}
						label='Amžius iki'
						type='number'
						fullWidth
					/>
				</div>
				<div className='col-12'>
					<FormControl margin='normal' fullWidth>
						<InputLabel htmlFor='driversLicense'>Turi galiojantį vairuotojo pažymėjimą</InputLabel>
						<NativeSelect
							required
							controlId='driversLicense'
							onChange={handleChange}
							value={state.driversLicense}
							inputProps={{
								name: 'driversLicense',
								id: 'driversLicense',
							}}
						>
							<option value={'all'}>Visi</option>
							<option value={true}>Taip</option>
							<option value={false}>Ne</option>
						</NativeSelect>
					</FormControl>
				</div>
				<div className='col-12'>
					<FormControlLabel
						className='mt-2'
						control={
							<Checkbox
								onChange={() =>
									setState({
										allOccupationTypes: !state.allOccupationTypes,
									})
								}
								checked={state.allOccupationTypes}
								color='primary'
							/>
						}
						label='Matomos visų užsiėmimų paraiškos'
					/>
				</div>

				{!state.allOccupationTypes && (
					<div className='col-12'>
						<br />
						<Typography>Užsiėmimas (-ai)</Typography>
						<List dense component='div' role='list'>
							{Object.keys(requestTranslation.occupationType).map((occupationType) => (
								<ListItem
									key={occupationType}
									role='listitem'
									button
									onClick={handleToggle(occupationType, 'occupationTypes')}
								>
									<ListItemIcon>
										<Checkbox
											checked={state.occupationTypes.indexOf(occupationType) !== -1}
											color='primary'
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': occupationType }}
										/>
									</ListItemIcon>
									<ListItemText
										id={occupationType}
										primary={requestTranslation.occupationType[occupationType]}
									/>
								</ListItem>
							))}

							{/* <ListItem
								key={'buy'}
								role='listitem'
								button
								onClick={handleToggle('buy', 'occupationTypes')}
							>
								<ListItemIcon>
									<Checkbox
										checked={state.occupationTypes.indexOf('buy') !== -1}
										color='primary'
										tabIndex={-1}
										disableRipple
										inputProps={{ 'aria-labelledby': 'buy' }}
									/>
								</ListItemIcon>
								<ListItemText id={'buy'} primary={requestTranslation.carCreditType.buy} />
							</ListItem> */}
						</List>
					</div>
				)}

				<div className='col-12'>
					<br />
					<Typography>Darbo stažas</Typography>
					<List dense component='div' role='list'>
						{workExperiences.map((value) => {
							const labelId = `transfer-list-all-item-${value}-label`;

							return (
								<ListItem
									key={value}
									role='listitem'
									button
									onClick={handleToggle(value, 'workExperiences')}
								>
									<ListItemIcon>
										<Checkbox
											checked={state.workExperiences.indexOf(value) !== -1}
											color='primary'
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={requestTranslation.workExperience[value]} />
								</ListItem>
							);
						})}
					</List>
				</div>
				<div className='col-12'>
					<FormGroup>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								fullWidth
								margin='dense'
								label='Rodyti paraiškas sukurtas nuo:'
								name='createdAtFrom'
								value={state.createdAtFrom}
								onChange={(date) =>
									setState({
										createdAtFrom: moment(date).format('YYYY-MM-DD'),
									})
								}
								format={'yyyy-MM-dd'}
							/>
						</MuiPickersUtilsProvider>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormControlLabel
						className='mt-2'
						control={
							<Checkbox
								onChange={() =>
									setState({
										bankAccountRequired: !state.bankAccountRequired,
									})
								}
								checked={state.bankAccountRequired}
								color='primary'
							/>
						}
						label='Reikalinga kliento banko sąskaita'
					/>
				</div>
				<div className='col-12'>
					<FormControlLabel
						className='mt-2'
						control={
							<Checkbox
								onChange={() =>
									setState({
										allMunicipalities: !state.allMunicipalities,
									})
								}
								checked={state.allMunicipalities}
								color='primary'
							/>
						}
						label='Matomos visų savivaldybių paraiškos'
					/>
				</div>
				{!state.allMunicipalities && (
					<div className='col-12'>
						<br />
						<Typography>Savivaldybės</Typography>
						<div
							style={{
								maxHeight: 300,
								overflowY: 'scroll',
							}}
						>
							<List dense component='div' role='list'>
								{context.municipalities.map((value) => {
									const labelId = `transfer-list-all-item-${value}-label`;

									if (!value) {
										return '';
									}

									return (
										<ListItem
											key={value}
											role='listitem'
											button
											onClick={handleToggleMunicipalities(value)}
										>
											<ListItemIcon>
												<Checkbox
													checked={state.municipalities.indexOf(value) !== -1}
													color='primary'
													tabIndex={-1}
													disableRipple
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</ListItemIcon>
											<ListItemText id={labelId} primary={value} />
										</ListItem>
									);
								})}
							</List>
						</div>
					</div>
				)}
			</div>
		</form>
	);
}

export default CreditCompanyTypeForm;
