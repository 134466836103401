import React, { Component } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link, withRouter } from 'react-router-dom';
import { AddBox, InsertDriveFile, AccountCircle, CloudDownload } from '@material-ui/icons';
import HelpCarousel from '../help/help-carousel';

class MobileBottomNavigation extends Component {
	constructor(props) {
		super(props);

		let appState = props.$helper.getAppState();

		this.state = {
			value: null,
			pathMap: {
				user: ['#', '/requests', '/submit', '/profile'],
				credit: ['#', '/requests', '/reports', '/profile'],
			},
			role: appState.role,
			open: false,
		};

		// set correct value on reload
		let pathMap = this.state.pathMap[this.state.role];

		if (pathMap) {
			let { pathname } = window.location;

			const value = pathMap.indexOf(pathname);

			if (value > -1) {
				this.state.value = value;
			}
		}
	}

	getContainerState = () => {
		return this.state;
	};

	setContainerState = (state) => {
		this.setState(state);
	};

	componentWillReceiveProps(newProps) {
		const { pathname } = newProps.location;
		const pathMap = this.state.pathMap[this.state.role];

		if (pathMap) {
			const value = pathMap.indexOf(pathname);
			if (value > -1) {
				this.setState({ value });
			}
		}
	}

	render() {
		return (
			<>
				<HelpCarousel
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
				/>
				{this.state.role === 'user' ? (
					<BottomNavigation
						value={this.state.value}
						onChange={(event, newValue) => {
							this.setState({ value: newValue });
						}}
						style={{
							position: 'fixed',
							bottom: 0,
							width: '100vw',
							zIndex: 1000,
							boxShadow: '0px 0px 10px -3px #000000',
						}}
						id='bottom-mobile-navbar'
					>
						<BottomNavigationAction to='#' label='' disabled disableRipple disableTouchRipple />
						<BottomNavigationAction
							component={Link}
							to='/requests'
							label='Paraiškos'
							icon={<InsertDriveFile />}
						/>
						<BottomNavigationAction
							component={Link}
							to='/submit'
							label='Paraiška'
							icon={<AddBox />}
						/>
						<BottomNavigationAction
							component={Link}
							to='/profile'
							label='Profilis'
							icon={<AccountCircle />}
						/>
					</BottomNavigation>
				) : (
					<BottomNavigation
						value={this.state.value}
						onChange={(event, newValue) => {
							this.setState({ value: newValue });
						}}
						style={{
							position: 'fixed',
							bottom: 0,
							width: '100vw',
							zIndex: 1000,
							boxShadow: '0px 0px 10px -3px #000000',
						}}
						id='bottom-mobile-navbar'
					>
						<BottomNavigationAction to='#' label='' disabled disableRipple disableTouchRipple />
						<BottomNavigationAction
							component={Link}
							to='/requests'
							label='Paraiškos'
							icon={<InsertDriveFile />}
						/>
						<BottomNavigationAction
							component={Link}
							to='/reports'
							label='Ataskaitos'
							icon={<CloudDownload />}
						/>
						<BottomNavigationAction
							component={Link}
							to='/profile'
							label='Profilis'
							icon={<AccountCircle />}
						/>
						{/* <BottomNavigationAction
							component={Link}
							to='#'
							onClick={() => {
								this.setState({
									open: true,
								});
							}}
							label='Pagalba'
							icon={<Help />}
						/> */}
					</BottomNavigation>
				)}
			</>
		);
	}
}

export default withRouter(MobileBottomNavigation);
