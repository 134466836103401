import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

class AcceptConfirmationModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
		};
	}

	handleAccept = () => {
		let confirmation = this.props.confirmation;
		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations/' + confirmation._id + '/accept', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		}).then((response) => window.location.reload());
	};

	handleClose = () => {
		this.props.setContainerState({ openAcceptDialog: false });
	};

	render() {
		let containerState = this.props.getContainerState();
		let { creditCompany, confirmation } = this.props;

		return (
			<Dialog
				onClose={this.handleClose}
				aria-labelledby='customized-dialog-title'
				open={containerState.openAcceptDialog}
			>
				<DialogTitle id='customized-dialog-title' onClose={this.handleClose}>
					Pasiūlymo patvirtinimas
				</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>
						Ar tikrai norite patvirtinti šį pasiūlymą? Patvirtinus pasiūlymą, su Jumis per
						artimiausią valandą susisieks kredito įstaigos darbuotojas. Rekomenduojame pasiūlymą
						patvirtinti tik sulaukus visų kredito įstaigų atsakymų ir palyginus paskolų sąlygas.
					</Typography>

					<div className='row'>
						<div className='col-lg-12 offset-lg-0 col-md-12 offset-md-0 my-2'>
							<table className='table w-100'>
								<thead>
									<tr>
										<th scope='col'>Kompanija</th>
										<th scope='col'>{creditCompany.name}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope='row'>Pasiūlymas</th>
										<th scope='row'>{confirmation.creditOfferFrom} €</th>
									</tr>
									{confirmation.creditOfferFrom !== confirmation.creditOfferTo && (
										<tr>
											<th scope='row'>Maksimalus pasiūlymas</th>
											<th scope='row'>
												{confirmation.creditOfferTo} €{' '}
												<Tooltip
													title='Maksimali paskolos suma, kurią gali pasiūlyti kredito įstaiga. Paskolos sąlygos gali keistis.'
													placement='top'
												>
													<Info
														className='user-request-info-tooltip'
														style={{
															width: 18,
															height: 18,
														}}
													/>
												</Tooltip>
											</th>
										</tr>
									)}
									<tr>
										<th scope='row'>Terminas</th>
										<th scope='row'>{confirmation.creditLength} mėn.</th>
									</tr>
									<tr>
										<th scope='row'>Palūkanos</th>
										<th scope='row'>{confirmation.interestRate || '0 '} %</th>
									</tr>
									<tr>
										<th scope='row'>BVKKMN</th>
										<th scope='row'>
											{confirmation.BVKKMN} %{' '}
											<Tooltip
												title='BVKKMN - bendra vartojimo kredito kainos metinė norma (metinė palūkanų norma ir administraciniai mokesčiai, išreiškiami procentais).'
												placement='top'
											>
												<Info
													className='user-request-info-tooltip'
													style={{
														width: 18,
														height: 18,
													}}
												/>
											</Tooltip>
										</th>
									</tr>
									<tr>
										<th scope='row'>Įmoka</th>
										<th scope='row'>{confirmation.monthlyPayment} € / mėn.</th>
									</tr>
									<tr>
										<th scope='col'>Papildomi vienkartiniai mokesčiai</th>
										<th></th>
									</tr>
									<tr>
										<th scope='row'>Sutarties mokestis</th>
										<th scope='row'>{confirmation.fee} €</th>
									</tr>
									{creditCompany.isCreditUnion && (
										<>
											<tr>
												<th scope='row'>Stojimo mokestis</th>
												<th scope='row'>{confirmation.entranceFee} €</th>
											</tr>
											<tr>
												<th scope='row'>Pajinis įnašas</th>
												<th scope='row'>{confirmation.share} €</th>
											</tr>
										</>
									)}
								</tbody>
							</table>
						</div>
					</div>

					<small>{creditCompany.description}</small>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleAccept} color='primary' disabled={this.state.isLoading}>
						Patvirtinti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default AcceptConfirmationModal;
