import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

class DeleteCreditCompanyTypeModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
		};
	}

	handleSubmit = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/creditCompanyTypes/' + this.props.type._id, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error('Nepavyko ištrinti kredito įstaigos tipo');
				} else {
					toast.success('Sėkmingai ištrintas kredito įstaigos tipas');

					this.props.getCreditCompanyTypes();
					this.handleClose();
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleClose = () => {
		this.props.setContainerState({
			deleteCreditCompanyTypeModalOpen: false,
		});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog open={containerState.deleteCreditCompanyTypeModalOpen} onClose={this.handleClose}>
				<DialogTitle>Kredito įstaigos tipo ištrinimas</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Ištrynus kredito įstaigos tipą, visos kredito įstaigos priklausančios šiam tipui, po
						ištrynimo pradės priklausyti numatytajam kredito įstaigos tipui. Numatytasis kredito
						įstaigos tipas matys visas naujai patvirtintas vartotojų paraiškas.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
					<Button
						type='submit'
						color='primary'
						disabled={this.state.isLoading}
						onClick={this.handleSubmit}
					>
						Patvirtinti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default DeleteCreditCompanyTypeModal;
