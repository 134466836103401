import React, { Component } from 'react';
import { toast } from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { FilterList, CloudDownload } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/lt';
import DateFnsUtils from '@date-io/date-fns';

class Filters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			availableCampaigns: [],
			availableKeywords: [],
			availableSources: [],
			availableUtmSources: [],
			availableUtmMediums: [],
			availableUtmCampaigns: [],
			availableUtmTerms: [],
			filterAnchor: null,
			filterOpen: false,
			reportDownloading: false,
			filter: {
				dateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
				dateTo: moment(Date.now()).format('YYYY-MM-DD'),
				campaign: 'all',
				keyword: 'all',
				source: 'all',
				utm_source: 'all',
				utm_medium: 'all',
				utm_campaign: 'all',
				utm_term: 'all',
			},
		};

		this.getCampaigns(true);
	}

	getCampaignsReport = () => {
		this.setState({ reportDownloading: true });

		const filter = new URLSearchParams({
			dateFrom: new Date(this.state.filter.dateFrom + 'T00:00:00.000Z'),
			dateTo: new Date(this.state.filter.dateTo + 'T23:59:59Z'),
			campaign: this.state.filter.campaign,
			keyword: this.state.filter.keyword,
			source: this.state.filter.source,
			utm_source: this.state.filter.utm_source,
			utm_medium: this.state.filter.utm_medium,
			utm_campaign: this.state.filter.utm_campaign,
			utm_term: this.state.filter.utm_term,
		}).toString();

		fetch(process.env.REACT_APP_API + '/api/campaigns/report?' + filter, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				const { href } = response;

				if (!href) {
					return toast.error('Nepavyko sugeneruoti ataskaitos');
				}

				const win = window.open(href, '_blank');
				win.focus();
			})
			.catch((error) => {
				toast.error('Serverio klaida');
			})
			.finally(() => {
				this.setState({ reportDownloading: false });
			});
	};

	getCampaigns = (isInitialLoad = false) => {
		const { setRequests, setStatistics } = this.props;

		const filter = new URLSearchParams({
			dateFrom: new Date(this.state.filter.dateFrom + 'T00:00:00.000Z'),
			dateTo: new Date(this.state.filter.dateTo + 'T23:59:59Z'),
			campaign: this.state.filter.campaign,
			keyword: this.state.filter.keyword,
			source: this.state.filter.source,
			utm_source: this.state.filter.utm_source,
			utm_medium: this.state.filter.utm_medium,
			utm_campaign: this.state.filter.utm_campaign,
			utm_term: this.state.filter.utm_term,
		}).toString();

		fetch(process.env.REACT_APP_API + '/api/campaigns?' + filter, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					throw '';
				}

				setRequests(response.requests);
				setStatistics(response.statistics);

				if (isInitialLoad)
					this.setState({
						availableCampaigns: response.available.campaigns,
						availableKeywords: response.available.keywords,
						availableSources: response.available.sources,
						availableUtmSources: response.available.utm_sources,
						availableUtmMediums: response.available.utm_mediums,
						availableUtmCampaigns: response.available.utm_campaigns,
						availableUtmTerms: response.available.utm_terms,
					});
			})
			.catch((error) => {
				toast.error('Serverio klaida');
			});
	};

	handleFilterOpen = (event) => {
		this.setState({
			filterAnchor: event.currentTarget,
			filterOpen: true,
		});
	};

	handleFilterClose = () => {
		this.setState({
			filterAnchor: null,
			filterOpen: false,
		});
	};

	handleFilterChange = (target, value) => {
		let { filter } = this.state;

		filter[target] = value;

		this.setState({
			filter,
		});
	};

	render() {
		return (
			<>
				<Tooltip title='Atsisiųsti CSV ataskaitą' placement='top'>
					<IconButton disabled={this.state.reportDownloading} onClick={this.getCampaignsReport}>
						{this.state.reportDownloading ? (
							<div
								style={{
									padding: 12,
								}}
							>
								<div class='spinner' />
							</div>
						) : (
							<CloudDownload />
						)}
					</IconButton>
				</Tooltip>

				<IconButton onClick={this.handleFilterOpen}>
					<FilterList />
				</IconButton>

				<Popover
					open={this.state.filterOpen}
					anchorEl={this.state.filterAnchor}
					onClose={this.handleFilterClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					<div className='p-4'>
						<div>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									fullWidth
									margin='normal'
									label='Nuo'
									name='from'
									className='m-0'
									value={this.state.filter.dateFrom}
									onChange={(date) =>
										this.handleFilterChange('dateFrom', moment(date).format('YYYY-MM-DD'))
									}
									format={'dd-MM-yyyy'}
								/>
							</MuiPickersUtilsProvider>
						</div>

						<div className='mt-3'>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									fullWidth
									margin='normal'
									label='Iki'
									name='to'
									className='m-0'
									value={this.state.filter.dateTo}
									onChange={(date) =>
										this.handleFilterChange('dateTo', moment(date).format('YYYY-MM-DD'))
									}
									format={'dd-MM-yyyy'}
								/>
							</MuiPickersUtilsProvider>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='campaign-label'>
								Campaign
							</InputLabel>
							<NativeSelect
								controlId='campaign'
								labelId='campaign-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('campaign', event.target.value)}
								value={this.state.filter.campaign}
								inputProps={{
									name: 'campaign',
									id: 'campaign',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableCampaigns.map((campaign, index) => (
									<option key={index} value={campaign}>
										{campaign}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='source-label'>
								Source
							</InputLabel>
							<NativeSelect
								controlId='source'
								labelId='source-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('source', event.target.value)}
								value={this.state.filter.source}
								inputProps={{
									name: 'source',
									id: 'source',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableSources.map((source, index) => (
									<option key={index} value={source}>
										{source}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='keyword-label'>
								Keyword
							</InputLabel>
							<NativeSelect
								controlId='keyword'
								labelId='keyword-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('keyword', event.target.value)}
								value={this.state.filter.keyword}
								inputProps={{
									name: 'keyword',
									id: 'keyword',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableKeywords.map((keyword, index) => (
									<option key={index} value={keyword}>
										{keyword}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='keyword-label'>
								utm_source
							</InputLabel>
							<NativeSelect
								controlId='utm_source'
								labelId='utm_source-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('utm_source', event.target.value)}
								value={this.state.filter.utm_source}
								inputProps={{
									name: 'utm_source',
									id: 'utm_source',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableUtmSources.map((source, index) => (
									<option key={index} value={source}>
										{source}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='keyword-label'>
								utm_medium
							</InputLabel>
							<NativeSelect
								controlId='utm_medium'
								labelId='utm_medium-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('utm_medium', event.target.value)}
								value={this.state.filter.utm_medium}
								inputProps={{
									name: 'utm_medium',
									id: 'utm_medium',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableUtmMediums.map((medium, index) => (
									<option key={index} value={medium}>
										{medium}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='keyword-label'>
								utm_campaign
							</InputLabel>
							<NativeSelect
								controlId='utm_campaign'
								labelId='utm_campaign-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('utm_campaign', event.target.value)}
								value={this.state.filter.utm_campaign}
								inputProps={{
									name: 'utm_campaign',
									id: 'utm_campaign',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableUtmCampaigns.map((campaign, index) => (
									<option key={index} value={campaign}>
										{campaign}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='mt-3'>
							<InputLabel shrink id='keyword-label'>
								utm_term
							</InputLabel>
							<NativeSelect
								controlId='utm_term'
								labelId='utm_term-label'
								className='w-100'
								onChange={(event) => this.handleFilterChange('utm_term', event.target.value)}
								value={this.state.filter.utm_term}
								inputProps={{
									name: 'utm_term',
									id: 'utm_term',
								}}
							>
								<option value='all'>Visi</option>

								{this.state.availableUtmTerms.map((term, index) => (
									<option key={index} value={term}>
										{term}
									</option>
								))}
							</NativeSelect>
						</div>

						<div className='text-right mt-2'>
							<Button
								color='primary'
								onClick={() => {
									this.getCampaigns();
									this.handleFilterClose();
								}}
							>
								Išsaugoti
							</Button>
						</div>
					</div>
				</Popover>
			</>
		);
	}
}

export default Filters;
