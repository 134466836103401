import { v4 } from "uuid";

const getReminders = () => {
	const reminders = localStorage.getItem('reminders');
	let remindersJson = {};

	if (reminders) {
		try {
			remindersJson = JSON.parse(reminders);
		} catch {
			localStorage.removeItem('reminders');
		}
	}

	return remindersJson;
};

const setReminders = (reminders) => {
	localStorage.setItem('reminders', JSON.stringify(reminders));
};

export const addReminder = (requestId, text, time) => {
  const reminders = getReminders();
  
  if (!reminders[requestId]) {
    reminders[requestId] = [];
  }
  
  reminders[requestId].push({
    id: v4(),
    requestId,
    text,
    time,
  });
  
  setReminders(reminders);
};

export const getRequestReminders = (requestId) => {
  const reminders = getReminders();
  return reminders[requestId] || [];
};

export const removeReminder = (requestId, id) => {
  const reminders = getReminders();
  
  if (reminders[requestId]) {
    reminders[requestId] = reminders[requestId].filter((reminder) => reminder.id !== id);
  }
  
  setReminders(reminders);
};

export const getActiveReminders = () => {
  const reminders = getReminders();
  const activeReminders = [];
  
  for (const requestId in reminders) {
    const requestReminders = reminders[requestId];
    
    for (const reminder of requestReminders) {
      if (reminder.time * 1000 <= Date.now()) {
        activeReminders.push(reminder);
      }
    }
  }
  
  return activeReminders;
};
