import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Logo from '../../../../assets/img/logo.png';
import { Redirect, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import authErrors from '../../../../locale/lt_LT/auth-errors';
import PasswordChecker from '../../../shared/pasword-checker';
import Popper from '@material-ui/core/Popper';

const queryString = require('query-string');

const styles = {
	img: {
		maxHeight: '40px !important',
		height: 'unset !important',
	},
};

class CompanySignup extends Component {
	constructor() {
		super();

		let queryParams = queryString.parse(window.location.search);

		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			contactNumber: '',
			confirmationCode: queryParams.confirmationCode || '',
			isLoading: false,
		};
	}

	componentDidMount() {
		document.title = 'Credit King - registracija';
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/users', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(this.state),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					this.setState({ isLoading: false });
					toast.error(authErrors[response.error] || response.error);
				} else {
					if (response.success) {
						// no errors occured, then login
						fetch(process.env.REACT_APP_API + '/api/users/login', {
							method: 'POST',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								email: this.state.email,
								password: this.state.password,
							}),
						})
							.then((response) => response.json())
							.then((response) => {
								if (response.error) {
									toast.error(authErrors[response.error] || response.error);
								} else {
									// saving JWT token in localStorage
									localStorage.setItem('token', response.token);

									window.location.reload();
								}
							})
							.catch((error) => {
								toast.error(error);
							});
					}
				}
			})
			.catch((error) => {
				this.setState({ isLoading: false });

				toast.error(error);
			});
	};

	render() {
		let appState = this.props.$helper.getAppState();

		// do not allow logged in users
		if (appState.authenticated) {
			return <Redirect to='/' />;
		}

		return (
			<div className='container auth-page' id='signin-page'>
				<div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 offset-0'>
					<Paper className='input-form-paper'>
						<div className='text-center'>
							<img
								src={Logo}
								className={`${this.props.classes.img} img-fluid auth-page-logo`}
								alt='creditking-logo'
							/>
						</div>
						<form onSubmit={this.handleSubmit}>
							<div className='row'>
								<div className='col-6'>
									<TextField
										required
										autoFocus
										fullWidth
										id='firstName'
										label='Vardas'
										type='text'
										onChange={this.handleChange}
										value={this.state.firstName}
										margin='dense'
									/>
								</div>
								<div className='col-6'>
									<TextField
										required
										fullWidth
										id='lastName'
										label='Pavardė'
										type='text'
										onChange={this.handleChange}
										value={this.state.lastName}
										margin='dense'
									/>
								</div>
								<div className='col-12'>
									<TextField
										required
										type='email'
										fullWidth
										id='email'
										label='El. paštas'
										onChange={this.handleChange}
										value={this.state.email}
										margin='dense'
									/>
								</div>
								<div className='col-12'>
									<TextField
										fullWidth
										required
										type='number'
										id='contactNumber'
										label='Telefono numeris'
										value={this.state.contactNumber}
										onChange={this.handleChange}
										margin='dense'
										InputProps={{
											startAdornment: <InputAdornment position='start'>+370</InputAdornment>,
										}}
									/>
								</div>
								<div className='col-12'>
									<TextField
										required
										type='password'
										fullWidth
										id='password'
										label='Slaptažodis'
										onChange={this.handleChange}
										value={this.state.password}
										margin='dense'
									/>
								</div>

								<Popper
									open={
										this.state.password &&
										this.state.password.length > 0 &&
										!PasswordChecker.isValid(this.state.password) &&
										this.state.email !== this.state.password
									}
									placement='bottom'
									anchorEl={document.getElementById('password')}
									style={{
										zIndex: 10000000000000000,
										position: 'absolute',
									}}
									disablePortal={true}
									className='fade-in mt-1'
								>
									<Paper
										className='p-1'
										style={{
											fontSize: 15,
										}}
									>
										<ul>
											<li
												style={{
													color: PasswordChecker.length(this.state.password) ? 'green' : 'red',
												}}
											>
												Slaptažodis privalo būti ne trumpesnis nei 8 simboliai.
											</li>
											<li
												style={{
													color: PasswordChecker.uppercase(this.state.password) ? 'green' : 'red',
												}}
											>
												Slaptažodyje privalo būti bent viena didžioji raidė (A-Z).
											</li>
											<li
												style={{
													color: PasswordChecker.lowercase(this.state.password) ? 'green' : 'red',
												}}
											>
												Slaptažodyje privalo būti bent viena mažoji raidė (a-z).
											</li>
											<li
												style={{
													color: PasswordChecker.digits(this.state.password) ? 'green' : 'red',
												}}
											>
												Slaptažodyje privalo būti bent vienas skaičius (0-9).
											</li>
											<li
												style={{
													color: this.state.email !== this.state.password ? 'green' : 'red',
												}}
											>
												Slaptažodis negali būti sudarytas iš el. pašto adreso ar jo dalies.
											</li>
										</ul>
									</Paper>
								</Popper>

								<div className='col-12'>
									<TextField
										required
										fullWidth
										id='confirmationCode'
										label='Patvirtinimo kodas'
										type='text'
										onChange={this.handleChange}
										value={this.state.confirmationCode}
										margin='dense'
									/>
								</div>
								<div className='col-12 mt-3'>
									<Button
										fullWidth
										type='submit'
										variant='contained'
										color='primary'
										disabled={
											this.state.isLoading ||
											(!PasswordChecker.isValid(this.state.password) &&
												this.state.email !== this.state.password)
										}
									>
										Registruotis
									</Button>
								</div>
							</div>
						</form>
						<div className='text-center mt-3 mb-3'>
							Jau turite paskyrą? <Link to='/'>Prisijunkite</Link>
						</div>
					</Paper>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(CompanySignup);
