import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Cookies from 'js-cookie';
import SubmitRequestForm from '../../shared/request/submit-request-form';

class SubmitRequest extends Component {
	constructor(props) {
		super(props);

		let appState = props.$helper.getAppState();

		this.state = {
			municipalities: [],
			isSubmited: false,
			creditType: 'spending',
			firstName: appState.firstName,
			lastName: appState.lastName,
			age: null,
			termsAccepted: false,
			termsAccepted2: false,
			termsAccepted3: false,
			contactNumber: '',
			personalID: '',
			documentID: '',
			documentType: '',
			occupationType: '',
			workExperience: '',
			personalIncome: '',
			financialObligations: '',
			changeInIncome: false,
			ownsRealEstate: false,
			ownsCar: false,
			incomeChangeReason: '',
			housingObligations: 0,
			residenceMunicipality: '',
			residenceCity: '',
			residenceAddress: '',
			maritalStatus: '',
			partnerFirstName: '',
			partnerLastName: '',
			partnerContactNumber: '',
			partnerEmail: '',
			partnerPersonalID: '',
			partnerPersonalIncome: '',
			partnerFinancialObligations: '',
			creditAmount: 1000,
			creditLength: 1,
			carInfo: '',
			carManufacturingYear: '',
			educationLevel: '',
			childrenCount: 0,
			isLoading: false,
			validIncome: false,
			documentFile1: null,
			documentFile2: null,
			documentFile3: null,
			documentFiles: [],
			refinancingFiles: [],
			refinancingFile1: null,
			refinancingFile2: null,
			refinancingFile3: null,
			driversLicense: false,
			futurePersonalIncome: '',
			futureFinancialObligations: '',
			carCreditType: null,
			additionalDocumentsModalOpen: false,
			acceptConfirmationModalOpen: false,
			requiredDocuments: [],
			activeStep: 0,

			// editable
			isEditing: false,
			editableBusinessCreditType: '',
			editableCreditType: '',
			editableCreditAmount: 0,
			editableCreditLength: 0,
		};

		this.state.ref = Cookies.get('creditking_ref');
		this.state.analyticsClientId = Cookies.get('_ga');

		// load user info
		fetch(process.env.REACT_APP_API + '/api/requests', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(response.error);
				} else {
					// keep the current ref
					const { ref, analyticsClientId } = this.state;

					this.setState(response[0]);
					this.setState({
						documentFile: false,
						creditType: 'spending',
						changeInIncome: false,
						incomeChangeReason: '',
						futurePersonalIncome: '',
						housingObligations: 0,
						futureFinancialObligations: '',
						workExperience: '',
						carInfo: '',
						carManufacturingYear: '',
						driversLicense: false,
						documentID: '',
						documentType: '',
						carCreditType: undefined,
						ownsRealEstate: false,
						ownsCar: false,
						ref,
						analyticsClientId,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});

		this.getMunicipalities();
	}

	componentDidMount() {
		document.title = 'Credit King - nauja paraiška';
	}

	getMunicipalities = () => {
		fetch(process.env.REACT_APP_API + '/api/static/lithuanianMunicipalities', {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((response) => {
				if (Array.isArray(response)) {
					this.setState({
						municipalities: response,
					});
				}
			});
	};

	checkIncome = () => {
		if (
			this.state.personalIncome !== 0 &&
			this.state.creditAmount / this.state.creditLength < this.state.personalIncome
		) {
			this.setState({
				validIncome: true,
			});
		} else {
			this.setState({
				validIncome: false,
			});
		}
	};

	handleSubmit = async (event, skipFileModal = false) => {
		event.preventDefault();

		let requiredDocuments = [];

		if (['working-abroad', 'self-employed'].includes(this.state.occupationType) && !skipFileModal) {
			requiredDocuments.push({
				title: 'Pajamas įrodantys dokumentai',
				description:
					'Prašome pateikti pajamas pagrindžiančius dokumentus už paskutinius 4 mėnesius. Galite įkelti ne daugiau kaip tris failus.',
				location: 'documentFiles',
				required: true,
				maxFiles: 3,
			});
		}

		if (this.state.creditType === 'refinancing' && !skipFileModal) {
			requiredDocuments.push({
				title: 'Refinansavimo dokumentai',
				description:
					'Prašome pateikti finansinių įsipareigojimų (paskolų, lizingo, kt.), kuriuos norite refinansuoti, sutarčių kopijas. Galite įkelti ne daugiau kaip tris failus.',
				location: 'refinancingFiles',
				required: true,
				maxFiles: 3,
			});
		}

		if (requiredDocuments.length > 0 && !skipFileModal) {
			return this.setState({
				additionalDocumentsModalOpen: true,
				requiredDocuments: requiredDocuments,
			});
		}

		this.checkIncome();
		this.setState({
			isSubmited: true,
			isLoading: true,
		});

		process.nextTick(() => {
			if (this.state.validIncome) {
				let formData = new FormData();

				for (let data in this.state) {
					formData.append(data, this.state[data]);
				}

				formData.append('documentFile1', this.state.documentFiles[0]);
				formData.append('documentFile2', this.state.documentFiles[1]);
				formData.append('documentFile3', this.state.documentFiles[2]);

				if (this.state.creditType === 'refinancing') {
					formData.append('refinancingFile1', this.state.refinancingFiles[0]);
					formData.append('refinancingFile2', this.state.refinancingFiles[1]);
					formData.append('refinancingFile3', this.state.refinancingFiles[2]);
				}

				fetch(process.env.REACT_APP_API + '/api/requests', {
					method: 'POST',
					headers: {
						// Accept: 'application/json',
						// 'Content-Type': 'application/json',
						// 'Content-Type': 'multipart/form-data',
						Authorization: localStorage.getItem('token'),
					},
					body: formData,
				})
					.then((response) => response.json())
					.then((response) => {
						if (response.error) {
							this.setState({ isLoading: false });
							return toast.error('Formos klaida');
						}

						toast.success('Paraiška sėkmingai sukurta');
						return this.props.$helper.initRedirect('/requests/' + response._id);
					})
					.catch((error) => {
						toast.error('Formos klaida');
					});
			} else {
				this.setState({
					isSubmited: false,
					isLoading: false,
				});

				toast.error('Nepakankamos pajamos');
			}
		});
	};

	render() {
		return (
			<div className='row fade-in' id='new-request-page'>
				<div className='col-12'>
					<h1 className='app-page-title'>Nauja paraiška</h1>
					<Paper className='input-form-paper'>
						<form onSubmit={this.handleSubmit}>
							<SubmitRequestForm
								state={this.state}
								setState={(state) => this.setState(state)}
								isSignup={false}
								handleSubmit={this.handleSubmit}
							/>
						</form>
					</Paper>
				</div>
			</div>
		);
	}
}

export default SubmitRequest;
