import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RequestAcceptForm from '../shared/request-accept-form';

class RequestAcceptModal extends Component {
	constructor() {
		super();

		this.state = {
			description: '',
			creditOfferFrom: null,
			creditOfferTo: null,
			interestRate: null,
			monthlyPayment: null,
			creditLength: null,
			fee: null,
			BVKKMN: null,
			share: null,
			entranceFee: null,
			isCarLoan: false,
			comment: '',
			isLoading: false,
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	checkIfDisabled = () => {
		function isFloat(x) {
			return !!(x % 1);
		}

		// cases where it is disabled
		return (
			this.state.creditOfferFrom <= 0 ||
			this.state.interestRate < 0 ||
			this.state.monthlyPayment <= 0 ||
			this.state.creditLength <= 0 ||
			this.state.fee < 0 ||
			isFloat(this.state.creditLength) ||
			isFloat(this.state.creditOfferFrom) ||
			(parseInt(this.state.creditOfferTo) && isFloat(this.state.creditOfferTo)) ||
			this.state.interestRate > 100
		);
	};

	handleClose = () => {
		this.props.setContainerState({
			requestAcceptModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleAccept = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let containerState = this.props.getContainerState();

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				creditRequestID: containerState.request._id,
				type: 'accept',
				description: this.state.description,
				creditOfferFrom: this.state.creditOfferFrom,
				creditOfferTo: !this.state.creditOfferTo
					? this.state.creditOfferFrom
					: this.state.creditOfferTo,
				interestRate: !this.state.isCarLoan ? this.state.interestRate : undefined,
				monthlyPayment: this.state.monthlyPayment,
				creditLength: this.state.creditLength,
				fee: this.state.fee,
				BVKKMN: !this.state.isCarLoan ? this.state.BVKKMN : undefined,
				share: this.state.share,
				entranceFee: this.state.entranceFee,
				comment: this.state.comment,
				isCarLoan: this.state.isCarLoan,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					this.handleClose();
					toast.success('Sėkmingai atnaujinta');

					// refresh requests
					this.props.getRequests('', false, true);
				} else {
					toast.error('Nepavyko atnaujinti');
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.requestAcceptModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
			>
				<form onSubmit={this.handleAccept}>
					<DialogTitle id='form-dialog-title'>Patvirtinimas</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Išsiuntus patvirtinimą, paraiška bus perkelta į skiltį "Pateikti pasiūlymai", o
							vartotojui pasirinkus Jūsų pasiūlymą, paraišką rasite skiltyje "Laukia skambučio"
						</DialogContentText>
						<RequestAcceptForm
							state={this.state}
							handleChange={this.handleChange}
							request={containerState.request}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							type='submit'
							color='primary'
							disabled={this.checkIfDisabled() || this.state.isLoading}
						>
							Patvirtinti
						</Button>
						<Button onClick={this.handleClose} color='primary'>
							Atšaukti
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

export default RequestAcceptModal;
