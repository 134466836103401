import React, { Component } from 'react';

class CreditRequestStatus extends Component {
	render() {
		let { request } = this.props;

		return (
			<>
				{request.status === 'unconfirmed' ? (
					<span className='request-status request-status-unconfirmed'>Patvirtinkite tapatybę</span>
				) : request.status === 'canceled' ? (
					<span className='request-status request-status-unconfirmed'>Nepavyko susisiekti</span>
				) : request.status === 'waiting_call' ? (
					<span className='request-status request-status-waiting-call'>Laukia skambučio</span>
				) : request.status === 'waiting_sign' ? (
					<span className='request-status request-status-waiting-call'>Laukia pasirašymo</span>
				) : request.status === 'signed' ? (
					<span className='request-status request-status-completed'>Paskola išduota</span>
				) : request.status === 'unavailable' ? (
					<span className='request-status request-status-unconfirmed'>Atšaukta</span>
				) : request.status === 'waiting_info' ? (
					<span className='request-status request-status-waiting-offer'>Laukia informacijos</span>
				) : request.status === 'waiting_partner_info' ? (
					<span className='request-status request-status-waiting-offer'>
						Laukia sutuoktinio informacijos
					</span>
				) : (
					<span className='request-status request-status-waiting-offer'>
						{request.creditConfirmations.length > 0 ? 'Gautas atsakymas' : 'Laukiama pasiūlymo'}
					</span>
				)}
			</>
		);
	}
}

export default CreditRequestStatus;
