import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

const SetIrrevelantModal = ({ isOpen, handleClose, changeRequestByIndex, request }) => {
	const availableReasons = [
		'Per maža suma',
		'Per didelės palūkanos',
		'Paskolos nebereikia',
		'Paskola paimta kitur',
		'Netinka įmonė',
		'Neatsiliepia',
		'Neturi pajinio įnašo',
		'Neturi kaip pasitvirtinti tapatybės',
		'Nagrinėjama kita kliento paraiška',
		'Neturi dokumentų',
		'Visos įmonės atmetė paraišką',
		'Nepridės sutuoktinio',
		'Kita',
	];

	const [irrevelantReason, setIrrevelantReason] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);

	const handleSubmit = () => {
		setIsLoading(true);

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				isIrrevelant: true,
				irrevelantReason,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					changeRequestByIndex('isIrrevelant', true);
					changeRequestByIndex('irrevelantReason', irrevelantReason);

					toast.success('Paraiška atnaujinta');
					return handleClose();
				}

				toast.error('Nepavyko padaryti paraiškos neaktualia');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby='set-irrevelant-title'
			fullWidth
			maxWidth='sm'
		>
			<DialogTitle id='set-irrevelant-title'>Pažymėti paraišką kaip neaktualią</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<InputLabel htmlFor='irrevelant-reason'>Priežastis</InputLabel>
					<NativeSelect
						value={irrevelantReason}
						onChange={({ target }) => setIrrevelantReason(target.value)}
						inputProps={{
							name: 'irrevelantReason',
							id: 'irrevelant-reason',
						}}
						fullWidth
					>
						<option aria-label='None' value='' />
						{availableReasons.map((reason, index) => (
							<option value={reason} key={index}>
								{reason}
							</option>
						))}
					</NativeSelect>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmit} disabled={!irrevelantReason || isLoading} color='primary'>
					Patvirtinti
				</Button>
				<Button onClick={handleClose} disabled={isLoading}>
					Atšaukti
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SetIrrevelantModal;
