import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import ProfileForm from './profile-form';

class Profile extends Component {
	componentDidMount() {
		document.title = 'Credit King - paskyra';
	}

	render() {
		let appState = this.props.$helper.getAppState();

		let roleTranslation = {
			credit: 'Kredito įstaigos darbuotojas',
			user: 'Vartotojas',
			admin: 'Administratorius',
			consultant: 'Konsultantas',
		};

		return (
			<>
				<h1 className='app-page-title'>Paskyra</h1>
				<Paper className='p-4'>
					<div className='row'>
						<div className='col-lg-4 col-md-12'>
							<div className='container-fluid text-center justify-content-center'>
								<AccountCircle className='profile-user-avatar' />
								<Typography
									variant='h5'
									style={{
										fontWeight: 'lighter',
										marginTop: '-15px',
									}}
								>
									{appState.firstName} {appState.lastName}{' '}
								</Typography>
								<Typography
									variant='caption'
									style={{
										fontWeight: 'lighter',
										marginTop: '-5px',
									}}
								>
									{roleTranslation[appState.role]}
								</Typography>
							</div>
						</div>
						<div className='col-lg-6 col-md-12'>
							<ProfileForm appState={appState} $helper={this.props.$helper} />
						</div>
					</div>
				</Paper>
			</>
		);
	}
}

export default Profile;
