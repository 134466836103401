import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import CreditCompanyTypeForm from './shared/credit-company-type-form';
import moment from 'moment';

class NewCreditCompanyTypeModal extends Component {
	constructor() {
		super();

		this.state = {
			name: '',
			creditAmountFrom: null,
			creditAmountTo: null,
			creditLengthFrom: null,
			creditLengthTo: null,
			personalIncomeFrom: null,
			personalIncomeTo: null,
			ageFrom: null,
			ageTo: null,
			municipality: 'all',
			municipalities: [],
			allMunicipalities: true,
			creditTypes: [],
			listOfCreditTypes: ['car', 'spending', 'refinancing', 'spending-real-estate-exchange'],
			carCreditTypes: [],
			workExperience: 'all',
			workExperiences: [],
			driversLicense: 'all',
			createdAtFrom: moment(0).format('YYYY-MM-DD'),
			bankAccountRequired: false,
			additionalChildIncomeRequired: false,
			additionalChildIncome: null,
			allOccupationTypes: true,
			occupationTypes: [],
			isLoading: false,
		};
	}
	componentWillMount() {
		let { props } = this;

		this.setState({
			name: props.type.name,
			creditAmountFrom: props.type.creditAmountFrom,
			creditAmountTo: props.type.creditAmountTo,
			creditLengthFrom: props.type.creditLengthFrom,
			creditLengthTo: props.type.creditLengthTo,
			personalIncomeFrom: props.type.personalIncomeFrom,
			personalIncomeTo: props.type.personalIncomeTo,
			ageFrom: props.type.ageFrom,
			ageTo: props.type.ageTo,
			municipality: props.type.municipality,
			creditTypes: props.type.creditTypes,
			carCreditTypes: props.type.carCreditTypes || [],
			listOfCreditTypes: ['car', 'spending', 'refinancing', 'spending-real-estate-exchange'],
			municipalities: props.type.municipalities || [],
			allMunicipalities: !(props.type.municipalities && props.type.municipalities.length > 0),
			workExperiences: props.type.workExperiences,
			driversLicense: props.type.driversLicense,
			createdAtFrom: moment(props.type.createdAtFrom ? props.type.createdAtFrom : 0).format(
				'YYYY-MM-DD',
			),
			bankAccountRequired: props.type.bankAccountRequired || false,
			additionalChildIncomeRequired: props.type.additionalChildIncome ? true : false,
			additionalChildIncome: props.type.additionalChildIncome,
			occupationTypes: props.type.occupationTypes || [],
			allOccupationTypes: !(props.type.occupationTypes && props.type.occupationTypes.length > 0),
			isLoading: false,
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/creditCompanyTypes/' + this.props.type._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				name: this.state.name,
				creditAmountFrom: this.state.creditAmountFrom,
				creditAmountTo: this.state.creditAmountTo,
				creditLengthFrom: this.state.creditLengthFrom,
				creditLengthTo: this.state.creditLengthTo,
				personalIncomeFrom: this.state.personalIncomeFrom,
				personalIncomeTo: this.state.personalIncomeTo,
				ageFrom: this.state.ageFrom,
				ageTo: this.state.ageTo,
				municipalities: this.state.allMunicipalities ? [] : this.state.municipalities,
				creditTypes: this.state.creditTypes,
				carCreditTypes: this.state.creditTypes.includes('car')
					? this.state.carCreditTypes
					: undefined,
				workExperiences: this.state.workExperiences,
				driversLicense: this.state.driversLicense,
				createdAtFrom: this.state.createdAtFrom + 'T00:00:00.000Z',
				bankAccountRequired: this.state.bankAccountRequired,
				additionalChildIncome: this.state.additionalChildIncomeRequired
					? this.state.additionalChildIncome
					: undefined,
				occupationTypes: this.state.allOccupationTypes ? [] : this.state.occupationTypes,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error('Nepavyko atnaujinti naujo kredito įstaigos tipo');
				} else {
					toast.success('Sėkmingai atnaujintas kredito įstaigos tipas');
					this.props.getCreditCompanyTypes();
					this.handleClose();
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleClose = () => {
		this.props.setContainerState({
			editCreditCompanyTypeModalOpen: false,
		});
	};

	checkIfDisabled = () => {
		return (
			this.state.isLoading ||
			!this.state.name ||
			!this.state.creditAmountFrom ||
			!this.state.creditAmountTo ||
			Number(this.state.creditAmountFrom) >= Number(this.state.creditAmountTo) ||
			Number(this.state.creditAmountFrom) < 1 ||
			Number(this.state.creditAmountTo) < 1 ||
			Number(this.state.creditLengthFrom) < 1 ||
			Number(this.state.creditLengthTo) > 84 ||
			Number(this.state.creditLengthFrom) >= Number(this.state.creditLengthTo) ||
			Number(this.state.creditTypes.length) === 0 ||
			Number(this.state.ageFrom) > Number(this.state.ageTo) ||
			Number(this.state.personalIncomeFrom) > Number(this.state.personalIncomeTo) ||
			this.state.workExperiences.length === 0 ||
			(this.state.creditTypes.includes('car') && this.state.carCreditTypes.length === 0) ||
			(this.state.additionalChildIncomeRequired && !this.state.additionalChildIncome) ||
			(!this.state.allOccupationTypes && this.state.occupationTypes.length === 0)
		);
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.editCreditCompanyTypeModalOpen}
				onClose={this.handleClose}
				onEnter={() => {
					this.setState({
						name: this.props.type.name,
						creditAmountFrom: this.props.type.creditAmountFrom,
						creditAmountTo: this.props.type.creditAmountTo,
						creditLengthFrom: this.props.type.creditLengthFrom,
						creditLengthTo: this.props.type.creditLengthTo,
						oldState: {
							name: this.props.type.name,
							creditAmountFrom: this.props.type.creditAmountFrom,
							creditAmountTo: this.props.type.creditAmountTo,
							creditLengthFrom: this.props.type.creditLengthFrom,
							creditLengthTo: this.props.type.creditLengthTo,
						},
					});
				}}
			>
				<DialogTitle>Kredito įstaigos tipo atnaujinimas</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Norint atnaujinti kredito įstaigos tipą, užpildykite apačioje esančią formą. Pakeitus
						tipą, automatiškai atsinaujins ir šiam tipui priklausančios kredito įstaigos.
					</DialogContentText>
					<CreditCompanyTypeForm
						state={this.state}
						setState={(state) => this.setState(state)}
						handleSubmit={this.handleSubmit}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
					<Button
						type='submit'
						color='primary'
						disabled={this.checkIfDisabled()}
						onClick={this.handleSubmit}
					>
						Patvirtinti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default NewCreditCompanyTypeModal;
