const PasswordChecker = {
	length: (val) => {
		return val.length >= 8;
	},
	uppercase: (val) => {
		return /[A-Z]/.test(val);
	},
	lowercase: (val) => {
		return /[a-z]/.test(val);
	},
	digits: (val) => {
		return /\d/.test(val);
	},
	special: (val) => {
		return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(val);
	},
	isValid: (val) => {
		return val.length >= 8 && /[A-Z]/.test(val) && /[a-z]/.test(val) && /\d/.test(val);
	},
};

export default PasswordChecker;
