import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

function StatisticsTable({ statistics }) {
	if (!statistics) {
		statistics = {};
	}

	return (
		<>
			<div>
				<Typography variant='h5' component='div' className='pl-2 pt-2'>
					Statistika
				</Typography>
			</div>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Sukurtos paraiškos</TableCell>
						<TableCell align='right'>Identifikuotos paraiškos</TableCell>
						<TableCell align='right'>Pasirašytos paraiškos</TableCell>
						<TableCell align='right'>Pasiraštų paraiškų suma</TableCell>
						<TableCell align='right'>Pelnas</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableCell>{statistics.allRequestsCount || '0'}</TableCell>
					<TableCell align='right'>{statistics.confirmedRequestsCount || '0'}</TableCell>
					<TableCell align='right'>{statistics.signedRequestsCount || '0'}</TableCell>
					<TableCell align='right'>{statistics.signedAmount || '0'} €</TableCell>
					<TableCell align='right'>{statistics.revenue || '0'} €</TableCell>
				</TableBody>
			</Table>
		</>
	);
}

export default StatisticsTable;
