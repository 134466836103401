import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { toast } from 'react-toastify';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { CloudUpload, Clear } from '@material-ui/icons';
import withWidth from '@material-ui/core/withWidth';

class InfoSubmissionModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			activeStep: 0,
			submittedInfo: [[]],
		};
	}

	handleClose = () => {
		this.props.setContainerState({ openInfoSubmissionModal: false });
	};

	handleTextInput = (event, index) => {
		let { submittedInfo } = this.state;
		submittedInfo[index] = event.target.value;

		this.setState({ submittedInfo });
	};

	handleDocumentUpload = async (event, submittedInfoIndex) => {
		let files = [...event.target.files];

		if (!event.target || !event.target.files || event.target.files.length < 1) return;

		for (let file of event.target.files) {
			if (file.size > 4000000)
				return toast.error(
					event.target.files.length === 1
						? 'Failas yra per didelis'
						: 'Vienas iš failų yra per didelis',
				);
		}

		let affectedSubmittedInfo = this.state.submittedInfo[submittedInfoIndex];
		let affectedInfoList = this.props.infoList[submittedInfoIndex];

		if (!affectedSubmittedInfo) affectedSubmittedInfo = [];

		if (affectedSubmittedInfo.length + event.target.files.length > affectedInfoList.maxFiles) {
			return toast.error('Failų kiekis per didelis');
		}

		let promises = [];
		this.setState({ isLoading: true });

		// handle file upload only if none of the files are too big
		for (let file of event.target.files) {
			promises.push(this.uploadDocument(file));
		}

		const resolved = await Promise.all(promises);

		files = files.map((file, index) => {
			if (!resolved[index].path) {
				toast.warn('Failo įkelti nepavyko');
				return {};
			}

			file.path = resolved[index].path;
			return file;
		});

		// clear the errored ones
		files = files.filter((file) => file.path);
		this.setState({ isLoading: false });

		let { submittedInfo } = this.state;
		if (!submittedInfo[submittedInfoIndex]) submittedInfo[submittedInfoIndex] = [];

		submittedInfo[submittedInfoIndex] = [...submittedInfo[submittedInfoIndex], ...files];

		this.setState({ submittedInfo });
	};

	uploadDocument = (file) => {
		let formData = new FormData();
		formData.append('file', file);

		return fetch(process.env.REACT_APP_API + '/api/files', {
			method: 'POST',
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			body: formData,
		})
			.then((response) => response.json())
			.then((response) => {
				return response;
			})
			.catch((error) => {
				toast.error(error);
				return { error };
			});
	};

	removeDocument = (index, submittedInfoIndex) => {
		const { infoList } = this.props;

		// only allow removing document for file types
		if (infoList[submittedInfoIndex].type !== 'file') return;
		let { submittedInfo } = this.state;
		let affectedSubmittedInfo = submittedInfo[submittedInfoIndex];

		if (!affectedSubmittedInfo) return;

		affectedSubmittedInfo = affectedSubmittedInfo.filter((_info, i) => i !== index);
		submittedInfo[submittedInfoIndex] = affectedSubmittedInfo;
		this.setState({ submittedInfo });
	};

	render() {
		let { containerState } = this.props;
		const { infoList } = this.props;

		return (
			<Dialog
				onClose={this.handleClose}
				aria-labelledby='info-submission-modal-title'
				open={containerState.openInfoSubmissionModal}
				width={'md'}
				fullWidth
				// make fullscreen for mobile using withWidth hook
				fullScreen={['xs', 'sm', 'md'].includes(this.props.width)}
			>
				<DialogTitle id='info-submission-modal-title' onClose={this.handleClose}>
					Papildomos informacijos pateikimas
				</DialogTitle>
				<DialogContent>
					<Stepper activeStep={this.state.activeStep} orientation='vertical' className='p-1'>
						{infoList.map((step, index) => (
							<Step key={index}>
								<StepLabel>{step.title}</StepLabel>
								<StepContent>
									{step.type === 'file' ? step.description : ''}

									{step.type === 'file' ? (
										<>
											{this.state.submittedInfo &&
												this.state.submittedInfo[index] &&
												this.state.submittedInfo[index].length > 0 && (
													<div className='my-3'>
														<table
															style={{
																width: '100%',
																borderSpacing: '0 10px',
															}}
														>
															{this.state.submittedInfo[index].map((file, submittedInfoIndex) => (
																<tr key={submittedInfoIndex} className='w-100'>
																	<td
																		className='py-1'
																		style={{
																			width: '60px',
																		}}
																	>
																		<img src={file.path} className='uploaded-file-img' />
																	</td>
																	<td class='py-1'>{file.name}</td>
																	<td class='py-1 text-right'>
																		<IconButton
																			disabled={this.state.isLoading}
																			className='p-1'
																			color='secondary'
																			onClick={() => this.removeDocument(submittedInfoIndex, index)}
																		>
																			<Clear />
																		</IconButton>
																	</td>
																</tr>
															))}
														</table>
													</div>
												)}

											{!this.state.submittedInfo[index] ||
											this.state.submittedInfo[index].length <
												this.props.infoList[index].maxFiles ? (
												<div
													class='p-3 mt-2 text-center'
													style={{
														border: '1px dashed  #3f51b5',
														borderRadius: '10px',
													}}
												>
													<IconButton
														variant='contained'
														component='label'
														color='primary'
														disabled={this.state.isLoading}
													>
														{!this.state.isLoading ? (
															<CloudUpload
																style={{
																	height: 84,
																	width: 84,
																}}
															/>
														) : (
															<CircularProgress
																style={{
																	height: 84,
																	width: 84,
																}}
															/>
														)}

														<input
															accept='image/*, application/pdf'
															type='file'
															style={{ display: 'none' }}
															onChange={(event) => this.handleDocumentUpload(event, index)}
															// disabled={state[currentDocument.location].length >= currentDocument.maxFiles}
															multiple
														/>
													</IconButton>

													<br />

													<div>
														<small>
															Maksimalus vieno failo dydis 4MB. Galimi formatai: .jpg, .jpeg, .gif,
															.png ir .pdf.
														</small>
													</div>
												</div>
											) : (
												''
											)}
										</>
									) : (
										<div className='my-2'>
											<TextField
												multiline
												fullWidth
												type='text'
												rows='4'
												onChange={(event) => this.handleTextInput(event, index)}
												value={this.state.submittedInfo[index]}
												label={step.description}
											/>
										</div>
									)}
									{/* uploaded files */}
								</StepContent>
							</Step>
						))}
					</Stepper>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							if (this.state.activeStep === 0) return this.handleClose();

							this.setState({ activeStep: this.state.activeStep - 1 });
						}}
						disabled={this.state.isLoading}
						color='primary'
					>
						Atgal
					</Button>
					<Button
						onClick={() => {
							if (this.state.activeStep === this.props.infoList.length - 1) {
								this.setState({ isLoading: true });

								return this.props
									.handleSubmit(this.state.submittedInfo)
									.then(() => {
										this.handleClose();
									})
									.finally(() => {
										this.setState({ isLoading: false });
									});
							}

							this.setState({ activeStep: this.state.activeStep + 1 });
						}}
						disabled={
							this.state.isLoading ||
							// disable if no files were uploaded
							(this.props.infoList[this.state.activeStep].required &&
								this.props.infoList[this.state.activeStep].type === 'file' &&
								(!this.state.submittedInfo[this.state.activeStep] ||
									this.state.submittedInfo[this.state.activeStep].length === 0)) ||
							// disable if no text was entered and it is required
							(this.props.infoList[this.state.activeStep].required &&
								this.props.infoList[this.state.activeStep].type === 'text' &&
								(!this.state.submittedInfo[this.state.activeStep] ||
									this.state.submittedInfo[this.state.activeStep].length === 0))
						}
						color='primary'
					>
						Tęsti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withWidth()(InfoSubmissionModal);
