import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Filters from './filters';
import CampaignTable from './table/campaign-table';
import StatisticsTable from './table/statistics-table';

class Reports extends Component {
	constructor() {
		super();

		this.state = {
			requests: [],
			statistics: {},
		};
	}

	setRequests = (requests) => this.setState({ requests });

	setStatistics = (statistics) => this.setState({ statistics });

	render() {
		return (
			<div>
				<h1 className='app-page-title'>Klientų srautai</h1>
				<Paper className='px-3 py-2'>
					<div className='w-100 text-right'>
						<Filters
							requests={this.state.requests}
							setRequests={this.setRequests}
							setStatistics={this.setStatistics}
						/>
					</div>

					<StatisticsTable statistics={this.state.statistics} />

					<br />

					<CampaignTable requests={this.state.requests} />
				</Paper>
			</div>
		);
	}
}

export default Reports;
