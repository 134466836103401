import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
	AddCircle,
	InsertDriveFile,
	AssignmentLate,
	Delete,
	Bookmarks,
	Spellcheck,
	Help,
	PermPhoneMsg,
	Assignment,
	AssignmentTurnedIn,
	CloudDownload,
	SupervisedUserCircle,
	AccountBalance,
	Domain,
	ContactSupport,
	Dashboard,
	Apps,
	LocalOffer,
	People,
	Receipt,
	MeetingRoom,
	AssignmentInd,
} from '@material-ui/icons';
import HelpCarousel from '../help/help-carousel';
import CreditCompanyHelpModal from '../help/credit-company-help-modal';

import Logo from '../../../assets/img/logo.png';

class Sidebar extends Component {
	constructor() {
		super();

		this.state = {
			// carousel
			open: false,
			creditCompanyHelpModalOpen: false,
		};
	}

	getContainerState = () => {
		return this.state;
	};

	setContainerState = (state) => {
		this.setState(state);
	};

	render() {
		let appState = this.props.$helper.getAppState();

		return (
			<nav id='sidenav-content'>
				<HelpCarousel
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
				/>
				<div className='text-center img-wrapper'>
					<NavLink to='/'>
						<img
							src={Logo}
							alt='brand-logo'
							className='img-fluid'
							id='main-sidebar-logo'
							ref='sidebarLogo'
						/>
					</NavLink>
				</div>
				<div className='sidenav-menu text-center'>
					{appState.role === 'user' && (
						<>
							<NavLink to='/submit' className='new-request-link'>
								<div className='px-3'>
									<Button
										variant='contained'
										color='default'
										fullWidth
										className='mb-5'
										id='new-request-button'
									>
										Nauja paraiška <AddCircle className='pl-1' id='new-request-circle' />
									</Button>
								</div>
							</NavLink>

							<NavLink
								to='/requests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<InsertDriveFile /> MANO PARAIŠKOS
								</div>
							</NavLink>
							<a href='https://creditking.lt/DUK/' target='_blank' className='sidenav-item'>
								<div className='text-left'>
									<Help /> PAGALBA
								</div>
							</a>
						</>
					)}

					{appState.role === 'credit' && (
						<div>
							{this.state.creditCompanyHelpModalOpen && (
								<CreditCompanyHelpModal
									creditCompanyHelpModalOpen={this.state.creditCompanyHelpModalOpen}
									setState={(state) => this.setState(state)}
								/>
							)}

							<NavLink
								exact={true}
								to='/requests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Bookmarks /> NAUJOS PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/lacking-info'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<ContactSupport /> TRŪKSTA INFORMACIJOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/accepted'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Spellcheck /> PATEIKTI PASIŪLYMAI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/waiting-call'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<PermPhoneMsg /> LAUKIA SKAMBUČIO
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/waiting-sign'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Assignment /> LAUKIA PASIRAŠYMO
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/signed'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<AssignmentTurnedIn /> PASIRAŠYTOS PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/archive'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<AssignmentLate /> NEPAVYKO SUSISIEKTI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests/declined'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Delete /> ATMESTOS PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/reports'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<CloudDownload /> ATASKAITOS
								</div>
							</NavLink>
							<a
								onClick={() => {
									this.setState({
										creditCompanyHelpModalOpen: true,
									});
								}}
								target='_blank'
								className='sidenav-item'
							>
								<div className='text-left'>
									<Help /> PAGALBA
								</div>
							</a>
						</div>
					)}

					{appState.role === 'consultant' && (
						<>
							<NavLink
								exact={true}
								to='/myRequests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<AssignmentInd /> MANO PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<InsertDriveFile /> VISOS PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/confirmations'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<LocalOffer /> PASIŪLYMAI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/potentialRequests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<People /> POTENCIALŪS KLIENTAI
								</div>
							</NavLink>
						</>
					)}

					{appState.role === 'admin' && (
						<>
							<NavLink
								exact={true}
								to='/overview'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Dashboard /> APŽVALGA
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/creditCompanies/overview'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Apps /> ĮSTAIGŲ APŽVALGA
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/sources'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<MeetingRoom /> KLIENTŲ SRAUTAI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/reports'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<CloudDownload /> ATASKAITOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/invoices'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Receipt /> SĄSKAITOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/creditCompanies'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<AccountBalance /> KREDITO ĮSTAIGOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/creditCompanies/types'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<Domain /> KREDITO ĮSTAIGŲ TIPAI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/users'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<SupervisedUserCircle /> VARTOTOJAI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/myRequests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<AssignmentInd /> MANO PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/requests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<InsertDriveFile /> PARAIŠKOS
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/confirmations'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<LocalOffer /> PASIŪLYMAI
								</div>
							</NavLink>
							<NavLink
								exact={true}
								to='/potentialRequests'
								className='sidenav-item'
								activeClassName='sidenav-item-active'
							>
								<div className='text-left'>
									<People /> POTENCIALŪS KLIENTAI
								</div>
							</NavLink>
						</>
					)}
				</div>
			</nav>
		);
	}
}

export default Sidebar;
