import React, { Component } from 'react';
import Sidebar from './partials/sidebar';
import MobileBottomNavigation from './partials/mobile-bottom-navigation';
import Navbar from './partials/navbar';
import Router from './router';
import reminderNotifier from '../../utils/reminderNotifier';

class Dashboard extends Component {
	constructor() {
		super();

		this.state = {
			open: true,
		};
		
		reminderNotifier();
	}

	render() {
		return (
			<>
				<div id='sidenav'>
					<Sidebar $helper={this.props.$helper} />
				</div>

				<div id='mobile-navigation'>
					<MobileBottomNavigation $helper={this.props.$helper} />
				</div>

				<div id='main-app-content'>
					<Navbar $helper={this.props.$helper} />
					<div id='main-app-window'>
						<Router $helper={this.props.$helper} />
					</div>
				</div>
			</>
		);
	}
}

export default Dashboard;
