import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';

class Situation extends Component {
	constructor(props) {
		super();

		const { request } = props;

		this.state = {
			situation: request.situation || '',
			inputOpen: false,
			isLoading: false,
		};
	}

	handleInputOpen = () => {
		if (this.state.isLoading) return;

		this.setState({
			inputOpen: true,
		});
	};

	handleInputClose = () => {
		if (this.state.isLoading) return;

		const { request } = this.props;

		// if no changes were made, just close
		if (
			request.situation == this.state.situation ||
			(!request.situation && !this.state.situation)
		) {
			return this.setState({
				inputOpen: false,
			});
		}

		const confirm = window.confirm('Ar tikrai norite pakeisti tekstą?');

		// reset if cancelled
		if (!confirm) {
			return this.setState({
				situation: request.situation || '',
				inputOpen: false,
			});
		}

		this.setState({
			isLoading: true,
		});

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/situation', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				situation: this.state.situation,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					return window.location.reload();
				}

				return toast.error('Nepavyko atnaujinti');
			})
			.finally(() => {
				this.setState({ isLoading: false, inputOpen: false });
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	render() {
		return (
			<>
				{!this.state.inputOpen && (
					<div className='cursor-pointer' onClick={this.handleInputOpen}>
						{this.state.situation || '-'}
					</div>
				)}

				{this.state.inputOpen && (
					<div className='cursor-pointer'>
						<TextField
							autoFocus
							margin='dense'
							name='situation'
							// label='Situacija'
							type='text'
							onBlur={this.handleInputClose}
							onChange={this.handleChange}
							value={this.state.situation}
							disabled={this.state.isLoading}
							fullWidth
						/>
					</div>
				)}
			</>
		);
	}
}

export default Situation;
