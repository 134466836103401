import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import SearchBar from './shared/search-bar';
import MobileRequestsMenu from './shared/mobile-requests-menu';
import CompanyRequest from './company-request';
const moment = require('moment');

class CompanyRequests extends Component {
	constructor(props) {
		super(props);

		this.state = {
			requestsPerPage: 15,
			status: props.status,
			requests: props.requests,
			isLoading: false,
		};

		props.getRequests('?status=' + this.state.status);
	}
	
	handleChangePage = (event, page) => {
		this.props.setState({ page });
	};

	render() {
		let { status, title, page } = this.props;
		let sliceFrom = page * this.state.requestsPerPage;
		let sliceTo = sliceFrom + this.state.requestsPerPage;
		let loans = [];

		for(var loan of this.props.requests && this.props.requests.slice(sliceFrom, sliceTo)){
			console.log(Date.parse(loan.createdAt) + " " + moment())
			if(Date.parse(loan.createdAt) <= moment()) loans.push(loan);			
		};

		// console.log(loans);
		return (
			<div className='fade-in'>
				<div className='row no-gutters'>
					<div className='col-md-5 col-12'>
						<h1 className='app-page-title'>
							{title}
							<MobileRequestsMenu />
						</h1>
					</div>
					<div className='col-md-7 col-12 text-right pt-2 pb-2'>
						<SearchBar
							getRequests={this.props.getRequests}
							status={status}
							$helper={this.props.$helper}
						/>
					</div>
				</div>
				<Paper id='company-requests-wrapper'>
					<Table
						style={{
							minWidth: 1000,
						}}
						size='small'
					>
						<TableHead>
							<TableRow className='px-3'>
								<TableCell>Paraiškos kodas</TableCell>
								<TableCell align='right'>Sukūrimo data</TableCell>
								<TableCell align='right'>Paraiškos pateikėjas</TableCell>
								<TableCell align='right'>Paskolos tipas</TableCell>
								<TableCell align='right'>Paskolos suma</TableCell>
								<TableCell align='right'>Paskolos terminas</TableCell>
								<TableCell align='right'>Įkelti dokumentai</TableCell>
								{this.props.status !== 'waiting_offer' && (
									<TableCell align='right'>Vadybininkas</TableCell>
								)}
								<TableCell align='right' />
							</TableRow>
						</TableHead>
						<TableBody>
							{/* {this.props.requests &&
								this.props.requests.slice(sliceFrom, sliceTo).map((request) => { */}
								{loans.map((request) => { 
									return (
										<CompanyRequest
											key={request._id}
											request={request} 
											status={status}
											$helper={this.props.$helper}
											getRequests={this.props.getRequests}
											isLoading={this.state.isLoading}
											setContainerState={(state) => this.setState(state)}
										/>
									)

								})}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={this.props.requests.length}
						rowsPerPage={this.state.requestsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</Paper>
			</div>
		);
	}
}

export default CompanyRequests;
