import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			displayedUsers: [],
			page: 0,
			usersPerPage: 10,
			role: 'all',
		};

		this.getUsers();
	}

	componentDidMount() {
		document.title = 'Credit King - vartotojai';
	}

	getUsers = () => {
		return fetch(process.env.REACT_APP_API + '/api/users?role=' + this.state.role, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Serverio klaida');
				}

				this.setState({
					users: response,
				});
			});
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChange = (event, skipGetUsers = false) => {
		this.setState(
			{
				[event.target.id]: event.target.value,
			},
			() => {
				if (!skipGetUsers) this.getUsers();
			},
		);
	};

	render() {
		let sliceFrom = this.state.page * this.state.usersPerPage;
		let sliceTo = sliceFrom + this.state.usersPerPage;

		return (
			<>
				<h1 className='app-page-title'>Vartotojai</h1>
				<Paper style={{ maxHeight: '80vh', width: '100%', overflowX: 'auto' }}>
					<Table
						style={{
							minWidth: 700,
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>Vartotojo ID</TableCell>
								<TableCell align='right'>Vardas, pavardė</TableCell>
								{/* <TableCell align='right'>Rolė</TableCell> */}

								<TableCell align='right'>
									<select
										id='role'
										onChange={this.handleChange}
										style={{
											background: 'transparent',
											border: 'none',
											color: 'rgba(0, 0, 0, 0.54)',
										}}
									>
										<option value='all'>Rolė (Visos)</option>
										<option value='user'>Rolė (user)</option>
										<option value='credit'>Rolė (credit)</option>
										<option value='admin'>Rolė (admin)</option>
										<option value='consultant'>Rolė (consultant)</option>
									</select>
								</TableCell>

								<TableCell align='right'>Kontaktinis telefonas</TableCell>
								<TableCell align='right'>Kontaktinis paštas</TableCell>
								<TableCell align='right'>Kredito įstaiga</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.users.slice(sliceFrom, sliceTo).map((user) => (
								<TableRow key={user._id}>
									<TableCell component='th' scope='row'>
										{user._id}
									</TableCell>
									<TableCell align='right'>
										{user.firstName} {user.lastName}
									</TableCell>
									<TableCell align='right'>{user.role}</TableCell>
									<TableCell align='right'>+370{user.contactNumber}</TableCell>
									<TableCell align='right'>{user.email}</TableCell>
									<TableCell align='right'>
										{user.role === 'credit' ? user.creditCompany.name : '-'}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={this.state.users.length}
						rowsPerPage={this.state.usersPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</Paper>
			</>
		);
	}
}

export default Users;
