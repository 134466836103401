import React, { Component } from 'react';
import { ExitToApp, AccountCircle } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import Notifications from '../../shared/notifications/notifications';
import HeaderBg from '../../../assets/img/header-bg.png';

class Navbar extends Component {
	logoutHandler = async (event) => {
		event.preventDefault();

		localStorage.removeItem('token');
		window.location.reload();
	};

	render() {
		let appState = this.props.$helper.getAppState();

		return (
			<nav id='navbar'>
				<div className='container-fluid'>
					<div className='text-right'>
						<ul id='navbar-items'>
							{['admin', 'consultant', 'credit'].includes(appState.role) && (
								<li className='navbar-item'>
									<Notifications />
								</li>
							)}

							<li className='navbar-item'>
								<NavLink
									to='/profile'
									activeClassName='navbar-item-active'
									id='profile-navbar-link'
								>
									<span className='pr-2 pt-3'>
										{appState.firstName} {appState.lastName}
									</span>
									<AccountCircle />
								</NavLink>
							</li>
							<li className='navbar-item'>
								<a onClick={this.logoutHandler} href='/logout'>
									<span className='pr-2 pt-3'>Atsijungti</span> <ExitToApp />
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div id='navbar-img-wrapper'>
					<img src={HeaderBg} alt='' className='unselectable' id='navbar-img' />
				</div>
			</nav>
		);
	}
}

export default Navbar;
