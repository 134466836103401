let submitRequestErrors = {
	age: (val) => {
		if (val === null) {
			return '';
		}

		if (!/^\d+$/.test(val) && val != '') {
			// check if it is only number
			return 'Amžius privalo būti sudarytas iš skaitmenų';
		}

		if (val !== null && val < 18 && val.length >= 2) {
			return 'Pateikti paraišką gali asmenys nuo 18 metų';
		}

		return '';
	},
};

export default submitRequestErrors;
