import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/lt';
import { TablePagination } from '@material-ui/core';

class Invoices extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dateFrom: Date.now(),
			dateTo: Date.now(),
			paymentDue: Date.now(),
			additionalFee: 0,
			creditCompany: null,
			isDisabled: false,
			invoices: [],
			invoiceCount: 0,
			page: 0,
			invoicesPerPage: 5,
			isLoading: false,
		};
	}

	setDate() {
		let date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth();

		this.setState({
			dateFrom: moment(new Date(y, m, 1)).format('YYYY-MM-DD'),
			dateTo: moment(new Date(y, m + 1, 0)).format('YYYY-MM-DD'),
		});
	}

	componentWillMount() {
		this.getInvoiceCount().then(() => {
			this.getInvoices();
		});
	}

	componentDidMount() {
		document.title = 'Credit King - sąskaitos';
		this.setDate();
	}

	generateInvoice = (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/invoices', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				dateFrom: new Date(this.state.dateFrom + 'T00:00:00.000Z'),
				dateTo: new Date(this.state.dateTo + 'T23:59:59Z'),
				creditCompany: this.state.creditCompany,
				additionalFee: this.state.additionalFee,
				paymentDue: this.state.paymentDue,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error(response.error);
				}

				toast.success('Sąskaita sėkmingai sugeneruota');
				this.getInvocies();
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	getInvoiceCount = () => {
		return fetch(process.env.REACT_APP_API + '/api/invoices/count', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error(response.error);
				}

				this.setState({ invoiceCount: response.count });
			});
	};

	getInvoices = () => {
		this.setState({ isLoading: true });
		const query = `?page=${this.state.page}&limit=${this.state.invoicesPerPage}`;

		fetch(process.env.REACT_APP_API + '/api/invoices' + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error(response.error);
				}

				this.setState({
					invoices: [...this.state.invoices, ...response],
				});
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	render() {
		let appState = this.props.$helper.getAppState();
		let sliceFrom = this.state.page * this.state.invoicesPerPage;
		let sliceTo = sliceFrom + this.state.invoicesPerPage;

		return (
			<div>
				<h1 className='app-page-title'>Sąskaitos</h1>
				<Paper className='px-3 py-2'>
					<Typography variant='h5'>Sąskaitų generavimas</Typography>

					<form onSubmit={this.generateInvoice}>
						<div className='row mt-3'>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='dense'
												label='Nuo'
												name='dateFrom'
												className='m-0'
												value={this.state.dateFrom}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'dateFrom',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</FormGroup>
							</div>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='dense'
												label='Iki'
												name='dateTo'
												className='m-0'
												value={this.state.dateTo}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'dateTo',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</FormGroup>
							</div>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<FormControl>
										<InputLabel>Kredito įstaiga</InputLabel>
										<Select
											fullWidth
											required
											margin='dense'
											value={this.state.creditCompany}
											onChange={this.handleChange}
											inputProps={{
												name: 'creditCompany',
												id: 'creditCompany',
											}}
										>
											{appState.creditCompanies.map((creditCompany, index) => {
												return (
													<MenuItem key={index} value={creditCompany.id}>
														{creditCompany.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</FormGroup>
							</div>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12'>
								<TextField
									fullWidth
									id='additionalFee'
									name='additionalFee'
									label='Papildomas mokestis'
									type='number'
									inputProps={{
										step: '0.01',
									}}
									onChange={this.handleChange}
									value={this.state.additionalFee}
									margin='dense'
								/>
							</div>

							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='dense'
												label='Sumokėti iki'
												name='paymentDue'
												className='m-0'
												value={this.state.paymentDue}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'paymentDue',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</FormGroup>
							</div>

							<div
								className='col-lg-2 col-md-3 col-sm-12 col-12 text-right mt-3'
								style={{
									justifyContent: 'center',
								}}
							>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									disabled={this.state.isLoading || !this.state.creditCompany}
								>
									GENERUOTI
								</Button>
							</div>
						</div>
					</form>
				</Paper>
				<Paper style={{ maxHeight: '70vh', width: '100%', overflowX: 'auto', marginTop: 10 }}>
					<Table
						style={{
							minWidth: 700,
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell align='left'>Sąskaitos ID</TableCell>
								<TableCell align='left'>Sukūrimo data</TableCell>
								<TableCell align='left'>Nuo</TableCell>
								<TableCell align='left'>Iki</TableCell>
								<TableCell align='left'>Kredito įstaiga</TableCell>
								<TableCell align='left'>Papildomas mokestis</TableCell>
								<TableCell align='left'>Sumokėti iki</TableCell>
								<TableCell align='left' />
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.invoices.slice(sliceFrom, sliceTo).map((invoice, index) => (
								<TableRow key={index}>
									<TableCell align='left'>{invoice._id}</TableCell>
									<TableCell align='left'>
										{moment(invoice.createdAt).locale('lt').format('lll')}
									</TableCell>
									<TableCell align='left'>
										{moment(invoice.info.dateFrom).locale('lt').format('ll')}
									</TableCell>
									<TableCell align='left'>
										{moment(invoice.info.dateTo).locale('lt').format('ll')}
									</TableCell>
									<TableCell align='left'>{invoice.creditCompany.name}</TableCell>
									<TableCell align='left'>{invoice.info.additionalFee || '-'}</TableCell>
									<TableCell align='left'>
										{moment(invoice.info.paymentDue).locale('lt').format('ll')}
									</TableCell>
									<TableCell align='left'>
										<a href={invoice.path} target='_blank'>
											ATSISIŲSTI
										</a>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={this.state.invoiceCount}
						rowsPerPage={this.state.invoicesPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={(_event, page) => {
							if (
								page > this.state.page &&
								page * this.state.invoicesPerPage >= this.state.invoices.length
							) {
								this.setState({ page: page, isLoading: true }, () => {
									this.getInvoices();
								});
							} else {
								this.setState({ page });
							}
						}}
					/>
				</Paper>
			</div>
		);
	}
}

export default Invoices;
