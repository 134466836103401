import React, { Component } from 'react';
import { Search, FilterList } from '@material-ui/icons/';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import FilterModal from './modal/filter-modal';
import moment from 'moment';

const styles = {
	textField: {
		color: '#fff',
		borderBottom: '1px solid #fff',
	},
	input: {
		border: 'none',
	},
	button: {
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		color: '#fff',
		'&:hover': {
			opacity: 0.7,
			cursor: 'pointer',
		},
		'&:focus': {
			opacity: 0.7,
			cursor: 'pointer',
		},
		'&:active': {
			opacity: 0.7,
			cursor: 'pointer',
		},
	},
};

class SearchBar extends Component {
	constructor(props) {
		super(props);

		let date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth(),
			d = date.getDate();

		let todaysDate = moment(new Date(y, m, d)).format('YYYY-MM-DD');

		this.state = {
			skip: 0,
			limit: 0,
			acceptedByCompany: false,
			status: props.status,
			search: '',
			filterTarget: '',
			consultants: [],
			hideConsultantFilter: props.hideConsultantFilter,
			filterDate: {
				enabled: false,
				from: 0,
				to: todaysDate,
			},
			filterType: {
				enabled: false,
				data: '',
			},
			filterManager: {
				enabled: false,
				data: '',
			},
			filterLength: {
				enabled: false,
				from: 1,
				to: 84,
			},
			filterStatus: {
				enabled: false,
				status: '',
			},
			filterText: {
				enabled: false,
				data: '',
			},
			filterConsultant: {
				enabled: false,
				data: '',
			},
		};

		// make a copy for previous filters
		this.state.previousFilters = (({
			filterDate,
			filterType,
			filterManager,
			filterLength,
			filterStatus,
			filterText,
			filterConsultant,
		}) => ({
			filterDate,
			filterType,
			filterManager,
			filterLength,
			filterStatus,
			filterText,
			filterConsultant,
		}))(this.state);
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API + '/api/users?role=consultant', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					consultants: response,
				});
			});
	}

	generateFilterQuery = (state) => {
		// helper function, that converts filtration JSON to query string
		let queryString = '?';
		queryString += 'skip=' + state.skip;
		queryString += '&limit=' + state.limit;

		if (state.search) {
			queryString += '&search=' + state.search;
		}

		// filter stuff
		if (state.filterDate.enabled) {
			queryString += state.filterDate.from
				? '&dateFrom=' + state.filterDate.from + 'T00:00:00.000Z'
				: '';
			queryString += state.filterDate.to ? '&dateTo=' + state.filterDate.to + 'T23:59:59Z' : '';
		}

		if (state.filterType.enabled && state.filterType.data !== 'all') {
			queryString += '&creditType=' + state.filterType.data;
		}

		if (state.filterLength.enabled) {
			queryString += state.filterLength.from ? '&creditLengthFrom=' + state.filterLength.from : '';
			queryString += state.filterLength.to ? '&creditLengthTo=' + state.filterLength.to : '';
		}

		if (state.filterManager.enabled) {
			queryString += '&manager=' + state.filterManager.data || '';
		}

		if (state.filterText.enabled && state.filterText.data) {
			queryString += '&text=' + state.filterText.data;
		}

		if (state.filterConsultant.enabled && state.filterConsultant.data) {
			queryString += '&consultant=' + state.filterConsultant.data;
		}

		if (['admin', 'consultant'].includes(this.props.$helper.getAppState().role)) {
			if (state.filterStatus.enabled) {
				queryString += '&status=' + state.filterStatus.status;
			} else {
				queryString += '&status=all';
			}
		} else {
			queryString += '&status=' + state.status;
		}

		return queryString;
	};

	setContainerState = (state) => {
		this.setState(state);
	};

	getContainerState = () => {
		return this.state;
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleFilterChange = (event, filterType, date) => {
		let value, filterData;
		if (typeof event === 'string') {
			value = date;
			filterData = event;
		} else {
			value = event.target.value;
			filterData = event.target.name;
		}

		let currentObject = Object.assign({}, this.state[filterType]);
		currentObject[filterData] = value;

		this.setState({
			[filterType]: currentObject,
		});
	};

	handleCheckboxChange = (event) => {
		let currentValue = this.state[event.target.name].enabled;

		this.setState({
			[event.target.name]: { ...this.state[event.target.name], enabled: !currentValue },
		});
	};

	handleSearch = (event) => {
		if (event.key === 'Enter') {
			this.props.getRequests(this.generateFilterQuery(this.state));
		}
	};

	handleFilterOpen = (event) => {
		this.setState({
			filterTarget: event.currentTarget,
		});
	};

	compareObject = (x, y) => {
		// function that simply compares two objects ignoring their props
		return JSON.stringify(x) === JSON.stringify(y);
	};

	handleFilterClose = () => {
		this.setState({
			filterTarget: null,
		});

		// check if there is anything to filter
		if (
			!(
				this.compareObject(this.state.filterDate, this.state.previousFilters.filterDate) &&
				this.compareObject(this.state.filterType, this.state.previousFilters.filterType) &&
				this.compareObject(this.state.filterManager, this.state.previousFilters.filterManager) &&
				this.compareObject(this.state.filterLength, this.state.previousFilters.filterLength) &&
				this.compareObject(this.state.filterStatus, this.state.previousFilters.filterStatus) &&
				this.compareObject(this.state.filterText, this.state.previousFilters.filterText) &&
				this.compareObject(this.state.filterConsultant, this.state.previousFilters.filterConsultant)
			)
		) {
			this.props.getRequests(this.generateFilterQuery(this.state));

			// after fetching new requests, save it as previous
			// one liner to copy subset of one object to another
			// https://stackoverflow.com/questions/17781484/how-to-get-a-subset-of-a-javascript-objects-properties
			this.setState({
				previousFilters: (({
					filterDate,
					filterType,
					filterManager,
					filterLength,
					filterStatus,
					filterText,
					filterConsultant,
				}) => ({
					filterDate,
					filterType,
					filterManager,
					filterLength,
					filterStatus,
					filterText,
					filterConsultant,
				}))(this.state),
			});
		}
	};

	render() {
		let { filterTarget } = this.state;
		let isFilterOpen = Boolean(filterTarget);

		return (
			<div>
				<Search className='requests-container-icon' />{' '}
				<TextField
					name='search'
					id='requests-container-search'
					value={this.state.search}
					onChange={this.handleChange}
					margin='none'
					onKeyDown={this.handleSearch}
					className={this.props.classes.textField}
					InputProps={{
						className: this.props.classes.input,
						disableUnderline: true,
					}}
				/>{' '}
				<Button
					aria-owns={isFilterOpen ? 'filter-popper' : undefined}
					aria-haspopup='true'
					onClick={this.handleFilterOpen}
					className={this.props.classes.button}
					disableTouchRipple={true}
					disableFocusRipple={true}
				>
					<FilterList className={`${this.props.classes.icon} requests-container-icon ml-2`} />
				</Button>
				<FilterModal
					getContainerState={this.getContainerState}
					setContainerState={this.setContainerState}
					handleFilterClose={this.handleFilterClose}
					handleCheckboxChange={this.handleCheckboxChange}
					handleFilterChange={this.handleFilterChange}
					$helper={this.props.$helper}
					status={this.props.status}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(SearchBar);
