import React, { Component } from 'react';
import { toast } from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
	Class,
	Print,
	SettingsBackupRestore,
	Clear,
	Done,
	Info,
	AccessTime,
	VisibilityOff,
	RotateLeft,
	PersonAdd,
	PersonAddDisabled,
	CloudUpload,
	Fingerprint,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import NativeSelect from '@material-ui/core/NativeSelect';
import ObjectFileModal from './object-file-modal';
import RevertLackingInfoModal from './lacking-info/revert-lacking-info-modal';
import { AppContext } from '../../../../../AppContext';
import ConfirmationInfoModal from '../../../credit-confirmations/admin/modal/confirmation-info-modal';
import MakeInactiveModal from './make-inactive-modal';
import AttachFilesModal from '../../admin/modal/attach-files-modal';
import Situation from '../shared/situation';
import moment from 'moment';
import ClientQuestions from './client-questions';
import SetIrrevelantModal from '../../admin/modal/set-irrevelant-modal';
import Popconfirm from '../../../../shared/popconfirm';
import Consultants from '../../../credit-request/consultants';
import Integrations from '../../../credit-request/integrations';
import IdentityConfirmations from '../../../credit-request/identity-confirmations';
import Checkbox from '@material-ui/core/Checkbox';
import ReminderNotifications from './reminder-notifications';

class RequestInfoModal extends Component {
	constructor() {
		super();

		this.state = {
			downloadingPersonalPdf: false,
			downloadingPartnerPdf: false,
			downloadingRequestPdf: false,
			downloadingRequestDocument: false,
			objectFileModalOpen: false,
			revertLackingInfoModalOpen: false,
			confirmationInfoModalOpen: false,
			creditTypeChangeConfirmOpen: false,
			removePartnerConfirmOpen: false,
			setIrrevelantModalOpen: false,
			noteEditorOpen: false,
			makeInactiveModalOpen: false,
			attachFilesModalOpen: false,
			noteTarget: {},
			notes: [],
			index: 0,
			path: '',
			request: {
				availableConfirmations: [],
			},
			availableCreditTypesToChange: [],
			creditTypeToChange: null,
			isLoading: false,
		};
	}

	getUser = () => {
		const { request } = this.props;
		const { user } = request;

		fetch(process.env.REACT_APP_API + '/api/users/' + user._id, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/octet-stream',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then(async (response) => {
				if (!response || response.error) {
					return toast.error('Nepavyko rasti vartotojo');
				}

				await this.setState({
					user: response,
				});
			});
	};

	getRequest = () => {
		// request only for admins with full info and all the requests
		fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/octet-stream',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then(async (response) => {
				if (!response || response.error) {
					return toast.error('Serverio klaida');
				}

				let request = response[0];

				request.availableConfirmations = await request.availableConfirmations.map(
					(confirmation) => {
						if (
							confirmation &&
							confirmation.creditConfirmation &&
							confirmation.creditConfirmation.type !== 'decline'
						) {
							confirmation.creditConfirmation.creditRequest = request;
						}

						return confirmation;
					},
				);

				this.setState({ request });
			});
	};

	spoofUser = () => {
		const confirmation = window.confirm('Ar tikrai norite tapti vartotoju?');

		if (!confirmation) return;

		const { request } = this.props;

		fetch(process.env.REACT_APP_API + '/api/users/' + request.user._id + '/spoof/', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				const { token } = response;
				window.open('https://app.creditking.lt/authenticate/' + token, '_blank');
			});
	};

	componentDidMount() {
		if (['admin', 'consultant'].includes(this.context.role)) {
			this.getRequest();
			this.getNotes();
			this.getUser();
		}
	}

	setContainerState = (state) => {
		this.setState(state);
	};

	getContainerState = () => {
		return this.state;
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.props.setContainerState({
					requestInfoOpen: false,
				});

				clearInterval(this.interval);
			});
	};

	handleClose = () => {
		this.props.setContainerState({
			requestInfoOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	downloadRequestDocument = () => {
		this.setState({
			objectFileModalOpen: true,
		});
	};

	openDocumentInViewer = (path) => {
		this.setState({
			objectFileModalOpen: true,
			path: path,
		});
	};

	downloadRequestAsPDF = () => {
		let { request } = this.props;

		this.setState({
			downloadingRequestPdf: true,
		});

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/pdf', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/octet-stream',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.text())
			.then((response) => {
				if (!response || response.error) {
					toast.error('Serverio klaida');
				} else {
					let linkElement = document.createElement('a');
					linkElement.setAttribute('href', 'data:application/pdf;base64,' + response);
					linkElement.setAttribute('download', request._id + '.pdf');
					let linkClickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false,
					});
					linkElement.dispatchEvent(linkClickEvent);
					linkElement.remove();
				}
			})
			.catch((error) => {
				console.error(error);
				toast.error('Serverio klaida');
			})
			.finally(() => {
				this.setState({
					downloadingRequestPdf: false,
				});
			});
	};

	downloadPDF = (isPartner) => {
		let pdf = false;
		const { request } = this.props;
		// pdf = requestConfirmation;
		console.log(this.props);
		if (!isPartner && request && request.personalConfirmation && request.personalConfirmation.path)
			pdf = request.personalConfirmation.path;

		if (isPartner && request && request.partnerConfirmation && request.partnerConfirmation.path)
			pdf = request.partnerConfirmation.path;

		if (!pdf) return toast.error('Nepavyko sugeneruoti dokumento');

		return window.open(pdf);
	};

	loader = () => {
		// return <CircularProgress size={20} thickness={1} style={{ color: 'grey' }} />;
		return (
			<div
				style={{
					padding: 12,
				}}
			>
				<div class='spinner' />
			</div>
		);
	};

	getNotes = () => {
		const { request } = this.props;

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/notes', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					notes: response,
				});
			});
	};

	submitNote = () => {
		const { note } = this.state.noteTarget;
		const { request } = this.props;

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/notes', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({ note }),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) return;

				this.getNotes();
				this.setState({ noteEditorOpen: false, noteTarget: {} });
			});
	};

	editNote = (note, newNote) => {
		const { request } = this.props;
		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/notes/' + note._id, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({ note: newNote }),
		}).then((response) => {
			this.getNotes();
			this.setState({ noteEditorOpen: false, noteTarget: {} });
		});
	};

	deleteNote = (note) => {
		const { request } = this.props;

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/notes/' + note._id, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.getNotes();
			});
	};

	changeCreditType = () => {
		const { request } = this.props;
		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/creditType', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				creditType: this.state.creditTypeToChange,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				window.location.reload();
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	addPartner = () => {
		const confirmResponse = window.confirm('Ar tikrai norite pridėti sutuoktinį?');

		if (!confirmResponse) return;

		const { request } = this.props;
		const id = request._id;

		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/requests/' + id + '/status', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				status: 'waiting_partner_info',
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					return window.location.reload();
				}

				toast.error('Nepavyko pridėti sutuoktinio');
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	removePartner = () => {
		const confirmResponse = window.confirm('Ar tikrai norite panaikinti sutuoktinį?');

		if (!confirmResponse) return;

		const { request } = this.props;
		const id = request.partnerConfirmation._id;

		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/requestConfirmations/' + id, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					return window.location.reload();
				}

				toast.error('Nepavyko panaikinti sutuoktinio');
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	deleteRequiredDocument = (type) => {
		const confirmation = window.confirm('Ar tikrai norite ištrinti dokumentą?');
		if (!confirmation) return;

		const { user } = this.state;

		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/users/' + user._id + '/requiredDocuments', {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				type,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					toast.success('Sėkmingai ištrinti dokumentai');
					return this.getUser();
				}

				toast.error('Nepavyko ištrinti dokumentų');
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};
	getCreditRequest = () => {
		// const { request } = this.props;
		let creditRequest = fetch(
			process.env.REACT_APP_API + '/api/requests/' + this.props.request._id,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/octet-stream',
					Authorization: localStorage.getItem('token'),
				},
			},
		)
			.then((response) => response.json())
			.then(async (response) => {
				if (!response || response.error) {
					return toast.error('Serverio klaida');
				}

				let request = response[0];
				if (request.companyVisibility === false) {
					// console.log("false")
					return false;
				} else if (request.companyVisibility === true) {
					// console.log("true")
					return true;
				}
			});
		// response.json()
		// console.log(response.body);
		//})

		return creditRequest;
	};
	showCreditCompnay = () => {
		fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/octet-stream',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then(async (response) => {
				if (!response || response.error) {
					return toast.error('Serverio klaida');
				}

				let request = response[0];

				console.log(request.companyVisibility);

				let isChecked = Array.from(document.getElementsByClassName('Mui-checked'));
				if (request.companyVisibility !== null) {
					if (request.companyVisibility === true && isChecked.length === 0) {
						fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id, {
							method: 'PATCH',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								Authorization: localStorage.getItem('token'),
							},
							body: JSON.stringify({
								companyVisibility: false,
							}),
						}).then((response) => {
							// response.json()
							// console.log(response.body);
						});
					} else if (request.companyVisibility === false && isChecked.length === 1) {
						fetch(process.env.REACT_APP_API + '/api/requests/' + this.props.request._id, {
							method: 'PATCH',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								Authorization: localStorage.getItem('token'),
							},
							body: JSON.stringify({
								companyVisibility: true,
							}),
						}).then((response) => {
							// response.json()
							// console.log(response.body);
						});
					}
				}
			});
	};

	checkBoxCheck = () => {
		const { request } = this.props;

		if (request.companyVisibility !== null) {
			if (request.companyVisibility === true) {
				return true;
			} else {
				return false;
			}
		}
		// } else {
		// 	console.log(request)
		// 	return defaultChecked;
		// }
	};

	render() {
		let { requestInfoOpen, request } = this.props;
		const translations = require('../../../../../locale/lt_LT/requests.json');

		if (
			request.status === 'waiting_call' ||
			request.status === 'waiting_sign' ||
			request.status === 'signed'
		) {
			request.creditConfirmations[0] = request.acceptedOffer;
		}

		const { user } = this.state;
		let requiredDocuments = [];

		if (user) {
			requiredDocuments = user.requiredDocuments || [];
		} else {
			requiredDocuments =
				request.user && request.user.requiredDocuments ? request.user.requiredDocuments : [];
		}

		let clientsOtherRequests = [];

		// fill clientsOtherRequests
		if (['admin', 'consultant'].includes(this.context.role) && user && user.creditRequests) {
			const { creditRequests } = user;

			clientsOtherRequests = creditRequests.filter(
				(creditRequest) => creditRequest.id !== request.id,
			);

			if (clientsOtherRequests.length >= 10) {
				clientsOtherRequests = clientsOtherRequests.slice(0, 10);
			}
		}

		return (
			<>
				<ObjectFileModal
					objectFileModalOpen={this.state.objectFileModalOpen}
					setContainerState={this.setContainerState}
					request={request}
					path={this.state.path}
				/>
				<RevertLackingInfoModal
					objectFileModalOpen={this.state.objectFileModalOpen}
					setContainerState={this.setContainerState}
					getContainerState={this.getContainerState}
					getRequests={this.props.getRequests}
					request={request}
					index={this.state.index}
				/>
				<Dialog
					open={requestInfoOpen}
					fullWidth={true}
					maxWidth={'md'}
					onClose={this.handleClose}
					onEntering={this.handleEnter}
				>
					<DialogTitle>
						<div className='row'>
							<div className='col-md-6 col-12'>
								Paraiškos informacija (
								<span
									style={{
										textTransform: 'uppercase',
									}}
								>
									{request.id}
								</span>
								)
							</div>
							<div className='col-md-6 col-12 text-right'>
								{['admin', 'consultant'].includes(this.context.role) && (
									<>
										<IconButton onClick={this.spoofUser}>
											<Tooltip title='Tapti vartotoju' placement='top'>
												<Fingerprint />
											</Tooltip>
										</IconButton>

										<IconButton
											onClick={() =>
												this.setState({
													attachFilesModalOpen: true,
												})
											}
										>
											<Tooltip title='Papildomų dokumentų įkėlimas' placement='top'>
												<CloudUpload />
											</Tooltip>
										</IconButton>

										<AttachFilesModal
											request={request}
											attachFilesModalOpen={this.state.attachFilesModalOpen}
											handleClose={() =>
												this.setState({
													attachFilesModalOpen: false,
												})
											}
										/>
									</>
								)}

								{['admin', 'consultant'].includes(this.context.role) &&
									request.status !== 'inactive' && (
										<>
											<MakeInactiveModal
												makeInactiveModalOpen={this.state.makeInactiveModalOpen}
												request={request}
												handleClose={() =>
													this.setState({
														makeInactiveModalOpen: false,
													})
												}
											/>

											<IconButton
												onClick={() =>
													this.setState({
														makeInactiveModalOpen: true,
													})
												}
											>
												<Tooltip title='Padaryti paraišką nematomą' placement='top'>
													<VisibilityOff />
												</Tooltip>
											</IconButton>
										</>
									)}

								{['admin', 'consultant'].includes(this.context.role) && !request.isIrrevelant && (
									<>
										<SetIrrevelantModal
											isOpen={this.state.setIrrevelantModalOpen}
											handleClose={() => this.setState({ setIrrevelantModalOpen: false })}
											request={request}
											changeRequestByIndex={this.props.changeRequestByIndex}
										/>

										<IconButton onClick={() => this.setState({ setIrrevelantModalOpen: true })}>
											<Tooltip title='Pažymėti paraiską kaip neaktualią' placement='top'>
												<Clear />
											</Tooltip>
										</IconButton>
									</>
								)}

								{['admin', 'consultant'].includes(this.context.role) &&
									request.status === 'unconfirmed' &&
									request.maritalStatus === 'married' &&
									request.withCoApplicant && (
										<IconButton onClick={this.removePartner}>
											<Tooltip title='Panaikinti sutuoktinį' placement='top'>
												<PersonAddDisabled />
											</Tooltip>
										</IconButton>
									)}

								{['admin', 'consultant'].includes(this.context.role) &&
									request.status === 'waiting_offer' &&
									!request.withCoApplicant && (
										<IconButton onClick={this.addPartner}>
											<Tooltip title='Pridėti sutuoktinį' placement='top'>
												<PersonAdd />
											</Tooltip>
										</IconButton>
									)}

								{['admin', 'consultant'].includes(this.context.role) &&
									request.creditType === 'spending' &&
									['waiting_offer', 'no_offers'].includes(request.status) &&
									(request.ownsRealEstate || request.ownsCar) && (
										<>
											<Dialog
												open={this.state.creditTypeChangeConfirmOpen}
												onClose={() =>
													this.setState({
														creditTypeChangeConfirmOpen: false,
													})
												}
												aria-labelledby='alert-dialog-title-2'
												aria-describedby='alert-dialog-description-2'
											>
												<DialogTitle id='alert-dialog-title-2'>
													Paraiškos tipo pakeitimas
												</DialogTitle>
												<DialogContent>
													<div>
														<NativeSelect
															required
															controlId='creditTypeToChange'
															fullWidth
															onChange={(event) =>
																this.setState({
																	[event.target.name]: event.target.value,
																})
															}
															value={this.state.creditTypeToChange}
															inputProps={{
																name: 'creditTypeToChange',
																id: 'creditTypeToChange',
															}}
														>
															<option value='' selected disabled></option>
															{this.state.availableCreditTypesToChange.map((creditType, index) => (
																<option key={index} value={creditType}>
																	{translations.creditType[creditType]}
																</option>
															))}
														</NativeSelect>
													</div>
												</DialogContent>
												<DialogActions>
													<Button
														onClick={() =>
															this.setState({
																creditTypeChangeConfirmOpen: false,
															})
														}
														color='primary'
														disabled={this.state.isLoading}
													>
														Atšaukti
													</Button>
													<Button
														onClick={this.changeCreditType}
														color='primary'
														autoFocus
														disabled={this.state.isLoading}
													>
														Patvirtinti
													</Button>
												</DialogActions>
											</Dialog>

											<IconButton
												onClick={() => {
													const availableCreditTypesToChange = [];

													if (request.ownsCar) availableCreditTypesToChange.push('car');
													if (request.ownsRealEstate)
														availableCreditTypesToChange.push('spending-real-estate-exchange');

													this.setState({
														creditTypeChangeConfirmOpen: true,
														availableCreditTypesToChange,
														creditTypeToChange: null,
													});
												}}
											>
												<Tooltip title='Pakeisti paraiškos tipą' placement='top'>
													<RotateLeft />
												</Tooltip>
											</IconButton>
										</>
									)}

								<IconButton onClick={() => this.downloadPDF(false)}>
									{this.state.downloadingPersonalPdf ? (
										this.loader()
									) : (
										<Tooltip title='Duomenų tvarkymo sutikimas' placement='top'>
											<Class />
										</Tooltip>
									)}
								</IconButton>

								{request.maritalStatus === 'married' && request.withCoApplicant && (
									<IconButton onClick={() => this.downloadPDF(true)}>
										{this.state.downloadingPartnerPdf ? (
											this.loader()
										) : (
											<Tooltip title='Sutuoktinio duomenų tvarkymo sutikimas' placement='top'>
												<Class />
											</Tooltip>
										)}
									</IconButton>
								)}

								<IconButton onClick={this.downloadRequestAsPDF}>
									{this.state.downloadingRequestPdf ? (
										this.loader()
									) : (
										<Tooltip title='Paraiška PDF formatu' placement='top'>
											<Print />
										</Tooltip>
									)}
								</IconButton>

								{['admin', 'consultant'].includes(this.context.role) && (
									<>
										<br />
										<Consultants
											request={this.props.request}
											changeRequestByIndex={this.props.changeRequestByIndex}
										/>
									</>
								)}
							</div>
						</div>
					</DialogTitle>
					<DialogContent>
						<div className='row py-1 px-2'>
							<div className='col-md-6 col-sm-12 col-12 mt-3'>
								{/* COLUMN 1 */}
								<div
									className='request-section-label mb-2 p-2'
									style={{
										border: '1px solid #3f51b5',
										textTransform: 'uppercase',
									}}
								>
									<i className='fas fa-file-invoice request-section-label-icon' />
									Paraiškos informacija
								</div>
								<div className='row'>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Tipas</div>
										<div className='company-request-text'>
											{translations.creditType[request.creditType]}
										</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Statusas</div>
										<div className='company-request-text'>
											{translations.status[request.status]}
										</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Suma</div>
										<div className='company-request-text'>{request.creditAmount}€</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Laikotarpis</div>
										<div className='company-request-text'>{request.creditLength} mėn.</div>
									</div>

									{/* conditional car information */}
									{request.creditType === 'car' && (
										<>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>
													Galiojantis vairutojo pažymėjimas
												</div>
												<div className='company-request-text'>
													{[true, false].includes(request.driversLicense)
														? request.driversLicense === true
															? 'Turi'
															: 'Neturi'
														: '-'}
												</div>
											</div>

											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Automobilio pagaminimo metai</div>
												<div className='company-request-text'>
													{request.carManufacturingYear || '-'}
												</div>
											</div>

											<div className='col-12 mb-2'>
												<div className='request-info-modal-title'>Autolizingo tipas</div>
												<div className='company-request-text'>
													{translations.carCreditType[request.carCreditType]}
												</div>
											</div>
											<div className='col-12 mb-2'>
												<div className='request-info-modal-title'>Automobilio informacija</div>
												<div className='company-request-text-normal'>{request.carInfo || '-'}</div>
											</div>
										</>
									)}

									{request.creditConfirmations[0] &&
										request.creditConfirmations[0].creditCompanyUser &&
										request.creditConfirmations[0].creditCompanyUser.firstName &&
										request.creditConfirmations[0].creditCompanyUser.lastName && (
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Vadybininkas</div>
												<div className='company-request-text'>
													{request.creditConfirmations[0].creditCompanyUser.firstName}{' '}
													{request.creditConfirmations[0].creditCompanyUser.lastName}
												</div>
											</div>
										)}

									<div className='col-12 mb-2'>
										<div className='request-info-modal-title'>Paraiškos situacija</div>
										<div className='company-request-text-normal'>
											{['admin', 'consultant'].includes(this.context.role) ? (
												<Situation request={request} getRequests={this.props.getRequests} />
											) : (
												<>{request.situation || '-'}</>
											)}
										</div>
									</div>

									{['admin', 'consultant'].includes(this.context.role) &&
									request.isIrrevelant &&
									request.irrevelantReason ? (
										<div className='col-12 mb-2'>
											<div className='request-info-modal-title'>Neaktualumo priežastis</div>
											<div className='company-request-text-normal'>{request.irrevelantReason}</div>
										</div>
									) : (
										''
									)}

									{request.status === 'canceled' && (
										<div className='col-12 mb-2'>
											<div className='request-info-modal-title'>Nepavyko susisiekti priežastis</div>
											<div className='company-request-text'>{request.canceledReason || '-'}</div>
										</div>
									)}
								</div>

								<div
									className='request-section-label mb-2 p-2'
									style={{
										border: '1px solid #3f51b5',
										textTransform: 'uppercase',
									}}
								>
									<i className='fas fa-money-bill-alt request-section-label-icon' />
									Pajamos ir įsipareigojimai
								</div>
								<div className='row'>
									<div className='col-12 mb-2'>
										<div className='request-info-modal-title'>Užsiėmimas</div>
										<div className='company-request-text'>
											{translations.occupationType[request.occupationType]}
										</div>
									</div>
									{request.occupationType !== 'other' && (
										<div className='col-6 mb-2'>
											<div className='request-info-modal-title'>
												{request.occupationType === 'official' ? 'Tarnybos stažas' : 'Darbo stažas'}
											</div>
											<div className='company-request-text'>
												{translations.workExperience[request.workExperience] || '-'}
											</div>
										</div>
									)}
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Pajamos</div>
										<div className='company-request-text'>{request.personalIncome}€ / mėn.</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Įsipareigojimai</div>
										<div className='company-request-text'>
											{request.financialObligations}€ / mėn.
										</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Būsto paskolos įmoka</div>
										<div className='company-request-text'>
											{[null, undefined].includes(request.housingObligations)
												? '-'
												: request.housingObligations}{' '}
											€ / mėn.
										</div>
									</div>

									{request.maritalStatus === 'married' && request.withCoApplicant && (
										<>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Sutuoktinio pajamos</div>
												<div className='company-request-text'>
													{[null, undefined].includes(request.partnerPersonalIncome)
														? '-'
														: request.partnerPersonalIncome}{' '}
													€ / mėn.
												</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Sutuoktinio įsipareigojimai</div>
												<div className='company-request-text'>
													{[null, undefined].includes(request.partnerFinancialObligations)
														? '-'
														: request.partnerFinancialObligations}{' '}
													€ / mėn.
												</div>
											</div>
										</>
									)}

									{['spending', 'refinancing'].includes(request.creditType) && (
										<div className='col-6 mb-2'>
											<div className='request-info-modal-title'>Turi neklinojamojo turto</div>
											<div className='company-request-text'>
												{[true, false].includes(request.ownsRealEstate)
													? request.ownsRealEstate === true
														? 'Taip'
														: 'Ne'
													: '-'}
											</div>
										</div>
									)}

									{request.creditType === 'spending' && (
										<div className='col-6 mb-2'>
											<div className='request-info-modal-title'>
												Turi savo vardu registruotą automobilį
											</div>
											<div className='company-request-text'>
												{[true, false].includes(request.ownsCar)
													? request.ownsCar === true
														? 'Taip'
														: 'Ne'
													: '-'}
											</div>
										</div>
									)}

									{![undefined, null].includes(request.futurePersonalIncome) && (
										<div className='col-6 mb-2'>
											<div className='request-info-modal-title'>Pajamos ateityje</div>
											<div className='company-request-text'>
												{request.futurePersonalIncome} € / mėn.
											</div>
										</div>
									)}
									{![undefined, null].includes(request.futureFinancialObligations) && (
										<div className='col-6 mb-2'>
											<div className='request-info-modal-title'>Įsipareigojimai ateityje</div>
											<div className='company-request-text'>
												{request.futureFinancialObligations} € / mėn.
											</div>
										</div>
									)}
								</div>

								{request.creditType === 'spending-real-estate-exchange' &&
									request.realEstateExchangeInfo && (
										<>
											<div
												className='request-section-label mb-2 p-2'
												style={{
													border: '1px solid #3f51b5',
													textTransform: 'uppercase',
												}}
											>
												<i className='fas fa-building request-section-label-icon' />
												Neklinojamojo turto informacija
											</div>
											<div className='row'>
												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Tipas</div>
													<div className='company-request-text'>
														{/* {request.realEstateExchangeInfo.realEstateType} */}
														{
															translations.realEstateType[
																request.realEstateExchangeInfo.realEstateType
															]
														}
													</div>
												</div>

												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Savivaldybė</div>
													<div className='company-request-text'>
														{request.realEstateExchangeInfo.realEstateMunicipality}
													</div>
												</div>

												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Miestas</div>
													<div className='company-request-text'>
														{request.realEstateExchangeInfo.realEstateCity}
													</div>
												</div>

												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Adresas</div>
													<div className='company-request-text'>
														{request.realEstateExchangeInfo.realEstateAddress}
													</div>
												</div>

												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Pašto kodas</div>
													<div className='company-request-text'>
														{request.realEstateExchangeInfo.realEstatePostCode}
													</div>
												</div>
											</div>
										</>
									)}

								{request.creditConfirmations[0] &&
									request.creditConfirmations[0].type === 'accept' &&
									!['admin', 'consultant'].includes(this.context.role) && (
										<>
											<div
												className='request-section-label mb-2 p-2'
												style={{
													border: '1px solid #3f51b5',
													textTransform: 'uppercase',
												}}
											>
												<i className='fas fa-euro-sign request-section-label-icon' />
												Pateiktas pasiūlymas
											</div>
											<div className='row mt-2'>
												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Nuo</div>
													<div className='company-request-text'>
														{request.creditConfirmations[0].creditOfferFrom}€
													</div>
												</div>
												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Iki</div>
													<div className='company-request-text'>
														{request.creditConfirmations[0].creditOfferTo}€
													</div>
												</div>
												{request.creditType === 'car' &&
												request.creditConfirmations[0].isCarLoan ? (
													<div className='col-12 mb-2'>
														<div className='request-info-modal-title'>
															Kredito įstaigos komentaras
														</div>
														<div className='company-request-text'>
															{request.creditConfirmations[0].comment}
														</div>
													</div>
												) : (
													<>
														<div className='col-6 mb-2'>
															<div className='request-info-modal-title'>Palūkanos</div>
															<div className='company-request-text'>
																{request.creditConfirmations[0].interestRate}%
															</div>
														</div>
														<div className='col-6 mb-2'>
															<div className='request-info-modal-title'>BVKKMN</div>
															<div className='company-request-text'>
																{request.creditConfirmations[0].BVKKMN || '- '}%
															</div>
														</div>
													</>
												)}

												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Sutarties mokestis</div>
													<div className='company-request-text'>
														{request.creditConfirmations[0].fee}€
													</div>
												</div>
												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Menesinė įmoka</div>
													<div className='company-request-text'>
														{request.creditConfirmations[0].monthlyPayment}€ / mėn.
													</div>
												</div>
												<div className='col-6 mb-2'>
													<div className='request-info-modal-title'>Trukmė</div>
													<div className='company-request-text'>
														{request.creditConfirmations[0].creditLength} mėn.
													</div>
												</div>
												{request.creditConfirmations[0].entranceFee !== undefined && (
													<div className='col-6 mb-2'>
														<div className='request-info-modal-title'>Stojamasis mokestis</div>
														<div className='company-request-text'>
															{request.creditConfirmations[0].entranceFee}€
														</div>
													</div>
												)}
												{request.creditConfirmations[0].share !== undefined && (
													<div className='col-6 mb-2'>
														<div className='request-info-modal-title'>Pajinis įnašas</div>
														<div className='company-request-text'>
															{request.creditConfirmations[0].share}€
														</div>
													</div>
												)}
												{(request.status === 'waiting_sign' || request.status === 'signed') &&
													request.signUrl && (
														<div className='col-12 mb-2'>
															<div className='request-info-modal-title'>
																Elektroninio pasirašymo nuoroda
															</div>
															<div className='company-request-text-normal'>{request.signUrl}</div>
														</div>
													)}

												{request.creditConfirmations[0].comment && (
													<div className='col-12 mb-2'>
														<div className='request-info-modal-title'>Komentaras</div>
														<div className='company-request-text-normal'>
															{request.creditConfirmations[0].comment}
														</div>
													</div>
												)}
											</div>
										</>
									)}

								{this.context.role === 'credit' &&
									request.creditConfirmations[0] &&
									request.creditConfirmations[0].additionalDocuments &&
									request.creditConfirmations[0].additionalDocuments.length > 0 && (
										<>
											<div
												className='request-section-label mb-2 p-2'
												style={{
													border: '1px solid #3f51b5',
													textTransform: 'uppercase',
												}}
											>
												<i className='fas fa-info-circle request-section-label-icon' />
												Trūkstama informacija
											</div>
											<div className='row mt-2'>
												{request.creditConfirmations[0].additionalDocuments.map(
													(document, index) => {
														return (
															<div className='col-6 mb-2' key={index}>
																<div className='request-info-modal-title'>{document.info}</div>
																<div className='company-request-text'>
																	{document.input ? (
																		<>
																			{document.type === 'text' && document.input}
																			{document.type === 'file' && (
																				<Button
																					variant='contained'
																					color='primary'
																					onClick={() => this.openDocumentInViewer(document.input)}
																				>
																					Atidaryti
																				</Button>
																			)}

																			{request.creditConfirmations[0].type === 'lacking-info' && (
																				<IconButton
																					onClick={() => {
																						this.setState({
																							revertLackingInfoModalOpen: true,
																							index,
																						});
																					}}
																				>
																					<Tooltip
																						title='Pažymėti kaip netinkamą informaciją'
																						placement='top'
																					>
																						<SettingsBackupRestore />
																					</Tooltip>
																				</IconButton>
																			)}
																		</>
																	) : (
																		'-'
																	)}
																</div>
															</div>
														);
													},
												)}
											</div>
										</>
									)}

								{/* signed request */}
								{request.status === 'signed' ? (
									<>
										<div
											className='request-section-label mb-2 p-2'
											style={{
												border: '1px solid #3f51b5',
												textTransform: 'uppercase',
											}}
										>
											<i className='fas fa-euro-sign request-section-label-icon' />
											Išduota paskola
										</div>
										<div className='row mt-2'>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Išduota</div>
												<div className='company-request-text'>{request.signedAmount}€</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Terminas</div>
												<div className='company-request-text'>{request.signedLength} mėn.</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Palūkanos</div>
												<div className='company-request-text'>{request.signedInterestRate}%</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>BVKKMN</div>
												<div className='company-request-text'>{request.signedBVKKMN || '0'}%</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Sutarties mokestis</div>
												<div className='company-request-text'>{request.signedFee}€</div>
											</div>
											<div className='col-12 mb-2'>
												<div className='request-info-modal-title'>Menesinė įmoka</div>
												<div className='company-request-text'>
													{request.signedMonthlyPayment}€ / mėn.
												</div>
											</div>
										</div>
									</>
								) : (
									''
								)}
							</div>
							<div className='col-md-6 col-sm-12 col-12 mt-3'>
								{/* COLUMN 2 */}
								<div
									className='request-section-label mb-2 p-2'
									style={{
										border: '1px solid #3f51b5',
										textTransform: 'uppercase',
									}}
								>
									<i className='fas fa-user request-section-label-icon' />
									Asmeniniai duomenys
								</div>
								<div className='row'>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Vardas</div>
										<div className='company-request-text'>{request.firstName}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Pavardė</div>
										<div className='company-request-text'>{request.lastName}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Asmens kodas</div>
										<div className='company-request-text'>{request.personalID}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Amžius</div>
										<div className='company-request-text'>{request.age}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Savivaldybė</div>
										<div className='company-request-text'>{request.residenceMunicipality}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Miestas</div>
										<div className='company-request-text'>{request.residenceCity}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Adresas</div>
										<div className='company-request-text'>{request.residenceAddress}</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Išsilavinimas</div>
										<div className='company-request-text'>
											{translations.educationLevel[request.educationLevel] || '-'}
										</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Ar susituokęs?</div>
										<div className='company-request-text'>
											{request.maritalStatus === 'married' ? 'Taip' : 'Ne'}
										</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Ar PEP?</div>
										<div className='company-request-text'>
											{request.isRelatedToPolitics === true ? 'Taip' : 'Ne'}
											{console.log(request)}
										</div>
									</div>
									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>
											Ar tikrasis paskolos naudos gavėjas?
										</div>
										<div className='company-request-text'>
											{request.beneficialOwner === true ? 'Taip' : 'Ne'}
											{console.log(request)}
										</div>
									</div>
									{request.maritalStatus === 'married' && (
										<div className='col-6 mb-2'>
											<div className='request-info-modal-title'>Paskola su sutuoktiniu</div>
											<div className='company-request-text'>
												{request.withCoApplicant ? 'Taip' : 'Ne'}
											</div>
										</div>
									)}

									<div className='col-6 mb-2'>
										<div className='request-info-modal-title'>Vaikų (išlaikytinių) skaičius</div>
										<div className='company-request-text'>{request.childrenCount || '-'}</div>
									</div>
									{['waiting_offer', 'waiting_call', 'waiting_sign', 'signed', 'canceled'].includes(
										request.status,
									) && (
										<div className='col-12 mb-2'>
											<div className='request-info-modal-title'>Banko saskaitos numeris</div>
											<div className='company-request-text'>
												{request.personalConfirmation.bankAccount || '-'}
											</div>
										</div>
									)}
								</div>

								{request.maritalStatus === 'married' && request.withCoApplicant ? (
									<>
										<div
											className='request-section-label mb-2 p-2'
											style={{
												border: '1px solid #3f51b5',
												textTransform: 'uppercase',
											}}
										>
											<i className='fas fa-users request-section-label-icon' />
											Sutuoktinio duomenys
										</div>
										<div className='row'>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Vardas</div>
												<div className='company-request-text'>
													{request.partnerFirstName || '-'}
												</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Pavardė</div>
												<div className='company-request-text'>{request.partnerLastName || '-'}</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Asmens kodas</div>
												<div className='company-request-text'>
													{request.partnerPersonalID || '-'}
												</div>
											</div>

											{[
												'waiting_offer',
												'waiting_call',
												'waiting_sign',
												'signed',
												'canceled',
											].includes(request.status) && (
												<div className='col-12 mb-2'>
													<div className='request-info-modal-title'>Banko saskaitos numeris</div>
													<div className='company-request-text'>
														{request.partnerConfirmation.bankAccount || '-'}
													</div>
												</div>
											)}
										</div>
									</>
								) : (
									''
								)}

								{/* show contacts for waiting_call or waiting_sign */}
								{request.status === 'waiting_call' ||
								request.status === 'waiting_sign' ||
								request.status === 'canceled' ||
								(this.context.role === 'credit' &&
									this.context.creditCompany &&
									this.context.creditCompany.contactVisibility) ||
								['admin', 'consultant'].includes(this.context.role) ? (
									<>
										<div
											className='request-section-label mb-2 p-2'
											style={{
												border: '1px solid #3f51b5',
												textTransform: 'uppercase',
											}}
										>
											<i className='fas fa-phone request-section-label-icon' />
											Kontaktiniai duomenys
										</div>
										<div className='row'>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Kontaktinis telefonas</div>
												<div className='company-request-text'>+370{request.user.contactNumber}</div>
											</div>
											<div className='col-6 mb-2'>
												<div className='request-info-modal-title'>Elektroninis paštas</div>
												<div className='company-request-text'>{request.user.email}</div>
											</div>

											{request.maritalStatus === 'married' && request.withCoApplicant && (
												<>
													<div className='col-6 mb-2'>
														<div className='request-info-modal-title'>Sutuoktinio tel. numeris</div>
														<div className='company-request-text'>
															+370{request.partnerContactNumber}
														</div>
													</div>
													<div className='col-6 mb-2'>
														<div className='request-info-modal-title'>Sutuoktinio el. paštas</div>
														<div className='company-request-text'>
															{request.partnerEmail || '-'}
														</div>
													</div>
												</>
											)}
										</div>
									</>
								) : (
									''
								)}
							</div>

							<ClientQuestions request={request} />

							{['admin', 'consultant'].includes(this.context.role) && (
								<ReminderNotifications request={request} />
							)}

							{/* notes */}
							{['admin', 'consultant'].includes(this.context.role) && (
								<>
									<div className='col-md-12 col-sm-12 col-12 mt-3'>
										<div
											className='request-section-label mb-2 p-2'
											style={{
												border: '1px solid #3f51b5',
												textTransform: 'uppercase',
											}}
										>
											<i className='fas fa-sticky-note request-section-label-icon' />
											Komentarai
										</div>

										<div>
											<Button
												onClick={() =>
													this.setState({
														noteEditorOpen: !this.state.noteEditorOpen,
														noteTarget: {},
													})
												}
											>
												Sukurti naują komentarą
											</Button>
										</div>

										{this.state.noteEditorOpen && (
											<>
												<div className='mt-3'>
													<TextField
														label='Komentaras'
														placeholder='Įrašykite savo komentarą'
														value={this.state.noteTarget.note}
														onChange={(event) => {
															let { noteTarget } = this.state.noteTarget;

															if (!noteTarget) noteTarget = {};

															noteTarget.note = event.target.value;

															this.setState({ noteTarget });
														}}
														fullWidth
														rows={4}
														multiline
													/>
												</div>

												<div className='text-right mt-3'>
													<Button
														onClick={() =>
															this.setState({
																noteEditorOpen: false,
																noteTarget: {},
															})
														}
													>
														Atšaukti
													</Button>

													<Button
														disabled={
															!this.state.noteTarget.note || this.state.noteTarget.length === 0
														}
														onClick={this.submitNote}
													>
														Patvirtinti
													</Button>
												</div>
											</>
										)}
										<div className='mt-3'>
											{this.state.notes.map((note, index) => (
												// <div key={index}>{note.note}</div>
												<Card key={index} className='mt-3'>
													<CardContent>
														<div
															className='mb-3'
															style={{
																display: 'flex',
															}}
														>
															<span>
																<Avatar>
																	{note.user.firstName[0]}
																	{note.user.lastName[0]}
																</Avatar>
															</span>

															<span className='my-auto pl-3'>
																<Typography>
																	{note.user.firstName} {note.user.lastName} (
																	{moment(note.createdAt).locale('LT').format('LLL')})
																</Typography>
															</span>
														</div>

														{!this.state.noteTarget || this.state.noteTarget._id !== note._id ? (
															<Typography
																onClick={() =>
																	this.setState({
																		noteTarget: note,
																	})
																}
																style={{
																	pointer: 'hover',
																}}
															>
																{note.note}
															</Typography>
														) : (
															<>
																<TextField
																	label='Komentaras'
																	placeholder='Įrašykite savo komentarą'
																	value={this.state.noteTarget.note}
																	onChange={(event) => {
																		let { noteTarget } = this.state;

																		if (!noteTarget) noteTarget = {};

																		noteTarget.note = event.target.value;

																		this.setState({ noteTarget });
																	}}
																	fullWidth
																	rows={4}
																	multiline
																/>
															</>
														)}

														{this.state.noteTarget && this.state.noteTarget._id === note._id && (
															<div className='row mt-3'>
																<div className='col-6'>
																	<Button color='secondary' onClick={() => this.deleteNote(note)}>
																		Ištrinti
																	</Button>
																</div>

																<div className='col-6 text-right'>
																	<Button
																		onClick={() => {
																			this.setState({
																				noteTarget: {},
																			});

																			this.getNotes();
																		}}
																	>
																		Atšaukti
																	</Button>

																	<Button
																		disabled={
																			!this.state.noteTarget.note ||
																			this.state.noteTarget.length === 0
																		}
																		onClick={() => this.editNote(note, this.state.noteTarget.note)}
																	>
																		Patvirtinti
																	</Button>
																</div>
															</div>
														)}
													</CardContent>
												</Card>
											))}
										</div>
									</div>
								</>
							)}

							{['admin', 'consultant'].includes(this.context.role) && (
								<>
									<div className='col-md-12 col-sm-12 col-12 mt-3'>
										<div
											className='request-section-label mb-2 p-2'
											style={{
												border: '1px solid #3f51b5',
												textTransform: 'uppercase',
											}}
										>
											<i className='fas fa-euro-sign request-section-label-icon' />
											Pateikti pasiūlymai
										</div>
										<div className='row'>
											<div className='col-12'>
												{this.state.confirmationInfoModalOpen && (
													<ConfirmationInfoModal
														state={this.state}
														setState={(state) => this.setState(state)}
														confirmation={this.state.confirmation}
														objectFileModalOpen={this.state.objectFileModalOpen}
														setContainerState={this.setContainerState}
														getContainerState={this.getContainerState}
														getRequests={this.props.getRequests}
														request={request}
													/>
												)}
												<table
													style={{
														width: '100%',
														maxWidth: '100%',
														overflow: 'scroll',
													}}
												>
													<tr>
														<th>Statusas</th>
														<th>Kompanija</th>
														<th>Atsakymas</th>
														<th>Komentaras</th>
														<th>Vadybininkas</th>
														<th>Pasirinkta</th>
														<th>Rodyti</th>
														<th></th>
													</tr>

													{this.state.request.availableConfirmations.map((confirmation) => {
														return (
															<tr>
																<td>
																	{!confirmation.visible || !confirmation.creditConfirmation ? (
																		<>
																			{confirmation.visible &&
																			!['unconfirmed', 'inactive'].includes(
																				this.state.request.status,
																			) ? (
																				<AccessTime />
																			) : (
																				<VisibilityOff style={{ color: 'red' }} />
																			)}
																		</>
																	) : (
																		<>
																			{confirmation.creditConfirmation.type === 'accept' && (
																				<Done style={{ color: 'green' }} />
																			)}
																			{confirmation.creditConfirmation.type === 'decline' && (
																				<Clear style={{ color: 'red' }} />
																			)}
																			{confirmation.creditConfirmation.type === 'lacking-info' && (
																				<Info color='primary' />
																			)}
																		</>
																	)}
																</td>
																<td>
																	{confirmation.name}{' '}
																	{this.state.request.hideLabels && (
																		<>
																			(<strong>{confirmation.hiddenLabelName}</strong>)
																		</>
																	)}
																</td>
																<td>
																	<IconButton
																		className='p-1'
																		disabled={
																			!confirmation ||
																			!confirmation.creditConfirmation ||
																			confirmation.creditConfirmation.type === 'decline'
																		}
																		onClick={() =>
																			this.setState({
																				confirmationInfoModalOpen: true,
																				confirmation: confirmation.creditConfirmation,
																			})
																		}
																	>
																		<Info />
																	</IconButton>
																</td>
																<td>
																	{confirmation &&
																	confirmation.creditConfirmation &&
																	confirmation.creditConfirmation.reason
																		? confirmation.creditConfirmation.reason
																		: ' - '}
																</td>
																<td>
																	{confirmation &&
																	confirmation.creditConfirmation &&
																	confirmation.creditConfirmation.creditCompanyUser ? (
																		<>
																			{confirmation.creditConfirmation.creditCompanyUser.firstName}{' '}
																			{confirmation.creditConfirmation.creditCompanyUser.lastName}
																		</>
																	) : (
																		'-'
																	)}
																</td>
																<td>
																	{confirmation &&
																	confirmation.creditConfirmation &&
																	confirmation.creditConfirmation.offerAccepted ? (
																		<Done style={{ color: 'green' }} />
																	) : (
																		<Clear style={{ color: 'red' }} />
																	)}
																</td>
																<td>
																	{confirmation.name === 'Paskolų klubas' &&
																	confirmation.companyVisibility !== null ? (
																		<Checkbox
																			defaultChecked={this.checkBoxCheck()}
																			color='primary'
																			inputProps={{ 'aria-label': 'secondary checkbox' }}
																		/>
																	) : (
																		''
																	)}
																</td>
																<td className='text-right'>
																	{confirmation &&
																	confirmation.creditConfirmation &&
																	confirmation.creditConfirmation.offerAccepted &&
																	this.state.request.status !== 'signed' ? (
																		<Popconfirm
																			trigger={<button>Atšaukti</button>}
																			onOkButtonClick={async () => {
																				try {
																					var response = await fetch(
																						process.env.REACT_APP_API +
																							'/api/creditConfirmations/' +
																							confirmation.creditConfirmation._id +
																							'/reset',
																						{
																							method: 'PATCH',
																							headers: {
																								Accept: 'application/json',
																								'Content-Type': 'application/json',
																								Authorization: localStorage.getItem('token'),
																							},
																						},
																					).then((r) => r.json());
																				} catch {
																					return toast.error('Nepavyko atnaujinti');
																				}

																				if (response.success) {
																					await this.getRequest();
																					this.props.changeRequestByIndex(
																						'status',
																						'waiting_offer',
																					);
																					return toast.success('Sėkmingai atnaujinta');
																				}
																			}}
																			title='Ar tikrai norite atšaukti kliento pasirinktą pasiūlymą?'
																			text='Atšaukus kliento pasirinktą pasiūlymą paraiškos statusas pasikeis į "Laukia pasiūlymo".'
																		/>
																	) : (
																		''
																	)}
																</td>
															</tr>
														);
													})}
												</table>
											</div>

											<div className='col-12 mt-3 text-right'>
												<Popconfirm
													trigger={
														<Button variant='contained' color='primary'>
															Atnaujinti
														</Button>
													}
													onOkButtonClick={async () => {
														const { request } = this.props;
														this.showCreditCompnay();
														try {
															var response = await fetch(
																process.env.REACT_APP_API +
																	'/api/requests/' +
																	request._id +
																	'/visibleCompaniesRenewal',
																{
																	method: 'POST',
																	headers: {
																		Accept: 'application/json',
																		'Content-Type': 'application/json',
																		Authorization: localStorage.getItem('token'),
																	},
																},
															).then((r) => r.json());
														} catch {
															return toast.error('Nepavyko atnaujinti');
														}

														this.getRequest();
														setTimeout(function () {
															window.location.reload();
														}, 2000);
														return toast.success('Sėkmingai atnaujinta');
													}}
												/>
											</div>
										</div>
									</div>

									<div className='col-md-12 col-sm-12 col-12 mt-3'>
										<div
											className='request-section-label mb-2 p-2'
											style={{
												border: '1px solid #3f51b5',
												textTransform: 'uppercase',
											}}
										>
											<i className='fas fa-laptop-code request-section-label-icon' />
											Integracijos
										</div>

										<div className='row'>
											<div className='col-12'>
												<Integrations request={this.state.request} />
											</div>
										</div>
									</div>
									<IdentityConfirmations
										request={this.state.request}
										getRequest={this.getRequest}
									/>

									{clientsOtherRequests && clientsOtherRequests.length > 0 && (
										<>
											<div className='col-md-12 col-sm-12 col-12 mt-3'>
												<div
													className='request-section-label mb-2 p-2'
													style={{
														border: '1px solid #3f51b5',
														textTransform: 'uppercase',
													}}
												>
													<i className='fas fa-paste request-section-label-icon' />
													KITOS VARTOTOJO PARAIŠKOS
												</div>
											</div>

											<div className='col-12 mt-3'>
												<table
													style={{
														width: '100%',
														minWidth: '100%',
													}}
												>
													<tr>
														<th className='w-50'>Paraiškos ID</th>
														<th className='w-50'>Paraiškos statusas</th>
													</tr>

													{clientsOtherRequests.map((creditRequest, index) => (
														<tr key={index}>
															<td className='w-50'>{creditRequest.id}</td>
															<td className='w-50'>{translations.status[creditRequest.status]}</td>
														</tr>
													))}
												</table>
											</div>
										</>
									)}

									{request.ref && (
										<div className='col-md-12 col-sm-12 col-12 mt-3'>
											<div
												className='request-section-label mb-2 p-2'
												style={{
													border: '1px solid #3f51b5',
													textTransform: 'uppercase',
												}}
											>
												<i className='fas fa-door-open request-section-label-icon' />
												Kliento atvykimo šaltinis
											</div>
											<div className='row'>
												<div className='col-12'>
													<table
														style={{
															width: '100%',
															minWidth: '100%',
														}}
													>
														<tr>
															<th>Source</th>
															<th>Campaign</th>
															<th>Keyword</th>
														</tr>
														<tr>
															<td>{request.ref.source}</td>
															<td>{request.ref.campaign}</td>
															<td>{request.ref.keyword}</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									)}
								</>
							)}

							<div className='col-md-12 col-sm-12 col-12 mt-3'>
								<div
									className='request-section-label mb-2 p-2'
									style={{
										border: '1px solid #3f51b5',
										textTransform: 'uppercase',
									}}
								>
									<i className='fas fa-paperclip request-section-label-icon' />
									Įkelti dokumentai
								</div>

								<div className='row'>
									<div className='col-12'>
										{requiredDocuments &&
											requiredDocuments
												.filter((document) => document.files && document.files.length > 0)
												.map((document, index) => (
													<div key={index} className='row mt-2'>
														<div className='col-6'>{document.type}</div>
														<div className='col-6 text-right'>
															{['admin', 'consultant'].includes(this.context.role) && (
																<Button
																	variant='outlined'
																	size='small'
																	color='secondary'
																	className='mr-2'
																	// onClick={() => this.openDocumentInViewer(document.files)}
																	onClick={() => this.deleteRequiredDocument(document.type)}
																>
																	Ištrinti
																</Button>
															)}

															<Button
																variant='outlined'
																size='small'
																color='primary'
																onClick={() => this.openDocumentInViewer(document.files)}
															>
																Atidaryti
															</Button>
														</div>
													</div>
												))}

										{request.attachedFiles && request.attachedFiles.length > 0 && (
											<div className='row mt-2'>
												<div className='col-6'>Papildomi dokumentai</div>
												<div className='col-6 text-right'>
													<Button
														variant='outlined'
														size='small'
														color='primary'
														onClick={() => this.openDocumentInViewer(request.attachedFiles)}
													>
														Atidaryti
													</Button>
												</div>
											</div>
										)}

										{request.creditType === 'spending-real-estate-exchange' &&
											request.realEstateExchangeInfo &&
											request.realEstateExchangeInfo.realEstateDocuments &&
											request.realEstateExchangeInfo.realEstateDocuments.length > 0 && (
												<div className='row mt-2'>
													<div className='col-6'>Nekilnojamojo turto dokumentai</div>
													<div className='col-6 text-right'>
														<Button
															variant='outlined'
															size='small'
															color='primary'
															onClick={() =>
																this.openDocumentInViewer(
																	request.realEstateExchangeInfo.realEstateDocuments,
																)
															}
														>
															Atidaryti
														</Button>
													</div>
												</div>
											)}

										{request.creditType === 'refinancing' &&
											request.refinancingFilePaths.length > 0 && (
												<div className='row mt-2'>
													<div className='col-6'>Refinansavimo dokumentai</div>
													<div className='col-6 text-right'>
														<Button
															variant='outlined'
															size='small'
															color='primary'
															onClick={() =>
																this.openDocumentInViewer(request.refinancingFilePaths)
															}
														>
															Atidaryti
														</Button>
													</div>
												</div>
											)}

										{request.documentFilePaths && request.documentFilePaths.length > 0 && (
											<div className='row mt-2'>
												<div className='col-6'>Pajamų dokumentai</div>
												<div className='col-6 text-right'>
													<Button
														variant='outlined'
														size='small'
														color='primary'
														onClick={() => this.openDocumentInViewer(request.documentFilePaths)}
													>
														Atidaryti
													</Button>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color='primary'>
							Išeiti
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

RequestInfoModal.contextType = AppContext;
export default RequestInfoModal;
