import { Avatar, Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';

const ConsultantDisplay = ({ consultant }) => (
	<>
		<span class='pr-1'>
			<Avatar
				style={{
					display: 'inline',
					fontSize: '14px',
					padding: '4px',
				}}
			>
				{consultant.firstName[0]}
				{consultant.lastName[0]}
			</Avatar>
		</span>
		{consultant.firstName} {consultant.lastName}
	</>
);

const Consultants = ({ request, changeRequestByIndex }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [consultants, setConsultants] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const getUserByRole = async (role = '') => {
		let response = await fetch(process.env.REACT_APP_API + '/api/users?role=' + role, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		});

		response = await response.json();

		if (response.error) {
			toast.error('Serverio klaida');
			return [];
		}

		return response;
	};

	const getConsultants = async () => {
		const consultants = await getUserByRole('consultant');
		const admins = await getUserByRole('admin');

		setConsultants([...consultants, ...admins]);
	};

	const handleClick = (event) => {
		if (consultants.length === 0) {
			getConsultants();
		}

		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSubmit = (newConsultant) => {
		setIsLoading(true);

		fetch(process.env.REACT_APP_API + `/api/requests/${request._id}/consultant`, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				consultant: newConsultant._id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko atnaujinti');
				}

				changeRequestByIndex('consultant', newConsultant);
				toast.success('Vadybininkas sėkmingai pakeistas');
			})
			.finally(() => {
				handleClose();

				// no good reason for this timeout, just looks a little bit better
				setTimeout(() => setIsLoading(false), 300);
			});
	};

	return (
		<>
			<Button onClick={handleClick}>
				{request.consultant ? (
					<ConsultantDisplay consultant={request.consultant} />
				) : (
					'Priskirti vadybininką'
				)}
			</Button>

			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				{consultants.length === 0 || isLoading ? (
					<>
						<MenuItem>
							<CircularProgress />
						</MenuItem>
					</>
				) : (
					<>
						{consultants.map((consultant, key) => (
							<MenuItem
								key={key}
								onClick={() => handleSubmit(consultant)}
								disabled={request.consultant && consultant._id == request.consultant._id}
							>
								<ConsultantDisplay consultant={consultant} />
							</MenuItem>
						))}
					</>
				)}
			</Menu>
		</>
	);
};

export default Consultants;
