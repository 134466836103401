import React, { Component } from 'react';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import { Slide } from 'material-auto-rotating-carousel';
import { red, blue, green } from '@material-ui/core/colors';
import FillRequest from '../../../assets/img/help/user/uzpildykite_paraiska.png';
import ConfirmRequest from '../../../assets/img/help/user/patvirtinkite_paraiska.png';

class HelpCarousel extends Component {
	render() {
		let containerState = this.props.getContainerState();
		let mobileWidth = 700;

		return (
			<AutoRotatingCarousel
				autoplay={false}
				// ButtonProps={{
				// 	style: {
				// 		display: 'none',
				// 	},
				// }}
				mobile={window.innerWidth < mobileWidth}
				label={window.innerWidth < mobileWidth ? 'Gauti pasiūlymą' : false}
				// label='Gauti geriausią pasiūlymą'
				open={containerState.open}
				onClose={() => this.props.setContainerState({ open: false })}
				onStart={() => this.props.setContainerState({ open: false })}
				style={{ position: 'absolute' }}
			>
				<Slide
					media={
						<img
							src={FillRequest}
							alt='Užpildykite paraišką'
							style={{
								width: '100%',
								height: '90%',
								borderRadius: 4,
							}}
						/>
					}
					mediaBackgroundStyle={{ backgroundColor: blue[600], height: 'auto' }}
					style={{ backgroundColor: blue[600] }}
					title='Užpildykite paraišką'
					subtitle='Just using this will blow your mind.'
				/>
				<Slide
					media={
						<img
							src={ConfirmRequest}
							alt='Patvirtinkite paraišką'
							style={{
								width: '100%',
								height: '90%',
								borderRadius: 4,
							}}
						/>
					}
					mediaBackgroundStyle={{ backgroundColor: red[600], height: 'auto' }}
					style={{ backgroundColor: red[600] }}
					title='Patvirtinkite paraišką'
					subtitle='Well just mix two colors and your are good to go!'
				/>
				<Slide
					media={
						<img
							src='http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png'
							alt='img'
						/>
					}
					mediaBackgroundStyle={{ backgroundColor: green[400] }}
					style={{ backgroundColor: green[600] }}
					title='May the force be with you'
					subtitle='The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe.'
				/>
			</AutoRotatingCarousel>
		);
	}
}

export default HelpCarousel;
