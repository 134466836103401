import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { SettingsBackupRestore } from '@material-ui/icons';
import moment from 'moment';
import ObjectFileModal from '../../../credit-requests/company/modal/object-file-modal';
import RevertLackingInfoModal from '../../../credit-requests/company/modal/lacking-info/revert-lacking-info-modal';

class ConfirmationInfoModal extends Component {
	constructor() {
		super();

		this.state = {
			objectFileModalOpen: false,
			revertLackingInfoModalOpen: false,
			index: null,
			path: '',
		};
	}

	render() {
		const { confirmation, state, setState, getRequests, request } = this.props;

		const handleClose = () => {
			setState({
				confirmationInfoModalOpen: false,
				revertLackingInfoModalOpen: false,
			});
		};

		return (
			<Dialog open={state.confirmationInfoModalOpen} onClose={handleClose}>
				<ObjectFileModal
					objectFileModalOpen={this.state.objectFileModalOpen}
					setContainerState={(state) => this.setState(state)}
					path={this.state.path}
				/>

				<RevertLackingInfoModal
					objectFileModalOpen={this.state.objectFileModalOpen}
					setContainerState={(state) => this.setState(state)}
					getContainerState={() => this.state}
					getRequests={getRequests}
					request={request}
					index={this.state.index}
				/>

				<DialogTitle>Atsakymo informacija</DialogTitle>
				<DialogContent>
					{confirmation.type === 'lacking-info' && (
						<Table>
							<TableBody>
								{confirmation.additionalDocuments.map((document, index) => (
									<TableRow key={index}>
										<TableCell>
											{document.info} (tipas: {document.type === 'file' ? 'failas' : 'tekstas'})
										</TableCell>
										<TableCell>
											{document.input ? (
												document.type === 'file' ? (
													<Button
														variant='contained'
														color='primary'
														onClick={() => {
															this.setState({
																objectFileModalOpen: true,
																path: document.input,
															});
														}}
													>
														ĮKELTAS
													</Button>
												) : (
													document.input
												)
											) : (
												'-'
											)}

											{document.input && (
												<IconButton
													className='p-1 ml-2'
													onClick={() => {
														this.setState({
															revertLackingInfoModalOpen: true,
															index,
														});
													}}
												>
													<Tooltip title='Pažymėti kaip netinkamą informaciją' placement='top'>
														<SettingsBackupRestore />
													</Tooltip>
												</IconButton>
											)}
										</TableCell>
									</TableRow>
								))}

								{confirmation.requiredDocuments.map((document, index) => (
									<TableRow key={index}>
										<TableCell colSpan='100%'>{document}</TableCell>
									</TableRow>
								))}

								<TableRow>
									<TableCell>Atnaujintas</TableCell>
									<TableCell>{moment(confirmation.updatedAt).locale('lt').format('LLL')}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Kontaktinis telefonas</TableCell>
									<TableCell>+370{confirmation.creditRequest.user.contactNumber}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>El. paštas</TableCell>
									<TableCell>{confirmation.creditRequest.user.email}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					)}

					{confirmation.type === 'accept' && (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell>{confirmation._id}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Paraiškos ID</TableCell>
									<TableCell>{confirmation.creditRequest.id}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Pasiūlymas nuo</TableCell>
									<TableCell>{confirmation.creditOfferFrom}€</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Pasiūlymas iki</TableCell>
									<TableCell>{confirmation.creditOfferTo}€</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Palūkanos</TableCell>
									<TableCell>{confirmation.interestRate}%</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>BVKKMN</TableCell>
									<TableCell>{confirmation.BVKKMN}%</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Menėsinė įmoka</TableCell>
									<TableCell>{confirmation.monthlyPayment}€ / mėn.</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Laikotarpis</TableCell>
									<TableCell>{confirmation.creditLength} mėn.</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Sutarties mokėstis</TableCell>
									<TableCell>{confirmation.fee}€</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Reakcijos laikas</TableCell>
									<TableCell>{confirmation.reactionTime} s.</TableCell>
								</TableRow>
								{confirmation.creditCompany.isCreditUnion && (
									<>
										<TableRow>
											<TableCell>Stojimo mokėstis</TableCell>
											<TableCell>{confirmation.entranceFee}€</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Pajinis įnašas</TableCell>
											<TableCell>{confirmation.share}€</TableCell>
										</TableRow>
									</>
								)}
								<TableRow>
									<TableCell>Kredito įstaiga</TableCell>
									<TableCell>{confirmation.creditCompany.name}</TableCell>
								</TableRow>

								{confirmation.comment && (
									<TableRow>
										<TableCell>Komentaras</TableCell>
										<TableCell>{confirmation.comment}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ConfirmationInfoModal;
