import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

class NotesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			note: '',
			notes: props.request.acceptedOffer.notes || [],
			isLoading: false,
		};
	}

	handleClose = () => {
		this.setState({ isLoading: false });
		this.props.setContainerState({
			notesModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		let { acceptedOffer } = this.props.request;

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations/' + acceptedOffer._id + '/notes', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				note: this.state.note,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) {
					return toast.error('Nepavyko atnaujinti');
				}

				let { notes } = this.state;
				notes.push(this.state.note);

				this.setState({ notes, note: '' });

				// refresh requests
				this.props.getRequests('', false, true);

				return toast.success('Sėkmingai atnaujinta');
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.notesModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
				maxWidth={'sm'}
				fullWidth={true}
			>
				<DialogTitle id='form-dialog-title'>Paraiškos užrašai</DialogTitle>
				<DialogContent>
					<form onSubmit={this.handleSubmit}>
						<div className='row'>
							{this.state.notes.map((note) => (
								<div className='col-12'>
									<div
										style={{
											wordWrap: 'break-word',
											background: '#3f51b5',
											color: 'white',
											borderRadius: '10px',
											padding: '5px',
											fontSize: '12px',
										}}
										color='primary'
										className='text-center my-1'
									>
										{note}
									</div>
								</div>
							))}
							<div className='col-12'>
								<TextField
									required
									label='Užrašas'
									multiline
									rowsMax='4'
									rows='4'
									name='note'
									id='note'
									value={this.state.note}
									onChange={this.handleChange}
									margin='normal'
									placeholder='Įveskite naują užrašą'
									fullWidth
								/>
							</div>
							<div className='col-12'>
								<Button diasbled={this.state.isLoading} type='submit' color='primary'>
									Pridėti
								</Button>
							</div>
						</div>
					</form>

					{this.state.isLoading && <LinearProgress className='my-1' />}
				</DialogContent>
				<DialogActions>
					<Button diasbled={this.state.isLoading} onClick={this.handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default NotesModal;
