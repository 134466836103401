import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {
	Info,
	Close,
	DeviceUnknown,
	Timer,
	Call,
	AssignmentLate,
	AssignmentTurnedIn,
	Backspace,
	VisibilityOff,
	Clear,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import RequestInfoModal from '../company/modal/request-info-modal';
import moment from 'moment';
import 'moment/locale/lt';
import { Avatar, Badge } from '@material-ui/core';

class AdminRequest extends Component {
	constructor(props) {
		super(props);

		this.state = {
			request: props.request,
			requestInfoOpen: false,
		};
	}

	setContainerState = (state) => {
		this.setState(state);
	};

	getContainerState = () => {
		return this.state;
	};

	render() {
		const translations = require('../../../../locale/lt_LT/requests.json');
		let { request, changeRequestByIndex } = this.props;

		const statusOptions = {
			canceled: { color: '#c0392b', icon: <Close className='request-status-icon' /> },
			unconfirmed: { color: '#e67e22', icon: <DeviceUnknown className='request-status-icon' /> },
			waiting_info: { color: '#2980b9', icon: <Info className='request-status-icon' /> },
			waiting_partner_info: { color: '#2980b9', icon: <Info className='request-status-icon' /> },
			waiting_offer: { color: '#2980b9', icon: <Timer className='request-status-icon' /> },
			waiting_call: { color: '#9b59b6', icon: <Call className='request-status-icon' /> },
			waiting_sign: { color: '#1abc9c', icon: <AssignmentLate className='request-status-icon' /> },
			signed: { color: '#27ae60', icon: <AssignmentTurnedIn className='request-status-icon' /> },
			inactive: { color: '#34495e', icon: <Backspace className='request-status-icon' /> },
			unavailable: { color: '#34495e', icon: <VisibilityOff className='request-status-icon' /> },
			no_offers: { color: '#34495e', icon: <Close className='request-status-icon' /> },
		};

		const positiveOfferCount = request.creditConfirmations.filter(
			(c) => c.type === 'accept',
		).length;

		return (
			<>
				{this.state.requestInfoOpen && (
					<RequestInfoModal
						requestInfoOpen={this.state.requestInfoOpen}
						request={request}
						setContainerState={this.setContainerState}
						// spoof request takeovers as they're not needed
						takeRequestOver={() => Promise.resolve()}
						deleteRequestTakeover={() => Promise.resolve()}
						// admin
						changeRequestByIndex={changeRequestByIndex}
					/>
				)}

				<TableRow className='px-3' id={request._id}>
					<TableCell
						component='th'
						scope='row'
						style={{
							textTransform: 'uppercase',
						}}
					>
						{request.consultant && (
							<span class='pr-1'>
								<Avatar
									style={{
										display: 'inline',
										fontSize: '14px',
										padding: '4px',
									}}
								>
									{request.consultant.firstName[0]}
									{request.consultant.lastName[0]}
								</Avatar>
							</span>
						)}

						{request.isIrrevelant && <Clear color='secondary' fontSize='small' />}

						{request.id}
					</TableCell>
					<TableCell
						align='right'
						style={{
							color: statusOptions[request.status].color,
						}}
					>
						{statusOptions[request.status].icon} {translations.status[request.status]}
					</TableCell>
					<TableCell align='right'>
						{moment(request.createdAt).locale('lt').format('LLL')}
					</TableCell>
					<TableCell align='right'>
						{request.firstName} {request.lastName}
					</TableCell>
					<TableCell align='right'>{translations.creditType[request.creditType]}</TableCell>
					<TableCell align='right'>{request.creditAmount}€</TableCell>
					<TableCell align='right'>{request.creditLength} mėn.</TableCell>
					<TableCell align='right'>
						{request.signedAmount ? request.signedAmount + '€' : '-'}
					</TableCell>
					<TableCell align='right'>
						<Tooltip title='Informacija'>
							<IconButton
								className='request-credit-action-btn'
								onClick={() => {
									let target = document.getElementsByClassName('active-company-request-item')[0];

									if (target) {
										target.classList.remove('active-company-request-item');
									}

									target = document.getElementById(request._id);
									target.classList.add('active-company-request-item');

									this.setState({
										requestInfoOpen: true,
									});
								}}
							>
								{request.status === 'waiting_offer' && positiveOfferCount !== 0 ? (
									<Badge badgeContent={positiveOfferCount} color='primary'>
										<Info />
									</Badge>
								) : (
									<Info />
								)}
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			</>
		);
	}
}

export default AdminRequest;
