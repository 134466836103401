import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { addReminder, getRequestReminders, removeReminder } from '../../../../../services/reminders';

const ReminderNotifications = ({ request }) => {
	const [text, setText] = React.useState('');
	const [time, setTime] = React.useState('');
  const [reminders, setReminders] = React.useState([]);
  
  React.useEffect(() => {
    setReminders(getRequestReminders(request.id));
  }, [request.id]);

	const handleCreateReminder = (event) => {
    event.preventDefault();
    
		if (text === '' || time === '') return;

		if (moment(time).isBefore(moment())) {
			return toast.error('Laikas turi būti ateityje');
		}
    
    addReminder(request.id, text, moment(time).unix());
    
    setText('');
    setTime('');
    setReminders(getRequestReminders(request.id));

    toast.success('Priminimas sukurtas');
	};
  
  const handleRemoveReminder = (id) => {
    if (!window.confirm('Ar tikrai norite pašalinti priminimą?')) return;
    
    removeReminder(request.id, id);
  
    setReminders(getRequestReminders(request.id));
    toast.success('Priminimas pašalintas');
  };

	return (
		<div className='col-md-12 col-sm-12 col-12 mt-3'>
			<div
				className='request-section-label mb-2 p-2'
				style={{
					border: '1px solid #3f51b5',
					textTransform: 'uppercase',
				}}
			>
				<i className='fas fa-clock request-section-label-icon' />
				Paraiškos priminimai
			</div>

			<form onSubmit={handleCreateReminder}>
				<div className='row mt-3'>
					<div className='col-6'>
						<TextField
							label='Pranešimas'
							type='text'
							value={text}
							onChange={(e) => setText(e.target.value)}
							fullWidth
						/>
					</div>
					<div className='col-4'>
						<TextField
							label='Pranešimo laikas'
							type='datetime-local'
							value={time}
							onChange={(e) => setTime(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/>
					</div>
					<div className='col-2 mt-auto'>
						<Button
							fullWidth
							type='submit'
							variant='contained'
							color='primary'
							disabled={text === '' || time === ''}
						>
							Sukurti
						</Button>
					</div>
				</div>
			</form>  
      {reminders.map(reminder => (
        <div key={reminder.id} className='row mt-3'>
          <div className="col-6 my-auto">
            {reminder.text}
          </div>
          <div className="col-4 my-auto">
            {moment.unix(reminder.time).format('YYYY-MM-DD HH:mm')}
          </div>
          <div className='col-2'>
            <Button
							fullWidth
							variant='contained'
							color='secondary'
              onClick={() => handleRemoveReminder(reminder.id)}
						>
							Ištrinti
						</Button>
          </div>
        </div>
      ))}
		</div>
	);
};

export default ReminderNotifications;
