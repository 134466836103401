import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Logo from '../../../assets/img/logo.png';
import Popper from '@material-ui/core/Popper';
import PasswordChecker from '../../shared/pasword-checker';
import qs from 'query-string';

class Reset extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			response: false,
			password: '',
			passwordConfirmation: '',
			wasSubmited: false,
		};

		let params = qs.parse(props.location.search);
		fetch(process.env.REACT_APP_API + '/api/resetTokens/' + params.token, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					response: response,
				});
			});
	}

	componentDidMount() {
		document.title = 'Credit King - slaptažodžio atkūrimas';
	}

	handleSubmit = (event) => {
		event.preventDefault();

		if (!this.state.wasSubmited) {
			this.setState({
				wasSubmited: true,
			});
		}

		if (this.state.password.length >= 5) {
			let params = qs.parse(this.props.location.search);

			fetch(process.env.REACT_APP_API + '/api/users/' + this.state.response.data + '/reset', {
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token: params.token,
					password: this.state.password,
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.error) {
						toast.error('Nepavyko pakeisti slaptažodžio');
					} else {
						toast.success('Slaptažodis sėkmingai pakeistas');

						this.props.$helper.initRedirect('/');
					}
				})
				.catch((error) => {
					toast.error('Serverio klaida');
				});
		}
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	render() {
		let params = qs.parse(this.props.location.search);

		if (!params.token) {
			return <Redirect to='/' />;
		} else {
			if (this.state.response.error) {
				return <Redirect to='/' />;
			} else {
				return (
					<div className='container' id='reset-page'>
						<div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 offset-0'>
							<Paper className='input-form-paper'>
								<div className='text-center'>
									<img src={Logo} className='img-fluid auth-page-logo' alt='creditking-logo' />
								</div>
								<form onSubmit={this.handleSubmit}>
									<div className='row'>
										<div className='col-12'>
											<TextField
												required
												type='password'
												fullWidth
												id='password'
												label='Naujas slaptažodis'
												onChange={this.handleChange}
												value={this.state.password}
												margin='dense'
											/>
										</div>

										<Popper
											open={
												this.state.password &&
												this.state.password.length > 0 &&
												!PasswordChecker.isValid(this.state.password)
											}
											placement='bottom'
											anchorEl={document.getElementById('password')}
											style={{
												zIndex: 10000000000000000,
												position: 'absolute',
											}}
											disablePortal={true}
											className='fade-in mt-1'
										>
											<Paper
												className='p-1'
												style={{
													fontSize: 15,
												}}
											>
												<ul>
													<li
														style={{
															color: PasswordChecker.length(this.state.password) ? 'green' : 'red',
														}}
													>
														Slaptažodis privalo būti ne trumpesnis nei 8 simboliai.
													</li>
													<li
														style={{
															color: PasswordChecker.uppercase(this.state.password)
																? 'green'
																: 'red',
														}}
													>
														Slaptažodyje privalo būti bent viena didžioji raidė (A-Z).
													</li>
													<li
														style={{
															color: PasswordChecker.lowercase(this.state.password)
																? 'green'
																: 'red',
														}}
													>
														Slaptažodyje privalo būti bent viena mažoji raidė (a-z).
													</li>
													<li
														style={{
															color: PasswordChecker.digits(this.state.password) ? 'green' : 'red',
														}}
													>
														Slaptažodyje privalo būti bent vienas skaičius (0-9).
													</li>
												</ul>
											</Paper>
										</Popper>

										<div className='col-12'>
											<TextField
												required
												type='password'
												fullWidth
												id='passwordConfirmation'
												label='Pakartokite slaptažodį'
												error={
													this.state.password !== this.state.passwordConfirmation &&
													this.state.password.length > 0 &&
													this.state.passwordConfirmation.length
												}
												onChange={this.handleChange}
												value={this.state.passwordConfirmation}
												margin='dense'
											/>
										</div>
										<div className='col-12 mt-3'>
											<Button
												fullWidth
												type='submit'
												variant='contained'
												color='primary'
												disabled={
													this.state.isLoading ||
													!this.state.password ||
													!this.state.passwordConfirmation ||
													this.state.password !== this.state.passwordConfirmation ||
													!PasswordChecker.isValid(this.state.password)
												}
											>
												Pakeisti slaptažodį
											</Button>
										</div>
									</div>
								</form>
							</Paper>
						</div>
					</div>
				);
			}
		}
	}
}

export default Reset;
