import ErrorTranslation from '../../locale/lt_LT/error';
import moment from 'moment';

let ErrorHandler = {
	firstName: (val) => {
		let errors = '';

		if (/\d/.test(val)) {
			errors += ErrorTranslation.firstName['0x1'];
		}

		return errors;
	},
	lastName: (val) => {
		let errors = '';

		if (/\d/.test(val)) {
			errors += ErrorTranslation.lastName['0x1'];
		}

		return errors;
	},
	personalID: (val) => {
		let errors = '';

		if (!val) {
			return errors;
		}

		if (('' + val).length !== 11) {
			errors += ErrorTranslation.personalID['0x1'];
		} else {
			// check if user is atleast 18 years old
			let year = parseInt(val[1] + val[2]);
			let month = parseInt(val[3] + val[4]);
			let day = parseInt(val[5] + val[6]);

			if (['1', '2'].includes(val[0])) {
				// born 1800-1899
				year += 1800;
			} else if (['3', '4'].includes(val[0])) {
				// born 1900-1999
				year += 1900;
			} else {
				// born after 2000
				year += 2000;
			}

			let yearsOld = moment().diff(`${year}-${month}-${day}`, 'years', false);

			if (yearsOld < 18) {
				errors += ErrorTranslation.personalID['0x2'];
			}
		}

		return errors;
	},
	contactNumber: (val) => {
		let errors = '';

		if (!val) {
			return errors;
		}

		if (('' + val).length !== 8) {
			errors += ErrorTranslation.contactNumber['0x1'];
		}

		return errors;
	},
	documentID: (type, val) => {
		let errors = '';

		if (!val) {
			return errors;
		}

		switch (type) {
			case 'passport':
				if (!/^\d{8}$/.test(val)) errors += ErrorTranslation.documentID['0x1'];
				break;
			case 'id-card':
				if (!/^\d{8}$/.test(val)) errors += ErrorTranslation.documentID['0x2'];
				break;
			default:
				errors += ErrorTranslation.documentID['0x3'];
		}

		return val.length > 0 ? errors : false;
	},
	age: (val) => {
		let errors = '';

		if (val !== null && val < 18) {
			errors += ErrorTranslation.age['0x1'];
		}

		return errors;
	},
	finances: (val) => {
		let errors = '';

		if (!val) {
			return errors;
		}

		if (val < 0) {
			errors += ErrorTranslation.finances['0x1'];
		}

		return errors;
	},
	partnerPersonalID: (val, personalID) => {
		let errors = '';

		if (val && personalID && val.toString() == personalID.toString()) {
			errors += ErrorTranslation.partnerPersonalID['0x1'];
		}

		return errors;
	},
};

export default ErrorHandler;
