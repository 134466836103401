import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Loader from '../../shared/loader';
import Report from './report';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';

class Reports extends Component {
	constructor(props) {
		super(props);

		let mainAppState = props.$helper.getAppState();

		this.state = {
			filterDate: {
				from: 0,
				to: 0,
			},
			filterType: {
				data: 'all',
			},
			filterManager: {
				data: 'all',
			},
			filterCreditCompany: {
				data: 'all',
			},
			filterStatus: {
				status: 'signed',
			},
			creditCompanyUsers: [],
			reports: [],
			isLoading: true,
			isDisabled: false,
		};

		if (mainAppState.role === 'credit') {
			fetch(
				process.env.REACT_APP_API +
					'/api/creditCompanies/' +
					mainAppState.creditCompany._id +
					'/users',
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			)
				.then((response) => response.json())
				.then((response) => {
					if (response.error) {
						toast.error('Serverio klaida');
					} else {
						this.setState({
							creditCompanyUsers: response,
						});

						this.fetchReports();
					}
				})
				.catch((error) => {
					toast.error('Serverio klaida');
				});
		} else {
			this.fetchReports();
		}
	}

	setDate() {
		let date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth();

		this.setState({
			filterDate: {
				from: moment(new Date(y, m, 1)).format('YYYY-MM-DD'),
				to: moment(new Date(y, m + 1, 0)).format('YYYY-MM-DD'),
			},
		});
	}

	componentDidMount() {
		this.setDate();

		document.title = 'Credit King - ataskaitos';
	}

	generateReport = (event) => {
		event.preventDefault();

		this.setState({
			isDisabled: true,
		});

		let appState = this.props.$helper.getAppState();

		fetch(process.env.REACT_APP_API + '/api/reports', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				dateFrom: new Date(this.state.filterDate.from + 'T00:00:00.000Z'),
				dateTo: new Date(this.state.filterDate.to + 'T23:59:59Z'),
				creditType: this.state.filterType.data,
				manager: this.state.filterManager.data,
				creditCompany:
					appState.role === 'admin' && this.state.filterCreditCompany.data !== 'all'
						? this.state.filterCreditCompany.data
						: undefined,
				status: this.state.filterStatus.status,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(
						response.error === 'No credit requests have been found'
							? 'Kredito paraiškų pagal kritrijus nerasta'
							: 'Serverio klaida',
					);

					this.setState({
						isDisabled: false,
					});
				} else {
					this.fetchReports();

					toast.success('Ataskaita sėkmingai sugeneruota');

					this.setState({
						isDisabled: false,
					});
				}
			})
			.catch((error) => {
				toast.error('Serverio klaida');
			});
	};

	fetchReports = () => {
		fetch(process.env.REACT_APP_API + '/api/reports', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error('Nepavyko atsisiųsti turimų paraiškų');
				} else {
					this.setState({
						isLoading: false,
						reports: response,
					});
				}
			})
			.catch((error) => {
				toast.error('Serverio klaida');
			});
	};

	downloadReport = (id) => {
		fetch(process.env.REACT_APP_API + '/api/reports/' + id, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.text())
			.then((response) => {
				if (!response) {
					toast.danger('Serverio klaida');
				} else {
					// as new Blob construcotr only supports accepting an array,
					// create an array out of the response
					let blobArray = [response];

					let blob = new Blob(blobArray, { type: 'application/octet-stream' });
					let fileUrl = window.URL.createObjectURL(blob);
					let linkElement = document.createElement('a');

					linkElement.setAttribute('href', fileUrl);
					linkElement.setAttribute('download', id + '.csv');

					let linkClickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false,
					});

					linkElement.dispatchEvent(linkClickEvent);
					linkElement.remove();
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Serverio klaida');
			});
	};

	handleFilterChange = (event, filterType, date) => {
		let value, filterData;
		if (typeof event === 'string') {
			value = date;
			filterData = event;
		} else {
			value = event.target.value;
			filterData = event.target.name;
		}

		let currentObject = Object.assign({}, this.state[filterType]);
		currentObject[filterData] = value;

		this.setState({
			[filterType]: currentObject,
		});
	};

	render() {
		let appState = this.props.$helper.getAppState();

		return (
			<div>
				<h1 className='app-page-title'>Ataskaitos</h1>
				<Paper className='px-3 py-2'>
					<Typography variant='h5'>Ataskaitos generavimas</Typography>
					<Typography>
						Ataskaitos generavimas gali užtrukti iki kelių minučių, išsiuntus užklausą, galite
						palikti šį puslapį ir į jį grįžti vėliau. Kaip atidaryti CSV atsisiųstą failą Excelyje
						galite pamatyti{' '}
						<a
							href='https://creditking.lt/wp-content/uploads/2019/11/Kaip-atidaryti-CSV-ataskaitos-faila_-MS-Office-Excel-programoje.pdf'
							target='_blank'
						>
							čią
						</a>
						.
					</Typography>
					<form onSubmit={this.generateReport}>
						<div className='row mt-3'>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='normal'
												label='Nuo'
												name='from'
												className='m-0'
												value={this.state.filterDate.from}
												onChange={(date) =>
													this.handleFilterChange(
														'from',
														'filterDate',
														moment(date).format('YYYY-MM-DD'),
													)
												}
												format={'dd-MM-yyyy'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</FormGroup>
							</div>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='normal'
												label='Iki'
												name='to'
												className='m-0'
												value={this.state.filterDate.to}
												onChange={(date) =>
													this.handleFilterChange(
														'to',
														'filterDate',
														moment(date).format('YYYY-MM-DD'),
													)
												}
												format={'dd-MM-yyyy'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</FormGroup>
							</div>
							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<FormControl>
										<InputLabel>Paskolos tipas</InputLabel>
										<Select
											fullWidth
											required
											margin='normal'
											value={this.state.filterType.data}
											onChange={(event) => this.handleFilterChange(event, 'filterType')}
											inputProps={{
												name: 'data',
												id: 'data',
											}}
										>
											<MenuItem defaultValue value='all'>
												<em>Visi</em>
											</MenuItem>
											<MenuItem value='spending'>Vartojimo paskola</MenuItem>
											<MenuItem value='car'>Automobilio lizingas</MenuItem>
											<MenuItem value='refinancing'>Refinansavimas</MenuItem>
										</Select>
									</FormControl>
								</FormGroup>
							</div>

							{appState.role === 'credit' && (
								<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='age-simple'>Vadybininkas</InputLabel>
											<Select
												required
												margin='normal'
												value={this.state.filterManager.data}
												onChange={(event) => this.handleFilterChange(event, 'filterManager')}
												inputProps={{
													name: 'data',
													id: 'data',
												}}
											>
												<MenuItem defaultValue value='all'>
													<em>Visi</em>
												</MenuItem>
												{this.state.creditCompanyUsers.map((user, index) => {
													return (
														<MenuItem value={user._id} key={index}>
															{user.firstName} {user.lastName}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</FormGroup>
								</div>
							)}

							{appState.role === 'admin' && (
								<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='age-simple'>Kredito įstaiga</InputLabel>
											<Select
												required
												margin='normal'
												value={this.state.filterCreditCompany.data}
												onChange={(event) => this.handleFilterChange(event, 'filterCreditCompany')}
												inputProps={{
													name: 'data',
													id: 'data',
												}}
											>
												<MenuItem defaultValue value='all'>
													<em>Visos</em>
												</MenuItem>
												{appState.creditCompanies.map((creditCompany, index) => {
													return (
														<MenuItem value={creditCompany.id} key={index}>
															{creditCompany.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</FormGroup>
								</div>
							)}

							<div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-2'>
								<FormGroup>
									<FormControl>
										<InputLabel>Statusas</InputLabel>
										<Select
											required
											margin='normal'
											value={this.state.filterStatus.status}
											onChange={(event) => this.handleFilterChange(event, 'filterStatus')}
											inputProps={{
												name: 'status',
												id: 'status',
											}}
										>
											<MenuItem value='accepted'>Pateikti pasiūlymai</MenuItem>
											<MenuItem value='declined'>Atmesti pasiūlymai</MenuItem>
											<MenuItem value='signed' defaultValue>
												Pasirašytos paraiškos
											</MenuItem>
											<MenuItem value='all'>Visos paraiškos</MenuItem>
											<MenuItem value='canceled'>Nepavyko susisiekti</MenuItem>
										</Select>
									</FormControl>
								</FormGroup>
							</div>
							<div
								className='col-lg-12 col-md-12 col-12 text-right mt-2'
								style={{
									justifyContent: 'center',
								}}
							>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									disabled={this.state.isDisabled}
								>
									GENERUOTI
								</Button>
							</div>
						</div>
					</form>
				</Paper>
				<Paper style={{ maxHeight: '70vh', width: '100%', overflowX: 'auto', marginTop: 10 }}>
					<Table
						style={{
							minWidth: 700,
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell align='left'>Ataskaitos ID</TableCell>
								<TableCell align='center'>Statusas</TableCell>
								<TableCell align='left'>Sukūrimo data</TableCell>
								<TableCell align='left'>Nuo</TableCell>
								<TableCell align='left'>Iki</TableCell>
								<TableCell align='left'>Paskolos tipas</TableCell>
								<TableCell align='left'>
									{appState.role === 'credit' ? 'Vadybininkas' : 'Kredito įstaiga'}
								</TableCell>
								<TableCell align='left'>Paraiškų tipas</TableCell>
								<TableCell align='left' />
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.isLoading ? (
								<Loader />
							) : (
								<>
									{this.state.reports.length === 0 ? (
										<>
											<Typography className='p-4'>
												Šiuo metu sugeneruotų ataskaitų neturite, norėdami sugeneruoti ataskaitą,
												užpildykite viršui esančią formą.
											</Typography>
										</>
									) : (
										<>
											{this.state.reports.map((report, index) => {
												return (
													<Report
														appState={appState}
														report={report}
														downloadReport={this.downloadReport}
														key={index}
														creditCompanyUsers={this.state.creditCompanyUsers}
													/>
												);
											})}
										</>
									)}
								</>
							)}
						</TableBody>
					</Table>
				</Paper>
			</div>
		);
	}
}

export default Reports;
