import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

class ConfirmationCodeModal extends Component {
	constructor() {
		super();

		this.state = {
			sendInvtitation: false,
			email: '',
		};
	}

	sendInvitation = (event) => {
		event.preventDefault();

		fetch(process.env.REACT_APP_API + '/api/creditCompanies/users/invite', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				email: this.state.email,
				href: 'https://partneriai.creditking.lt/companySignup?confirmationCode=' + this.props.hash,
				creditCompany: this.props.creditCompany.name,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error('Nepavyko išsiųsti pakvietimo');
				} else {
					toast.success('Pakvietimas sėkmingai išsiųstas');
					this.setState({
						sendInvtitation: false,
						email: '',
					});
				}
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	render() {
		let containerState = this.props.getContainerState();

		return (
			<Dialog
				open={containerState.confirmationCodeModalOpen}
				onClose={() => {
					this.props.setContainerState({
						confirmationCodeModalOpen: false,
					});
				}}
			>
				<DialogTitle>Patvirtinimo kodas ({this.props.creditCompany.name})</DialogTitle>
				<DialogContent className='text-center'>
					<Typography>
						Norint pakviesti naują vadybininką (darbuotoją) į kredito įmonę, perduokite šį
						patvirtinimo kodą būsimam vartotojui, kuris gali būti panaudotas sukurti naujai paskyrai
						kuri priklausys <strong>{this.props.creditCompany.name}</strong> kredito įstaigai.
					</Typography>
					<br />
					<Typography>
						<strong>{this.props.hash}</strong>
					</Typography>
					<hr />
					<Typography>
						Šį kodą būsimas kredito įstaigos darubotojas gali panaudoti paspaudus{' '}
						<a
							href={
								'https://partneriai.creditking.lt/companySignup?confirmationCode=' + this.props.hash
							}
						>
							čia
						</a>
					</Typography>
					<div className='row text-left'>
						<div className='col-12'>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.sendInvtitation}
										onChange={() =>
											this.setState({
												sendInvtitation: !this.state.sendInvtitation,
											})
										}
										value='sendInvitation'
										color='primary'
									/>
								}
								label='Siųsti pakvietimą elektroniniu paštu'
							/>
						</div>
						{this.state.sendInvtitation && (
							<div className='col-12'>
								<form onSubmit={this.sendInvitation}>
									<div className='row'>
										<div className='col-12'>
											<TextField
												required
												autoFocus
												type='email'
												fullWidth
												id='email'
												label='El. paštas'
												onChange={this.handleChange}
												value={this.state.email}
												margin='dense'
											/>
										</div>
										<div className='col-12'>
											<Button
												color='primary'
												variant='contained'
												type='submit'
												className='mt-2 mb-2'
												fullWidth
											>
												Siųsti
											</Button>
										</div>
									</div>
								</form>
							</div>
						)}
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}

export default ConfirmationCodeModal;
