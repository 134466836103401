import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const isImage = (fileName) => {
	const fileExtension = fileName.split('.').pop();
	const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

	return imageExtensions.includes(fileExtension);
};

const ObjectDisplay = ({ path }) => {
	if (isImage(path)) {
		return (
			<div
				style={{
					width: '90%',
					height: 500,
					position: 'relative',
					overflow: 'hidden',
				}}
			>
				<ReactPanZoom image={path} alt='Dokumentas' />
			</div>
		);
	}

	return <iframe width='100%' height='500px' src={path} alt='Dokumentas' />;
};

class ObjectFileModal extends Component {
	handleClose = () => {
		this.props.setContainerState({
			objectFileModalOpen: false,
		});
	};

	render() {
		return (
			<Dialog
				open={this.props.objectFileModalOpen}
				fullWidth={true}
				maxWidth={'lg'}
				onClose={this.handleClose}
			>
				<DialogTitle>Prisegtas dokumentas</DialogTitle>
				<DialogContent>
					{Array.isArray(this.props.path) ? (
						this.props.path.map((file, index) => (
							<div key={index}>
								{typeof file === 'object' ? (
									<>
										<h5>{file.name}</h5>
										<ObjectDisplay path={file.path} />
									</>
								) : (
									<ObjectDisplay path={file} />
								)}
							</div>
						))
					) : (
						<ObjectDisplay path={this.props.path} />
					)}
				</DialogContent>

				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Išeiti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ObjectFileModal;
