import React, { Component } from 'react';
import { toast } from 'react-toastify';
import CreditConfirmations from './credit-confirmations';

class CreditConfirmationsRouter extends Component {
	constructor(props) {
		super();

		this.state = {
			status: props.status,
			confirmations: [],
			creditCompany: 'all',
			isInfoSubmited: 'all',
			isAccepted: 'all',
			search: '',
		};

		this.getConfirmations();
	}

	componentDidMount() {
		const titles = {
			accept: 'Patvirtinti pasiūlymai',
			decline: 'Atmesti pasiūlymai',
			'lacking-info': 'Trūksta informacijos',
		};

		document.title = 'Credit King - ' + titles[this.props.status].toLowerCase();
	}

	generateFilterQuery = () => {
		let query = '?status=' + this.state.status;

		if (this.state.creditCompany && this.state.creditCompany !== 'all') {
			query += '&creditCompany=' + this.state.creditCompany;
		}

		if (this.state.isInfoSubmited && this.state.isInfoSubmited !== 'all') {
			query += '&isInfoSubmited=' + this.state.isInfoSubmited;
		}

		if (this.state.isAccepted && this.state.isAccepted !== 'all') {
			query += '&isAccepted=' + this.state.isAccepted;
		}

		if (this.state.search && this.state.search.length > 0) {
			query += '&search=' + this.state.search;
		}

		return query;
	};

	getConfirmations = () => {
		const query = this.generateFilterQuery();

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations' + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					toast.error(response.error);
				} else {
					this.setState({
						confirmations: response,
					});
				}
			})
			.catch((error) => {
				// toast.error(error);
			})
			.finally(() => {
				// not loading anymore
				this.setState({
					isLoading: false,
				});
			});
	};

	changeStatus = async (newStatus) => {
		await this.setState({
			status: newStatus,
		});

		const titles = {
			accept: 'Patvirtinti pasiūlymai',
			decline: 'Atmesti pasiūlymai',
			'lacking-info': 'Trūksta informacijos',
		};

		document.title = 'Credit King - ' + titles[this.state.status].toLowerCase();
		this.getConfirmations();
	};

	handleChange = async (event, skipConfirmations) => {
		await this.setState({
			[event.target.id]: event.target.value,
		});

		if (!skipConfirmations) {
			this.getConfirmations();
		}
	};

	render() {
		return (
			<CreditConfirmations
				status={this.state.status}
				getConfirmations={this.getConfirmations}
				changeStatus={this.changeStatus}
				confirmations={this.state.confirmations}
				state={this.state}
				handleChange={this.handleChange}
			/>
		);
	}
}

export default CreditConfirmationsRouter;
