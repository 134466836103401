import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CreditRequestStatus from '../../../shared/credit-request-status';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/lt';

const translations = require('../../../../locale/lt_LT/requests.json');

class UserRequest extends Component {
	constructor() {
		super();

		this.state = {
			additionalInfo: false,
		};
	}

	toggleAdditionalInfo(event) {
		let currentState = this.state.additionalInfo;

		if (currentState) {
			event.target.classList.remove('fa-minus');
			event.target.classList.add('fa-plus');
		} else {
			event.target.classList.add('fa-minus');
			event.target.classList.remove('fa-plus');
		}

		this.setState({
			additionalInfo: !currentState,
		});
	}

	render() {
		let request = this.props.requestObject;
		this.toggleAdditionalInfo = this.toggleAdditionalInfo.bind(this);

		return (
			<Paper className='credit-request-table-paper'>
				<div className='container-fluid py-3'>
					<Link to={'/requests/' + request._id} className='credit-request-table-link'>
						<div className='row credit-request-table-text credit-requests-container-content py-2'>
							<div className='col-1'>
								<Typography>{request.id}</Typography>
							</div>
							<div className='col-2'>
								<Typography>
									{moment(request.createdAt)
										.locale('lt')
										.format('LL')}
								</Typography>
							</div>
							<div className='col-2'>
								<Typography>{request.personalID}</Typography>
							</div>
							<div className='col-2'>
								<Typography>{translations.creditType[request.creditType]}</Typography>
							</div>
							<div className='col-2'>
								<Typography>{request.creditAmount}€</Typography>
							</div>
							<div className='col-1'>
								<Typography>{request.creditLength} mėn.</Typography>
							</div>
							<div className='col-2 text-center unselectable'>
								<CreditRequestStatus
									request={request}
									creditCompanies={this.props.mainAppState.creditCompanies}
								/>
							</div>
						</div>

						<div className='row credit-request-table-text credit-requests-container-content-mobile py-2'>
							<div className='col-4'>
								<Typography>{request.id}</Typography>
							</div>

							<div className='col-3'>
								<Typography>{request.creditAmount}€</Typography>
							</div>

							<div className='col-5 text-center unselectable'>
								<CreditRequestStatus
									request={request}
									creditCompanies={this.props.mainAppState.creditCompanies}
								/>
							</div>
						</div>
					</Link>
				</div>
			</Paper>
		);
	}
}

export default UserRequest;
