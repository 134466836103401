import React from 'react';
import { Button, Chip, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../AppContext';
import { Formik, Field, Form } from 'formik';
import moment from 'moment';
import 'moment/locale/lt';

const ClientQuestions = ({ request }) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [isQuestionBeingAdded, setIsQuestionBeingAdded] = React.useState(false);
	const [questions, setQuestions] = React.useState([]);

	const appContext = React.useContext(AppContext);
	const { role } = appContext;

	const getQuestions = () => {
		setIsLoading(true);

		return fetch(process.env.REACT_APP_API + '/api/questions?creditRequestId=' + request._id, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) {
					return Promise.reject();
				}

				const { questions = [] } = response;
				setQuestions(questions);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleNewQuestionSubmit = (values) => {
		const { question } = values;

		if (!question) {
			return;
		}

		setIsLoading(true);

		fetch(process.env.REACT_APP_API + '/api/questions', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				question,
				creditRequestId: request._id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) {
					return Promise.reject();
				}

				toast.success('Klausimas sėkmingai sukurtas');
				setIsQuestionBeingAdded(false);

				return getQuestions();
			})
			.catch(() => {
				toast.error('Nepavyko sukurti klausimo');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleAnswerSubmit = (questionId, answer) => {
		setIsLoading(true);

		fetch(process.env.REACT_APP_API + '/api/questions/' + questionId, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				answer,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) {
					return Promise.reject();
				}

				toast.success('Atsakymas sėkmingai atnaujintas');

				return getQuestions();
			})
			.catch(() => {
				toast.error('Nepavyko atnaujinti atsakymo');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	React.useEffect(() => {
		getQuestions();
	}, []);

	return (
		<>
			<div className='col-md-12 col-sm-12 col-12 mt-3'>
				<div
					className='request-section-label mb-2 p-2'
					style={{
						border: '1px solid #3f51b5',
						textTransform: 'uppercase',
					}}
				>
					<i className='fas fa-user request-section-label-icon' />
					Klausimai klientui
				</div>
			</div>

			<div className='col-12'>
				{role == 'credit' && (
					<>
						{isQuestionBeingAdded ? (
							<div className='mt-3'>
								<Formik
									initialValues={{
										question: '',
									}}
									onSubmit={handleNewQuestionSubmit}
								>
									<Form>
										<Field
											label='Klausimas'
											multiline
											name='question'
											rows={4}
											required
											fullWidth
											as={TextField}
										/>

										<div class='text-right mt-2'>
											<Button
												onClick={() => setIsQuestionBeingAdded(false)}
												variant='outlined'
												type='button'
												disabled={isLoading}
											>
												Atšaukti
											</Button>{' '}
											<Button variant='outlined' color='primary' type='submit' disabled={isLoading}>
												Patvirtinti
											</Button>
										</div>
									</Form>
								</Formik>
							</div>
						) : (
							<Button
								onClick={() => setIsQuestionBeingAdded(true)}
								variant='outlined'
								color='primary'
							>
								Naujas klausimas
							</Button>
						)}
					</>
				)}

				<div class='mt-3'>
					{questions.length === 0 && (
						<Typography>
							<em>Klausimų šiuo metu nėra.</em>
						</Typography>
					)}

					{questions.map((question, index) => (
						<Accordion key={index}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								id={'question-' + index}
								aria-controls={'question-content-' + index}
								className='pl-3'
								style={{
									userSelect: 'auto',
								}}
							>
								{question.answer ? (
									<Chip label='Atsakytas' color='primary' />
								) : (
									<Chip label='Neatsakytas' size='small' />
								)}
								<Typography className='ml-2 my-auto'>{question.question}</Typography>

								{['admin', 'consultant'].includes(role) && (
									<>
										<Typography variant='overline' className='ml-1'>
											{' '}
											— {question.creditCompany.name}
										</Typography>
									</>
								)}

								<>
									<Typography align='right' variant='overline' className='ml-1'>
										({moment(question.createdAt).format('YYYY-MM-DD HH:mm')})
									</Typography>
								</>
							</AccordionSummary>
							<AccordionDetails>
								{/* credit company */}
								{role === 'credit' && (
									<>
										{question.answer ? (
											<Typography>
												{question.answer}
												<br />
												<br />
												<small>
													Atsakymas pateiktas:{' '}
													{moment(question.updatedAt).format('YYYY-MM-DD HH:mm')}
												</small>
											</Typography>
										) : (
											<Typography>
												<em>Atsakymo šiuo metu nėra.</em>
											</Typography>
										)}
									</>
								)}

								{/* admin */}
								{['admin', 'consultant'].includes(role) && (
									<>
										{question.answer ? (
											<>
												<div className='row w-100'>
													<div className='col-12 mb-3'>
														<Typography component='p'>{question.answer}</Typography>
													</div>

													<div className='col-6'>
														<small>
															Atsakymas pateiktas:{' '}
															{moment(question.updatedAt).format('YYYY-MM-DD HH:mm')}
														</small>
													</div>

													<div className='col-6 text-right'>
														<Button
															variant='outlined'
															color='secondary'
															onClick={() => {
																if (!window.confirm('Ar tikrai norite ištrinti atsakymą?')) {
																	return;
																}

																handleAnswerSubmit(question._id, null);
															}}
															disabled={isLoading}
														>
															Ištrinti atsakymą
														</Button>
													</div>
												</div>
											</>
										) : (
											<div className='w-100'>
												<Formik
													initialValues={{
														answer: '',
													}}
													onSubmit={(values) => handleAnswerSubmit(question._id, values.answer)}
												>
													<Form>
														<Field
															label='Atsakymas'
															multiline
															rows={4}
															required
															fullWidth
															as={TextField}
															name='answer'
														/>
														<div class='text-right mt-2'>
															<Button
																variant='outlined'
																color='primary'
																type='submit'
																disabled={isLoading}
															>
																Patvirtinti
															</Button>
														</div>
													</Form>
												</Formik>
											</div>
										)}
									</>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			</div>
		</>
	);
};

export default ClientQuestions;
