import React from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/lab/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Info, Edit } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorHandler from '../error-handler';
import submitRequestErrors from '../submit-request-live-errors';
import PasswordChecker from '../pasword-checker';
import { withStyles } from '@material-ui/core/styles';
import AdditionalDocumentsModal from './modal/additional-documents-modal';
import AcceptConfirmationModal from './modal/accept-confirmation-modal';
import { IconButton } from '@material-ui/core';

// ICONS
import AccountIcon from '../../../assets/img/submit-request/account.svg';
import BusinessIcon from '../../../assets/img/submit-request/business.svg';
import FinancialIcon from '../../../assets/img/submit-request/financial.svg';
import LocationIcon from '../../../assets/img/submit-request/location.svg';
import PartnerIcon from '../../../assets/img/submit-request/partner.svg';
import PersonalInfoIcon from '../../../assets/img/submit-request/personal-info.svg';
import RequestIcon from '../../../assets/img/submit-request/request.svg';
import RequestDataIcon from '../../../assets/img/submit-request/request-data.svg';

const CreditSlider = withStyles({
	thumb: {
		height: 20,
		width: 20,
	},
})(Slider);

function SubmitRequestForm({ state, setState, isSignup, handleSubmit }) {
	// HELPER FUNCTIONS START
	const checkIfDisabled = () => {
		// if (
		// 	['working-abroad', 'self-employed'].includes(state.occupationType) &&
		// 	state.documentFiles.length < 1
		// ) {
		// 	return true;
		// }

		if (isSignup && (!PasswordChecker.isValid(state.password) || state.email === state.password)) {
			return true;
		}

		if (
			ErrorHandler.firstName(state.firstName) !== '' ||
			ErrorHandler.lastName(state.lastName) !== '' ||
			ErrorHandler.personalID(state.personalID) !== '' ||
			ErrorHandler.age(state.age) !== '' ||
			ErrorHandler.documentID(state.documentType, state.documentID) !== '' ||
			ErrorHandler.finances(state.personalIncome) !== '' ||
			ErrorHandler.finances(state.financialObligations) !== '' ||
			ErrorHandler.finances(state.housingObligations) !== '' ||
			ErrorHandler.firstName(state.partnerFirstName) !== '' ||
			ErrorHandler.lastName(state.partnerLastName) !== '' ||
			ErrorHandler.personalID(state.partnerPersonalID) !== '' ||
			ErrorHandler.contactNumber(state.partnerContactNumber) ||
			ErrorHandler.partnerPersonalID(state.partnerPersonalID, state.personalID) ||
			state.isEditing
		) {
			return true;
		}

		return false;
	};

	const handleDocumentUpload = (event, location) => {
		if (!event.target || !event.target.files || event.target.files.length < 1) return;

		if (event.target.files[0].size > 4000000) {
			return toast.error('Failas yra per didelis');
		}

		let documentFiles = state[location];
		documentFiles.push(event.target.files[0]);

		setState({
			[location]: documentFiles,
		});
	};

	const removeUploadedFile = (index, location) => {
		let documentFiles = state[location];
		documentFiles.splice(index, 1);

		setState({
			[location]: documentFiles,
		});
	};

	const handleChange = (event) => {
		setState({
			[event.target.name]: event.target.value,
		});
	};

	const creditAmountChange = (event, value) => {
		setState({
			creditAmount: value,
		});
	};

	const creditLengthChange = (event, value) => {
		setState({
			creditLength: value,
		});
	};

	const handleCreditTypeChange = (event) => {
		const creditType = event.target.value;

		const predefined = {
			spending: {
				amount: 1000,
				length: 36,
			},
			car: {
				amount: 7500,
				length: 60,
			},
			refinancing: {
				amount: 10000,
				length: 84,
			},
		};

		return setState({
			editableCreditAmount: predefined[creditType].amount,
			editableCreditLength: predefined[creditType].length,
			editableCreditType: creditType,
		});
	};

	// HELPER FUNCTIONS END

	const translations = {
		car: 'Automoblio lizingas',
		spending: 'Vartojimo paskola',
		refinancing: 'Refinansavimas',
	};

	const carYears = [];

	for (let y = new Date().getFullYear(); y >= 1900; y--) {
		carYears.push(y);
	}

	const creditAmountMin = 300;
	const creditAmountMax = 25000;
	const creditLengthMin = 1;
	const creditLengthMax = 84;

	return (
		<div className='row'>
			{state.additionalDocumentsModalOpen && (
				<AdditionalDocumentsModal
					setState={(state) => setState(state)}
					state={state}
					additionalDocumentsModalOpen={state.additionalDocumentsModalOpen}
					requiredDocuments={state.requiredDocuments}
					handleDocumentUpload={handleDocumentUpload}
					removeUploadedFile={removeUploadedFile}
					handleSubmit={handleSubmit}
				/>
			)}

			{state.acceptConfirmationModalOpen && (
				<AcceptConfirmationModal
					setState={setState}
					acceptConfirmationModalOpen={state.acceptConfirmationModalOpen}
				/>
			)}
			<div className='col-lg-7 col-md-12'>
				<div className='mobile-content'>
					<div className='w-100 mb-3'>
						<div className='w-100'>
							<div className='request-section-label-financial'>
								<img
									src={RequestIcon}
									style={{
										height: '30px',
										paddingRight: '10px',
									}}
								/>
								Paskolos informacija
							</div>

							<form>
								<div className='row'>
									<div className='col-6 text-left credit-selector-header'>Paskolos tipas</div>
									<div className='col-6 text-right credit-selector-header credit-selector-type'>
										{state.isEditing ? (
											<FormControl margin='dense' className='mt-0' fullWidth>
												<NativeSelect
													required
													controlId='editableCreditType'
													onChange={handleCreditTypeChange}
													value={state.editableCreditType}
													inputProps={{
														name: 'editableCreditType',
														id: 'editableCreditType',
													}}
												>
													<option value='spending'>Vartojimo paskola</option>
													<option value='car'>Automobilio lizingas</option>
													<option value='refinancing'>Refinansavimas</option>
												</NativeSelect>
											</FormControl>
										) : (
											<>{translations[state.creditType]}</>
										)}
									</div>
								</div>

								<hr />

								<div className='row'>
									<div className='col-8 text-left credit-selector-header'>Paskolos suma</div>
									<div className='col-4 text-right credit-selector-header credit-selector-type'>
										{state.isEditing ? (
											<TextField
												required
												type='number'
												step={1}
												name='editableCreditAmount'
												value={state.editableCreditAmount}
												onChange={handleChange}
												fullWidth
												error={
													state.editableCreditAmount < creditAmountMin ||
													state.editableCreditAmount > creditAmountMax
												}
												helperText={
													state.editableCreditAmount < creditAmountMin ||
													state.editableCreditAmount > creditAmountMax ? (
														<>
															Suma nuo {creditAmountMin} iki {creditAmountMax} €
														</>
													) : (
														''
													)
												}
												InputProps={{
													endAdornment: <InputAdornment position='end'>€</InputAdornment>,
												}}
												size='small'
											/>
										) : (
											<>{state.creditAmount} €</>
										)}
									</div>
								</div>

								<div className='row mt-4'>
									<div className='col-8 text-left credit-selector-header'>Paskolos laikotarpis</div>
									<div className='col-4 text-right credit-selector-header credit-selector-type'>
										{state.isEditing ? (
											<TextField
												fullWidth
												required
												type='number'
												step={1}
												name='editableCreditLength'
												value={state.editableCreditLength}
												onChange={handleChange}
												error={
													state.editableCreditLength < creditLengthMin ||
													state.editableCreditLength > creditLengthMax
												}
												helperText={
													state.editableCreditLength < creditLengthMin ||
													state.editableCreditLength > creditLengthMax ? (
														<>
															Terminas nuo {creditLengthMin} iki {creditLengthMax} mėn.
														</>
													) : (
														''
													)
												}
												InputProps={{
													endAdornment: <InputAdornment position='end'>mėn.</InputAdornment>,
												}}
												size='small'
											/>
										) : (
											<>{state.creditLength} mėn.</>
										)}
									</div>
								</div>

								<hr />

								<div className='text-right mt-3 mb-3'>
									{state.isEditing ? (
										<>
											<Button
												variant='contained'
												className='mr-3'
												onClick={() =>
													setState({
														isEditing: false,
														editableBusinessCreditType: state.businessCreditType,
														editableCreditType: state.creditType,
														editableCreditAmount: state.creditAmount,
														editableCreditLength: state.creditLength,
													})
												}
											>
												Atšaukti
											</Button>
											<Button
												variant='contained'
												color='primary'
												disabled={
													!state.editableCreditAmount ||
													!state.editableCreditLength ||
													state.editableCreditAmount < creditAmountMin ||
													state.editableCreditAmount > creditAmountMax ||
													state.editableCreditLength < creditLengthMin ||
													state.editableCreditLength > creditLengthMax
												}
												onClick={() =>
													setState({
														isEditing: false,
														businessCreditType: state.editableBusinessCreditType,
														creditType: state.editableCreditType,
														creditAmount: parseInt(state.editableCreditAmount) || 3000,
														creditLength: parseInt(state.editableCreditLength) || 36,
													})
												}
											>
												Išsaugoti
											</Button>
										</>
									) : (
										<>
											<Button
												variant='contained'
												color='primary'
												onClick={() =>
													setState({
														isEditing: true,
														editableBusinessCreditType: state.businessCreditType,
														editableCreditType: state.creditType,
														editableCreditAmount: state.creditAmount,
														editableCreditLength: state.creditLength,
													})
												}
											>
												Keisti <Edit className='pl-2' />
											</Button>
										</>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>

				{state.creditType === 'car' && (
					<>
						<div className='row mb-3'>
							<div className='col-12'>
								<div className='request-section-label'>
									<img
										src={RequestDataIcon}
										style={{
											height: '30px',
											paddingRight: '10px',
										}}
									/>
									Paskolos duomenys
								</div>
							</div>

							<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
								<FormControl fullWidth margin='dense'>
									<InputLabel htmlFor='occupationType'>Autolizingo tipas</InputLabel>
									<NativeSelect
										required
										controlId='carCreditType'
										onChange={handleChange}
										value={state.carCreditType}
										inputProps={{
											name: 'carCreditType',
											id: 'carCreditType',
										}}
									>
										<option value='' selected disabled></option>
										<option value='buy'>Noriu įsigyti automobilį</option>
										<option value='exchange'>Turiu automobilį ir sutinku jį įkeisti</option>
									</NativeSelect>
								</FormControl>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
								<FormControl fullWidth margin='dense'>
									<InputLabel htmlFor='occupationType'>Pagaminimo metai</InputLabel>
									<NativeSelect
										required
										controlId='carManufacturingYear'
										onChange={handleChange}
										value={state.carManufacturingYear}
										inputProps={{
											name: 'carManufacturingYear',
											id: 'carManufacturingYear',
										}}
									>
										<option value='' selected disabled></option>

										{carYears.map((year, index) => (
											<option key={index} value={year}>
												{year}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<TextField
									required
									type='text'
									fullWidth
									name='carInfo'
									label='Automobilio informacija'
									helperText='Įveskite informaciją apie automobilį (markę, modelį arba automobilio skelbimą)'
									onChange={handleChange}
									value={state.carInfo}
									margin='dense'
								/>
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<FormControlLabel
									className='mt-3'
									control={
										<Checkbox
											onChange={() =>
												setState({
													driversLicense: !state.driversLicense,
												})
											}
											checked={state.driversLicense}
											color='primary'
										/>
									}
									label='Turiu galiojantį vairuotojo pažymėjimą'
								/>
							</div>
						</div>
					</>
				)}

				<div className='row mb-3'>
					<div className='col-12'>
						<div className='request-section-label'>
							<img
								src={PersonalInfoIcon}
								style={{
									height: '30px',
									paddingRight: '10px',
								}}
							/>
							Asmeniniai duomenys
						</div>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-6'>
						<TextField
							required
							type='text'
							fullWidth
							name='firstName'
							label='Vardas'
							onChange={handleChange}
							value={state.firstName}
							error={ErrorHandler.firstName(state.firstName)}
							helperText={ErrorHandler.firstName(state.firstName)}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-6'>
						<TextField
							required
							type='text'
							fullWidth
							name='lastName'
							label='Pavardė'
							onChange={handleChange}
							value={state.lastName}
							error={ErrorHandler.lastName(state.lastName)}
							helperText={ErrorHandler.lastName(state.lastName)}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='number'
							fullWidth
							name='personalID'
							label='Asmens kodas'
							onChange={handleChange}
							value={state.personalID}
							error={ErrorHandler.personalID(state.personalID)}
							helperText={ErrorHandler.personalID(state.personalID)}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='number'
							fullWidth
							name='age'
							label='Amžius'
							min='0'
							onChange={handleChange}
							value={state.age}
							error={submitRequestErrors.age(state.age)}
							helperText={submitRequestErrors.age(state.age)}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<FormControl fullWidth margin='dense'>
							<InputLabel htmlFor='occupationType'>Dokumento tipas</InputLabel>
							<NativeSelect
								required
								controlId='documentType'
								onChange={handleChange}
								value={state.documentType}
								inputProps={{
									name: 'documentType',
									id: 'documentType',
								}}
							>
								<option value='' selected disabled></option>
								<option value='passport'>Pasas</option>
								<option value='id-card'>Tapatybės kortelė</option>
							</NativeSelect>
						</FormControl>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='number'
							fullWidth
							name='documentID'
							label='Dokumento numeris'
							onChange={handleChange}
							value={state.documentID}
							error={ErrorHandler.documentID(state.documentType, state.documentID)}
							helperText={ErrorHandler.documentID(state.documentType, state.documentID)}
							margin='dense'
						/>
					</div>

					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<FormControl fullWidth margin='dense'>
							<InputLabel htmlFor='educationLevel'>Išsilavinimas</InputLabel>
							<NativeSelect
								required
								controlId='educationLevel'
								onChange={handleChange}
								value={state.educationLevel}
								inputProps={{
									name: 'educationLevel',
									id: 'educationLevel',
								}}
							>
								<option value='' selected disabled></option>
								<option value='doctor'>Aukštasis (mokslų daktaras)</option>
								<option value='masters'>Aukštasis (magistras)</option>
								<option value='bachelor'>Aukštasis (bakalauras)</option>
								<option value='secondary'>Vidurinis</option>
								<option value='vocational'>Profesinis</option>
								<option value='unfinished-secondary'>Nebaigtas vidurinis</option>
							</NativeSelect>
						</FormControl>
					</div>

					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							type='number'
							min={0}
							max={15}
							fullWidth
							required
							name='childrenCount'
							label='Vaikų (išlaikytinių) skaičius'
							onChange={handleChange}
							value={state.childrenCount}
							margin='dense'
						/>
					</div>

					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<FormControl fullWidth margin='dense'>
							<InputLabel htmlFor='occupationType'>Šeiminė padėtis</InputLabel>
							<NativeSelect
								required
								controlId='maritalStatus'
								onChange={handleChange}
								value={state.maritalStatus}
								inputProps={{
									name: 'maritalStatus',
									id: 'maritalStatus',
								}}
							>
								<option value='' selected disabled></option>
								<option value='married'>Vedęs / Ištekėjusi</option>
								<option value='not-married'>Nevedęs / Neištekėjusi</option>
							</NativeSelect>
						</FormControl>
					</div>
				</div>

				{state.maritalStatus === 'married' ? (
					<div className='row mb-3'>
						<div className='col-12'>
							<div className='request-section-label'>
								<img
									src={PartnerIcon}
									style={{
										height: '30px',
										paddingRight: '10px',
									}}
								/>
								Sutuoktinio duomenys
							</div>
						</div>

						<div className='col-lg-4 col-md-6 col-sm-6 col-6'>
							<TextField
								required
								type='text'
								fullWidth
								name='partnerFirstName'
								label='Vardas'
								onChange={handleChange}
								value={state.partnerFirstName}
								error={ErrorHandler.firstName(state.partnerFirstName)}
								helperText={ErrorHandler.firstName(state.partnerFirstName)}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-6'>
							<TextField
								required
								type='text'
								fullWidth
								name='partnerLastName'
								label='Pavardė'
								onChange={handleChange}
								value={state.partnerLastName}
								error={ErrorHandler.lastName(state.partnerLastName)}
								helperText={ErrorHandler.lastName(state.partnerLastName)}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='number'
								fullWidth
								name='partnerPersonalID'
								label='Asmens kodas'
								onChange={handleChange}
								value={state.partnerPersonalID}
								error={
									ErrorHandler.personalID(state.partnerPersonalID) ||
									ErrorHandler.partnerPersonalID(state.partnerPersonalID, state.personalID)
								}
								helperText={
									ErrorHandler.personalID(state.partnerPersonalID) ||
									ErrorHandler.partnerPersonalID(state.partnerPersonalID, state.personalID)
								}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								fullWidth
								required
								type='number'
								name='partnerContactNumber'
								label='Telefono numeris'
								value={state.partnerContactNumber}
								onChange={handleChange}
								error={ErrorHandler.contactNumber(state.partnerContactNumber)}
								helperText={ErrorHandler.contactNumber(state.partnerContactNumber)}
								margin='dense'
								InputProps={{
									startAdornment: <InputAdornment position='start'>+370</InputAdornment>,
								}}
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='email'
								fullWidth
								name='partnerEmail'
								label='El. paštas'
								onChange={handleChange}
								value={state.partnerEmail}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='number'
								fullWidth
								name='partnerPersonalIncome'
								label='Sutuoktinio pajamos'
								onChange={handleChange}
								value={state.partnerPersonalIncome}
								error={ErrorHandler.finances(state.partnerPersonalIncome)}
								helperText={
									ErrorHandler.finances(state.partnerPersonalIncome)
										? ErrorHandler.finances(state.partnerPersonalIncome)
										: 'eurais per mėnesį'
								}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='number'
								fullWidth
								name='partnerFinancialObligations'
								label={'Sutuoktinio įsipareigojimai'}
								onChange={handleChange}
								value={state.partnerFinancialObligations}
								error={ErrorHandler.finances(state.partnerFinancialObligations)}
								helperText={
									ErrorHandler.finances(state.partnerFinancialObligations)
										? ErrorHandler.finances(state.partnerFinancialObligations)
										: 'eurais per mėnesį'
								}
								margin='dense'
							/>
						</div>
					</div>
				) : (
					''
				)}

				<div className='row mb-3'>
					<div className='col-12'>
						<div className='request-section-label'>
							<img
								src={FinancialIcon}
								style={{
									height: '30px',
									paddingRight: '10px',
								}}
							/>
							Veikla ir finansiniai duomenys
						</div>
					</div>

					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<FormControl fullWidth margin='dense'>
							<InputLabel htmlFor='occupationType'>Užsiėmimas</InputLabel>
							<NativeSelect
								required
								controlId='occupationType'
								onChange={handleChange}
								value={state.occupationType}
								inputProps={{
									name: 'occupationType',
									id: 'occupationType',
								}}
							>
								<option value='' selected disabled></option>
								<option value='worker'>Dirbantis pagal darbo sutartį</option>
								<option value='official'>Valstybės tarnautojas</option>
								<option value='self-employed'>Savarankiškai dirbantis asmuo</option>
								<option value='working-abroad'>Dirbantis užsienyje</option>
								<option value='other'>Kita</option>
							</NativeSelect>
						</FormControl>
					</div>

					{state.occupationType !== 'other' && (
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<FormControl fullWidth margin='dense'>
								<InputLabel htmlFor='occupationType'>
									{state.occupationType === 'official' ? 'Tarnybos stažas' : 'Darbo stažas'}
								</InputLabel>
								<NativeSelect
									required
									controlId='workExperience'
									onChange={handleChange}
									value={state.workExperience}
									inputProps={{
										name: 'workExperience',
										id: 'workExperience',
									}}
								>
									<option value='' selected disabled></option>
									<option value='0-3'>0 iki 3 mėnesių</option>
									<option value='4-12'>4 iki 12 mėnesių</option>
									{/* <option value='12+'>Daugiau nei metai</option> */}
									<option value='12-24'>12 iki 24 mėnesių</option>
									<option value='24-36'>24 iki 36 mėnesių</option>
									<option value='36-60'>36 iki 60 mėnesių</option>
									<option value='60+'>Daugiau nei 60 mėnesių</option>
								</NativeSelect>
							</FormControl>
						</div>
					)}

					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='number'
							fullWidth
							name='personalIncome'
							label='Pajamos'
							onChange={handleChange}
							value={state.personalIncome}
							error={ErrorHandler.finances(state.personalIncome)}
							helperText={
								ErrorHandler.finances(state.personalIncome)
									? ErrorHandler.finances(state.personalIncome)
									: 'eurais per mėnesį'
							}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='number'
							fullWidth
							name='financialObligations'
							label={'Įsipareigojimai'}
							onChange={handleChange}
							value={state.financialObligations}
							error={ErrorHandler.finances(state.financialObligations)}
							helperText={
								ErrorHandler.finances(state.financialObligations)
									? ErrorHandler.finances(state.financialObligations)
									: 'eurais per mėnesį'
							}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							type='number'
							fullWidth
							required
							name='housingObligations'
							label='Būsto paskolos įmoka'
							onChange={handleChange}
							value={state.housingObligations}
							error={ErrorHandler.finances(state.housingObligations)}
							helperText={
								ErrorHandler.finances(state.housingObligations)
									? ErrorHandler.finances(state.housingObligations)
									: 'eurais per mėnesį'
							}
							margin='dense'
						/>
					</div>

					{['spending', 'refinancing'].includes(state.creditType) && (
						<div className='col-12'>
							<FormControlLabel
								className='mt-2'
								control={
									<Checkbox
										onChange={() =>
											setState({
												ownsRealEstate: !state.ownsRealEstate,
											})
										}
										checked={state.ownsRealEstate}
										color='primary'
									/>
								}
								label='Turiu nekilnojamojo turto'
							/>
						</div>
					)}

					{state.creditType === 'spending' && (
						<div className='col-12'>
							<FormControlLabel
								className='mt-2'
								control={
									<Checkbox
										onChange={() =>
											setState({
												ownsCar: !state.ownsCar,
											})
										}
										checked={state.ownsCar}
										color='primary'
									/>
								}
								label='Turiu savo vardu registruotą automobilį'
							/>
						</div>
					)}

					<div className='col-12'>
						<FormControlLabel
							className='mt-2'
							control={
								<Checkbox
									// checked={state.checkedA}
									onChange={() =>
										setState({
											changeInIncome: !state.changeInIncome,
											futurePersonalIncome: '',
											futureFinancialObligations: '',
										})
									}
									checked={state.changeInIncome}
									color='primary'
								/>
							}
							label='Mano finansinė situacija ateityje keisis'
						/>
					</div>

					{state.changeInIncome && (
						<>
							<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
								<TextField
									required
									type='number'
									fullWidth
									name='futurePersonalIncome'
									label='Pajamos ateityje'
									onChange={handleChange}
									value={state.futurePersonalIncome}
									margin='dense'
									helperText='eurais per mėnesį'
								/>
							</div>
							<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
								<TextField
									required
									type='number'
									fullWidth
									name='futureFinancialObligations'
									label='Įsipareigojimai ateityje'
									onChange={handleChange}
									value={state.futureFinancialObligations}
									margin='dense'
									helperText='eurais per mėnesį'
								/>
							</div>
							<div className='col-12'></div>
						</>
					)}
				</div>

				<div className='row mb-3'>
					<div className='col-12'>
						<div className='request-section-label'>
							<img
								src={LocationIcon}
								style={{
									height: '30px',
									paddingRight: '10px',
								}}
							/>
							Gyvenamoji vieta
						</div>
					</div>

					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<FormControl fullWidth margin='dense'>
							<InputLabel htmlFor='occupationType'>Savivaldybė</InputLabel>
							<NativeSelect
								required
								controlId='residenceMunicipality'
								onChange={handleChange}
								value={state.residenceMunicipality}
								inputProps={{
									name: 'residenceMunicipality',
									id: 'residenceMunicipality',
								}}
							>
								<option value='' selected disabled></option>
								{state.municipalities.map((municipality, index) => {
									if (municipality === '') {
										return (
											<option key={index} disabled={true}>
												{' '}
											</option>
										);
									}

									return (
										<option key={index} value={municipality}>
											{municipality}
										</option>
									);
								})}
							</NativeSelect>
						</FormControl>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='text'
							fullWidth
							name='residenceCity'
							label='Miestas'
							onChange={handleChange}
							value={state.residenceCity}
							margin='dense'
						/>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<TextField
							required
							type='text'
							fullWidth
							name='residenceAddress'
							label='Adresas'
							onChange={handleChange}
							value={state.residenceAddress}
							margin='dense'
						/>
					</div>
				</div>

				{isSignup && (
					<div className='row mb-3'>
						<div className='col-12'>
							<div className='request-section-label'>
								<img
									src={AccountIcon}
									style={{
										height: '30px',
										paddingRight: '10px',
									}}
								/>
								Paskyros duomenys
							</div>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='email'
								fullWidth
								name='email'
								label='El. paštas'
								onChange={handleChange}
								value={state.email}
								margin='dense'
							/>
						</div>
						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								fullWidth
								required
								type='number'
								name='contactNumber'
								label='Telefono numeris'
								value={state.contactNumber}
								onChange={handleChange}
								error={ErrorHandler.contactNumber(state.contactNumber)}
								helperText={ErrorHandler.contactNumber(state.contactNumber)}
								margin='dense'
								InputProps={{
									startAdornment: <InputAdornment position='start'>+370</InputAdornment>,
								}}
							/>
						</div>

						<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
							<TextField
								required
								type='password'
								fullWidth
								name='password'
								id='password'
								label='Slaptažodis'
								onChange={handleChange}
								value={state.password}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Tooltip
												placement='top'
												title='Susigalvokite naują slaptažodį. Vėliau šį slaptažodį turėsite naudoti prisijungdami prie savo Credit King paskyros.'
											>
												<IconButton className='p-0'>
													<Info fontSize='small' />
												</IconButton>
											</Tooltip>
										</InputAdornment>
									),
								}}
								margin='dense'
							/>
						</div>

						<Popper
							open={
								state.password &&
								state.password.length > 0 &&
								!PasswordChecker.isValid(state.password) &&
								state.email !== state.password
							}
							placement='bottom'
							anchorEl={document.getElementById('password')}
							style={{
								zIndex: 10000000000000000,
								position: 'absolute',
							}}
							disablePortal={true}
							className='fade-in mt-1'
						>
							<Paper
								className='p-1'
								style={{
									fontSize: 15,
								}}
							>
								<ul>
									<li
										style={{
											color: PasswordChecker.length(state.password) ? 'green' : 'red',
										}}
									>
										Slaptažodis privalo būti ne trumpesnis nei 8 simboliai.
									</li>
									<li
										style={{
											color: PasswordChecker.uppercase(state.password) ? 'green' : 'red',
										}}
									>
										Slaptažodyje privalo būti bent viena didžioji raidė (A-Z).
									</li>
									<li
										style={{
											color: PasswordChecker.lowercase(state.password) ? 'green' : 'red',
										}}
									>
										Slaptažodyje privalo būti bent viena mažoji raidė (a-z).
									</li>
									<li
										style={{
											color: PasswordChecker.digits(state.password) ? 'green' : 'red',
										}}
									>
										Slaptažodyje privalo būti bent vienas skaičius (0-9).
									</li>
									<li
										style={{
											color: state.email !== state.password ? 'green' : 'red',
										}}
									>
										Slaptažodis negali būti sudarytas iš el. pašto adreso ar jo dalies.
									</li>
								</ul>
							</Paper>
						</Popper>
					</div>
				)}
			</div>
			<div className='col-lg-5 col-md-12 col-12 mt-lg-0 mt-4'>
				{state.creditType ? (
					<div className='row credit-selector-container'>
						<div className='col-xl-10 offset-xl-1 col-md-12 offset-md-0 desktop-content'>
							<div className='request-section-label-financial'>
								<img
									src={RequestIcon}
									style={{
										height: '30px',
										paddingRight: '10px',
									}}
								/>
								Paskolos informacija
							</div>

							<form>
								<div className='row'>
									<div className='col-6 text-left credit-selector-header'>Paskolos tipas</div>
									<div className='col-6 text-right credit-selector-header credit-selector-type'>
										{state.isEditing ? (
											<FormControl margin='dense' className='mt-0' fullWidth>
												<NativeSelect
													required
													controlId='editableCreditType'
													onChange={handleCreditTypeChange}
													value={state.editableCreditType}
													inputProps={{
														name: 'editableCreditType',
														id: 'editableCreditType',
													}}
												>
													<option value='spending'>Vartojimo paskola</option>
													<option value='car'>Automobilio lizingas</option>
													<option value='refinancing'>Refinansavimas</option>
												</NativeSelect>
											</FormControl>
										) : (
											<>{translations[state.creditType]}</>
										)}
									</div>
								</div>

								<hr />

								<div className='row'>
									<div className='col-8 text-left credit-selector-header'>Paskolos suma</div>
									<div className='col-4 text-right credit-selector-header credit-selector-type'>
										{state.isEditing ? (
											<TextField
												required
												type='number'
												step={1}
												name='editableCreditAmount'
												value={state.editableCreditAmount}
												onChange={handleChange}
												fullWidth
												error={
													state.editableCreditAmount < creditAmountMin ||
													state.editableCreditAmount > creditAmountMax
												}
												helperText={
													state.editableCreditAmount < creditAmountMin ||
													state.editableCreditAmount > creditAmountMax ? (
														<>
															Suma nuo {creditAmountMin} iki {creditAmountMax} €
														</>
													) : (
														''
													)
												}
												InputProps={{
													endAdornment: <InputAdornment position='end'>€</InputAdornment>,
												}}
												size='small'
											/>
										) : (
											<>{state.creditAmount} €</>
										)}
									</div>
								</div>

								<div className='row mt-4'>
									<div className='col-8 text-left credit-selector-header'>Paskolos laikotarpis</div>
									<div className='col-4 text-right credit-selector-header credit-selector-type'>
										{state.isEditing ? (
											<TextField
												fullWidth
												required
												type='number'
												step={1}
												name='editableCreditLength'
												value={state.editableCreditLength}
												onChange={handleChange}
												error={
													state.editableCreditLength < creditLengthMin ||
													state.editableCreditLength > creditLengthMax
												}
												helperText={
													state.editableCreditLength < creditLengthMin ||
													state.editableCreditLength > creditLengthMax ? (
														<>
															Terminas nuo {creditLengthMin} iki {creditLengthMax} mėn.
														</>
													) : (
														''
													)
												}
												InputProps={{
													endAdornment: <InputAdornment position='end'>mėn.</InputAdornment>,
												}}
												size='small'
											/>
										) : (
											<>{state.creditLength} mėn.</>
										)}
									</div>
								</div>

								<hr />

								<div className='text-right mt-3 mb-3'>
									{state.isEditing ? (
										<>
											<Button
												variant='contained'
												className='mr-3'
												onClick={() =>
													setState({
														isEditing: false,
														editableBusinessCreditType: state.businessCreditType,
														editableCreditType: state.creditType,
														editableCreditAmount: state.creditAmount,
														editableCreditLength: state.creditLength,
													})
												}
											>
												Atšaukti
											</Button>
											<Button
												variant='contained'
												color='primary'
												disabled={
													!state.editableCreditAmount ||
													!state.editableCreditLength ||
													state.editableCreditAmount < creditAmountMin ||
													state.editableCreditAmount > creditAmountMax ||
													state.editableCreditLength < creditLengthMin ||
													state.editableCreditLength > creditLengthMax
												}
												onClick={() =>
													setState({
														isEditing: false,
														businessCreditType: state.editableBusinessCreditType,
														creditType: state.editableCreditType,
														creditAmount: parseInt(state.editableCreditAmount) || 3000,
														creditLength: parseInt(state.editableCreditLength) || 36,
													})
												}
											>
												Išsaugoti
											</Button>
										</>
									) : (
										<>
											<Button
												variant='contained'
												color='primary'
												onClick={() =>
													setState({
														isEditing: true,
														editableBusinessCreditType: state.businessCreditType,
														editableCreditType: state.creditType,
														editableCreditAmount: state.creditAmount,
														editableCreditLength: state.creditLength,
													})
												}
											>
												Keisti <Edit className='pl-2' />
											</Button>
										</>
									)}
								</div>
							</form>
						</div>
						<div className='col-xl-10 offset-xl-1 col-md-12 offset-md-0 '>
							{isSignup && (
								<FormControlLabel
									className='mt-2'
									control={
										<Checkbox
											onChange={() =>
												setState({
													marketingAccept: !state.marketingAccept,
												})
											}
											checked={state.marketingAccept}
											color='primary'
										/>
									}
									label={
										<span
											style={{
												fontSize: 13,
											}}
										>
											Sutinku, kad mano duomenys būtų tvarkomi tiesioginės rinkodaros tikslais
										</span>
									}
								/>
							)}
							<FormControlLabel
								// className='mt-2'
								control={
									<Checkbox
										onChange={() =>
											setState({
												termsAccepted2: !state.termsAccepted2,
											})
										}
										checked={state.termsAccepted2}
										required
										color='primary'
									/>
								}
								labelStyle={{ zIndex: 3 }}
								label={
									<span
										style={{
											fontSize: 13,
										}}
									>
										Patvirtinu, kad susipažinau ir sutinku su{' '}
										<a href='https://creditking.lt/tinklapio-taisykles/' target='_blank'>
											Credit King svetainės naudojimosi taisyklėmis
										</a>
									</span>
								}
							/>
							<FormControlLabel
								// className='mt-2'
								control={
									<Checkbox
										onClick={(event) => {
											event.preventDefault();

											if (!state.termsAccepted3) {
												return setState({
													acceptConfirmationModalOpen: true,
													termsAccepted3: false,
												});
											} else {
												return setState({
													acceptConfirmationModalOpen: false,
													termsAccepted3: false,
												});
											}
										}}
										checked={state.termsAccepted3}
										value={state.termsAccepted3}
										required
										color='primary'
									/>
								}
								labelStyle={{ zIndex: 3 }}
								label={
									<span
										style={{
											fontSize: 13,
										}}
									>
										Patvirtinu, kad susipažinau ir sutinku su sutikimu dėl{' '}
										<a
											href='https://creditking.lt/wp-content/uploads/2020/08/KLIENTO-SUTIKIMAS-DĖL-ASMENS-DUOMENŲ-TVARKYMO-V12.pdf'
											target='_blank'
										>
											asmens duomenų tvarkymo
										</a>
									</span>
								}
							/>
							<FormControlLabel
								// className='mt-2'
								control={
									<Checkbox
										onChange={() =>
											setState({
												termsAccepted4: !state.termsAccepted4,
											})
										}
										checked={state.termsAccepted4}
										required
										color='primary'
									/>
								}
								labelStyle={{ zIndex: 3 }}
								label={
									<span
										style={{
											fontSize: 13,
										}}
									>
										Patvirtinu, kad susipažinau ir sutinku su{' '}
										<a href='https://creditking.lt/privatumo-politika' target='_blank'>
											Credit King privatumo politika
										</a>
									</span>
								}
							/>
							<div className='mt-2'>
								<Button
									fullWidth
									type='submit'
									variant='contained'
									color='primary'
									disabled={state.isLoading || checkIfDisabled()}
								>
									Tęsti
								</Button>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
}

export default SubmitRequestForm;
