import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CreditConfirmation from './credit-confirmation';
import { Search } from '@material-ui/icons/';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AppContext } from '../../../../AppContext';

const styles = {
	textField: {
		color: '#fff',
		borderBottom: '1px solid #fff',
	},
	input: {
		border: 'none',
		color: 'white',
	},
	button: {
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		color: '#fff',
		'&:hover': {
			opacity: 0.7,
			cursor: 'pointer',
		},
		'&:focus': {
			opacity: 0.7,
			cursor: 'pointer',
		},
		'&:active': {
			opacity: 0.7,
			cursor: 'pointer',
		},
	},
};

class CreditConfirmations extends Component {
	constructor() {
		super();

		this.state = {
			requestsPerPage: 15,
			page: 0,
		};
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	render() {
		const { context } = this;
		let { status, changeStatus, confirmations } = this.props;
		let sliceFrom = this.state.page * this.state.requestsPerPage;
		let sliceTo = sliceFrom + this.state.requestsPerPage;

		const titles = {
			accept: 'Patvirtinti pasiūlymai',
			decline: 'Atmesti pasiūlymai',
			'lacking-info': 'Trūksta informacijos',
		};

		return (
			<div className='fade-in'>
				<div className='row no-gutters'>
					<div className='col-md-7 col-12'>
						<Select
							style={{
								color: 'white',
								fontWeight: 100,
								fontSize: '40px',
								lineHeight: '50px',
							}}
							required
							controlId='status'
							onChange={(event) => changeStatus(event.target.value)}
							value={status}
							inputProps={{
								name: 'status',
								id: 'status',
							}}
						>
							{Object.keys(titles).map((key) => (
								<MenuItem value={key}>{titles[key]}</MenuItem>
							))}
						</Select>
					</div>
					<div className='col-md-5 col-12 text-right'>
						<div
							style={{
								marginTop: '20px',
							}}
						>
							<Search className='requests-container-icon' />{' '}
							<TextField
								name='search'
								id='search'
								value={this.props.state.search}
								onChange={(event) => this.props.handleChange(event, true)}
								margin='none'
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										this.props.getConfirmations();
									}
								}}
								className={this.props.classes.textField}
								InputProps={{
									className: this.props.classes.input,
									disableUnderline: true,
								}}
							/>{' '}
						</div>
					</div>
				</div>

				<Paper>
					<Table
						style={{
							minWidth: 1000,
						}}
						size='small'
					>
						<TableHead>
							<TableRow className='px-3'>
								<TableCell>ID</TableCell>
								<TableCell>Sukūrimo data</TableCell>
								<TableCell>
									<select
										id='creditCompany'
										onChange={this.props.handleChange}
										style={{
											background: 'transparent',
											border: 'none',
											color: 'rgba(0, 0, 0, 0.54)',
										}}
									>
										<option value='all' selected>
											Kredito įstaiga (Visos)
										</option>
										{context.creditCompanies &&
											context.creditCompanies.map((company) => (
												<option value={company.id}>Kredito įstaiga ({company.name})</option>
											))}
									</select>
								</TableCell>
								<TableCell>Paraiškos ID</TableCell>
								{status === 'accept' && (
									<TableCell>
										<select
											id='isAccepted'
											onChange={this.props.handleChange}
											style={{
												background: 'transparent',
												border: 'none',
												color: 'rgba(0, 0, 0, 0.54)',
											}}
										>
											<option value='all' selected>
												Pasirinkta (Visos)
											</option>

											<option value='true'>Pasirinkta (Taip)</option>

											<option value='false'>Pasirinkta (Ne)</option>
										</select>
									</TableCell>
								)}
								{status === 'lacking-info' && (
									<TableCell>
										<select
											id='isInfoSubmited'
											onChange={this.props.handleChange}
											style={{
												background: 'transparent',
												border: 'none',
												color: 'rgba(0, 0, 0, 0.54)',
											}}
										>
											<option value='all' selected>
												Suteikta informacija (Visos)
											</option>

											<option value='true'>Suteikta informacija (Taip)</option>

											<option value='false'>Suteikta informacija (Ne)</option>
										</select>
									</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{confirmations.slice(sliceFrom, sliceTo).map((confirmation, key) => (
								<CreditConfirmation status={status} confirmation={confirmation} key={key} />
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={confirmations.length}
						rowsPerPage={this.state.requestsPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</Paper>
			</div>
		);
	}
}

CreditConfirmations.contextType = AppContext;
export default withStyles(styles)(CreditConfirmations);
