import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppContext } from '../../../../../../AppContext';

function RequestAcceptForm({ handleChange, state, request }) {
	const context = useContext(AppContext);

	return (
		<div className='row'>
			{request.creditType === 'car' && (
				<div className='col-12'>
					<FormControlLabel
						// className='mt-2'
						control={
							<Checkbox
								onChange={() =>
									handleChange({
										target: {
											id: 'isCarLoan',
											value: !state.isCarLoan,
										},
									})
								}
								checked={state.isCarLoan}
								color='primary'
							/>
						}
						labelStyle={{ zIndex: 3 }}
						label='Auto nuoma'
					/>
				</div>
			)}

			<div className='col-md-6 col-sm-6 col-12'>
				<TextField
					autoFocus
					required
					margin='dense'
					id='creditOfferFrom'
					label='Tvirtinama suma'
					type='number'
					onChange={handleChange}
					InputProps={{
						endAdornment: <InputAdornment position='end'>€</InputAdornment>,
					}}
					value={state.creditOfferFrom}
					fullWidth
				/>
			</div>
			<div className='col-md-6 col-sm-6 col-12'>
				<TextField
					margin='dense'
					id='creditOfferTo'
					label='Maksimali suma'
					type='number'
					onChange={handleChange}
					// InputLabelProps={{ required: false }}
					InputProps={{
						endAdornment: <InputAdornment position='end'>€</InputAdornment>,
					}}
					value={state.creditOfferTo}
					fullWidth
				/>
			</div>
			{!state.isCarLoan && (
				<div className='col-md-6 col-sm-6 col-12'>
					<TextField
						required
						margin='dense'
						id='interestRate'
						label='MPN (metinė palūkanų norma)'
						type='number'
						onChange={handleChange}
						InputProps={{
							endAdornment: <InputAdornment position='end'>%</InputAdornment>,
						}}
						inputProps={{
							step: '0.01',
						}}
						value={state.interestRate}
						fullWidth
					/>
				</div>
			)}

			{!state.isCarLoan && (
				<div className='col-md-6 col-sm-6 col-12'>
					<TextField
						required
						margin='dense'
						id='BVKKMN'
						label='BVKKMN'
						type='number'
						onChange={handleChange}
						InputProps={{
							endAdornment: <InputAdornment position='end'>%</InputAdornment>,
						}}
						inputProps={{
							step: '0.01',
						}}
						value={state.BVKKMN}
						fullWidth
					/>
				</div>
			)}

			<div className='col-md-6 col-sm-6 col-12'>
				<TextField
					required
					margin='dense'
					id='monthlyPayment'
					label='Menėsinė įmoka'
					type='number'
					onChange={handleChange}
					InputProps={{
						endAdornment: <InputAdornment position='end'>€</InputAdornment>,
					}}
					inputProps={{
						step: '0.01',
					}}
					value={state.monthlyPayment}
					fullWidth
				/>
			</div>
			<div className='col-md-6 col-sm-6 col-12'>
				<TextField
					required
					margin='dense'
					id='creditLength'
					label='Laikotarpis'
					type='number'
					onChange={handleChange}
					InputProps={{
						endAdornment: <InputAdornment position='end'>mėn.</InputAdornment>,
					}}
					value={state.creditLength}
					fullWidth
				/>
			</div>
			<div className='col-md-6 col-sm-6 col-12'>
				<TextField
					required
					margin='dense'
					id='fee'
					label='Sutarties mokestis'
					type='number'
					onChange={handleChange}
					InputProps={{
						endAdornment: <InputAdornment position='end'>€</InputAdornment>,
					}}
					value={state.fee}
					fullWidth
				/>
			</div>

			{context.creditCompany.isCreditUnion && (
				<>
					<div className='col-md-6 col-sm-6 col-12'>
						<TextField
							required
							margin='dense'
							id='share'
							label='Pajinis įnašas'
							type='number'
							onChange={handleChange}
							InputProps={{
								endAdornment: <InputAdornment position='end'>€</InputAdornment>,
							}}
							value={state.share}
							fullWidth
						/>
					</div>
					<div className='col-md-6 col-sm-6 col-12'>
						<TextField
							required
							margin='dense'
							id='entranceFee'
							label='Stojamasis mokestis'
							type='number'
							onChange={handleChange}
							InputProps={{
								endAdornment: <InputAdornment position='end'>€</InputAdornment>,
							}}
							value={state.entranceFee}
							fullWidth
						/>
					</div>
				</>
			)}
			<div className='col-12'>
				<TextField
					multiline
					rows={4}
					required={state.isCarLoan}
					margin='dense'
					id='comment'
					label='Papildoma informacija klientui'
					type='text'
					onChange={handleChange}
					value={state.comment}
					fullWidth
				/>
			</div>
			<div className='col-12'>
				<br />
				<small>
					Visuose laukuose (išskyrus “Maksimali suma”) pildykite tvirtinamos sumos informaciją.
				</small>
			</div>
		</div>
	);
}

export default RequestAcceptForm;
