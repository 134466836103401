import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { Help, Comment } from '@material-ui/icons';
import CommentModal from './modal/comment-modal';
import moment from 'moment';
import 'moment/locale/lt';

const translations = require('../../../locale/lt_LT/requests.json');

class PotentialRequests extends Component {
	constructor(props) {
		super(props);

		this.state = {
			potentialRequests: [],
			displayedRequests: [],
			page: 0,
			requestsPerPage: 10,
			commentModalOpen: false,
			potentialRequest: {},
			isLoading: false,
		};
	}

	checkIfUserExists = (contactNumber) => {
		if (!contactNumber) {
			return;
		}

		this.setState({ isLoading: true });

		fetch(process.env.REACT_APP_API + '/api/users?contactNumber=' + contactNumber, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then(async (response) => {
				if (response.length < 1) return toast.error('Vartotojas neegzistuoja');

				response = response[0];

				if (response.creditRequests.length < 1)
					return toast.warn('Vartotojas egzistuoja, tačiau neturi paraiškų. Galima problema.');

				if (response.creditRequests.length > 0) {
					let ids = await response.creditRequests.map((creditRequest) => creditRequest.id);

					return toast.success(`Vartotojas su paraiška (-omis) egzistuoja [${ids.join(', ')}]`);
				}
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	getPotentialRequests = () => {
		fetch(process.env.REACT_APP_API + '/api/potentialRequests', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Serverio klaida');
				}

				this.setState({
					potentialRequests: response,
					page: 0,
				});
			});
	};

	componentDidMount() {
		document.title = 'Credit King - potencialūs klientai';
		this.getPotentialRequests();
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	render() {
		let sliceFrom = this.state.page * this.state.requestsPerPage;
		let sliceTo = sliceFrom + this.state.requestsPerPage;

		return (
			<>
				{this.state.commentModalOpen && (
					<CommentModal
						commentModalOpen={this.state.commentModalOpen}
						setState={(state) => this.setState(state)}
						potentialRequest={this.state.potentialRequest}
					/>
				)}

				<h1 className='app-page-title'>Potencialūs klientai</h1>
				<Paper style={{ maxHeight: '80vh', width: '100%', overflowX: 'auto' }}>
					<Table
						style={{
							minWidth: 700,
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell align='right'>Laikas</TableCell>
								<TableCell align='right'>Elektroninis paštas</TableCell>
								<TableCell align='right'>Telefonas</TableCell>
								<TableCell align='right'>Paskolos tipas</TableCell>
								<TableCell align='right'></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.potentialRequests.slice(sliceFrom, sliceTo).map((request) => (
								<TableRow key={request._id}>
									<TableCell component='th' scope='row'>
										{request._id}
									</TableCell>
									<TableCell align='right'>
										{moment(request.createdAt).locale('lt').format('LLL')}
									</TableCell>
									<TableCell align='right'>
										{request.data && request.data.email ? request.data.email : '-'}
									</TableCell>
									<TableCell align='right'>
										{request.data && request.data.contactNumber
											? '+370' + request.data.contactNumber
											: '-'}
									</TableCell>
									<TableCell align='right'>
										{request.data && request.data.creditType
											? translations.creditType[request.data.creditType]
											: '-'}
									</TableCell>
									<TableCell align='right'>
										<IconButton
											color='primary'
											className='p-1'
											disabled={this.state.isLoading}
											onClick={() =>
												this.setState({
													commentModalOpen: true,
													potentialRequest: request,
												})
											}
										>
											<Comment />
										</IconButton>

										<IconButton
											className='p-1'
											disabled={this.state.isLoading}
											onClick={() => this.checkIfUserExists(request.data.contactNumber)}
										>
											<Help />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={false}
						component='div'
						count={this.state.potentialRequests.length}
						rowsPerPage={this.state.requestsPerPage}
						page={this.state.page}
						backIconButtonProps={{
							'aria-label': 'Praeitas puslapis',
						}}
						nextIconButtonProps={{
							'aria-label': 'Kitas puslapis',
						}}
						onChangePage={this.handleChangePage}
					/>
				</Paper>
			</>
		);
	}
}

export default PotentialRequests;
