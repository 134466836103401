import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CloudUpload } from '@material-ui/icons';

class AcceptConfirmationModal extends Component {
	constructor() {
		super();

		this.state = {
			scrolledThrough: false,
		};
	}

	handleClose = () => {
		const { setState } = this.props;

		setState({
			acceptConfirmationModalOpen: false,
			termsAccepted3: false,
		});
	};

	handleSubmit = () => {
		const { setState } = this.props;

		setState({
			termsAccepted3: true,
			acceptConfirmationModalOpen: false,
		});
	};

	render() {
		const { acceptConfirmationModalOpen, setState } = this.props;

		return (
			<Dialog
				maxWidth={'sm'}
				open={acceptConfirmationModalOpen}
				onClose={this.handleClose}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Asmens duomenų tvarkymas</DialogTitle>
				<DialogContent
					className='text-justify'
					id='dialog-content-scrollable'
					onScroll={(event) => {
						this.setState({
							scrolledThrough:
								event.target.scrollTop >=
								event.target.scrollHeight - event.target.offsetHeight - 200,
						});
					}}
				>
					<DialogContentText>
						<h5>KLIENTO SUTIKIMAS DĖL ASMENS DUOMENŲ TVARKYMO</h5>

						<p>
							Sutikdamas su šia sutikimo forma aš patvirtinu, kad esu informuotas ir suprantu, kad
							UAB „Vertikalus dangus“ („Bendrovė“) veikia kaip finansinių paslaugų tarpininkė, t.y.
							yra pasirašiusi bendradarbiavimo sutartis su finansines paslaugas (vartojimo kredito,
							išperkamosios nuomos (vartojimo kredito), finansavimo, lizingo, finansinės nuomos ir
							kt.) teikiančiomis įmonėmis (toliau - („Partneriai“) ir tarpininkauja suteikiant man
							tokias paslaugas. Partneriai yra šie asmenys: UAB „Credit Service“, UAB „Sotero LT“,
							UAB „Bendras finansavimas”, UAB „Pro Invest Group”, UAB „mogo LT”, AB „NEO Finance”,
							kredito unija„Centro kredito unija”, kredito unija „Taupa”, kredito unija „Magnus”,
							kredito unija „Mėmelio taupomoji kasa“, kredito unija „Rato”, UAB „Nordecum”, UAB
							„Giantus finance”, AS „Citadele banka“, UAB „Hipotekiniai kreditai“, AS „West Kredit”
							filialas, UAB „BnP Finance”, AB „Mano bankas", kredito unija „Germanto lobis“.
						</p>

						<p>
							Sutikdamas su šia sutikimo forma aš patvirtinu, kad esu informuotas ir suprantu, kad
							Partneriai yra mano asmens duomenų valdytojai finansinių paslaugų suteikimo tikslais,
							t.y. Partneris, kuris suteikia man finansinę paslaugą, mano asmens duomenis tvarko
							pagal savo veikloje įdiegtas atskiras asmens duomenų tvarkymo politikas (Partneris yra
							mano asmens duomenų valdytojas).
						</p>

						<h5>
							Su aktualiu Partnerių sąrašu ir jų privatumo informacija galite susipažinti čia:
						</h5>

						<p>
							<a href='https://creditking.lt/partneriai/'>https://creditking.lt/partneriai/</a>
						</p>

						<p>
							Sutikdamas su šia sutikimo forma aš patvirtinu, kad susipažinau su tuo, kad duomenų
							valdytojas UAB „Vertikalus dangus“ („Bendrovė“) Privatumo politikoje nurodytomis
							sąlygomis perduos mano asmens duomenis finansines paslaugas teikiančioms įmonėms,
							galinčioms pateikti man tinkamiausią finansavimo pasiūlymą („Partneriai“), ir laisva
							valia sutinku su tokiu mano asmens duomenų tvarkymu.
						</p>

						<p>
							Partneriai tvarkys mano asmens duomenis tiek, kiek tai bus būtina paskolos (vartojimo
							kredito) sutarties sudarymo ir vykdymo tikslais. Partneriai šiais tikslais gaus,
							tikslins ir kitaip tvarkys mano asmens duomenis asmens tapatybės nustatymo, asmens
							duomenų teisingumo patikrinimo, kreditingumo ir finansinės rizikos įvertinimo,
							įsiskolinimo valdymo, paskolos (vartojimo kredito) sutarties sudarymo, vykdymo,
							kontrolės, sandorių ir klientų apskaitos, finansinių nuostolių prevencijos, Partnerių
							teisių ir teisėtų interesų apsaugos ir gynimo, skolų valdymo ir išieškojimo bei asmens
							veiksnumo patikros tikslais.
						</p>

						<p>
							Tuo atveju, jei esu susituokęs (vedusi) ir jei Paskolos suma imama šeimos poreikiams
							tenkinti, turiu pateikti ir sutuoktinio sutikimą dėl jo (jos) asmens duomenų tvarkymo.
						</p>

						<p>
							Sutinku, kad mano asmens duomenys: vardas, pavardė, asmens kodas, pageidautų
							finansinių įsipareigojimų, dėl kurių buvo priimtas neigiamas sprendimas, rūšys ir
							sumos, esamų finansinių įsipareigojimų rūšys, sumos, vykdymo terminai, duomenys apie
							šių įsipareigojimų vykdymą, taip pat duomenys apie buvusius finansinius
							įsipareigojimus ir jų vykdymą kreditingumo vertinimo tikslais būtų gaunami iš visų
							Lietuvos Respublikoje veikiančių vartojimo kredito įstaigų ar finansų įmonių,
							dalyvaujančių UAB „Creditinfo Lietuva“, juridinio asmens kodas 111689163, buveinės
							adresas: A. Goštauto g. 40A, LT 01112 Vilnius, Lietuva, www.manocreditinfo.lt, tel.:
							(8 5) 2394131, administruojamoje informacinėje sistemoje Infobankas bei Kreditų biuro
							sistemoje (informacija apie šias vartojimo kredito įstaigas ir finansų įmones
							pateikiama svetainėje www.creditinfo.lt).
						</p>

						<p>
							Sutinku, kad asmenys, tvarkantys mano asmens duomenis (VĮ Registrų centras,
							Valstybinio socialinio draudimo fondo valdyba prie Lietuvos Respublikos socialinės
							apsaugos ir darbo ministerijos, Valstybinės mokesčių inspekcija prie Lietuvos
							Respublikos finansų ministerijos, UAB „Creditinfo Lietuva“, VĮ „Regitra“, Lietuvos
							bankas, kredito įstaigos ir finansų įmonės, kurios teikia finansines paslaugas, kitos
							privačios ar valstybinės įmonės, įstaigos ar institucijos) Partnerių prašymu suteiktų
							žemiau nurodytus asmens duomenis.
						</p>

						<p>
							Sutinku, kad Partneriai tvarkytų šiuos asmens duomenis: vardas, pavardė, asmens kodas,
							pilietybė, gimimo data, gimimo metai, lytis, telefono numeris, gyvenamoji vieta
							(adresas), elektroninio pašto adresas, šeiminė padėtis, nepilnamečių vaikų ir
							išlaikytinių skaičius, duomenys apie sutuoktinį (vardas, pavardė, gimimo data),
							išsilavinimas, darbovietė (darbdavys, užimamos pareigos darbe, bendras darbo stažas,
							darbo stažas paskutinėje darbovietėje, veiklos tipas), turimas nekilnojamas turtas
							(įskaitant areštuotą turtą, jeigu tokio būtų), palikėjo (skolininko) įpėdiniai, gautos
							ir paskirtos pastovios ir vienkartinės socialinės išmokos, pajamos ir išlaidos, pajamų
							tipas ir šaltiniai, socialinių išmokų tipai, socialinio draudimo draudėjai, socialinio
							draudimo periodo (termino) pradžia ir pabaiga, ne darbo santykių pajamos ir turtas –
							pajamų ir turto sumos, pajamų ir turto deklaravimo laikotarpiai, ekonominės veiklos,
							iš kurių gautos pajamos, rūšys, vairuotojo pažymėjimo numeris, turimų transport
							priemonių duomenys, asmens tapatybės dokumento duomenys (asmens tapatybės kortelės
							(paso) numeris, išdavimo data, vieta ir kiti duomenys), kreditingumo
							informacija/informacija apie kliento finansinius įsipareigojimus (aktyvios ir
							pasibaigusios paskolos tipas, sutarties pasirašymo data/pabaigos data, suma, mėnesinė
							įmoka, mokėjimų grafikas, pavėluotų įmokų skaičius, turimų paskolų mėnesinių įmokų
							dydis, susidariusių skolų kitoms įmonėms suma, paskutinės skolos apmokėjimo data),
							informacija apie aktyvias paskolos paraiškas (paskolos tipas, paraiškos data, prašomos
							paskolos suma), sąskaitos numeris, banko sąskaitos išrašas, ar asmuo yra
							nekredituotinų asmenų sąraše (taip/ne), šeimos mėnesinės pajamos ir išlaidos, duomenys
							apie asmens veiksnumą bei kita su išvardintais duomenimis susijusi informacija.
						</p>

						<p>
							Esu informuotas, kad man delsiant vykdyti savo įsipareigojimus daugiau kaip 30 dienų,
							Partneriai teikia informaciją apie mano tapatybę, kontaktinius duomenis ir kredito
							istoriją, t. y. finansinius ir turtinius įsipareigojimus ir jų vykdymą, skolas ir jų
							apmokėjimą, Kredito biurui UAB „Creditinfo Lietuva“. Kreditų biuras tvarko ir teikia
							tretiesiems asmenims (finansų įstaigoms, telekomunikacijų bendrovėms, draudimui,
							elektros ir komunalinių paslaugų teikėjams, prekybos įmonėms ir kt.) Jūsų informaciją
							siekdamas teisėtų interesų ir tikslų – įvertinti kreditingumą ir valdyti įsiskolinimą.
							Kredito istorijos duomenys tvarkomi 10 metų po įsipareigojimų įvykdymo.
						</p>

						<p>
							Man yra žinoma, kad galiu susipažinti su savo kredito istorija kreipdamasis tiesiogiai
							į kreditų biurą arba, pasinaudodamas Finpass mobilia programėle (App Store, Google
							play).
						</p>

						<p>
							Man yra žinoma, kad turiu teisę prašyti ištaisyti arba ištrinti, arba apriboti duomenų
							tvarkymą, ir teisę nesutikti, kad duomenys būtų tvarkomi, taip pat teisę į duomenų
							perkeliamumą.
						</p>

						<p>
							Esu informuotas, kad dėl savo teisių pažeidimo, aš galiu kreiptis į duomenų apsaugos
							pareigūną el. paštu duomenu.apsauga@creditinfo.lt arba anksčiau nurodytu telefonu ar
							pateikti skundą Valstybinei duomenų apsaugos inspekcijai arba teismui.
						</p>

						<p>
							Esu informuotas, kad man neįvykdžius turtinių įsipareigojimų pagal paskolos (vartojimo
							kredito) sutartį, Partneriai taip pat turės teisę teikti mano asmens duomenis kitų
							jungtinių skolininkų duomenų tvarkytojams, finansines paslaugas teikiančioms įmonėms
							ir organizacijoms, telekomunikacijų bendrovėms, skolų valdymo įmonėms ir visiems
							teisėtą interesą turintiems asmenims.
						</p>

						<p>
							Esu informuotas, kad turiu teisę, patvirtinęs savo asmens tapatybę, susipažinti su
							savo asmens duomenimis ir kaip jie yra tvarkomi, t. y. gauti informaciją, iš kokių
							šaltinių ir kokie jo asmens duomenys surinkti, kokiu tikslu jie tvarkomi, kam
							teikiami, reikalauti ištaisyti, sunaikinti savo asmens duomenis arba sustabdyti,
							išskyrus saugojimą, savo asmens duomenų tvarkymo veiksmus, kai duomenys tvarkomi
							nesilaikant Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo ar kitų
							įstatymų nuostatų, nesutikti, kad mano asmens duomenys būtų tvarkomi tiesioginės
							rinkodaros tikslais, turiu teisę, nurodydamas nesutikimo motyvus, nesutikti, kad mano
							asmens duomenys būtų tvarkomi dėl teisėto intereso, kurio siekia Partneriai arba
							trečiasis asmuo, kuriam teikiami mano asmens duomenys.
						</p>

						<p>
							Man yra žinoma, kad nesutikus pateikti ir leisti Partneriams tvarkyti šiame sutikime
							nurodytų mano asmens duomenų, Partneriai neturės galimybės įvertinti mano mokumo ir
							suteikti paslaugų.
						</p>

						<p>
							Esu informuotas, kad savo teises galiu įgyvendinti kreipdamasis į Bendrovę adresu
							info@creditking.lt.
						</p>

						<p>
							Patvirtinu, kad šis mano sutikimas dėl asmens duomenų naudojimo ir tvarkymo yra
							prilyginamas mano sutikimui raštu.
						</p>

						<p>
							Kliento vardas, pavardė (A.K. xxxxxxxxxxx). Patvirtinimo kodas: V12_xxxxxxxxx.
							Patvirtinimo data: 20xx m. xxxxxx d., xxxxxx val.
						</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Išeiti
					</Button>

					<Button
						disabled={!this.state.scrolledThrough}
						onClick={this.handleSubmit}
						color='primary'
					>
						Sutinku
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default AcceptConfirmationModal;
