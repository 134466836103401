import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../shared/loader';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InfoSubmissionModal from '../credit-request/modal/info-submission-modal';
import CreditRequestStatus from '../../shared/credit-request-status';
import CompanyConfirmation from './company-confirmation';
import WaitingPartnerInfoForm from './waiting-partner-info/waiting-partner-info-form';
import moment from 'moment';
import 'moment/locale/lt';

const translations = require('../../../locale/lt_LT/requests.json');

class Offers extends Component {
	constructor() {
		super();

		this.state = {
			request: false,
			municipalities: [],
			// real estate exchange stuff
			realEstateType: null,
			realEstateMunicipality: null,
			realEstateCity: null,
			realEstatePostCode: null,
			realEstateAddress: null,
			infoList: [],
			carInfo: null,
			carManufacturingYear: '',
			openInfoSubmissionModal: false,
			isLoading: false,
			selectedTab: 0,
		};

		this.getMunicipalities();
	}

	async componentWillMount() {
		await this.getRequest();

		if (!this.state.request) {
			return;
		}

		const request = this.state.request[0];

		if (request.status !== 'waiting_offer') {
			return;
		}

		this.interval = setInterval(() => {
			this.getRequest();
		}, 20000);
	}

	componentWillUnmount() {
		if (!this.interval) {
			return;
		}

		clearInterval(this.interval);
	}

	getRequest = async () => {
		const requestID = this.props.match.params.id;

		return fetch(process.env.REACT_APP_API + '/api/requests/' + requestID, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response) {
					toast.error('Paraiška neegzistuoja');

					return this.props.$helper.initRedirect('/');
				}

				if (response.error) {
					toast.error(response.error);

					return this.props.$helper.initRedirect('/');
				}

				this.setState({
					request: response,
				});

				let request = this.state.request[0];

				if (request.status === 'unconfirmed') {
					return this.props.$helper.initRedirect('/requests/' + request._id + '/confirmation');
				}
			});
	};

	getMunicipalities = () => {
		fetch(process.env.REACT_APP_API + '/api/static/lithuanianMunicipalities', {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((response) => {
				if (Array.isArray(response)) {
					this.setState({
						municipalities: response,
					});
				}
			});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleRealEstateSubmit = (submittedInfo) => {
		if (!submittedInfo) {
			return this.setState({
				openInfoSubmissionModal: true,
				infoList: [
					{
						title: 'Nekilnojamojo turto registro išrašas',
						type: 'file',
						description: 'Prašome įkelti dokumentus. Galite įkelti ne daugiau kaip penkis failus.',
						location: 0,
						required: true,
						maxFiles: 5,
					},
					{
						title: 'Nekilnojamojo turto vertinimo ataskaita arba nekilnojamojo turto nuotraukos',
						type: 'file',
						description: 'Prašome įkelti dokumentus. Galite įkelti ne daugiau kaip penkis failus.',
						location: 1,
						required: true,
						maxFiles: 5,
					},
				],
			});
		}

		if (!submittedInfo || !submittedInfo[0] || !submittedInfo[0].length === 0) return;

		let realEstateDocuments = [];

		submittedInfo.forEach((infoArray) => {
			return infoArray.forEach((info) => realEstateDocuments.push(info.path));
		});

		if (!realEstateDocuments || realEstateDocuments.length === 0) return;

		const body = {
			realEstateType: this.state.realEstateType,
			realEstateMunicipality: this.state.realEstateMunicipality,
			realEstateCity: this.state.realEstateCity,
			realEstatePostCode: this.state.realEstatePostCode,
			realEstateAddress: this.state.realEstateAddress,
			realEstateDocuments,
		};

		const request = this.state.request[0];

		return fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/exchangeInfo', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) return toast.error('Informacijos įkelti nepavyko');

				return window.location.reload();
			});
	};

	handleCarSubmit = (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const request = this.state.request[0];

		return fetch(process.env.REACT_APP_API + '/api/requests/' + request._id + '/exchangeInfo', {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				carInfo: this.state.carInfo,
				carManufacturingYear: this.state.carManufacturingYear,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.success) return toast.error('Informacijos įkelti nepavyko');

				return window.location.reload();
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	componentDidMount() {
		document.title = 'Credit King - paraiška';
	}

	render() {
		if (!this.state.request) {
			return <Loader />;
		}

		let request = this.state.request[0];
		let creditCompanies = this.props.$helper.getAppState().creditCompanies;

		const carYears = [];

		for (let y = new Date().getFullYear(); y >= 1900; y--) {
			carYears.push(y);
		}

		return (
			<div className='fade-in'>
				<h1 className='app-page-title'>Mano paraiška</h1>
				<Paper id='credit-request-table-header'>
					<div className='credit-requests-container-content px-4 py-3'>
						<div className='row credit-request-table-heading mb-1'>
							<div className='col-1'>
								<Typography>Kodas</Typography>
							</div>
							<div className='col-2'>
								<Typography>Sukūrimo data</Typography>
							</div>
							<div className='col-2'>
								<Typography>Asmens kodas</Typography>
							</div>
							<div className='col-2'>
								<Typography>Tipas</Typography>
							</div>
							<div className='col-2'>
								<Typography>Suma</Typography>
							</div>
							<div className='col-1'>
								<Typography>Terminas</Typography>
							</div>
							<div className='col-2 text-center'>
								<Typography>Statusas</Typography>
							</div>
						</div>
						<div className='row credit-request-table-text pt-4'>
							<div className='col-1'>
								<Typography>{request.id}</Typography>
							</div>
							<div className='col-2'>
								<Typography>{moment(request.createdAt).locale('lt').format('LL')}</Typography>
							</div>
							<div className='col-2'>
								<Typography>{request.personalID}</Typography>
							</div>
							<div className='col-2'>
								<Typography>{translations.creditType[request.creditType]}</Typography>
							</div>
							<div className='col-2'>
								<Typography>{request.creditAmount}€</Typography>
							</div>
							<div className='col-1'>
								<Typography>{request.creditLength} mėn.</Typography>
							</div>
							<div className='col-2 text-center unselectable'>
								<CreditRequestStatus request={request} creditCompanies={creditCompanies} />
							</div>
						</div>
					</div>
					<div className='credit-requests-container-content-mobile'>
						<ExpansionPanel className='w-100'>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<div className='row w-100'>
									<div className='col-6 credit-request-table-heading'>
										<Typography>Paraiškos Nr.</Typography>
									</div>
									<div className='col-6 credit-request-table-text'>
										<Typography>{request.id}</Typography>
									</div>
									<div className='col-6 credit-request-table-heading mt-2'>
										<Typography>Paskolos tipas</Typography>
									</div>
									<div className='col-6 credit-request-table-text mt-2'>
										<Typography>{translations.creditType[request.creditType]}</Typography>
									</div>
								</div>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<div className='row w-100'>
									<div className='col-6 credit-request-table-heading mt-2'>
										<Typography>Sukūrimo data</Typography>
									</div>
									<div className='col-6 credit-request-table-text pl-0 pr-0 mt-2'>
										<Typography>{moment(request.createdAt).locale('lt').format('LL')}</Typography>
									</div>
									<div className='col-6 credit-request-table-heading mt-2'>
										<Typography>Asmens kodas</Typography>
									</div>
									<div className='col-6 credit-request-table-text pl-0 pr-0 mt-2'>
										<Typography>{request.personalID}</Typography>
									</div>
									<div className='col-6 credit-request-table-heading mt-2'>
										<Typography>Suma</Typography>
									</div>
									<div className='col-6 credit-request-table-text pl-0 pr-0 mt-2'>
										<Typography>{request.creditAmount}€</Typography>
									</div>
									<div className='col-6 credit-request-table-heading mt-2'>
										<Typography>Terminas</Typography>
									</div>
									<div className='col-6 credit-request-table-text pl-0 pr-0 mt-2'>
										<Typography>{request.creditLength} mėn.</Typography>
									</div>
									<div className='col-6 credit-request-table-heading mt-2'>
										<Typography>Statusas</Typography>
									</div>
									<div className='col-6 credit-request-table-text pl-0 pr-0 mt-2 unselectable'>
										<CreditRequestStatus request={request} creditCompanies={creditCompanies} />
									</div>
								</div>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</div>
				</Paper>
				<Paper className='mt-4'>
					{request.status === 'waiting_call' ||
					request.status === 'waiting_sign' ||
					request.status === 'canceled' ? (
						<>
							<Tabs
								value={this.state.selectedTab}
								onChange={(_event, newValue) =>
									this.setState({
										selectedTab: newValue,
									})
								}
								indicatorColor='primary'
								textColor='primary'
								centered
							>
								<Tab label='Pasirinktas pasiūlymas' />
								<Tab label='Kiti pasiūlymai' />
							</Tabs>

							{this.state.selectedTab === 0 && (
								<div className='p-4 text-center'>
									{request.status === 'waiting_call' && (
										<>
											<h1>Pasiūlymas sėkmingai patvirtintas!</h1>
											<Typography>
												Sveikiname pasirinkus geriausią asmeninį pasiūlymą. Netrukus su Jumis
												susisieks pasiūlymą pateikusi kredito įstaiga.
											</Typography>
										</>
									)}

									{request.status === 'waiting_sign' && (
										<>
											<h1>Su kredito įstaiga sėkmingai susisiekta!</h1>
											<Typography>
												Sveikiname sėkmingai susisiekus su kredito įstaiga. Prašome sekti kredito
												įstaigos nurodymus, kad pasirašytumėte sutartį.
											</Typography>

											{request.signUrl && (
												<>
													<Typography>
														Tai padaryti galite paspaudę apačioje esantį mygtuką.
													</Typography>

													<Button
														variant='contained'
														color='primary'
														target='_blank'
														href={request.signUrl}
														className='my-2'
													>
														Pasirašyti sutartį dabar
													</Button>
												</>
											)}
										</>
									)}

									{request.status === 'canceled' && (
										<>
											<h1>Kredito įstaigai nepavyko su Jumis susisiekti</h1>
											<Typography>
												Informuokite mus, kada kredito įstaiga galėtų pabandyti Jums paskambinti dar
												kartą. Mus informuoti galite telefonu +370 613 10007, el. paštu{' '}
												<a href='mailto:info@creditking.lt'>info@creditking.lt</a> arba žinute gyvos
												pagalbos chate.
											</Typography>
										</>
									)}

									<div className='text-left mt-3'>
										<CompanyConfirmation
											confirmation={request.acceptedOffer}
											creditCompany={request.acceptedOffer.creditCompany}
											request={request}
											$helper={this.props.$helper}
											selected
										/>
									</div>
								</div>

								/*
								<div className='p-4 text-center'>
									{request.status === 'waiting_call' ? (
										<>
											<h1>Pasiūlymas sėkmingai patvirtintas!</h1>
											<Typography>
												Sveikiname pasirinkus geriausią asmeninį pasiūlymą. Netrukus su Jumis
												susisieks pasiūlymą pateikusi kredito įstaiga.
											</Typography>

											<div className='text-left mt-3'>
												<CompanyConfirmation
													confirmation={request.acceptedOffer}
													creditCompany={request.acceptedOffer.creditCompany}
													request={request}
													$helper={this.props.$helper}
													selected
												/>
											</div>
										</>
									) : (
										<>
											<h1>Su kredito įstaiga sėkmingai susisiekta!</h1>
											<Typography>
												Sveikiname sėkmingai susisiekus su kredito įstaiga. Prašome sekti kredito
												įstaigos nurodymus, kad pasirašytumėte sutartį.
											</Typography>

											{request.signUrl && (
												<>
													<Typography>
														Tai padaryti galite paspaudę apačioje esantį mygtuką.
													</Typography>

													<Button
														variant='contained'
														color='primary'
														target='_blank'
														href={request.signUrl}
														className='my-2'
													>
														Pasirašyti sutartį dabar
													</Button>
												</>
											)}
										</>
									)}
								</div>
								*/
							)}

							{this.state.selectedTab === 1 && (
								<div className='p-4'>
									{request.creditConfirmations.map((confirmation) => {
										if (confirmation.type !== 'accept') {
											return;
										}

										return (
											<CompanyConfirmation
												confirmation={confirmation}
												creditCompany={confirmation.creditCompany}
												request={request}
												$helper={this.props.$helper}
												selected={confirmation.offerAccepted}
											/>
										);
									})}
								</div>
							)}
						</>
					) : request.status === 'waiting_info' ? (
						<div className='p-4'>
							{request.creditType === 'car' && (
								<form onSubmit={this.handleCarSubmit}>
									<Typography variant='h6'>Įkeičiamo automobilio informacija</Typography>
									<div className='row'>
										<div className='col-12'>
											<FormControl fullWidth margin='dense'>
												<InputLabel htmlFor='occupationType'>Pagaminimo metai</InputLabel>
												<NativeSelect
													required
													controlId='carManufacturingYear'
													onChange={this.handleChange}
													value={this.state.carManufacturingYear}
													inputProps={{
														name: 'carManufacturingYear',
														id: 'carManufacturingYear',
													}}
												>
													<option value='' selected disabled></option>

													{carYears.map((year, index) => (
														<option key={index} value={year}>
															{year}
														</option>
													))}
												</NativeSelect>
											</FormControl>
										</div>
										<div className='col-12'>
											<TextField
												required
												multiline
												rows={4}
												type='text'
												fullWidth
												name='carInfo'
												label='Informacija apie automobilį'
												helperText='Įveskite informaciją apie automobilį (markę, modelį arba automobilio skelbimą)'
												onChange={this.handleChange}
												value={this.state.carInfo}
												margin='dense'
											/>
										</div>

										<div className='col-12 text-right'>
											<Button variant='contained' color='primary' className='my-2' type='submit'>
												Patvirtinti
											</Button>
										</div>
									</div>
								</form>
							)}

							{request.creditType === 'spending-real-estate-exchange' && (
								// <Typography>Pateikite papildoma informaciją kredito įstaigoms:</Typography>
								<div className='row'>
									{this.state.openInfoSubmissionModal && (
										<InfoSubmissionModal
											containerState={this.state}
											setContainerState={(state) => this.setState(state)}
											infoList={this.state.infoList}
											handleSubmit={this.handleRealEstateSubmit}
										/>
									)}

									<div className='col-12'>
										<Typography variant='h6'>Įkeičiamo nekilnojamojo turto informacija</Typography>
										<form
											onSubmit={(event) => {
												event.preventDefault();
												this.handleRealEstateSubmit();
											}}
										>
											<div className='row'>
												<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
													<FormControl fullWidth margin='dense'>
														<InputLabel htmlFor='occupationType'>Tipas</InputLabel>
														<NativeSelect
															required
															controlId='realEstateType'
															onChange={this.handleChange}
															value={this.state.realEstateType}
															inputProps={{
																name: 'realEstateType',
																id: 'realEstateType',
															}}
														>
															<option value='' selected disabled></option>
															<option value='flat'>Butas</option>
															<option value='house'>Namas</option>
															<option value='office'>Biuras</option>
															<option value='warehouse'>Sandėlis</option>
															<option value='commercial-facilities'>Komercinės patalpos</option>
															<option value='investment-object'>Investicinis objektas</option>
															<option value='production-facilities'>Gamybinės patalpos</option>
															<option value='land'>Žemė</option>
														</NativeSelect>
													</FormControl>
												</div>

												<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
													<FormControl fullWidth margin='dense'>
														<InputLabel htmlFor='occupationType'>Savivaldybė</InputLabel>
														<NativeSelect
															required
															controlId='realEstateMunicipality'
															onChange={this.handleChange}
															value={this.state.realEstateMunicipality}
															inputProps={{
																name: 'realEstateMunicipality',
																id: 'realEstateMunicipality',
															}}
														>
															<option value='' selected disabled></option>
															{this.state.municipalities.map((municipality, index) => {
																if (municipality === '') {
																	return (
																		<option key={index} disabled={true}>
																			{' '}
																		</option>
																	);
																}

																return (
																	<option key={index} value={municipality}>
																		{municipality}
																	</option>
																);
															})}
														</NativeSelect>
													</FormControl>
												</div>
												<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
													<TextField
														required
														type='text'
														fullWidth
														name='realEstateCity'
														label='Miestas'
														onChange={this.handleChange}
														value={this.state.realEstateCity}
														margin='dense'
													/>
												</div>
												<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
													<TextField
														required
														type='text'
														fullWidth
														name='realEstatePostCode'
														label='Pašto kodas'
														onChange={this.handleChange}
														value={this.state.realEstatePostCode}
														margin='dense'
													/>
												</div>
												<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
													<TextField
														required
														type='text'
														fullWidth
														name='realEstateAddress'
														label='Adresas'
														onChange={this.handleChange}
														value={this.state.realEstateAddress}
														margin='dense'
													/>
												</div>
												<div className='col-12 text-right'>
													<Button
														variant='contained'
														color='primary'
														className='my-2'
														type='submit'
													>
														Patvirtinti
													</Button>
												</div>
											</div>
										</form>
									</div>
								</div>
							)}
						</div>
					) : request.status === 'waiting_partner_info' ? (
						<WaitingPartnerInfoForm request={request} />
					) : request.status === 'unavailable' ? (
						<div className='container-fluid py-3 px-4'>
							<Typography>
								Apgailestaujame, nė viena kredito bendrovė paskolos Jums suteikti negali, kadangi
								Jūsų paraiška neatitinka kredito bendrovių reikalavimų.
							</Typography>
						</div>
					) : request.status === 'signed' ? (
						<div className='p-4 text-center'>
							<Typography variant='h4'>Jūsų paskola sėkmingai išduota!</Typography>
							<Typography>Ačiū, kad naudojatės Credit King paslaugomis.</Typography>

							<br />

							<div className='row'>
								<div
									className='col-lg-10 offset-lg-1 col-md-12 offset-md-0'
									style={{
										minWidth: '300px',
										overflowX: 'scroll',
									}}
								>
									<table className='table w-100'>
										<thead>
											<tr>
												<th scope='col'>Išduota suma</th>
												<th scope='col'>Sutarties mokestis</th>

												{request.signedInterestRate && request.signedBVKKMN ? (
													<>
														<th scope='col'>Palūkanos</th>
														<th scope='col'>BVKKMN</th>
													</>
												) : (
													''
												)}

												<th scope='col'>Paskolos laikotarpis</th>
												<th scope='col'>Menesinė įmoka</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope='row'>{request.signedAmount}€</th>
												<td>{request.signedFee}€</td>

												{request.signedInterestRate && request.signedBVKKMN ? (
													<>
														<td>{request.signedInterestRate}%</td>
														<td>{request.signedBVKKMN || '0'}%</td>
													</>
												) : (
													''
												)}

												<td>{request.signedLength} mėn.</td>
												<td>{request.signedMonthlyPayment} € / mėn.</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					) : (
						<>
							<div className='container-fluid'>
								<div className='row credit-request-table-heading mb-1 credit-requests-container-content py-3 px-4'>
									<div className='col-2'>
										<Typography>Kompanija</Typography>
									</div>
									<div className='col-2'>
										<Typography>Pasiūlymas</Typography>
									</div>
									<div className='col-2'>
										<Typography>Terminas</Typography>
									</div>
									<div className='col-2'>
										<Typography>Palūkanos</Typography>
									</div>
									<div className='col-2'>
										<Typography>Įmoka</Typography>
									</div>
									<div className='col-2'>
										<Typography className='text-center'>Statusas</Typography>
									</div>
								</div>
							</div>
							<hr className='request-table-seperator credit-requests-container-content' />
							{request.availableConfirmations.map((availableConfirmation, index) => {
								return (
									<CompanyConfirmation
										key={index}
										confirmation={availableConfirmation.creditConfirmation}
										creditCompany={availableConfirmation}
										request={request}
										$helper={this.props.$helper}
									/>
								);
							})}
						</>
					)}
				</Paper>
			</div>
		);
	}
}

export default Offers;
