import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';

class Overview extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: true,
			period: 7,
			dateFrom: moment().subtract(7, 'd').format('YYYY-MM-DD'),
			dateTo: moment(Date.now()).format('YYYY-MM-DD'),
			allRequests: [],
			confirmedRequests: [],
			signedRequests: [],
			requestsOverview: [],
			requestAmounts: {},
		};
	}

	componentWillMount() {
		this.getChartData();
	}

	componentDidMount() {
		document.title = 'Credit King - apžvalga';
	}

	getRequests = (type, stateObject) => {
		return new Promise((resolve, reject) => {
			fetch(
				process.env.REACT_APP_API +
					'/api/requests/overview?type=' +
					type +
					'&period=' +
					this.state.period +
					'&dateFrom=' +
					this.state.dateFrom +
					'T00:00:00.000Z' +
					'&dateTo=' +
					this.state.dateTo +
					'T23:59:59Z',
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: localStorage.getItem('token'),
					},
				},
			)
				.then((response) => response.json())
				.then(async (response) => {
					// if (Array.isArray(response)) {
					// 	response = await response.map((r) => {
					// 		r[0] = new Date(r[0]);

					// 		return r;
					// 	});
					// }

					this.setState({
						[stateObject]: response,
					});

					resolve();
				});
		});
	};

	getMixedOverview = () => {
		let { allRequests, confirmedRequests, signedRequests } = this.state;
		let requestsOverview = [];

		for (let i = 0; i < allRequests.length; i++) {
			requestsOverview.push({
				// name: allRequests[i][0],
				name: moment(new Date(allRequests[i][0])).format('YYYY-MM-DD'),
				'Visos paraiškos': allRequests[i][1],
				'Identifikuotos paraiškos': confirmedRequests[i][1],
				'Pasirašytos paraiškos': signedRequests[i][1],
			});
		}

		this.setState({
			requestsOverview,
		});
	};

	getChartData = async () => {
		this.setState({
			isLoading: true,
		});

		await this.getRequests('all', 'allRequests');
		await this.getRequests('confirmed', 'confirmedRequests');
		await this.getRequests('signed', 'signedRequests');
		await this.getRequests('amount', 'requestAmounts');
		this.getMixedOverview();

		this.setState({
			isLoading: false,
		});
	};

	handleChange = async (event) => {
		await this.setState({
			[event.target.name]: event.target.value,
		});

		this.getChartData();
	};

	render() {
		return (
			<>
				<h1 className='app-page-title'>Apžvalga</h1>
				<Paper style={{ maxHeight: '80vh', width: '100%', overflowX: 'hidden' }} className='p-2'>
					<div className='row px-2'>
						<div className='col-8 pt-2'>
							<Chip
								style={{
									background: 'rgb(66, 133, 244)',
									color: 'white',
								}}
								label={
									'Visų paraiškų kiekis: ' +
									(this.state.requestAmounts.allRequests !== undefined
										? this.state.requestAmounts.allRequests
										: '-')
								}
								className='mx-2 my-2'
							/>
							<Chip
								style={{
									background: 'rgb(219, 68, 55)',
									color: 'white',
								}}
								label={
									'Identifikuotų paraiškų kiekis: ' +
									(this.state.requestAmounts.confirmedRequests !== undefined
										? this.state.requestAmounts.confirmedRequests
										: '-')
								}
								className='mx-2 my-2'
							/>
							<Chip
								style={{
									background: 'rgb(244, 180, 0)',
									color: 'white',
								}}
								label={
									'Pasirašytų paraiškų kiekis: ' +
									(this.state.requestAmounts.signedRequests !== undefined
										? this.state.requestAmounts.signedRequests
										: '-')
								}
								className='mx-2 my-2'
							/>
							<Chip
								color='primary'
								label={
									'Pasirašytų paskolų suma: ' +
									(this.state.requestAmounts.signedAmount !== undefined
										? this.state.requestAmounts.signedAmount
										: '- ') +
									'€'
								}
								className='mx-2 my-2'
							/>
						</div>
						<div className='col-4 text-right pt-2'>
							<div className='row'>
								<div className='col-6'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='normal'
												label='Nuo'
												name='from'
												className='m-0'
												value={this.state.dateFrom}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'dateFrom',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</div>
								<div className='col-6'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div justify='space-around'>
											<DatePicker
												fullWidth
												margin='normal'
												label='Iki'
												name='to'
												className='m-0'
												value={this.state.dateTo}
												onChange={(date) =>
													this.handleChange({
														target: {
															name: 'dateTo',
															value: moment(date).format('YYYY-MM-DD'),
														},
													})
												}
												format={'yyyy-MM-dd'}
											/>
										</div>
									</MuiPickersUtilsProvider>
								</div>
							</div>
						</div>

						{this.state.isLoading && (
							<div className='col-12 mt-3'>
								<LinearProgress />
							</div>
						)}

						{!this.state.isLoading && (
							<div class='col-12 mt-3'>
								<ResponsiveContainer width={'95%'} height={400}>
									<LineChart data={this.state.requestsOverview}>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis dataKey='name' />
										<YAxis />
										<Tooltip />
										<Legend />
										<Line type='monotone' dataKey='Visos paraiškos' stroke='#4286f4' />
										<Line type='monotone' dataKey='Identifikuotos paraiškos' stroke='#db4437' />
										<Line type='monotone' dataKey='Pasirašytos paraiškos' stroke='#f4b400' />
									</LineChart>
								</ResponsiveContainer>
							</div>
						)}
					</div>
				</Paper>
			</>
		);
	}
}

export default Overview;
