import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../shared/loader';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepLabel from '@material-ui/core/StepLabel';
import QueryString from 'query-string';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { Error, ArrowForwardIos } from '@material-ui/icons';
import Script from 'react-load-script';
import SmartidConfirmationModal from './modal/smartid-confirmation-modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Confirmation method logos
import ElektroninisLogo from '../../../../assets/img/confirmation-methods/elektroninis-parasas.png';
import SmartidLogo from '../../../../assets/img/confirmation-methods/smartid.png';
import SwedbankLogo from '../../../../assets/img/confirmation-methods/swedbank.png';
import SebLogo from '../../../../assets/img/confirmation-methods/seb.png';
import CitadeleLogo from '../../../../assets/img/confirmation-methods/citadele.png';
import LuminorDnbLogo from '../../../../assets/img/confirmation-methods/luminor-dnb.png';
import LuminorNordeaLogo from '../../../../assets/img/confirmation-methods/luminor-nordea.png';
import MedicinosBankasLogo from '../../../../assets/img/confirmation-methods/medicinos-bankas.png';
import SiauliuBankasLogo from '../../../../assets/img/confirmation-methods/siauliu-bankas.png';
import PayseraLogo from '../../../../assets/img/confirmation-methods/paysera.png';

class RequestConfirmation extends Component {
	constructor(props) {
		super();

		this.state = {
			request: false,
			activeStep: 0,
			completed: {},
			isConfirming: false,
			isLoading: false,
			transferModalOpen: false,
			bank: null,
		};

		this.props = props;
		const queryParams = QueryString.parse(props.location.search);

		if (queryParams.success == 'false') {
			toast.error('Paraiškos patvirtinti nepavyko, bandykite dar kartą');
		} else if (queryParams.success == 'true') {
			toast.success('Transakcija sėkmingai atlikta');
		}
	}

	async componentWillMount() {
		await this.getRequest();

		this.interval = setInterval(() => {
			this.getRequest();
		}, 20000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getRequest = () => {
		try {
			var requestID = this.props.match.params.id;
		} catch (error) {
			toast.error('Serverio klaida');
		}

		return fetch(process.env.REACT_APP_API + '/api/requests/' + requestID, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response) {
					toast.error('Paraiška neegzistuoja');

					this.props.$helper.initRedirect('/');
				} else {
					if (response.error) {
						toast.error(response.error);

						this.props.$helper.initRedirect('/');
					} else {
						const request = response[0];
						let isConfirming = false;

						if (
							request &&
							request.personalConfirmation &&
							request.personalConfirmation.isProcessing &&
							!request.personalConfirmation.completed
						)
							isConfirming = true;

						if (
							request &&
							request.partnerConfirmation &&
							request.partnerConfirmation.isProcessing &&
							!request.partnerConfirmation.completed
						)
							isConfirming = true;

						this.setState({
							request,
							isConfirming,
						});
					}
				}
			});
	};

	handleNeopay = (requestConfirmation, bank) => {
		this.setState({
			isLoading: true,
		});

		fetch(
			process.env.REACT_APP_API +
				'/api/requestConfirmations/' +
				requestConfirmation._id +
				'/neopay/identification',
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token'),
				},
				body: JSON.stringify({
					bank: this.state.bank,
				}),
			},
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.error) {
					return toast.error('Nepavyko inicijuoti pavedimo');
				}

				const NEOWidgetHost = 'https://psd2.neopay.lt';
				const { NEOWidget } = window;

				NEOWidget.initialize(NEOWidgetHost, response.token, response.paymentInfo);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	componentDidMount() {
		document.title = 'Credit King - tapatybės patvirtinimas';
	}

	render() {
		if (!this.state.request) {
			return <Loader />;
		}

		let activeStep = 0;
		let { request } = this.state;

		if (request.personalConfirmation.completed) {
			activeStep++;
			if (request.maritalStatus === 'married' && request.partnerConfirmation.completed) {
				activeStep++;
			}
		}

		const confirmationMethods = [
			{
				title: 'Elektroninis parašas',
				description: 'Tapatybė bus patvirtinta iš karto.',
				logo: ElektroninisLogo,
				onClick: () => {
					window.location.href =
						process.env.REACT_APP_API +
						`/api/requestConfirmations/${
							activeStep === 0 ? request.personalConfirmation._id : request.partnerConfirmation._id
						}/url`;
				},
			},
			{
				title: 'Smart-ID',
				description:
					'Tapatybė bus patvirtinta iš karto. Reikalinga „Smart-ID“ paskyra (jeigu turite „Smart-ID Basic” paskyrą, šiuo būdu tapatybės patvirtinti negalėsite).',
				logo: SmartidLogo,
				onClick: () => {
					this.setState({
						smartidConfirmationModalOpen: true,
					});
				},
			},
			{
				title: 'Swedbank',
				description: 'Tapatybės patvirtinimas užtruks iki 1 minutės.',
				logo: SwedbankLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: 'Swedbank',
					});
				},
			},
			{
				title: 'SEB',
				description: 'Tapatybės patvirtinimas užtruks iki 1 minutės.',
				logo: SebLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: 'Seb',
					});
				},
			},
			{
				title: 'Luminor DNB',
				description:
					'Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.',
				logo: LuminorDnbLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: null,
					});
				},
			},
			{
				title: 'Luminor Nordea',
				description:
					'Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.',
				logo: LuminorNordeaLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: null,
					});
				},
			},
			{
				title: 'Citadele',
				description:
					'Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.',
				logo: CitadeleLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: null,
					});
				},
			},
			{
				title: 'Medicinos bankas',
				description:
					'Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.',
				logo: MedicinosBankasLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: null,
					});
				},
			},
			{
				title: 'Šiaulių bankas',
				description:
					'Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.',
				logo: SiauliuBankasLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: null,
					});
				},
			},
			{
				title: 'Paysera',
				description:
					'Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.',
				logo: PayseraLogo,
				onClick: () => {
					this.setState({
						transferModalOpen: true,
						bank: null,
					});
				},
			},
		];

		return (
			<div>
				<Script url='https://psd2.neopay.lt/widget.js' />

				<h1 className='app-page-title'>Paraiškos patvirtinimas</h1>
				<Paper>
					<div className='text-center row'>
						<div className='col-lg-8 offset-lg-2 col-md-12 offset-md-0'>
							{request.maritalStatus === 'not-married' ? (
								<Stepper alternativeLabel activeStep={activeStep}>
									<Step key={'0'}>
										{/* <StepButton disabled completed={request.personalConfirmation.completed} /> */}
										<StepLabel>Asmeninis patvirtinimas</StepLabel>
									</Step>

									<Step key={'1'}>
										<StepLabel>Pasiūlymų gavimas</StepLabel>
									</Step>
								</Stepper>
							) : (
								<>
									<Stepper
										activeStep={activeStep}
										orientation='vertical'
										className='mobile-content'
									>
										<Step key={'0'}>
											{/* <StepButton disabled completed={request.personalConfirmation.completed} /> */}
											<StepLabel>Asmeninis patvirtinimas</StepLabel>
										</Step>
										<Step key={'1'}>
											<StepLabel>Sutuoktinio patvirtinimas</StepLabel>
										</Step>
										<Step key={'2'}>
											<StepLabel>Pasiūlymų gavimas</StepLabel>
										</Step>
									</Stepper>
									<Stepper alternativeLabel activeStep={activeStep} className='desktop-content'>
										<Step key={'0'}>
											{/* <StepButton disabled completed={request.personalConfirmation.completed} /> */}
											<StepLabel>Asmeninis patvirtinimas</StepLabel>
										</Step>
										<Step key={'1'}>
											<StepLabel>Sutuoktinio patvirtinimas</StepLabel>
										</Step>
										<Step key={'2'}>
											<StepLabel>Pasiūlymų gavimas</StepLabel>
										</Step>
									</Stepper>
								</>
							)}

							{this.state.isConfirming ? (
								<div>
									<CircularProgress className='mt-2 mb-2' size={60} />
									<div className='mb-4 mt-2'>
										<Typography>
											Prašome palaukti, šiuo metu vyksta tapatybės patvirtinimas. Tapatybės
											patvirtinimas gali užtrukti iki 1 valandos.
										</Typography>
									</div>
								</div>
							) : (
								<>
									{(request.maritalStatus === 'married' && activeStep <= 1) ||
									(request.maritalStatus === 'not-married' && activeStep === 0) ? (
										<div className='px-4 text-center'>
											{request.maritalStatus === 'married' && activeStep === 1 ? (
												<>
													<Typography>
														Paskutinis žingsnis, kuris Jus skiria nuo palankiausių paskolų pasiūlymų
														- sutuoktinio tapatybės patvirtinimas. Remiantis Lietuvos Respublikos
														įstatymais, prieš pateikdamos asmeninius pasiūlymus, kredito įstaigos
														privalo gauti sutuoktinio tapatybės patvirtinimą ir sutuoktinio sutikimą
														dėl asmens duomenų tvarkymo. Su šiuo sutikimu galite susipažinti{' '}
														<a
															href='https://creditking.lt/wp-content/uploads/2020/08/SUTUOKTINIO-SUTIKIMAS-DĖL-ASMENS-DUOMENŲ-TVARKYMO-V12.pdf'
															target='_blank'
														>
															čia
														</a>
														.
													</Typography>
													<br />
													<Typography>
														Prašome Jūsų sutuoktinį patvirtinti tapatybę elektroniniu parašu,
														Smart-ID arba atliekant 0,01 € mokėjimą per Paysera sistemą (atlikus
														pavedimą, prašome neišjungti mokėjimo lango). Nuorodas į tapatybės
														patvirtinimą Jūsų sutuoktiniui išsiuntėme el. paštu. Nuoroda galioja 48
														valandas nuo el. laiško gavimo.
													</Typography>
												</>
											) : (
												<>
													<Typography>
														Ačiū, kad užpildėte paraišką! Tam, kad mūsų partneriai galėtų pateikti
														geriausią asmeninį pasiūlymą, prašome patvirtinti savo tapatybę
														elektroniniu parašu, Smart-ID arba atliekant 0,01 € mokėjimą iš savo
														banko sąskaitos (atlikus pavedimą, prašome neišjungti mokėjimo lango).
													</Typography>
													<br />
													<Typography>
														Nepatvirtinus savo tapatybės, partneriai pasiūlymo pagal užpildytą
														paraišką pateikti negalės.
													</Typography>
												</>
											)}

											<div className='row my-4'>
												{(request.personalConfirmation && request.personalConfirmation.error) ||
												(request.partnerConfirmation && request.partnerConfirmation.error) ? (
													<div className='col-12 mt-2 mb-2 text-center'>
														<small style={{ color: 'red' }}>
															Apgailestaujame, tapatybės patvirtinti nepavyko. Patikrinkite, ar
															paraiškoje nurodytas asmens kodas yra teisingas ir bandykite dar
															kartą.
														</small>
													</div>
												) : (
													''
												)}

												<div className='col-12'>
													<Table aria-label='confirmations table' padding='dense'>
														<TableBody>
															{confirmationMethods.map((method, index) => (
																<TableRow
																	key={index}
																	hover
																	onClick={method.onClick}
																	style={{
																		cursor: 'pointer',
																	}}
																>
																	<TableCell
																		align='center'
																		className='confirmation-method-logo-col d-none d-md-table-cell'
																		style={{
																			padding: '15px 5px',
																		}}
																	>
																		<img
																			src={method.logo}
																			className='confirmation-method-logo'
																			alt={method.title}
																		/>
																	</TableCell>
																	<TableCell
																		align='left'
																		style={{
																			padding: '15px 5px',
																		}}
																	>
																		<div className='d-md-none text-center my-3'>
																			<img
																				src={method.logo}
																				className='img-fluid'
																				style={{
																					maxHeight: 50,
																				}}
																				alt={method.title}
																			/>
																		</div>

																		{method.title}
																		<br />
																		<small>{method.description}</small>
																	</TableCell>
																	<TableCell
																		align='right'
																		className='d-none d-md-table-cell'
																		style={{
																			padding: '15px 5px',
																		}}
																	>
																		<ArrowForwardIos style={{ fontSize: 12 }} />
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>

													{this.state.smartidConfirmationModalOpen && (
														<SmartidConfirmationModal
															requestConfirmation={
																activeStep === 0
																	? request.personalConfirmation
																	: request.partnerConfirmation
															}
															handleClose={() =>
																this.setState({
																	smartidConfirmationModalOpen: false,
																})
															}
														/>
													)}

													<Dialog
														maxWidth={'sm'}
														open={this.state.transferModalOpen}
														onClose={() => {
															this.setState({
																transferModalOpen: false,
															});
														}}
														aria-labelledby='form-dialog-title'
													>
														<DialogTitle id='form-dialog-title'>
															Reikalingas asmens kodas
														</DialogTitle>
														<DialogContent className='text-justify' id='dialog-content-scrollable'>
															<DialogContentText className='text-center mb-2'>
																<Error
																	style={{
																		fontSize: '125px',
																	}}
																/>
															</DialogContentText>
															{/* <div className='text-center mb-2'>/></div> */}
															Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens
															kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus
															patvirtinta.
														</DialogContent>
														<DialogActions>
															<Button
																onClick={() => {
																	this.setState({
																		transferModalOpen: false,
																	});
																}}
																color='primary'
															>
																Atšaukti
															</Button>

															<Button
																color='primary'
																disabled={this.state.isLoading}
																{...(this.state.bank
																	? {
																			onClick: () =>
																				this.handleNeopay(
																					activeStep === 0
																						? request.personalConfirmation
																						: request.partnerConfirmation,
																					this.state.bank,
																				),
																	  }
																	: {
																			href:
																				activeStep === 0
																					? request.personalConfirmation.href
																					: request.partnerConfirmation.href,
																	  })}
															>
																Tęsti
															</Button>
														</DialogActions>
													</Dialog>
												</div>
											</div>

											{this.state.isConfirming && <LinearProgress className='my-2' />}
										</div>
									) : (
										<div className='px-4 py-3'>
											<Typography className='text-center'>
												Sveikiname! Jūsų paraiška patvirtinta ir sėkmingai perduota mūsų
												partneriams. Netrukus Jus pasieks{' '}
												<strong>geriausi asmeniniai kredito pasiūlymai</strong>. Gautus pasiūlymus
												galite matyti prisijungę prie savo asmeninės paskyros. Apie pirmąjį gautą
												pasiūlymą informuosime ir trumpąja SMS žinute, apie vėliau gautus pasiūlymus
												- el. paštu.
											</Typography>

											<div className='row'>
												<div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 offset-0 mt-3'>
													<Button
														className='request-confirmation-button'
														fullWidth
														variant='contained'
														color='primary'
														href={'/requests/' + this.props.match.params.id}
													>
														Grįžti į paraišką
													</Button>
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

export default RequestConfirmation;
