import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class RequestDeclineModal extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			reason: '',
			dropdownValue: '',
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});

		const { reason } = this.state;

		if (!reason || reason.length < 1) {
			return true;
		} else return false;
	};

	handleDropdownChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			reason: event.target.value === 'Kita' ? '' : event.target.value,
		});
	};

	handleEnter = () => {
		this.props
			.takeRequestOver()
			.then(() => {
				this.interval = setInterval(() => {
					this.props.takeRequestOver();
				}, 5000);
			})
			.catch(() => {
				this.handleClose();
			});
	};
	handleClose = () => {
		this.props.setContainerState({
			requestDeclineModalOpen: false,
		});

		clearInterval(this.interval);
		this.props.deleteRequestTakeover();
	};

	handleDecline = (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let containerState = this.props.getContainerState();

		fetch(process.env.REACT_APP_API + '/api/creditConfirmations', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({
				creditRequestID: containerState.request._id,
				type: 'decline',
				reason: this.state.reason,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					this.handleClose();
					toast.success('Sėkmingai atnaujinta');

					// refresh requests
					this.props.getRequests('', false, true);
				} else {
					toast.error('Nepavyko atnaujinti');
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	render() {
		let containerState = this.props.getContainerState();

		const availableDeclineOptions = [
			'Pradelsti mokėjimai',
			'Bloga kredito istorija',
			'Nepakankamos pajamos',
			'Paskolos suteikiamos tik pastovias ir oficialias pajamas gaunantiems asmenims',
			'Trūksta sutuoktinio sutikimo (kreditas tik šeimos reikmėms)',
			'Neatitinka kreditingumo vertinimo taisyklių',
			'Deklaruota gyvenamoji vieta ne Lietuvoje',
			'Asmens dokumentas negalioja',
			'Išnaudotas kredito limitas',
			'Viešai registruotos skolos',
			'DTI - viršytas įsipareigojimų ir pajamų santykis',
			'Esamas klientas',
			'Reikalingas sutuoktinio sutikimas',
			'Kita',
		];

		return (
			<Dialog
				open={containerState.requestDeclineModalOpen}
				onClose={this.handleClose}
				onEntering={this.handleEnter}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Atmetimas</DialogTitle>
				<DialogContent>
					<DialogContentText>Atmetus paraišką, vartotojas bus informuotas.</DialogContentText>

					<div className='row'>
						<div className='col-12'>
							<Select
								fullWidth
								label='Pasirinktie priežastį (nebūtina)'
								value={this.state.dropdownValue}
								onChange={this.handleDropdownChange}
								inputProps={{
									name: 'dropdownValue',
									id: 'dropdownValue',
								}}
							>
								<MenuItem value='' defaultValue></MenuItem>

								{availableDeclineOptions.map((option, index) => (
									<MenuItem value={option} key='index'>
										{option}
									</MenuItem>
								))}
							</Select>
						</div>

						{this.state.dropdownValue === 'Kita' && (
							<div className='col-12'>
								<TextField
									margin='dense'
									id='reason'
									onChange={this.handleChange}
									value={this.state.reason} onChange={(e) => this.setState({reason: e.target.value})}
									// value={this.state.reason}
									label='Priežastis'
									type='text'
									multiline
									fullWidth
								/>
							</div>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleDecline} color='primary' disabled={!this.state.reason}>
						Patvirtinti
					</Button>
					<Button onClick={this.handleClose} color='primary'>
						Atšaukti
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default RequestDeclineModal;
